<template>
  <f7-page :page-content="false" @page:init="onPageInit" @page:beforeremove="onPageBeforeRemove" class="my-bg-color">
    <f7-navbar class>
      <f7-nav-left back-link="Back"></f7-nav-left>
      <f7-nav-title>Tạo Yêu Cầu Phân Tích</f7-nav-title>
    </f7-navbar>
    <f7-fab position="right-bottom" slot="fixed" text="Tạo YC" @click="runCreateYCPT()">
      <f7-icon ios="f7:checkmark" aurora="f7:checkmark" md="material:checkmark"></f7-icon>
    </f7-fab>
    <f7-tabs>
      <f7-tab class="page-content" tab-active style="padding-top: 28px">
        <f7-block-title>Thông tin phân tích</f7-block-title>
        <div class="card">
          <f7-list no-hairlines-md>
            <f7-list-item
              class="my-title-font-size"
              title="Đơn vị yêu cầu (*):"
              smart-select
              :smart-select-params="{openIn: 'popup',searchbar: true, searchbarPlaceholder: 'Search', scrollToSelectedItem: true, closeOnSelect: true, virtualList: true}"
            >
              <select name="phongBanLab" v-model="chiTietYCPT.khachHangId">
                <option
                  :key="index"
                  v-for="(item, index) in dsKhachHangLab"
                  :value="item.id"
                  :data-display-as="item.hoTen"
                >{{cleanAccents(item.shortname?item.hoTen+' ('+item.shortname+')':item.hoTen)}}</option>
              </select>
            </f7-list-item>
            <f7-list-input
              label="Số chứng thư Vụ Giám Định (*):"
              placeholder
              type="text"
              :value="chiTietYCPT.soChungThuVuGiamDinh"
              @input="chiTietYCPT.soChungThuVuGiamDinh = $event.target.value"
            ></f7-list-input>
            <f7-list-input
              label="Nơi lấy mẫu :"
              type="text"
              placeholder
              :value="chiTietYCPT.noiLayMau"
              @input="chiTietYCPT.noiLayMau = $event.target.value"
            ></f7-list-input>
            <f7-list-item
              class="my-title-font-size"
              title="Trung tâm (*):"
              smart-select
              :smart-select-params="{openIn: 'sheet', closeOnSelect: true}"
            >
              <select name="phongBanLab" v-model="chiTietYCPT.phongBanId">
                <option
                  :key="index"
                  v-for="(item, index) in dsPhongBanLab"
                  :value="item.id"
                >{{item.ten}}</option>
              </select>
            </f7-list-item>
            <f7-list-input
              label="Ngày muốn nhận KQPT:"
              type="date"
              placeholder
              :value="ngayMuonNhanKQPT"
              @input="ngayMuonNhanKQPT = $event.target.value"
            ></f7-list-input>
          </f7-list>
        </div>
        <f7-block-title>
          Danh sách mẫu :
          <span style="color: green">{{itemsThongTinPhanTich.length}}</span>
        </f7-block-title>
        <div class="card">
          <f7-button medium raised fill style="margin-top: 15px; height: 40px; line-height: 40px;">
            <f7-link
              class="fs-roboto-bold text-color-white"
              style="width: 100%;"
              @click="runThemMau()"
              link
            >Thêm mẫu</f7-link>
          </f7-button>
        </div>
        <div class="card">
          <f7-list>
            <f7-list-item
              swipeout
              :key="index"
              v-for="(item, index) in itemsThongTinPhanTich"
              :title="item.soNiem?item.tenMau + '/'+ item.soNiem:item.tenMau"
            >
              <f7-swipeout-actions right>
                <!-- <f7-swipeout-button color="orange">Sửa</f7-swipeout-button> -->
                <f7-swipeout-button @click="removeMau(index)" delete>Xóa</f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </div>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import TrangThai from "./../../common.js";
var dateFns = require("date-fns");
import NavBar from "./../components/NavBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
import { EventLogin } from "../event-login.js";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      isLoading: true,
      dsPhongBanLab: [],
      ngayMuonNhanKQPT: "",
      itemThongTinPhanTich: {},
      itemsThongTinPhanTich: [],
      dsBoChiTieu: [],
      enableUpdateButton: false,
      popupOpened: false,
      dsMau: [],
      chiTietYCPT: {},
      clickChangeTenCongViec: false,
    };
  },
  updated() {},
  mounted() {
    EventLogin.$on("themMauYCPT", (itemThongTinPhanTich) => {
      this.itemsThongTinPhanTich.push(itemThongTinPhanTich);
      console.log(
        "viet eventlogin this.itemsThongTinPhanTich",
        this.itemsThongTinPhanTich
      );
    });
  },
  methods: {
    cleanAccents(str) {
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
      str = str.replace(/Đ/g, "D");
      // Combining Diacritical Marks
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // huyền, sắc, hỏi, ngã, nặng
      str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // mũ â (ê), mũ ă, mũ ơ (ư)

      return str;
    },
    onPageInit() {},
    openAlert(titlename, cb) {
      const app = this.$f7;
      app.dialog.alert("" + titlename, "Thông báo", cb);
    },
    openPreloader(titlename, time) {
      const app = this.$f7;
      app.dialog.preloader(titlename);
      setTimeout(function () {
        app.dialog.close();
      }, time);
    },
    removeMau(index) {
      this.itemsThongTinPhanTich.splice(index, 1);
    },
    validateYCPT(ycpt) {
      let check = false;
      if (!ycpt) {
        this.openAlert("Yêu cầu phân tích không tồn tại");
        return check;
      }

      if (!ycpt.khachHangId) {
        this.openAlert("Bạn chưa chọn Khách hàng");
        return check;
      }
      if (!ycpt.soChungThuVuGiamDinh) {
        this.openAlert("Bạn chưa nhập Số chứng thư vụ giám định");
        return check;
      }
      if (!ycpt.phongBanId) {
        this.openAlert("Bạn chưa chọn Trung tâm phân tích");
        return check;
      }
      if (!this.itemsThongTinPhanTich.length) {
        this.openAlert("Bạn chưa thêm Mẫu phân tích");
        return check;
      }
      check = true;
      return check;
    },
    async runCreateYCPT() {
      let check = this.validateYCPT(this.chiTietYCPT);
      if (!check) {
        return;
      }
      this.openPreloader("Đang thực hiện...", 3000);

      let count = 0;

      for (const item of this.itemsThongTinPhanTich) {
        try {
          let ycpt = {};
          ycpt.ngayYeuCau = new Date();
          ycpt.khachHangId = this.chiTietYCPT.khachHangId;
          ycpt.phongBanId = this.chiTietYCPT.phongBanId;
          ycpt.noiLayMau = this.chiTietYCPT.noiLayMau;
          ycpt.soChungThuVuGiamDinh = this.chiTietYCPT.soChungThuVuGiamDinh;
          ycpt.vuGiamDinhId = this.chiTietYCPT.vuGiamDinhId;
          ycpt.tenMau = item.tenMau;
          ycpt.soNiem = item.soNiem;
          ycpt.idBoChiTieu = item.idBoChiTieu;
          ycpt.cacChiTieuPhanTich = item.cacChiTieuPhanTich;
          ycpt.ngayMuonNhanKQPT = new Date(this.ngayMuonNhanKQPT);
          let userInfo = JSON.parse(localStorage.getItem("eic_userInfo"));
          ycpt.nguoiYeuCauUserId = userInfo.userId;
          ycpt.trangthai = TrangThai.LAB_YCPT_ChoChapNhan_081;
          ycpt.lastModified = new Date();
          ycpt.lastUserId = userInfo.userId;

          let rs = await axios.post(apiEndPoint + "api/YeuCauPhanTiches", ycpt);
          await this.SaveHistory(
            80,
            rs.data.id,
            "Tạo Yêu Cầu Phân Tích (mqlgd)",
            ""
          );
          // if (rs) {
          //     this.openAlert("Thực hiện thành công!");
          // }
        } catch (error) {
          alert(error);
        }
        EventLogin.$emit("reloadPageHomeLab");
        this.$f7router.back();
      }
    },
    async SaveHistory(actionId, objectId, message, note) {
      let userInfo = JSON.parse(localStorage.getItem("eic_userInfo"));
      let history = {
        actionId: actionId,
        appUserId: userInfo.userId,
        objectId: objectId,
        objectType: "YeuCauPhanTich",
        date: new Date(),
        message: message,
        note: note,
      };
      try {
        let res = await axios.post(
          apiEndPoint + "api/ActionHistories",
          history
        );
        console.log("viet res", res);
      } catch (error) {
        alert(error);
      }
    },
    runThemMau() {
      this.$f7router.navigate("/them-mau-yeu-cau-phan-tich/");
    },
    openPopup() {
      console.log("viet open popup");
    },
    onPageBeforeRemove() {
      console.log("viet onPageBeforeRemove ");
      const self = this;
      // Destroy popup when page removed
      if (self.popup1) self.popup1.destroy();
    },
    getTenMauById(id) {
      let found = this.dsBoChiTieu.find((e) => e.id == id);
      if (!found) return "";
      return found.ten;
    },
    themMau() {
      this.itemThongTinPhanTich.tenMau = this.getTenMauById(
        this.itemThongTinPhanTich.idBoChiTieu
      );

      this.itemsThongTinPhanTich.push(this.itemThongTinPhanTich);
      this.itemThongTinPhanTich = { idBoChiTieu: null, soNiem: "" };
      this.$f7.popup.destroy();
      console.log("viet themMau", this.itemsThongTinPhanTich);
    },
    async getCongViecGiamDinhVien(item) {
      let url = apiEndPoint + "api/CongViecGiamDinhViens";
      let filter = {
        where: {
          vuGiamDinhId: item.id,
        },
      };
      try {
        let res = await axios.get(url, {
          params: {
            filter: filter,
          },
        });
        this.dsCongViecGDV = res.data;
        console.log("viet this.dsCongViecGDV", this.dsCongViecGDV);
      } catch (error) {
        alert(error);
      }
    },
    async loadChiTieuCon(id) {
      console.log("viet loadChiTieuCon", id);
      let url = apiEndPoint + "api/ChiTieuCons";
      let filter = { where: { boChiTieuId: id } };
      if (this.itemsThongTinPhanTich == undefined)
        this.itemsThongTinPhanTich = [];
      console.log(
        "viet this.itemsThongTinPhanTich",
        this.itemsThongTinPhanTich
      );
      let index = this.itemsThongTinPhanTich.findIndex(
        (e) => id == e.idBoChiTieu
      ); // kiểm tra xem đã có tên mẫu (bộ chỉ tiêu) chưa, nếu có rồi thì load lại các mục chọn chỉ tiêu phân tích.

      if (index != -1) {
        this.itemThongTinPhanTich.cacChiTieuPhanTich = this.itemsThongTinPhanTich[
          index
        ].cacChiTieuPhanTich;
        console.log("viet index!=-1", index);
      } else {
        try {
          let res = await axios.get(url, {
            params: {
              filter: filter,
            },
          });
          this.itemThongTinPhanTich.cacChiTieuPhanTich = res.data;
          console.log(
            "viet  this.itemThongTinPhanTich.cacChiTieuPhanTich",
            this.itemThongTinPhanTich.cacChiTieuPhanTich
          );
        } catch (error) {
          alert(error);
        }
        // Mặc định chọn hết các chỉ tiêu isSelected == true
        this.itemThongTinPhanTich.cacChiTieuPhanTich.forEach((e) => {
          if (e.tenChiTieu && e.tenChiTieu.length) {
            e.isSelected = true;
          } else {
            e.isSelected = false;
          }
        });
      }
    },
    async getDSBoChiTieu() {
      let url = apiEndPoint + "api/BoChiTieus";
      let filter = {
        fields: {
          id: true,
          ten: true,
        },
      };
      try {
        let res = await axios.get(url, {
          params: {
            filter: filter,
          },
        });
        this.dsBoChiTieu = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    getPhongBanByID(id) {
      let found = this.dsPhongBan.find((e) => e.id == id);
      if (!found) return "";
      return found;
    },
    getKhachHangByMa(maKH) {
      let found = this.dsKhachHangLab.find((e) => e.ma == maKH);
      if (!found) return "";
      return found;
    },
    getTenKhachHang(id) {
      let found = this.dsKhachHangLab.find((e) => e.id == id);
      if (!found) return "";
      return found.hoTen;
    },
    getTenPhongBan(id) {
      let found = this.dsPhongBan.find((e) => e.id == id);
      if (!found) return "";
      return found.ten;
    },
    getTenNhanVien(id) {
      let found = this.dsNhanVien.find((e) => e.id == id);
      if (!found) return "";
      return found.ten;
    },
    openFile(item) {
      window.open(item, "_system");
      return false;
    },
    formatDate(date) {
      var result = dateFns.format(new Date(date), "dd/MM/yyyy HH:mm");
      return result;
    },
  },
  async created() {
    this.dsPhongBan = JSON.parse(localStorage.getItem("eic_dsPhongBan"));
    this.dsPhongBanLab = this.dsPhongBan.filter((e) => e.boPhan == "LAB");
    this.dsKhachHangLab = JSON.parse(
      localStorage.getItem("eic_dsKhachHangLab")
    );
    this.dsNhanVien = JSON.parse(localStorage.getItem("eic_dsNhanVien"));
    await this.getDSBoChiTieu();
  },
};
</script>
