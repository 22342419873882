<style>
  .my-title-font-size{
      font-size: 12px;
  }
  .my-label-font-size{
      font-size: 14px;
  }
</style>
<template>
<f7-page :page-content="false" @page:init="onPageInit" class="my-bg-color">
    <f7-navbar class="">
        <f7-nav-left  back-link="Back"></f7-nav-left>
        <f7-nav-title>VGD số {{chiTietVGD.soChungThu}}</f7-nav-title>

    </f7-navbar>




     <!-- Toolbar FAB Morph Target -->
    <!-- <f7-toolbar bottom class="fab-morph-target fab-close">
      <div style="text-align: center">
        <f7-button  fill @click="runHoanThanhHT(chiTietVGD)" v-if="enableHoanThanhHT(chiTietVGD)">Hoàn thành HT</f7-button>
        <f7-button fill @click="runTiepTucTaiHT(chiTietVGD)" v-if="enableTiepTucTaiHT(chiTietVGD)">Tiếp tục tại HT</f7-button>
        <f7-button fill @click="runSave(chiTietVGD)" v-if="enableLuuDieuChinh(chiTietVGD)">Cập nhật</f7-button>
        </div>
    </f7-toolbar> -->


    <!-- FAB Right Bottom (Orange) -->
      <f7-fab position="right-bottom" slot="fixed" >
        <i class="fa fa-bars" aria-hidden="true"></i>
        <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
        <f7-fab-buttons position="top">
           <f7-fab-button :fab-close="true" label="Lưu cập nhật"  v-if="enableLuuDieuChinh(chiTietVGD)"  @click="runSave(chiTietVGD)" color="orange">
            <i class="fas fa-save" aria-hidden="true"></i>
          </f7-fab-button>
          <f7-fab-button :fab-close="true" label="Hoàn thành HT" v-if="enableHoanThanhHT(chiTietVGD)"  @click="runHoanThanhHT(chiTietVGD)" color="green">
             <i class="fas fa-briefcase" aria-hidden="true"></i>
          </f7-fab-button>
          <f7-fab-button :fab-close="true" label="Tiếp tục tại HT"  v-if="enableTiepTucTaiHT(chiTietVGD)"  @click="runTiepTucTaiHT(chiTietVGD)" color="yellow">
            <i class="fas fa-exchange-alt" aria-hidden="true"></i>
          </f7-fab-button>
          <f7-fab-button :fab-close="true" label="Trang chủ"    @click="veTrangChu()" color="lightblue">
            <i class="fa fa-home" aria-hidden="true"></i>
          </f7-fab-button>

        </f7-fab-buttons>
      </f7-fab>
        
        <div class="page-content" >
            <div class="list media-list no-margin no-hairlines" v-show="loadingVGD==true">
                                  <div v-for="n in 10" :key="n" :class="`card item-content skeleton-text skeleton-effect-${effect}`">
                                             <div class="item-inner">
                                                <div class="item-title-row">
                                                    <div class="item-title">
                                                        999999
                                                    </div>
                                                </div>
                                                <div class="item-text">
                                                   Lorem ipsum dolor,
                                                </div>
                                               
                                            </div>
                                    </div>
            </div>
            <div v-show="loadingVGD==false">
                <div class="card">
                    <f7-list  accordion-list>
                        <f7-list-item accordion-item title="1. Thông tin đối tác" >
                            <f7-accordion-content>
                                <f7-list no-hairlines-md>
                                    <f7-list-item class="my-title-font-size" title="Yêu cầu I (*):" smart-select :smart-select-params="{searchbar: true, searchbarPlaceholder: 'Search', scrollToSelectedItem: true, closeOnSelect: true, virtualList: true}">
                                        <div class="my-label-font-size" v-if="!clickChangeDoiTac"> {{getTenKhachHang(chiTietVGD.khachHangId)}}</div>
                                        <select name="khachHang1" v-model="chiTietVGD.khachHangId"  @change="onChange_DoiTacYeuCau(chiTietVGD.khachHangId)">
                                            <option :key="index" v-for="(item, index) in dsKhachHang" :value="item.id" :data-display-as="item.hoTen" >{{cleanAccents(item.shortname?item.hoTen+' ('+item.shortname+')':item.hoTen)}}</option>
                                        </select>
                                    </f7-list-item>
                                    <f7-list-item  class="my-title-font-size" title="Yêu cầu II :" smart-select  :smart-select-params="{searchbar: true, searchbarPlaceholder: 'Search', scrollToSelectedItem: true, closeOnSelect: true, virtualList: true}">
                                        <div class="my-label-font-size" v-if="!clickChangeDoiTacII"> {{getTenKhachHang(chiTietVGD.khachHangIdII)}}</div>
                                        <select name="khachHang2" v-model="chiTietVGD.khachHangIdII"  @change="onChange_DoiTacYeuCauII($event)">
                                            <option :key="index" v-for="(item, index) in dsKhachHang" :value="item.id" :data-display-as="item.hoTen" >{{cleanAccents(item.shortname?item.hoTen+' ('+item.shortname+')':item.hoTen)}}</option>
                                        </select>
                                    </f7-list-item>
                                    <f7-list-item  class="my-title-font-size" title="Liên quan I:" smart-select  :smart-select-params="{searchbar: true, searchbarPlaceholder: 'Search', scrollToSelectedItem: true, virtualList: true}">
                                        <div class="my-label-font-size" v-if="!clickChangeDoiTacLienQuanI"> {{getTenKhachHang(khachHangLienQuanIdI[0])}}</div>
                                        <select name="khachHangLienQuan1" multiple v-model="khachHangLienQuanIdI" @change="onChange_DoiTacLienQuanI($event)">
                                            <option :key="index" v-for="(item, index) in dsKhachHang" :value="item.id" :data-display-as="item.hoTen" >{{cleanAccents(item.shortname?item.hoTen+' ('+item.shortname+')':item.hoTen)}}</option>
                                        </select>
                                    </f7-list-item>
                                    <f7-list-item  class="my-title-font-size" title="Liên quan II:" smart-select  :smart-select-params="{searchbar: true, searchbarPlaceholder: 'Search',scrollToSelectedItem: true, virtualList: true}">
                                        <div class="my-label-font-size" v-if="!clickChangeDoiTacLienQuanII"> {{getTenKhachHang(khachHangLienQuanIdII[0])}}</div>
                                        <select name="khachHangLienQuan2" multiple v-model="khachHangLienQuanIdII" @change="onChange_DoiTacLienQuanII($event)">
                                            <option :key="index" v-for="(item, index) in dsKhachHang" :value="item.id" :data-display-as="item.hoTen" >{{cleanAccents(item.shortname?item.hoTen+' ('+item.shortname+')':item.hoTen)}}</option>
                                        </select>
                                    </f7-list-item>
                                </f7-list>
                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>
                </div>
                <div class="card">
                    <f7-list>
                        <f7-list-item accordion-item title="2. Thông tin hợp đồng">
                            <f7-accordion-content>
                                <f7-list no-hairlines-md>
                                <f7-list-item class="my-title-font-size"
                                    title="Hợp đồng của Đối tác:"
                                    smart-select
                                    :smart-select-params="{openIn: 'sheet', closeOnSelect: true}"
                                    >
                                    <div class="my-label-font-size" v-if="!clickChangeHopDong"> {{getTenHopDong(chiTietVGD.hDCungCapDichVuId)}}</div>
                                    <select
                                        name="hopDong"
                                        v-model="chiTietVGD.hDCungCapDichVuId"
                                        @change ="onChange_HopDong($event)"
                                    >
                                        <option
                                        :key="index"
                                        v-for="(item, index) in itemsHopDongKhachHang"
                                        :value="item.id"
                                        :data-display-as="item.soHopDong"
                                        >{{item.soHopDong+' (Ngày ký: '+formatDate2(item.ngayKy)+ ')'}}</option>
                                    </select>
                                    </f7-list-item>
                                </f7-list>
                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>
                </div>
                <div class="card">
                    <f7-list>
                        <f7-list-item accordion-item title="3. Thông tin đơn hàng">
                            <f7-accordion-content>
                                <f7-list no-hairlines-md>
                                    <f7-list-input label="Tên hàng (*):" type="text" placeholder="" :value="chiTietVGD.tenHang" @input="chiTietVGD.tenHang = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Mã ký hiệu :" type="text" placeholder="" :value="chiTietVGD.maKyHieu" @input="chiTietVGD.maKyHieu = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Số lượng :" type="number" placeholder="" :value="chiTietVGD.soLuong" @input="chiTietVGD.soLuong = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Đơn vị Số lượng :" type="text" placeholder="" :value="chiTietVGD.dVSoLuong" @input="chiTietVGD.dVSoLuong = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Khối lượng (Cả bì) :" type="number" placeholder="" :value="chiTietVGD.khoiLuong" @input="chiTietVGD.khoiLuong = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Đơn vị Khối lượng (Cả bì) :" type="text" placeholder="" :value="chiTietVGD.dVKhoiLuong" @input="chiTietVGD.dVKhoiLuong = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Khối lượng (tịnh) :" type="number" placeholder="" :value="chiTietVGD.khoiLuongTinh" @input="chiTietVGD.khoiLuongTinh = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Đơn vị Khối lượng (tịnh) :" type="text" placeholder="" :value="chiTietVGD.dVKhoiLuongTinh" @input="chiTietVGD.dVKhoiLuongTinh = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Đơn bảo hiểm :" type="text" placeholder="" :value="chiTietVGD.donBaoHiem" @input="chiTietVGD.donBaoHiem = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Số đơn bảo hiểm :" type="text" placeholder="" :value="chiTietVGD.soDonBaoHiem" @input="chiTietVGD.soDonBaoHiem = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Vận đơn số :" type="text" placeholder="" :value="chiTietVGD.vanDonSo" @input="chiTietVGD.vanDonSo = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Cảng xếp hàng :" type="text" placeholder="" :value="chiTietVGD.cangXepHang" @input="chiTietVGD.cangXepHang = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Cảng dỡ hàng :" type="text" placeholder="" :value="chiTietVGD.cangDoHang"  @input="chiTietVGD.cangDoHang = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Phương tiện vận tải :" type="text" placeholder="" :value="chiTietVGD.phuongTienVanTai"  @input="chiTietVGD.phuongTienVanTai = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-item class="my-title-font-size" title="Tình trạng H.Hóa :" smart-select :smart-select-params="{openIn: 'sheet', closeOnSelect: true}">
                                        <div class="my-label-font-size" v-if="!clickChangeTinhTrangHangHoa" > {{chiTietVGD.tinhTrangHangHoa}}</div>
                                        <select name="tinhTrangHangHoa" v-model="chiTietVGD.tinhTrangHangHoa" @change="() => clickChangeTinhTrangHangHoa=true">
                                            <option value="Bình thường">Bình thường</option>
                                            <option value="Hàng rời">Hàng rời</option>
                                            <option value="Hàng hóa bị hư hỏng">Hàng hóa bị hư hỏng</option>
                                        </select>
                                    </f7-list-item>
                                </f7-list>
                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>
                </div>
                <div class="card">
                    <f7-list>
                        <f7-list-item accordion-item title="4. Thông tin giám định">
                            <f7-accordion-content>
                                <f7-list no-hairlines-md>
                                    <f7-list-item class="my-title-font-size" title="Hạng mục YCGĐ(*):" smart-select :smart-select-params="{openIn: 'sheet', closeOnSelect: true}">
                                        <div class="my-label-font-size" v-if="!clickChangeHangMucYCGD"> {{hangMucYeuCauGiamDinhArray.toString()}}</div>
                                        <select name="hangMucYCGD" multiple  v-model="hangMucYeuCauGiamDinhArray" @change=" () => clickChangeHangMucYCGD=true">
                                            <option :key="index" v-for="(item, index) in dsHangMucYCGD" :value="item.tenDanhMuc">{{item.tenDanhMuc}}</option>
                                        </select>
                                    </f7-list-item>
                                    <f7-list-input label="Thời gian bắt đầu giám định (*):" type="date" placeholder="" :value="thoiGianGiamDinh1" @input="thoiGianGiamDinh1 = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Thời gian kết thúc giám định :" type="date" placeholder="" :value="thoiGianGiamDinh2" @input="thoiGianGiamDinh2 = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Địa điểm giám định (*):" type="text" placeholder="" :value="chiTietVGD.diaDiemGiamDinh" @input="chiTietVGD.diaDiemGiamDinh = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Thông tin liên hệ :" type="text" placeholder="" :value="chiTietVGD.tenNguoiLienHe" @input="chiTietVGD.tenNguoiLienHe = $event.target.value">
                                    </f7-list-input>
                                    <f7-list-input label="Ghi chú :" type="text" placeholder="" :value="chiTietVGD.ghiChu" @input="chiTietVGD.ghiChu = $event.target.value">
                                    </f7-list-input>
                                </f7-list>
                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>
                </div>
                <div class="card">
                    <f7-list>
                        <f7-list-item accordion-item title="5. Giám định viên đang thực hiện" :accordion-item-opened="trangThai_TaoMoiVGD(chiTietVGD)">
                            <f7-accordion-content>
                                <f7-list-item :disabled="disableChiDinhGDV()" class="my-title-font-size" title="GĐV chính:" smart-select :smart-select-params="{openIn: 'sheet'}">
                                <div class="my-label-font-size" v-if="!clickChangeGDVChinh"> {{dSGiamDinhVienChinhID?"hiện có " + dSGiamDinhVienChinhID.length + " người":"hiện có 0 người"}}</div>
                                        <select name="giamDinhVienChinh" multiple v-model="dSGiamDinhVienChinhID" @change="() => clickChangeGDVChinh = true">
                                            <option :key="index" v-for="(item, index) in dsGiamDinhVien" :value="item.id">{{item.ten}}</option>
                                        </select>
                                    </f7-list-item>
                                    <f7-list-item :disabled="disableChiDinhGDV()" class="my-title-font-size" title="GĐV phụ:" smart-select :smart-select-params="{openIn: 'sheet'}">
                                <div class="my-label-font-size" v-if="!clickChangeGDVPhu"> {{dSGiamDinhVienPhuID?"hiện có " + dSGiamDinhVienPhuID.length + " người":"hiện có 0 người"}}</div>
                                        <select name="giamDinhVienPhu" multiple v-model="dSGiamDinhVienPhuID"  @change="() => clickChangeGDVPhu = true">
                                            <option :key="index" v-for="(item, index) in dsGiamDinhVien" :value="item.id">{{item.ten}}</option>
                                        </select>
                                    </f7-list-item>
                                <f7-block-title v-if="dSGiamDinhVienChinhID.length">Giám định viên chính:</f7-block-title>
                                <f7-list v-if="dSGiamDinhVienChinhID.length">
                                    <f7-list-item :key="index" v-for="(item, index) in dSGiamDinhVienChinhID" :value="item">- {{getTenNhanVien(item)}}</f7-list-item>
                                </f7-list>


                                <f7-block-title v-if="dSGiamDinhVienPhuID.length">Giám định viên phụ:</f7-block-title>
                                <f7-list v-if="dSGiamDinhVienPhuID.length">
                                    <f7-list-item :key="index" v-for="(item, index) in dSGiamDinhVienPhuID" :value="item">- {{getTenNhanVien(item)}}</f7-list-item>
                                </f7-list>

                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>
                </div>
                <div class="card">
                    <f7-list>
                        <f7-list-item accordion-item title="6. Cập nhật công việc hiện trường">
                            <f7-accordion-content>
                                <f7-button medium raised fill style="margin: 15px; height: 40px; line-height: 40px;">
                                    <f7-link class="fs-roboto-bold text-color-white"  @click="themCongViecGDV(chiTietVGD.id)">Thêm công việc - tài liệu</f7-link>
                                </f7-button>
                                <f7-block-title v-if="dsCongViecGDV.length">Công việc đã thực hiện:</f7-block-title>
                                <f7-list>
                                    <f7-list-item :key="index" v-for="(item, index) in dsCongViecGDV" link="#" @click="viewChiTietCongViecGDV(item)">- {{item.tenCongViec}}</f7-list-item>
                                </f7-list>
                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>
                </div>
                <div class="card">
                    <f7-list>
                        <f7-list-item accordion-item title="7. Phân tích mẫu">
                            <f7-accordion-content>
                                <div class="row" style="margin:10px">
                                    <div class="col-50">
                                <f7-button medium raised fill >
                                    <f7-link class="fs-roboto-bold text-color-white"  @click="guiYCPT(chiTietVGD)">Tạo YCPT</f7-link>
                                </f7-button>
                                    </div>
                                    <div class="col-50">
                                <f7-button medium raised fill color="green" >
                                    <f7-link class="fs-roboto-bold text-color-white"  @click="guiYCPT_New(chiTietVGD)">Tạo YCPT (NHIỀU MẪU)</f7-link>
                                </f7-button>
                                    </div>
                                </div>
                                
                                <f7-block-title>Danh sách mẫu đã gửi: <span style="color: green">{{dsYCPT.length}}</span></f7-block-title>
                                <f7-list media-list>
                                    <f7-list-item :key="index" v-for="(item, index) in dsYCPT" link="#" @click="viewChiTietYCPT(item)" :title="item.soNiem?item.tenMau + '/'+ item.soNiem:item.tenMau" :subtitle="'bởi '+ getTenNhanVien(item.nguoiYeuCauUserId)" :text="formatDate(item.ngayYeuCau)"></f7-list-item>
                                </f7-list>
                                <f7-block-title>Danh sách mẫu đã có kết quả: <span style="color: green">{{dsVPT.length}}</span></f7-block-title>
                                <f7-list media-list>
                                    <f7-list-item :key="index" v-for="(item, index) in dsVPT" link="#" @click="viewChiTietVPT(item)"  :title="item.soNiem?item.tenMau + '/'+ item.soNiem:item.tenMau" :subtitle="'bởi '+ getTenNhanVien(item.nguoiYeuCauUserId)" :text="formatDate(item.ngayYeuCau)"></f7-list-item>
                                </f7-list>
                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>
                </div>
                <div class="card">
                    <f7-list>
                        <f7-list-item accordion-item title="8. Lịch sử">
                            <f7-accordion-content>
                                <!-- <f7-list >
                                    <f7-list-item :key="index" v-for="(item, index) in dsLichSuVGD" link="#">- {{item.message}}</f7-list-item>
                                </f7-list> -->
                                <f7-list media-list>
                                    <f7-list-item :key="index" v-for="(item, index) in dsLichSuVGD" :title="item.message" :subtitle="'bởi '+ getTenNhanVien(item.appUserId)" :text="formatDate(item.date)"></f7-list-item>
                                </f7-list>

                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>
                </div>
                <div class="card" v-if="showChamCong()">
                    <f7-list>
                        <f7-list-item accordion-item title="9. Giám định viên chấm công">
                            <f7-accordion-content>
                                <f7-button medium raised fill style="margin: 15px; height: 40px; line-height: 40px;">
                                    <f7-link class="fs-roboto-bold text-color-white"  @click="themChamCongGDV(chiTietVGD)">Thêm chấm công</f7-link>
                                </f7-button>
                                <div class="data-table data-table-init">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th class="label-cell">STT</th>
                                                <th class="label-cell">Tên GDV</th>
                                                <th class="label-cell">Ngày công</th>
                                                <th class="label-cell">Tỷ lệ tự chấm (%)</th>
                                                <th class="label-cell">Trạng thái</th>
                                                <th class="label-cell">Người duyệt</th>
                                                <th class="label-cell">Ngày duyệt</th>
                                                <th class="label-cell">Tỷ lệ TP duyệt (%)</th>
                                                <th class="label-cell">Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="index" v-for="(item, index) in itemsChamCong">
                                                <td class="label-cell">{{index+1}}</td>
                                                <td class="label-cell">{{item.tenNhanVien}}</td>
                                                <td class="label-cell">{{formatDate2(item.ngayCong)}}</td>
                                                <td class="label-cell">{{item.tyLe}}%</td>
                                                <td class="label-cell" style="color:orange" v-if="item.trangThai==='chua-duyet'">{{item.trangThai==='chua-duyet'?'Chờ duyệt':'Đã duyệt'}}</td>
                                                <td class="label-cell" style="color:green" v-if="item.trangThai==='da-duyet'">{{item.trangThai==='chua-duyet'?'Chờ duyệt':'Đã duyệt'}}</td>
                                                <td class="label-cell">{{getTenNhanVien(item.idNguoiDuyetChamCong)}}</td>
                                                <td class="label-cell">{{formatDate2(item.ngayDuyetChamCong)}}</td>
                                                <td class="label-cell" style="color:green">{{item.idNguoiDuyetChamCong?item.tyLe_Duyet:0}}%</td>
                                                <td class="label-cell">
                                                    <div v-if="item.trangThai==='chua-duyet'">
                                                        <f7-button fill color="red" @click="runDeleteChamCong(item)">Xóa</f7-button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>
                </div>
                <div class="card">
                    <f7-list>
                        <f7-list-item accordion-item title="10. Niêm">
                            <f7-accordion-content>
                                    <f7-button medium raised fill style="margin: 15px; height: 40px; line-height: 40px;" @click="themNiem(chiTietVGD)">
                                        <f7-link class="fs-roboto-bold text-color-white"  >Thêm Niêm</f7-link>
                                    </f7-button>
                                <f7-block-title>Danh sách Niêm đã sử dụng cho vụ: <span style="color: green">{{dsNiemThuocVuGiamDinh.length}}</span></f7-block-title>
                                <f7-list v-if="dsNiemThuocVuGiamDinh.length">
                                    <f7-list-item :key="index" v-for="(item, index) in dsNiemThuocVuGiamDinh" :value="item.id">
                                        {{++index + ') ' + item.guid + ' | '+ getSealType(item.type) +' | '+formatDate2(item.dayUsed)+ ' | ' + getTenNhanVien(item.userId) }}
                                         <f7-button small raised fill color="red" style="margin: 15px; height: 40px; line-height: 40px;" @click="xoaNiemVGD(item)" v-if="item.userId===userInfo.userId">
                                            <i class="fas fa-trash"></i>
                                    </f7-button>
                                    </f7-list-item>
                                </f7-list>
                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>
                </div>
                <div class="card" v-if="showCheckin()">
                    <f7-list>
                        <f7-list-item accordion-item title="11. Checkin">
                            <f7-accordion-content>
                                <f7-button medium raised fill style="margin: 15px; height: 40px; line-height: 40px;">
                                    <f7-link class="fs-roboto-bold text-color-white"  @click="themCheckinGDV(chiTietVGD)">Thêm checkin</f7-link>
                                </f7-button>
                                <div class="data-table data-table-init">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th class="label-cell">STT</th>
                                                <th class="label-cell">Số CT</th>
                                                <th class="label-cell">Tên GDV</th>
                                                <th class="label-cell">Ngày Checkin</th>
                                                <th class="label-cell">Vị trí GPS</th>
                                                <th class="label-cell">Hình Checkin</th>
                                                <th class="label-cell">Loại</th>
                                                <th class="label-cell">Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="index" v-for="(item, index) in itemsCheckin">
                                                <td class="label-cell">{{index+1}}</td>
                                                <td class="label-cell">{{item.soChungThu}}</td>
                                                <td class="label-cell">{{getTenNhanVien(item.userId)}}</td>
                                                <td class="label-cell">{{formatDate(item.dateCreated)}}</td>
                                                <td class="label-cell">
                                                    <f7-link :href="getLinkMap(item.latitude,item.longitude)" target="_blank">
                                                        {{item.locationName}}
                                                </f7-link>
                                                </td>
                                                <td class="label-cell">
                                                        <img :src="item.imageUrl" />
                                                </td>
                                                <td class="label-cell">{{item.type}}</td>
                                                <td class="label-cell" >
                                                    <div>
                                                        <f7-button fill color="red"  v-if="checkEnableDeleteCheckin(item)" @click="runDeleteCheckin(item)">Xóa</f7-button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>

                </div>
                <div class="card" v-if="showCheckin()">
                    <f7-list>
                        <f7-list-item accordion-item title="12. Checkout">
                            <f7-accordion-content>
                                <f7-button medium raised fill style="margin: 15px; height: 40px; line-height: 40px;">
                                    <f7-link class="fs-roboto-bold text-color-white"  @click="themCheckoutGDV(chiTietVGD)">Thêm checkout</f7-link>
                                </f7-button>
                                <div class="data-table data-table-init">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th class="label-cell">STT</th>
                                                <th class="label-cell">Số CT</th>
                                                <th class="label-cell">Tên GDV</th>
                                                <th class="label-cell">Ngày Checkout</th>
                                                <th class="label-cell">Vị trí GPS</th>
                                                <th class="label-cell">Hình Checkout</th>
                                                <th class="label-cell">Loại</th>
                                                <th class="label-cell">Hành động</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :key="index" v-for="(item, index) in itemsCheckout">
                                                <td class="label-cell">{{index+1}}</td>
                                                <td class="label-cell">{{item.soChungThu}}</td>
                                                <td class="label-cell">{{getTenNhanVien(item.userId)}}</td>
                                                <td class="label-cell">{{formatDate(item.dateCreated)}}</td>
                                                <td class="label-cell">
                                                    <f7-link :href="getLinkMap(item.latitude,item.longitude)" target="_blank">
                                                        {{item.locationName}}
                                                </f7-link>
                                                </td>
                                                <td class="label-cell">
                                                        <img :src="item.imageUrl" />
                                                </td>
                                                <td class="label-cell">{{item.type}}</td>
                                                <td class="label-cell" >
                                                    <div >
                                                        <f7-button fill color="red" v-if="checkEnableDeleteCheckin(item)" @click="runDeleteCheckout(item)">Xóa</f7-button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>
                    <div class="m-b-100"></div>

                </div>
            </div>
        </div>

    
</f7-page>
</template>

<script>
import {
    Roles,
    PhongBanKinhDoanh,
    ChiNhanh
} from "./../../global.js";
import TrangThai from "./../../common.js";
var dateFns = require('date-fns');
import NavBar from "./../components/NavBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
import {
    EventLogin
} from "../event-login.js";
export default {
    components: {
        NavBar,
    },
    data() {
        return {
          effect: 'blink',
          loadingVGD: false,
          dsNiemThuocVuGiamDinh: [],
          itemsChamCong:[],
          itemsCheckin:[],
          itemsCheckout:[],
          allHopDong:[],
          itemsHopDongKhachHang: [],
          dsLichSuVGD:[],
          dsVPT:[],
          dsYCPT:[],
          dsCongViecGDV: [],
          dsGiamDinhVien: [],
          dsNhanVien:[],
          dSGiamDinhVienChinhID:[],
          dSGiamDinhVienPhuID: [],
          hangMucYeuCauGiamDinhArray: [],
            khachHangLienQuanIdI: [],
            khachHangLienQuanIdII: [],
            hangMucYeuCauGiamDinhArray: [],
            thoiGianGiamDinh1: '',
            thoiGianGiamDinh2: '',
            clickChangeDoiTac: false,
            clickChangeDoiTacII: false,
            clickChangeDoiTacLienQuanI: false,
            clickChangeDoiTacLienQuanII: false,
            clickChangeTinhTrangHangHoa: false,
            clickChangeHangMucYCGD: false,
            clickChangeGDVChinh: false,
            clickChangeGDVPhu: false,
            clickChangeHopDong: false,
            dsHangMucYCGD: [],
            dsKhachHang: [],
            countLike: 0,
            hideBtn: true,
            isBottom: true,
            popupOpened: false,
            chiTietVGD: {},
            isLoggedIn: false,
            hiddenItem: [],
            photos: [],
            getDataUser: {},
            hidePreloader: false,
            showPreloader: true,
            idCurrent: "",
            urlCode: "https://play.google.com/store/apps/details?id=eic.vinaas.app",
            userId: "",
            showLike: true,
            hideDayTin: false,
            getPhoto: []
        };
    },
    updated() {

    },
    mounted() {
       EventLogin.$on("reloadPageChiTietVGD", async e => {
            console.log("viet reloadPageChiTietVGD");
            await this.getChiTietVGD();

        });
        // window.FB.XFBML.parse();
        // if (this.$userInfoGlobal) {
        //     if (this.$userInfoGlobal.role == 'admin')
        //         this.isAdmin = true; // user có role là admin
        //     console.log(this.$userInfoGlobal)
        //     this.isLoggedIn = true; // user đã đăng nhập
        //     this.name = this.$userInfoGlobal.name;
        // }
        // EventLogin.$on("userLoggedIn", userInfo => {
        //     this.isLoggedIn = true;
        //     console.log('viet this.isLoggedIn', this.isLoggedIn);

        //     this.name = userInfo.name;
        //     console.log('viet this.name', this.name);

        // });
        // EventLogin.$on("updateProfile", userInfo => {
        //     this.isLoggedIn = true;
        //     this.name = userInfo.name;
        // });
        // EventLogin.$on("userLoggedOut", e => {
        //     this.isLoggedIn = false;
        //     this.isAdmin = false;
        //     this.name = "";
        //     this.$userInfoGlobal = null;
        // });
    },
    methods: {
         getSealType(type){
            switch(type){
                case 'niem_tau': return 'Niêm tàu'
                case 'niem_xalan': return 'Niêm xà lan'
                case 'niem_bon': return 'Niêm bồn'
                case 'niem_xe_bon': return 'Niêm xe bồn'
                case 'niem_mau': return 'Niêm mẫu'
                case 'niem_duongong': return 'Niêm đường ống'
                case 'niem_khac': return 'Khác'
            }
        },
        async getDSNiemThuocVuGiamDinh(soChungThu) {
            let url = apiEndPoint + "api/Seals/getAllNiemThuocVuGiamDinh";
            let result = [];
            try {
                let res = await axios.get(url, {
                    params: {
                        soChungThu : soChungThu
                    }
                });
                result = res.data.result;

            } catch (error) {
                console.log(error);
            }
            return result;
        },
        checkEnableDeleteCheckin(item){
            //chỉ cho phép xóa Checkin trong vòng 30 phút.
            let newDate = dateFns.addMinutes(new Date(item.dateCreated), 30) // 30 phút sau
            if(new Date()< newDate)
                return true;
            return false;
        },
        getLinkMap(lat,long){
            let locationUrl = `https://www.google.com/maps/@${lat},${long},18z?hl=vi-VN/`
            return locationUrl;
        },
        showChamCong(){
            if(this.userInfo===Roles.CS || this.userInfo===Roles.CSCN || this.userInfo===Roles.CS_Lab){
                return false;
            }
            if(this.chiTietVGD.trangthai===TrangThai.VGD_NEW_020){
                return false;
            }
            return true;
        },
        showCheckin(){
            if(this.userInfo===Roles.CS || this.userInfo===Roles.CSCN || this.userInfo===Roles.CS_Lab){
                return false;
            }
            if(this.chiTietVGD.trangthai===TrangThai.VGD_NEW_020){
                return false;
            }
            return true;
        },
         async runDeleteChamCong(item){
            console.log('runDeleteChamCong()');
            this.openConfirm("Chắc chắn bạn muốn xóa chấm công?", async() => {
             let rs = await axios.delete(apiEndPoint + 'api/Chamcongs/'+item.id);
             console.log('viet delete cham cong',rs.data);
              if(rs.data.count){
                this.itemsChamCong = await this.getAllChamCongGDV();
                this.openAlert("Thực hiện thành công!");
            }

            });
        },
         async runDeleteCheckin(item){
            this.openConfirm("Chắc chắn bạn muốn xóa checkin này?", async() => {
             let rs = await axios.delete(apiEndPoint + 'api/Checkins/'+item.id);
              if(rs.data.count){
                this.itemsCheckin = await this.getAllCheckinGDV();
                this.openAlert("Thực hiện thành công!");
            }

            });
        },
         async runDeleteCheckout(item){
            this.openConfirm("Chắc chắn bạn muốn xóa checkout này?", async() => {
             let rs = await axios.delete(apiEndPoint + 'api/Checkins/'+item.id);
              if(rs.data.count){
                this.itemsCheckout = await this.getAllCheckoutGDV();
                this.openAlert("Thực hiện thành công!");
            }

            });
        },
        async getAllChamCongGDV(){
            let filterChamCong = { 
                where:{
                    idVuGiamDinh : this.chiTietVGD.id,
                    idNhanVien : this.userInfo.userId
                },
                order: "ngayCong desc"
                };
            let request = {
                params: {
                    filter: filterChamCong
                }
            }
             let rs = await axios.get(apiEndPoint + 'api/Chamcongs',request);
            return rs.data;
        },
        async getAllCheckinGDV(){
            let filterCheckin = { 
                where:{
                    idVuGiamDinh : this.chiTietVGD.id,
                    userId : this.userInfo.userId,
                    type: "checkin"
                },
                order: "dateCreated desc"
                };
            let request = {
                params: {
                    filter: filterCheckin
                }
            }
             let rs = await axios.get(apiEndPoint + 'api/Checkins',request);
            return rs.data;
        },
        async getAllCheckoutGDV(){
            let filterCheckout = { 
                where:{
                    idVuGiamDinh : this.chiTietVGD.id,
                    userId : this.userInfo.userId,
                    type: "checkout"
                },
                order: "dateCreated desc"
                };
            let request = {
                params: {
                    filter: filterCheckout
                }
            }
             let rs = await axios.get(apiEndPoint + 'api/Checkins',request);
            return rs.data;
        },
        filterHopDong(idKH) {
            this.itemsHopDongKhachHang = this.allHopDong.filter(e => e.khachHangId === idKH);
                //lọc ra những hợp đồng còn hiệu lực của khách hàng:
                this.itemsHopDongKhachHang = this.itemsHopDongKhachHang.filter(e => {
                if(e.ngayHetHieuLuc===null || e.ngayHetHieuLuc >= new Date()) return e;
                })
        },
      async GetPeriodTime(actionNum,phongbanId){
          let request = {
            params: {
              actionNum: actionNum,
              phongbanId: phongbanId
            }
          }
          let rs = await axios.get(apiEndPoint + 'api/Actions/getPeriodTime/',request);
          console.log('viet period time rs',rs.data);
          return rs.data;
      },
      async ThietLapDeadline(actionCode,item){
          // thiết lập deadline mới cho trạng thái mới của VPT
          let today = new Date();
          let thoigianbatdau = new Date();
          thoigianbatdau.setHours(8, 0, 0, 0);
          let thoigianketthuc = new Date();
          thoigianketthuc.setHours(17, 0, 0, 0);
          let thoigianbatdau_tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));
          thoigianbatdau_tomorrow.setHours(8, 0, 0, 0);
          let res = await this.GetPeriodTime(actionCode,item.phongBanId);
          let periodTime = res.periodTime;
          if(periodTime == 0 || periodTime == -1) periodTime = 8*60*60; // chưa thiết lập defaultperiodTime thì mặc định là 8 tiếng.

          let deadline;

          // xử lý không cho deadline nằm ngoài giờ hành chính: 8h->17h
          if (thoigianbatdau.getTime() <= today.getTime() && thoigianketthuc.getTime() >= today.getTime()) {
            deadline = new Date(today.getTime() + 1000 * periodTime); // periodTime đổi sang miliseconds
            if (deadline > thoigianketthuc) deadline = new Date(deadline.getTime() + thoigianbatdau.getTime() - thoigianketthuc.getTime() + (24 * 60 * 60 * 1000));
          } else if (thoigianbatdau.getTime() > today.getTime()) {
            deadline = new Date(thoigianbatdau.getTime() + 1000 * periodTime);
          } else if (thoigianketthuc.getTime() < today.getTime()) {
            deadline = new Date(thoigianbatdau_tomorrow.getTime() + 1000 * periodTime);
          }

          return deadline;
      },
      async ThongBaoVuGiamDinh(actionCode, item) {
          let dsNhanVien = JSON.parse(localStorage.getItem('eic_dsNhanVien'));

          let toListNhanVien = [];
          let nhanVien = dsNhanVien.find(e => e.id == item.lastUserId);
          let fromNhanVien = nhanVien;
          let fromEmail = fromNhanVien.email;
       //hi Việt, e tắt mấy rule này khỏi gửi mail nha: do mình đã điều chỉnh rule rồi nên thông báo không còn ý nghĩa nữa:
        // 1. GĐV chấp nhận chỉ định : 22
        // 2. Đã hoàn thành hiện trường : 25
        // 3. Kết thúc hiện trường : 24
        // 4. Chấp nhận yêu cầu : 13

        if(actionCode == 21){
          //Chỉ định giám định viên
           let listId = [];
                if(item.dSGiamDinhVienPhuID){
                   listId = [...item.dSGiamDinhVienChinhID,...item.dSGiamDinhVienPhuID]
                }else{
                   listId = [...item.dSGiamDinhVienChinhID]
                }
            toListNhanVien =  dsNhanVien.filter(e => listId.includes(e.id));
        }
        else if(actionCode == 25){
              //theo yêu cầu EIC ngày 22/2/2019 : Không cần xác nhận của trưởng phòng về kết thúc hiện trường.
              // (GDV có thể kết thúc hiện trường luôn).
              // GDV thực hiện luôn việc xác nhận kết thúc VGD ở hiện trường nên sẽ gửi thông báo cho trưởng phòng biết.
              let dsNhanVienTP = dsNhanVien.filter(e => {
              let arrRoleId = [
                          Roles.TruongPhong,
                          Roles.TruongPhongNotHD,
                          Roles.TruongPhongHD
                          ];
                          if(e.phongBanQuanLyId){
                            return arrRoleId.includes(e.roleId) && (e.phongBanId == item.phongBanId || e.phongBanQuanLyId.includes(item.phongBanId));
                          }else{
                            return arrRoleId.includes(e.roleId) && e.phongBanId == item.phongBanId;
                          }

                      });

              toListNhanVien = [...dsNhanVienTP];
        }


        let dsemail = toListNhanVien.map(function (element) {
            return element.email;
        });
        let toListEmails = dsemail.join(', ');
        console.log('toListEmails', toListEmails);

        let tenDoiTuong = 'Vụ giám định Số chứng thư ' + item.soChungThu;
        let getHistoryMessage = function(num){
              if(num == 25){
                  return "Đã hoàn thành VGD ở hiện trường";
              }
              else if(num == 21){
                  return "Chỉ định giám định viên";
              }
        };
        let hanhDong = getHistoryMessage(actionCode);
        let subject = tenDoiTuong + ' | ' + hanhDong + ' bởi ' + fromNhanVien.ten;

        let tenKhachHang =  this.getTenKhachHang(item.khachHangId);
        let getDanhSachTenByIds = function(listIds){
                  if(!listIds) return '';
                  let listNV = dsNhanVien.filter(e=>listIds.includes(e.id));
                  let dsTen = listNV.map(nv => {return nv.ten});
                  return dsTen.join(', ')
                }

        let dsTenGDVChinh = getDanhSachTenByIds(item.dSGiamDinhVienChinhID);
        let dsTenGDVPhu = getDanhSachTenByIds(item.dSGiamDinhVienPhuID);

        let text = `Tên khách hàng: ${tenKhachHang}. \r\n <br/>
                    Số Chứng Thư: ${item.soChungThu}. \r\n <br/>
                    Hạng mục yêu cầu giám định: ${item.hangMucYeuCauGiamDinh}. \r\n <br/>
                    Thời gian giám định: ${item.thoiGianGiamDinh}. \r\n <br/>
                    Địa điểm giám định: ${item.diaDiemGiamDinh}. \r\n <br/>
                    Phương tiện vận tải: ${item.phuongTienVanTai}. \r\n <br/>
                    Người liên hệ: ${item.tenNguoiLienHe}. \r\n <br/>
                    Số điện thoại: ${item.dienThoaiNguoiLienHe}. \r\n <br/>
                    Giám Định Viên chính: ${dsTenGDVChinh}. \r\n <br/>
                    Danh Sách Giám định Viên Phụ: ${dsTenGDVPhu}`
        return this.GuiEmail(fromEmail, toListEmails, subject, text, "THONGBAO");
      },
      async GuiEmail(fromEmail, toListEmails, subject, bodyText, LoaiEmail) {
          console.log('viet GuiEmail  test', toListEmails);
          let item = {
              to: toListEmails, from: fromEmail,
              subject: subject, text: bodyText
          };
        let rec = await axios.post(apiEndPoint+ "api/ActionHistories/sendEmail", item);
        return rec.data;
      },
      veTrangChu(){
        if(this.userInfo.roleId == 5){
          this.$f7router.back('/home-gdv/');
        }else{
          this.$f7router.back('/home/');

        }
      },
       async getLichSuVGD(item){
         let url = apiEndPoint + "api/ActionHistories";
            let filter = {
                where: {
                    objectId: item.id,
                    objectType: "VuGiamDinh"
                },
                order:"date desc"
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.dsLichSuVGD = res.data;
                console.log('viet this.dsLichSuVGD',this.dsLichSuVGD);
            } catch (error) {
               alert(error);
            }
      },
       async getDanhSachYCPT(item){
         let url = apiEndPoint + "api/YeuCauPhanTiches";
            let filter = {
                where: {
                    soChungThuVuGiamDinh: item.soChungThu
                },
                order:"lastModified desc"
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.dsYCPT = res.data;
            } catch (error) {
               alert(error);
            }
      },
       async getDanhSachVPT(item){
         if(!item.replyIds || !item.replyIds.length) return;
         let url = apiEndPoint + "api/VuPhanTiches";
            let filter = {
                where: {
                    id:{inq:item.replyIds}
                },
                order:"lastModified desc"
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.dsVPT = res.data;
            } catch (error) {
               alert(error);
            }
      },
      themCongViecGDV(vgdId){
        localStorage.setItem("eic_temp", vgdId);
        this.$f7router.navigate("/them-cong-viec-gdv/" );
      },
    getVaiTro(idNV,listGDVChinhId,listGDVPhuId){
            if(listGDVChinhId.includes(idNV)){
            return 'gdv-chinh';
            }else if(listGDVPhuId.includes(idNV)){
            return 'gdv-phu';
            }else{
            return '';
            }
        },
      themChamCongGDV(vgd){
        let vaiTro = this.getVaiTro(this.userInfo.userId,vgd.dSGiamDinhVienChinhID,vgd.dSGiamDinhVienPhuID)
        localStorage.setItem("eic_temp_vaiTro",vaiTro);
        localStorage.setItem("eic_temp_vgdId", vgd.id);
        localStorage.setItem("eic_temp_soChungThu", vgd.soChungThu);
        this.$f7router.navigate("/them-cham-cong-gdv/" );
      },
      themCheckinGDV(vgd){
        // begin - kiểm tra xem đã checkin rồi chưa. Nếu chưa mới được phép checkin.
            let listCheckinToday = this.itemsCheckin.filter(e => new Date(e.dateCreated) <= dateFns.endOfToday() && new Date(e.dateCreated) >= dateFns.startOfToday())
            if(listCheckinToday && listCheckinToday.length>0){
                this.openAlert("Bạn đã checkin hôm nay rồi. Không được checkin nữa.");
            return;
            }
        // end
        localStorage.setItem("eic_temp_vgdId", vgd.id);
        localStorage.setItem("eic_temp_soChungThu", vgd.soChungThu);
        this.$f7router.navigate("/them-checkin-gdv/" );
      },
      themCheckoutGDV(vgd){
        // begin - kiểm tra xem đã checkin rồi chưa. Nếu chưa mới được phép checkin.
        let listCheckoutToday = this.itemsCheckout.filter(e => new Date(e.dateCreated) <= dateFns.endOfToday() && new Date(e.dateCreated) >= dateFns.startOfToday())
        if(listCheckoutToday && listCheckoutToday.length>0){
            this.openAlert("Bạn đã checkout hôm nay rồi. Không được checkout nữa.");
        return;
        }
        // end
        localStorage.setItem("eic_temp_vgdId", vgd.id);
        localStorage.setItem("eic_temp_soChungThu", vgd.soChungThu);
        this.$f7router.navigate("/them-checkout-gdv/" );
      },
      guiYCPT(item){
        localStorage.setItem("eic_temp", JSON.stringify(item));
        this.$f7router.navigate("/tao-moi-yeu-cau-phan-tich/" );
      },
      guiYCPT_New(item){
        localStorage.setItem("eic_temp", JSON.stringify(item));
        this.$f7router.navigate("/tao-moi-yeu-cau-phan-tich-new/" );
      },
      themNiem(item){
        console.log('viet themNiem')
        localStorage.setItem("eic_temp", JSON.stringify(item));
        this.$f7router.navigate("/chon-danh-sach-niem/" );
      },
        getSealType(type){
            switch(type){
                case 'niem_tau': return 'Niêm tàu'
                case 'niem_xalan': return 'Niêm xà lan'
                case 'niem_bon': return 'Niêm bồn'
                case 'niem_xe_bon': return 'Niêm xe bồn'
                case 'niem_mau': return 'Niêm mẫu'
                case 'niem_duongong': return 'Niêm đường ống'
                case 'niem_khac': return 'Khác'
            }
        },
        async getNhanVienById(userId) {
            let url = apiEndPoint + "api/AppUsers/"+userId;
            let result = [];
            try {
                let res = await axios.get(url);
                result = res.data;
            } catch (error) {
                console.log(error);
            }
            return result;
        },
    async ThongBaoXoaNiemVGD(item){
      let user = await this.getNhanVienById(this.userInfo.userId)
      let text = '<table><tr><td colspan=2>- Thông tin Niêm trước khi xóa khỏi VGD:<td><tr>';
      text += '<tr><td>Niêm số:</td><td>' + item.guid + '</td></tr>';
      text += '<tr><td>Vụ giám định:</td><td>' + item.soChungThu + '</td></tr>';
      text += '<tr><td>Trạng thái niêm:</td><td>'+ item.status + '</td></tr>';
      text += '<tr><td>Loại niêm:</td><td>' +  this.getSealType(item.type) + '</td></tr>';
      text += '<tr><td>Người niêm phong:</td><td>' + user.ten + '</td></tr>';
      text += '<tr><td>Ngày niêm phong:</td><td>'+ dateFns.format(new Date(item.dayUsed),'dd/MM/yyyy') + '</td></tr>'; 
      text += '<tr><td>Ngày nhập lên hệ thống:</td><td>' + dateFns.format(new Date(item.dayInput),'dd/MM/yyyy HH:mm:ss') + '</td></tr>';
      text += '<tr><td colspan=2>- Thông tin Niêm sau khi xóa khỏi VGD:</td></tr>';
      text += '<tr><td>Ngày xóa niêm khỏi VGD:</td><td>' + dateFns.format(new Date(),'dd/MM/yyyy HH:mm:ss') + '</td></tr>';
      text += '<tr><td>Người xóa niêm khỏi VGD:</td><td>' +  user.ten + '</td></tr>';
      text += '</table>';
      let title  = "Thông báo đã chuyển Niêm số " + item.guid + " sử dụng cho VGD "+ item.soChungThu + " về trạng thái Chưa Sử Dụng bởi " + user.ten;
      let fromEmail = 'admin@eic.com.vn';
      try{
        await this.GuiEmail(fromEmail,user.email,title,text,'THONGBAO');
      }catch(err){
        alert(err);
      }
    },
      async xoaNiemVGD(item){
        this.openConfirm("Chắc chắn bạn muốn xóa niêm?", async() => {
            try{
                    let data = {
                        soChungThu : "0",
                        status : "NEW",
                        type : "",
                        dayUsed : null,
                        dayInput : null
                    }
                    await axios.patch(apiEndPoint + 'api/Seals/' + item.id, data).then(async res => {
                        this.dsNiemThuocVuGiamDinh = await this.getDSNiemThuocVuGiamDinh(this.chiTietVGD.soChungThu);
                    })
                     await this.ThongBaoXoaNiemVGD(item);
            }catch(err){
                console.error(err);
                this.openAlert("Có lỗi xảy ra!");
            }
        })
      },
      viewChiTietCongViecGDV(item){
        localStorage.setItem("eic_temp", JSON.stringify(item));
        this.$f7router.navigate("/chi-tiet-cong-viec-gdv/" );
      },
      viewChiTietYCPT(item){
        localStorage.setItem("eic_temp", JSON.stringify(item));
        if(item.soLuongMau){
             this.$f7router.navigate("/xem-chi-tiet-yeu-cau-phan-tich-new/" );
        }else{
             this.$f7router.navigate("/xem-chi-tiet-yeu-cau-phan-tich/" );
        }
      },
      viewChiTietVPT(item){
        if(item.trangthai !="LAB_VPT_KhachHangChapNhanKQPT_0881"){
          this.openAlert("Đang cập nhật thêm kết quả phân tích!");
          return;
        }
        localStorage.setItem("eic_temp", JSON.stringify(item));
        if(item.soLuongMau){
            this.$f7router.navigate("/xem-chi-tiet-vu-phan-tich-new/" );
        }else{
            this.$f7router.navigate("/xem-chi-tiet-vu-phan-tich/" );
        }
      },
       async SaveHistory(actionId, objectId, message, note){
            let userInfo = JSON.parse(localStorage.getItem('eic_userInfo'));
            let history = {
               actionId: actionId,
                appUserId: userInfo.userId,
                objectId : objectId ,
                objectType : "VuGiamDinh" ,
                date: new Date(),
                message:  message ,
                note:  note
            }
             try {
                let res = await axios.post(apiEndPoint + "api/ActionHistories", history)
                console.log('viet res', res);
            } catch (error) {
                alert(error);
            }
        },
      async getCongViecGiamDinhVien(item){
         let url = apiEndPoint + "api/CongViecGiamDinhViens";
            let filter = {
                where: {
                    vuGiamDinhId: item.id
                },
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.dsCongViecGDV = res.data;
                console.log('viet this.dsCongViecGDV',this.dsCongViecGDV)
            } catch (error) {
               alert(error);
            }
      },
      disableChiDinhGDV(){
        let check = true;
        if(this.userInfo.roleId == Roles.TruongPhong || this.userInfo.roleId == Roles.TruongPhongHD || this.userInfo.roleId == Roles.TruongPhongNotHD){
          check = false;
        }
        return check;
      },
      enableHoanThanhHT(item){
        let check = false;
        if(item.trangthai == TrangThai.VGD_XuLyHienTruong_022){
          if(this.userInfo.roleId == Roles.TruongPhong || this.userInfo.roleId == Roles.TruongPhongHD || this.userInfo.roleId == Roles.TruongPhongNotHD){
            check = true;
          }
        }

        return check;
      },
      enableTiepTucTaiHT(item){
         let check = false;
        if(item.trangthai == TrangThai.VGD_HoanThanhHienTruong_025){
           if(this.userInfo.roleId == Roles.TruongPhong || this.userInfo.roleId == Roles.TruongPhongHD || this.userInfo.roleId == Roles.TruongPhongNotHD){
              check = true;
            }
        }

        return check;
      },
      enableLuuDieuChinh(){
         let check = true;
        return check;
      },
      async Patch(item){
          var rs;
          if (item.id > 0) {
            rs = await axios.patch(apiEndPoint + 'api/VuGiamDinhs' + '/' + item.id, item)
            console.log('viet save vgd',rs);
            if (rs) {
                this.openAlert("Thực hiện thành công!");
                this.$f7router.back();
                // this.$f7router.navigate("/home/",{clearPreviousHistory  : true,ignoreCache :true,reloadAll :true});
                EventLogin.$emit("reloadPage");

            }else {
                this.openAlert("Có lỗi xảy ra!");
            }
          }
      },
      validateVuGiamDinh(vgd){
          let check = false;
          if(!vgd){
            this.openAlert("Vụ giám định không tồn tại")
            return check;
          }

          if(!vgd.khachHangId){
            this.openAlert("Bạn chưa chọn Đối tác yêu cầu I");
            return check;
          }
          if(!vgd.tenHang){
            this.openAlert("Bạn chưa nhập Tên Hàng");
            return check;
          }
          if(!this.hangMucYeuCauGiamDinhArray.length){
            this.openAlert("Bạn chưa chọn Hạng mục YCGĐ");
            return check;
          }
          if(!vgd.diaDiemGiamDinh){
            this.openAlert("Bạn chưa nhập Địa điểm giám định");
            return check;
          }
          if(!this.thoiGianGiamDinh1){
            this.openAlert("Bạn chưa chọn Thời gian bắt đầu giám định");
            return check;
          }

          check = true;
          return check;
        },
      async runHoanThanhHT(item){
          console.log('viet runSave');
           let check = this.validateVuGiamDinh(item);
            if(!check){
              return ;
            }
          this.openPreloader("Đang thực hiện...",2000);


        this.chiTietVGD.dSGiamDinhVienChinhID = this.dSGiamDinhVienChinhID;
        this.chiTietVGD.dSGiamDinhVienPhuID = this.dSGiamDinhVienPhuID;
        this.chiTietVGD.thoiGianGiamDinh = this.xuLyThoiGianGiamDinh(this.thoiGianGiamDinh1, this.thoiGianGiamDinh2);
        this.chiTietVGD.beginThoiGianGiamDinh = new Date(this.thoiGianGiamDinh1);
        this.chiTietVGD.hangMucYeuCauGiamDinh = this.hangMucYeuCauGiamDinhArray.join();
        this.chiTietVGD.khachHangLienQuanIdI = this.khachHangLienQuanIdI;
        this.chiTietVGD.khachHangLienQuanIdII = this.khachHangLienQuanIdII;

            item.trangthai = TrangThai.VGD_HoanThanhHienTruong_025;



          item.lastModified = new Date();

          let userInfo = JSON.parse(localStorage.getItem('eic_userInfo'));
          item.lastUserId = userInfo.userId;
          this.SaveHistory(25,item.id,"Hoàn thành hiện trường (mqlgd)","");
          this.ThongBaoVuGiamDinh(25,item);
          item.deadline = await this.ThietLapDeadline(25,item);
          await this.Patch(item);

      },
      async runTiepTucTaiHT(item){
         let check = this.validateVuGiamDinh(item);
            if(!check){
              return ;
            }
        this.openPreloader("Đang thực hiện...",2000);

        this.chiTietVGD.dSGiamDinhVienChinhID = this.dSGiamDinhVienChinhID;
        this.chiTietVGD.dSGiamDinhVienPhuID = this.dSGiamDinhVienPhuID;
        this.chiTietVGD.thoiGianGiamDinh = this.xuLyThoiGianGiamDinh(this.thoiGianGiamDinh1, this.thoiGianGiamDinh2);
        this.chiTietVGD.beginThoiGianGiamDinh = new Date(this.thoiGianGiamDinh1);
        this.chiTietVGD.hangMucYeuCauGiamDinh = this.hangMucYeuCauGiamDinhArray.join();
        this.chiTietVGD.khachHangLienQuanIdI = this.khachHangLienQuanIdI;
        this.chiTietVGD.khachHangLienQuanIdII = this.khachHangLienQuanIdII;

            item.trangthai = TrangThai.VGD_XuLyHienTruong_022;


          item.lastModified = new Date();

          item.lastUserId = this.userInfo.userId;
          this.SaveHistory(30,item.id,"Tiếp tục tại hiện trường (mqlgd)","");
          item.deadline = await this.ThietLapDeadline(30,item);

          await this.Patch(item);



      },
      async runSave(item){
        let check = this.validateVuGiamDinh(item);
            if(!check){
              return ;
            }
        this.openPreloader("Đang thực hiện...",2000);


        this.chiTietVGD.dSGiamDinhVienChinhID = this.dSGiamDinhVienChinhID;
        this.chiTietVGD.dSGiamDinhVienPhuID = this.dSGiamDinhVienPhuID;
        this.chiTietVGD.thoiGianGiamDinh = this.xuLyThoiGianGiamDinh(this.thoiGianGiamDinh1, this.thoiGianGiamDinh2);
        this.chiTietVGD.beginThoiGianGiamDinh = new Date(this.thoiGianGiamDinh1);
        this.chiTietVGD.hangMucYeuCauGiamDinh = this.hangMucYeuCauGiamDinhArray.join();
        this.chiTietVGD.khachHangLienQuanIdI = this.khachHangLienQuanIdI;
        this.chiTietVGD.khachHangLienQuanIdII = this.khachHangLienQuanIdII;

          if(item.trangthai == TrangThai.VGD_NEW_020 && this.dSGiamDinhVienChinhID.length>0){
            item.trangthai = TrangThai.VGD_XuLyHienTruong_022;
             this.SaveHistory(21,item.id,"Chỉ định Giám định viên (mqlgd)","");
             this.ThongBaoVuGiamDinh(21,item);
             item.deadline = await this.ThietLapDeadline(21,item);
          }else{
               this.SaveHistory(1000,item.id,"Điều chỉnh thông tin VGD (mqlgd)","");
               item.deadline = await this.ThietLapDeadline(1000,item);

          }

          item.lastModified = new Date();

          item.lastUserId = this.userInfo.userId;

          var rs
          if (item.id > 0) {
            rs = await axios.patch(apiEndPoint + 'api/VuGiamDinhs' + '/' + item.id, item)
            console.log('viet save vgd',rs);
            if (rs) {
                this.openAlert("Thực hiện thành công!");
                this.$f7router.back();
                // this.$f7router.navigate("/home/",{clearPreviousHistory  : true});
                EventLogin.$emit("reloadPage");

            }else {
                this.openAlert("Có lỗi xảy ra!");
            }

          }

      },
      openToast(text, position, closeTimeout) {
          const app = this.$f7;
          // Create center toast
          var toast = app.toast.create({
              text,
              position,
              closeTimeout
          });
          toast.open();
      },
      trangThai_TaoMoiVGD(item){
        if(item.trangthai == TrangThai.VGD_NEW_020) return true;
        return false;
      },
     cleanAccents(str){
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Combining Diacritical Marks
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // huyền, sắc, hỏi, ngã, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // mũ â (ê), mũ ă, mũ ơ (ư)

    return str;
},
        getAllGDV(vgd){
            let phongBanId = vgd.phongBanId;
            let dsGDV = this.dsNhanVien.filter(element => {
                let dsPhongBanIds = [];
                if (element.phongBanId !== null ||element.phongBanId !== undefined){
                dsPhongBanIds.push(element.phongBanId);
                }
                if (element.phongBanQuanLyId !== null && element.phongBanQuanLyId !== undefined && element.phongBanQuanLyId.length !== 0){
                element.phongBanQuanLyId.forEach(e2 => {
                    dsPhongBanIds.push(e2);
                });
                }
                if (dsPhongBanIds.includes(vgd.phongBanId)) return element;
            });
      return dsGDV;
    },
    titleCase(string) {
          var sentence = string.trim().toLowerCase().split(" ");
          for(var i = 0; i< sentence.length; i++){
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
          }
      return sentence.join(" ");
      },
      getTenNhanVien(id) {
            let found = this.dsNhanVien.find(e => e.id == id);
            if (!found) return "";
            return this.titleCase(found.ten);
        },
      xuLyThoiGianGiamDinh(myDate1, myDate2) {
            if (!myDate1) return "";
            if (!myDate2) return this.yyyyMMddToddMMyyyy(myDate1);
            if (new Date(myDate1) > new Date(myDate2)) {
                this.openAlert('Khoảng thời gian không hợp lệ.');
                return "";
            }
            return this.yyyyMMddToddMMyyyy(myDate1) + " đến " + this.yyyyMMddToddMMyyyy(myDate2);

        },
        yyyyMMddToddMMyyyy(myDate) {
            let str = myDate.split("-");
            return str[2] + "/" + str[1] + "/" + str[0];
        },
        ddMMyyyyToyyyyMMdd(myDate) {
            let str = myDate.split("/");
            return str[2] + "-" + str[1] + "-" + str[0];
        },
      getTenKhachHang(id) {
            let found = this.dsKhachHang.find(e => e.id == id);
            if (!found) return "";
            return found.hoTen;
        },
      getTenHopDong(id) {
            let found = this.allHopDong.find(e => e.id == id);
            if (!found) return "";
            return found.soHopDong;
        },
      onChange_HopDong(){
          this.clickChangeHopDong = true;
      },
      onChange_DoiTacYeuCau(idKH){
          this.clickChangeDoiTac = true;
          this.filterHopDong(idKH);
      },
      onChange_DoiTacYeuCauII(){
          this.clickChangeDoiTacII = true;
      },
      onChange_DoiTacLienQuanI(){
          this.clickChangeDoiTacLienQuanI = true;
      },
      onChange_DoiTacLienQuanII(){
          this.clickChangeDoiTacLienQuanII = true;
      },
        likeNews() {
            console.log("viet likeNews", this.isLoggedIn);
            if (this.isLoggedIn === false) {
                this.openAlert("Bạn cần đăng nhập");
            } else {
                let urlPost = apiEndPoint + "api/News";
                if (!this.chiTietVGD.listUserLike) {
                    this.chiTietVGD.listUserLike = [];
                }

                this.chiTietVGD.listUserLike.push(this.userId);
                try {
                    axios.patch(urlPost, this.chiTietVGD).then(res => {
                        console.log('viet res', res);
                        this.showLike = false;
                        this.countLike++;
                    }).catch(error => {
                        console.log('viet error', error);
                    })
                } catch (err) {
                    console.log(err);
                }
            }
        },
        dislikeNews() {
            let urlPost = apiEndPoint + "api/News";
            this.chiTietVGD.listUserLike = this.chiTietVGD.listUserLike && this.chiTietVGD.listUserLike.filter(v => v != this.userId) || [];
            this.chiTietVGD.countLike = this.chiTietVGD.listUserLike.length;
            try {
                axios.patch(urlPost, this.chiTietVGD).then(res => {
                    console.log('viet res', res);
                    this.showLike = true;
                    this.countLike--;

                }).catch(error => {
                    console.log('viet error', error);
                })
            } catch (err) {
                console.log(err);
            }
        },
        fbShare() {
            window.plugins.socialsharing.share('Link tải App eic', null, null, 'https://play.google.com/store/apps/details?id=eic.vinaas.app');
        },
        callToNumber(number) {
            var temp;
            if (number.search('-') > -1) {
                temp = number.split('-');
            } else {
                temp = number.split('/');
            }
            var phone = temp[0];
            phone = phone.replace(/\./g, '');
            phone = phone.replace(/ /g, '');
            phone = phone.replace(/,/g, '');
            var onSuccess = function (result) {
                console.log("Success:" + result);
            }

            var onError = function (result) {
                console.log("Error:" + result);
            }
            window.plugins.CallNumber.callNumber(onSuccess, onError, phone, false);

        },
        openAlert(titlename, cb) {
            const app = this.$f7;
            app.dialog.alert('' + titlename, 'Thông báo', cb);
        },
        openPreloader(titlename, time) {
            const app = this.$f7;
            app.dialog.preloader(titlename);
            setTimeout(function () {
              app.dialog.close();
            }, time);
        },
        clickCopy() {
            let testingCodeToCopy = document.querySelector('#testing-code')
            testingCodeToCopy.setAttribute('type', 'text') // 不是 hidden 才能複製
            testingCodeToCopy.select()
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'thành công' : 'không thành công';
                this.openAlert("Sao chép link " + msg);
            } catch (err) {
                this.openAlert('Oops, unable to copy');
            }
            testingCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
        tinHienTai(id) {
            console.log(id)
            this.idCurrent = id;
        },
        editTin(id) {
            console.log(id)
            this.$f7router.navigate("/edit-tin-dang/" + id + '/');
        },
        openConfirm(titlename, cb) {
            const app = this.$f7;
            app.dialog.confirm('' + titlename, 'Cảnh báo', cb);
        },
        deleteTin(id) {
            this.openConfirm("Chắc chắn bạn muốn xóa tin?", () => {

            });
        },
        async hiddenTin(id) {
            let urlPost = apiEndPoint + "api/News";
            let filter = {
                where: {
                    id: id,

                },
            };
            try {
                let res = await axios.get(urlPost, {
                    params: {
                        filter: filter
                    }
                });
                this.hiddenItem = res.data[0];
                console.log("this.hiddenItem", this.hiddenItem)

            } catch (error) {
                console.log(error);
            }
            this.hiddenItem.xuatBan = false;
            try {
                await axios.patch(urlPost, this.hiddenItem).then(res => {

                }).catch(error => {
                    console.log('viet error', error);
                })
            } catch (err) {
                console.log(err);
            }
        },
        async kiemTraDangNhap() {
            this.userItem = JSON.parse(localStorage.getItem('eic_userInfo'));
            console.log('viet this.userItem', this.userItem);
            if (this.userItem == null || this.userItem == "") {
                this.isLoggedIn = false;
            } else {
                this.isLoggedIn = true;
                if (this.userItem.username == this.chiTietVGD.username) {
                    console.log("this.userItem.username === this.chiTietVGD.username", this.userItem.username, this.chiTietVGD.username)
                    this.hideDayTin = true;
                } else {
                    this.userId = this.userItem.userId;
                    if (this.chiTietVGD.listUserLike && this.chiTietVGD.listUserLike.includes(this.userId)) {
                        this.showLike = false;
                    } else {
                        this.showLike = true;
                    }
                }
            }

        },
        getTinhTrangTin(tinhTrang) {
            if (tinhTrang == 'da-su-dung') {
                return "Đã qua sử dụng";
            } else if (tinhTrang == 'moi') {
                return "Còn mới";
            } else if (tinhTrang == 'cu') {
                return "Cũ";
            }
            return '';
        },
        getImages(item) {
            console.log('viet getImages(item)', item);
            return item && item.replace('/images/', '/added/');
        },
        formatDate(date) {
            if (!date) {
                return "";
            } else {
                return dateFns.format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
            }

        },
        formatDate2(date) {
            if (!date) {
                return "";
            } else {
                return dateFns.format(new Date(date), 'dd/MM/yyyy')
            }

        },
        formatPrice(value) {
            console.log(value);
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            console.log(value);
        },
        async getChiTietVGD() {
            this.loadingVGD = true;
            let vgdId = this.$f7route.params.id;
            try {
                let res = await axios.get(apiEndPoint + "api/VuGiamDinhs/" + vgdId);
                this.chiTietVGD = res.data;
                this.dSGiamDinhVienChinhID = this.chiTietVGD.dSGiamDinhVienChinhID || [];
                this.dSGiamDinhVienPhuID = this.chiTietVGD.dSGiamDinhVienPhuID || [];
                // if(this.chiTietVGD.trangthai == TrangThai.VGD_NEW_020){
                //    this.openToast('Vụ cần chỉ định GDV!', 'center', 3000);

                // }
                this.khachHangLienQuanIdI =  this.chiTietVGD.khachHangLienQuanIdI;
                this.khachHangLienQuanIdII = this.chiTietVGD.khachHangLienQuanIdII;
                this.hangMucYeuCauGiamDinhArray = this.chiTietVGD.hangMucYeuCauGiamDinh.split(',');
                let thoiGian1 = this.chiTietVGD.thoiGianGiamDinh.split(" đến ")[0];
                let thoiGian2 = this.chiTietVGD.thoiGianGiamDinh.split(" đến ")[1];
                this.thoiGianGiamDinh1 = this.ddMMyyyyToyyyyMMdd(thoiGian1);
                this.thoiGianGiamDinh2 = thoiGian2?this.ddMMyyyyToyyyyMMdd(thoiGian2):"";
                this.dsNiemThuocVuGiamDinh = await this.getDSNiemThuocVuGiamDinh(this.chiTietVGD.soChungThu);
                await this.getCongViecGiamDinhVien(this.chiTietVGD);
                await this.getDanhSachYCPT(this.chiTietVGD);
                await this.getDanhSachVPT(this.chiTietVGD);
                this.getLichSuVGD(this.chiTietVGD);
                this.itemsChamCong = await this.getAllChamCongGDV();
                this.itemsCheckin= await this.getAllCheckinGDV();
                this.itemsCheckout= await this.getAllCheckoutGDV();

            } catch (error) {
                console.log(error);
            }
            this.loadingVGD = false;

        },

        async getUser() {
            let url = apiEndPoint + "api/AppUsers";
            let filter = {
                where: {
                    id: this.chiTietVGD.userId
                }
            };
            try {
                let res = await axios.get(url + '/findOne', {
                    params: {
                        filter: filter
                    }
                });
                console.log('res', res);
                this.getDataUser = res.data;
                console.log('this.getDataUser', this.getDataUser);

            } catch (error) {
                console.log(error);
            }
        },
        showPhotoBrowser(index) {
            let self = this;
            console.log(this.chiTietVGD.images)
            // this.getPhoto = this.chiTietVGD.images.replace('/images/', '/added/')
            this.chiTietVGD.images.forEach(e => {
                this.getPhoto.push(e.replace('/images/', '/added/'));
            });
            console.log('viet this.getPhoto', this.getPhoto);
            self.myPhotoBrowserStandalone = self.$f7.photoBrowser.create({
                photos: this.getPhoto,
                theme: 'dark'
            });
            self.myPhotoBrowserStandalone.open(index);
        },
        onPageInit() {
            let self = this;
            self.mySwiper3 = self.$f7.swiper.create(".demo-swiper-tin-chi-tiet", {
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true,
                },
                slidesPerView: 1,
                effect: 'fade',
                speed: 1000,
                autoplay: {
                    delay: 3000,
                },
                observer: true,
                observeParents: true,
            });

        }
    },
    async created() {
        this.userInfo =  JSON.parse(localStorage.getItem('eic_userInfo'));
        await this.getChiTietVGD();
        this.allHopDong = JSON.parse(localStorage.getItem("eic_dsHopDong"));
        this.dsKhachHang = JSON.parse(localStorage.getItem('eic_dsKhachHang'));
        this.dsNhanVien = JSON.parse(localStorage.getItem('eic_dsNhanVien'));
        this.dsHangMucYCGD = JSON.parse(localStorage.getItem('eic_dsHangMucYCGD'));
        this.dsGiamDinhVien = this.getAllGDV(this.chiTietVGD);
        this.filterHopDong(this.chiTietVGD.khachHangId);
     



    }
}
</script>
