<style>
.title {
    color: #fff;
}

.md .navbar .right {
    margin-left: 0px;
}

.md .navbar-inner {
    justify-content: space-between;
}

.style-icon-bar i {
    font-size: 33px;
}

.ios-img-logo {
    margin-top: -3px;
}
</style>
<template>
<!-- <f7-subnavbar :inner="false" class="no-shadow" style="top: 0px; height: 48px;">
    <f7-searchbar class="box-search " style="height: 48px;" placeholder="Tìm kiếm" search-container=".search-list" search-in=".item-title" :disable-button="!$theme.aurora"></f7-searchbar>
</f7-subnavbar> -->
<f7-navbar color="white" class="">
    <f7-nav-left style="margin-right: 0px">
       <f7-link class="md-ios-link" href="" color="white" style="margin-right: 0px"></f7-link>
    </f7-nav-left>
    <f7-nav-title color="white" class="fs-roboto-medium fs-15"><img src="./../assets/images/logo.png" alt="" width="100" srcset=""/></f7-nav-title>

    <f7-nav-right>
        <f7-link color="white"></f7-link>
    </f7-nav-right>
</f7-navbar>
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {

        }
    }
}
</script>
