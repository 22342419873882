<template>
<f7-page :page-content="false" @page:init="onPageInit" class="my-bg-color">
    <f7-navbar class="">
        <f7-nav-left back-link="Back"></f7-nav-left>
        <f7-nav-title>Sao Chép YCPT Đã Gửi</f7-nav-title>

    </f7-navbar>
 
 <div class="page-content">
     <div class="card">
   <div class="searchbar">
            <div class="searchbar-inner">
                <div class="searchbar-input-wrap">
                    <input type="search" @input="searchInput($event)" placeholder="Search">
                    <i class="searchbar-icon"></i>
                    <span class="input-clear-button"></span>
                </div>
                <span class="searchbar-disable-button if-not-aurora">Cancel</span>
            </div>
        </div>
     </div>
     
        <div class="list media-list no-margin no-hairlines" v-show="loading==true">
             <div v-for="n in 6" :key="n" :class="`card item-content skeleton-text skeleton-effect-${effect}`">
                                             <div class="item-inner">
                                                <div class="item-title-row">
                                                    <div class="item-title">
                                                        999999
                                                    </div>
                                                </div>
                                                <div class="item-text">
                                                   Lorem ipsum dolor,
                                                </div>
                                                <div class="item-text">
                                                   Lorem ipsum dolor, sit amet
                                                </div>
                                                <div class="item-text">
                                                   Lorem ipsum dolor, sit amet consectetur
                                                </div>
                                                <div class="item-text">
                                                   Lorem ipsum dolor, sit amet consecte
                                                </div>
                                                <div class="item-text">
                                                   Lorem ipsum dolor, sit am
                                                </div>
                                            </div>
                                    </div>
        </div>
        <div class="list media-list no-margin no-hairlines" v-show="loading==false">
            <div class="csstransition" >
                <div class="block" v-if="!dsYCPT.length">
                    <div class="block-inner" style="color: #ccc;text-align:center">
                        <span style="font-size: 16px;">Không có dữ liệu!</span> <br>
                    </div>
                </div>
                <div class="card" v-for="(item , index) in dsYCPT" :key="index">
                    <a v-bind:href="'/chi-tiet-yeu-cau-phan-tich/' + item.id + '/'" class="item-link item-content no-chevron">
                        <!-- <div class="item-media"><img src="https://via.placeholder.com/150" width="80" height="80" style="object-fit: cover; border-radius: 5px" /></div> -->
                        <div class="item-inner">
                            <div class="item-title-row">
                                <div class="item-title fs-roboto-bold fs-15" style="text-transform: capitalize; font-weight: normal; color: #007aff;padding:5px;">
                                    {{item.soChungThuVuGiamDinh}}
                                </div>
                            </div>
                            <div class="item-text fs-roboto-light">
                                <span style='color:#616161'>Khách hàng:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="getTenKhachHang(item.khachHangId)"></span>
                            </div>
                            <div class="item-text fs-roboto-light">
                                <span style='color:#616161'>Tên mẫu:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.tenMau"></span>
                            </div>
                            <div class="item-text fs-roboto-light">
                                <span style='color:#616161'>Số niêm:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.soNiem"></span>
                            </div>
                            <!-- <div class="item-text fs-roboto-light">
                                <span style='color:#616161'>Số chứng thư VGD:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.soChungThuVuGiamDinh"></span>
                            </div> -->
                            <div class="item-text fs-roboto-light">
                                <span style='color:#616161'>Ngày YC:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="formatDate(item.ngayYeuCau)"></span>
                            </div>
                            <!-- <div class="item-text fs-roboto-light">
                                Trạng Thái: <span class="no-margin" style="text-transform: capitalize;" v-html="item.trangthai"></span>
                            </div> -->
                            <!-- <div class="item-text fs-roboto-light">
                                Phòng Ban: <span class="no-margin" style="text-transform: capitalize;" v-html="getTenPhongBan(item.phongBanId)"></span>
                            </div> -->

                        </div>
                    </a>
                    <!-- <div class="triangle-topright" ></div> -->
                    <!-- <div class="text-triangle-topright fs-roboto-medium fs-10 text-color-white text-uppercase">New</div> -->
                    <!-- <div style="position: absolute; right: 5px; bottom: 10px;"><i class="fa fa-heart-o align-self-center fs-15 text-color-pink" aria-hidden="true"></i></div> -->
                    <div style="position: absolute; right: 5px; bottom: 50px;">
                      <f7-button f7-button large raised fill color="orange" @click="runCloneYCPT(item)"><i class="fa fa-copy"></i></f7-button>
                    </div>
                </div>

            </div>
            <!-- <p class="row" style="padding: 0px 15px" v-if="dsYCPT.length>=20">
                <button class="col button button-raised fs-roboto-medium fs-13"><a @click="loadThemVPT_ChuaChiDinh()">Xem thêm</a></button>
            </p> -->
        </div>
 </div>
  

</f7-page>
</template>

<script>
import {
    Roles,
    PhongBanKinhDoanh,
    ChiNhanh
} from "./../../global.js";
import TrangThai from "./../../common.js";
var dateFns = require('date-fns');
import NavBar from "./../components/NavBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
import {
    EventLogin
} from "../event-login.js";
export default {
    components: {
        NavBar,
    },
    data() {
        return {
          chiTietCV: null,
          clickChangeTenCongViec: false,
          dsVgd: [],
          loading: true,
          effect: 'blink',
          dsYCPT: [],
          dsKhachHangLab:[],
          filter: null,
        };
    },
    updated() {},
    mounted() {
      this.load();
    },
    methods: {
        onPageInit() {

        },
        async searchInput($event) {
            let text = $event.target.value;
            await this.loadVPTTheoSoChungThu(text);
        },
        async loadVPTTheoSoChungThu(text) {
            if (!text) {
                delete this.filter.where.soChungThuVuGiamDinh;
                return;
            } else if (text.length < 6) {
                return;
            } else {
                this.filter.where.soChungThuVuGiamDinh = {
                    regexp: text
                };
            }

            let url = apiEndPoint + "api/YeuCauPhanTiches";
            let filter2 = this.CreateFilterByPhongBan(this.filter, this.userInfo.phongBanId, this.userInfo.roleId, this.userInfo.dsphongBanId, this.userInfo.userId)
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter2
                    }
                });
                this.dsYCPT = res.data;

            } catch (error) {
                console.log(error);
            }
        },
        runCloneYCPT(item){
          localStorage.setItem("eic_cloneYCPT",JSON.stringify(item));
          EventLogin.$emit("cloneYCPT", item);
          this.$f7router.back("/them-mau-yeu-cau-phan-tich/");// về trang trước
        },
        getTenKhachHang(id) {
            let found = this.dsKhachHangLab.find(e => e.id == id);
            if (!found) return "";
            return found.hoTen;
        },
         load: function (effect) {
            var self = this;
            if (self.loading) return;
            self.effect = 'blink';
            self.loading = true;
        },
        CreateFilterByPhongBan(filter, phongBanId, roleId, dsphongBanId, userId) {
            Object.assign({}, filter);
            let filter1 = Object.assign({}, filter); //kế thừa toàn bộ skip, limit, order ... từ filter cũ
            filter1.where = {}; //xóa giá trị where

            if (phongBanId > 0) {
                filter1.where['and'] = [];
                filter1.where['and'].push(filter.where); //bỏ giá trị filter cũ vào 'and'

                // if (roleId !== Roles.Admin || roleId !== Roles.GiamDoc)

                if (filter.where["soChungThu"] !== undefined && filter.where["soChungThu"] !== null) {
                    let filter2 = filter.where.soChungThu
                    filter1.where['and'].push({
                        "soChungThu": filter2
                    });
                }
                if (filter.where["phongBanId"] !== undefined && filter.where["phongBanId"] !== null) {
                    let filter3 = filter.where.phongBanId
                    filter1.where['and'].push({
                        "phongBanId": filter3
                    });
                }
                if (filter.where["khachHangId"] !== undefined && filter.where["khachHangId"] !== null) {
                    let filter4 = filter.where.khachHangId
                    filter1.where['and'].push({
                        "khachHangId": filter4
                    });
                }

                if (roleId === Roles.Admin ||
                    roleId === Roles.GiamDoc ||
                    roleId === Roles.KeToan ||
                    roleId === Roles.KinhDoanh ||
                    roleId === Roles.GiamDoc_Lab) {

                } else if (roleId === Roles.TruongPhong && PhongBanKinhDoanh.ListPhongBan.includes(phongBanId)) {

                } else if (roleId === Roles.TruongPhong && phongBanId === 10) {

                } else if (phongBanId === ChiNhanh.CNMienBac) {

                    let filterPhongban = {
                        or: []
                    }
                    ChiNhanh.ListMienBac.forEach(element => {
                        filterPhongban.or.push({
                            "phongBanId": element
                        })
                    });

                    filter1.where['and'].push(filterPhongban)
                } else if (phongBanId === ChiNhanh.CNMienTrung) {

                    let filterPhongban = {
                        or: []
                    }
                    ChiNhanh.ListMienTrung.forEach(element => {
                        filterPhongban.or.push({
                            "phongBanId": element
                        })
                    });

                    filter1.where['and'].push(filterPhongban)

                } else if (phongBanId === ChiNhanh.CNMienNam) {
                    let filterPhongban = {
                        or: []
                    }

                    ChiNhanh.ListMienNam.forEach(element => {
                        filterPhongban.or.push({
                            "phongBanId": element
                        })
                    });

                    filter1.where['and'].push(filterPhongban)

                } else if (dsphongBanId !== undefined && dsphongBanId !== null) { // filter theo nhieu phong ban

                    let filterPhongban = {
                        or: []
                    };
                    let dsphongban = [];

                    dsphongban.push(phongBanId);

                    dsphongBanId.forEach(idpb => {
                        dsphongban.push(idpb)
                    })

                    dsphongban.forEach(element => {
                        filterPhongban.or.push({
                            "phongBanId": element
                        })
                    });

                    if (roleId === Roles.CS || roleId === Roles.CSCN) {
                        filterPhongban.or.push({
                            "nguoiYeuCauUserId": userId
                        })
                    }

                    filter1.where['and'].push(filterPhongban)
                    // logger.info("filterPhongban", JSON.stringify(filterPhongban))
                    // logger.info("filter1", JSON.stringify(filter1))
                } else {
                    //      filter.where["phongBanId"] = phongBanId;
                    //  filter.where = [{and : phongBanId = phongBanId, or: filter.where["or"]} ];
                    if (roleId === Roles.CS || roleId === Roles.CSCN) {
                        let filterPhongban = {
                            or: []
                        };
                        filterPhongban.or.push({
                            "nguoiYeuCauUserId": userId
                        })
                        filterPhongban.or.push({
                            "phongBanId": phongBanId
                        }) // fix tạm nhìn thấy yc của user đã tạo
                        filter1.where['and'].push(filterPhongban)
                    }
                    filter1.where['and'].push({
                        'phongBanId': phongBanId
                    })
                }

                // logger.info("GetCount", JSON.stringify(filter1))

                return filter1;
            }
        },
        getTenNhanVien(id) {
            let found = this.dsNhanVien.find(e => e.id == id);
            if (!found) return "";
            return found.ten;
        },
        openFile(item){
          window.open(item, '_system'); return false;
        },
        formatDate(date) {
            return dateFns.format(new Date(date), 'dd/MM/yyyy')
        },

    },
    mounted() {},
    async created() {
      this.userInfo =  JSON.parse(localStorage.getItem('eic_userInfo'));
      this.dsKhachHangLab = JSON.parse(localStorage.getItem('eic_dsKhachHangLab'));

      this.filter = { order: "id desc",
                      fields: {
                        id: true,
                        khachHangId: true,
                        tenMau: true,
                        soNiem: true,
                        soChungThuVuGiamDinh: true,
                        ngayYeuCau: true
                      },
                      skip: 0, limit: 100, where: {} };
      let filterVGD = {order: "id desc", fields: { soChungThu: true}, where: {} };
      let obj = JSON.parse(localStorage.getItem('eic_SaoChepBoChiTieuKhachHang'));
      filterVGD.where["khachHangId"] = obj.khachHangId;

      // await this.quanlygiamdinhSrv.GetAllByPhongBan(this.filterVGD).then(rec => this.dsVgd = rec);
      let url = apiEndPoint + "api/VuGiamDinhs";
      let filterVGD2 = this.CreateFilterByPhongBan(filterVGD, this.userInfo.phongBanId, this.userInfo.roleId, this.userInfo.dsphongBanId, this.userInfo.userId);
      try {
          let res = await axios.get(url, {
              params: {
                  filter: filterVGD2
              }
          });
          this.dsVgd = res.data;
          this.dsVgd = this.dsVgd.map(e => e.soChungThu);
           // fix lỗi Network Error khi dữ liệu quá nhiều ( khách hàng có quá nhiều VGD)
          if(this.dsVgd && this.dsVgd.length>100){
            let length = this.dsVgd.length;
            this.dsVgd.splice(101,length - 100);
          }


          // lọc danh sách Vpt của danh sách VgdId
          this.filter.where["idBoChiTieu"] = obj.boChiTieuId;
          this.filter.where["soChungThuVuGiamDinh"] = { inq: this.dsVgd};

          let url_YCPT = apiEndPoint + "api/YeuCauPhanTiches";
          let res_YCPT = await axios.get(url_YCPT, {
              params: {
                  filter: this.filter
              }
          });
          this.dsYCPT = res_YCPT.data;
          this.loading = false;

      } catch (error) {
          console.log(error);
      }

        // await this.getChiTietVGD();
        // this.chiTietCV = JSON.parse(localStorage.getItem('eic_temp'));

        // this.dsNhanVien = JSON.parse(localStorage.getItem('eic_dsNhanVien'));
        // this.dsHangMucYCGD = JSON.parse(localStorage.getItem('eic_dsHangMucYCGD'));
        // this.dsGiamDinhVien = this.getAllGDV(this.chiTietVGD);
        // console.log('viet this.dsGiamDinhVien ',this.dsGiamDinhVien );

    }
}
</script>
