<style>
  .my-title-font-size{
      font-size: 12px;
  }
  .my-label-font-size{
      font-size: 14px;
  }
</style>
<template>
<f7-page :page-content="false"  class="my-bg-color">
    <f7-navbar class="">
        <f7-nav-left  back-link="Back"></f7-nav-left>
        <f7-nav-title>Niêm số {{chiTietNiem.guid}}</f7-nav-title>

    </f7-navbar>




   


      <f7-fab position="right-bottom" slot="fixed" >
        <i class="fa fa-bars" aria-hidden="true"></i>
        <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
        <f7-fab-buttons position="top">
           <f7-fab-button :fab-close="true" label="Lưu cập nhật" @click="runSave(chiTietNiem)"  color="orange">
            <i class="fas fa-save" aria-hidden="true"></i>
          </f7-fab-button>
         
          <f7-fab-button :fab-close="true" label="Trang chủ"    color="lightblue">
            <i class="fa fa-home" aria-hidden="true"></i>
          </f7-fab-button>

        </f7-fab-buttons>
      </f7-fab>

        <div class="page-content">
           
            <div class="card">
                <f7-list>
                    <f7-list-item accordion-item title="1. Thông tin Niêm" :accordion-item-opened="true">
                        <f7-accordion-content>
                            <f7-list >
                                <f7-list-input label="Số niêm:" type="text" placeholder="" :value="chiTietNiem.guid" disabled>
                                </f7-list-input>
                            </f7-list>
                            <f7-list >
                                <f7-list-input label="Trạng thái:" type="text" placeholder="" :value="getSealStatus(chiTietNiem.status)" disabled>
                                </f7-list-input>
                            </f7-list>
                            <f7-list >
                                <f7-list-input label="Vụ giám định sử dụng:" type="text" placeholder="" :value="chiTietNiem.soChungThu" @input="chiTietNiem.soChungThu = $event.target.value">
                                </f7-list-input>
                            </f7-list>
                            <f7-list-item title="Phân loại niêm :" smart-select :smart-select-params="{openIn: 'sheet', closeOnSelect: true}">
                                    <div class="my-label-font-size" v-if="!clickChangePhanLoaiNiem" > {{getSealType(chiTietNiem.type)}}</div>
                                    <select  name="tinhTrangHangHoa" v-model="chiTietNiem.type" @change="() => clickChangePhanLoaiNiem=true">
                                        <option value="niem_tau">Niêm tàu</option>
                                        <option value="niem_xalan">Niêm xà lan</option>
                                        <option value="niem_bon">Niêm bồn</option>
                                        <option value="niem_xe_bon">Niêm xe bồn</option>
                                        <option value="niem_mau">Niêm mẫu</option>
                                        <option value="niem_duongong">Niêm đường ống</option>
                                        <option value="niem_khac">Khác</option>
                                    </select>
                            </f7-list-item>
                            <f7-list-input label="Ngày niêm phong:" type="date" placeholder=""  :value="chiTietNiem.dayUsed" @input="chiTietNiem.dayUsed = $event.target.value">
                            </f7-list-input>
                        </f7-accordion-content>
                    </f7-list-item>
                </f7-list>
            </div>
            <f7-button medium raised fill style="margin: 15px; height: 40px; line-height: 40px;" @click="runSave(chiTietNiem)">
                    <f7-link class="fs-roboto-bold text-color-white"  >Lưu</f7-link>
            </f7-button>
        </div>

    
</f7-page>
</template>

<script>
import {
    Roles,
} from "./../../global.js";
import TrangThai from "./../../common.js";
var dateFns = require('date-fns');
import NavBar from "./../components/NavBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
import {
    EventLogin
} from "../event-login.js";
export default {
    components: {
        NavBar,
    },
    data() {
        return {
            chiTietNiem:{},
            clickChangePhanLoaiNiem: false
        };
    },
    updated() {

    },
    mounted() {
     
       
    },
    methods: {
        getSealStatus(type){
            switch(type){
                case 'NEW': return 'Mới'
                case 'USED': return 'Đã dùng'
                case 'BROKEN': return 'Hỏng'
                case 'LOST': return 'Mất'
            }
        },
        getSealType(type){
            switch(type){
                case 'niem_tau': return 'Niêm tàu'
                case 'niem_xalan': return 'Niêm xà lan'
                case 'niem_bon': return 'Niêm bồn'
                case 'niem_mau': return 'Niêm mẫu'
                case 'niem_duongong': return 'Niêm đường ống'
                case 'niem_khac': return 'Khác'
            }
        },
         async getChiTietNiem(id) {
            try {
                let res = await axios.get(apiEndPoint + "api/Seals/" + id);
                return res.data;
            } catch (error) {
                console.error(error);
                return {};
            }
        },
         openAlert(titlename, cb) {
            const app = this.$f7;
            app.dialog.alert('' + titlename, 'Thông báo', cb);
        },
        openPreloader(titlename, time) {
            const app = this.$f7;
            app.dialog.preloader(titlename);
            setTimeout(function () {
              app.dialog.close();
            }, time);
        },
        async runSave(item){
            // item.dayUsed = new Date(item.dayUsed);
            console.log('runSave item',item);
            if(item.soChungThu && item.soChungThu!=='0' && item.soChungThu.length!==8){
                this.openAlert("Số chứng thư vụ giám định phải có 8 chữ số");
                return;
            }
            else if(!item.soChungThu || item.soChungThu === "0"){
                item.soChungThu = "0";
                item.status = "NEW";
                item.type = "";
                item.dayUsed = "";
            }
            else if(item.soChungThu.length===8){
                if(!item.type){
                    this.openAlert("Vui lòng chọn Phân loại niêm");
                    return;
                }
                if(!item.dayUsed){
                    this.openAlert("Vui lòng chọn ngày niêm phong");
                    return;
                }
                item.status = "USED";
            }

          
            this.openPreloader("Đang thực hiện...",2000);

            if (item.id > 0) {
                try{
                    let rs = await axios.patch(apiEndPoint + 'api/Seals/' + item.id, item)
                    console.log('viet save seal',rs);
                    this.openAlert("Thực hiện thành công!");
                    this.$f7router.back();
                    EventLogin.$emit("reloadPage");
                }catch(err){
                    console.error(err);
                    this.openAlert("Có lỗi xảy ra!");
                }
            }

      },
    },
    async created() {
        this.userInfo =  JSON.parse(localStorage.getItem('eic_userInfo'));
       this.chiTietNiem =  await this.getChiTietNiem(this.$f7route.params.id);
        console.log('viet this.chiTietNiem',this.chiTietNiem);


    }
}
</script>
