<template>
<f7-page :page-content="false" @page:init="onPageInit" class="my-bg-color">
    <f7-navbar class="">
        <f7-nav-left back-link="Back"></f7-nav-left>
        <f7-nav-title>Thêm chấm công GĐV</f7-nav-title>

    </f7-navbar>

     <f7-fab position="right-bottom" slot="fixed" text="Thêm"    @click="runThemChamCongGDV(chiTietCC)">
        <f7-icon ios="f7:checkmark" aurora="f7:checkmark" md="material:checkmark"></f7-icon>
    </f7-fab>


<div class="page-content">
  <div class="card">
    <f7-list >
              <f7-list-input label="Ngày công (*):" type="date" placeholder="" :value="ngayCong" @input="ngayCong = $event.target.value"  @change="onChangeNgayCong(ngayCong)">
              </f7-list-input>
              <f7-list-input label="Tỷ lệ giờ công (%) (*):" type="number" placeholder="" :value="chiTietCC.tyLe" @input="chiTietCC.tyLe = $event.target.value">
              </f7-list-input>
            </f7-list>
  </div>
  <div style="margin-left:20px;color:red;font-style:italic" v-if="tyLeConLai>0">Giá trị cho phép tối đa là <strong>{{tyLeConLai}}%</strong></div>
  <div style="margin-left:20px;color:red;font-style:italic" v-if="tyLeConLai===0">Bạn đã chấm hết 100% giờ công ngày {{ngayCong}} rồi!</div>
        
</div>


</f7-page>
</template>

<script>
import TrangThai from "./../../common.js";
var dateFns = require('date-fns');
import NavBar from "./../components/NavBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
import {
    EventLogin
} from "../event-login.js";
export default {
    components: {
        NavBar,
    },
    data() {
        return {
          tyLeConLai:null,
          ngayCong: '',
          chiTietCC: {},
          clickChangeTenCongViec: false,
          vuGiamDinhID: null,
          soChungThu: null,
          vaiTro: null,
          selectedFiles: null,
        };
    },
    updated() {},
    mounted() {

    },
    methods: {
        onPageInit() {

        },
        async onChangeNgayCong(ngayCong){
          console.log('viet ngayCong',ngayCong);
          this.tyLeConLai = await this.getTyLeChamCongConLai(this.userInfo.userId,ngayCong);
          this.chiTietCC.tyLe = this.tyLeConLai;
          console.log('viet this.tyLeConLai',this.tyLeConLai);
        },
          async getAllChamCongGDV(idNV,date){
            let filterChamCong = { 
                where:{
                     idNhanVien : idNV,
                      ngayCong: new Date(date.replace(/-/g, "/"))
                      // do new Date('2020/10/21') khác new Date('2020-10-21')
                }
                };
            let request = {
                params: {
                    filter: filterChamCong
                }
            }
             let rs = await axios.get(apiEndPoint + 'api/Chamcongs',request);
             console.log('viet rs',rs.data);
            return rs.data;
        },
        async getTyLeChamCongConLai(idNV,date){
          let listChamCong = await this.getAllChamCongGDV(idNV,date);
          if(!listChamCong.length) return 100;
          let temp = listChamCong.map(e => e.tyLe);
          let sum = temp.reduce((a,b)=> a+b);
          let result = 100 -sum;
          return result;
        },
        openAlert(titlename, cb) {
            const app = this.$f7;
            app.dialog.alert('' + titlename, 'Thông báo', cb);
        },
        openPreloader(titlename, time) {
            const app = this.$f7;
            app.dialog.preloader(titlename);
            setTimeout(function () {
              app.dialog.close();
            }, time);
        },
       async runThemChamCongGDV(item){
          console.log('viet this.ngayCong',this.ngayCong);
         
          if(!this.ngayCong){
            this.openAlert("Bạn chưa chọn Ngày Công");
            return ;
          }
          if(new Date(this.ngayCong.replace(/-/g, "/"))>new Date()){
            this.openAlert("Bạn không được chấm công sớm!");
            return;
          }
          if(!item.tyLe){
            this.openAlert("Tỷ lệ giờ công không hợp lệ");
            return ;
          }
          if(item.tyLe>this.tyLeConLai){
            this.openAlert("Tỷ lệ giờ công không hợp lệ.");
            return ;
          }
          if(item.tyLe<=0){
            this.openAlert("Tỷ lệ giờ công không hợp lệ.");
            return ;
          }
         item.idNhanVien = this.userInfo.userId;
         item.idVuGiamDinh = this.vuGiamDinhID;
         item.ngayCong = new Date(this.ngayCong.replace(/-/g, "/"));
         item.ngayTao = new Date();
         item.soChungThu = this.soChungThu;
         item.tenNhanVien = this.userInfo.ten;
         item.trangThai = "chua-duyet";
         item.tyLe_Duyet = item.tyLe;
         item.vaiTro = this.vaiTro;
         console.log('viet item',item);
         this.openPreloader("Đang thực hiện...",2000);
          let rec = await axios.post(apiEndPoint + 'api/ChamCongs', item);
          if(rec.data){
           this.openAlert("Thực hiện thành công!");
           this.$f7router.back();
           EventLogin.$emit("reloadPageChiTietVGD");
         }

        },
        getTenNhanVien(id) {
            let found = this.dsNhanVien.find(e => e.id == id);
            if (!found) return "";
            return found.ten;
        },
        openFile(item){
          window.open(item, '_system'); return false;
        },
        formatDate(date){
          var result = dateFns.format(
              new Date(date),
              'dd/MM/yyyy HH:mm'
            )
          return result;
        },
        formatDate2(date){
          var result = dateFns.format(
              new Date(date),
              'yyyy-MM-dd'
            )
          return result;
        }

    },
    mounted() {},
    async created() {
        this.vuGiamDinhID = localStorage.getItem('eic_temp_vgdId');
        this.soChungThu = localStorage.getItem('eic_temp_soChungThu');
        this.vaiTro = localStorage.getItem('eic_temp_vaiTro');
        console.log('viet this.vuGiamDinhID',this.vuGiamDinhID);
        this.dsNhanVien = JSON.parse(localStorage.getItem('eic_dsNhanVien'));
        this.userInfo =  JSON.parse(localStorage.getItem('eic_userInfo'));
        this.ngayCong = this.formatDate2(new Date());
        this.onChangeNgayCong(this.ngayCong);

    }
}
</script>
