
<template>
<f7-toolbar tabbar labels :position="isBottom ? 'bottom' : 'top'">
    <f7-link tab-link-active tab-link="#tab-home">
        <i class="fa fa-home icon" aria-hidden="true"></i>
        <span class="tabbar-label textformat fs-roboto-medium">Trang chủ</span>
    </f7-link>
    <f7-link tab-link-active tab-link="#tab-thongbao">
        <i class="fa fa-bell icon" aria-hidden="true"></i>
        <span class="tabbar-label textformat fs-roboto-medium">Thông báo</span>
    </f7-link>
</f7-toolbar>
</template>

<script>
export default {

    data() {
        return {
            isBottom: true,

        }
    },
}
</script>
