
const TrangThai = {

  YC_TaoMoi_010: "YC_TaoMoi_010",
  YC_BiTraVe_013: "YC_BiTraVe_013",
  YC_DieuChinh_012: "YC_DieuChinh_012",
  YC_ThieuDoiTac_014: "YC_ThieuDoiTac_014",
  YC_ChoChapNhan_011: "YC_ChoChapNhan_011",
  YC_DaChapNhan_013: "YC_DaChapNhan_013",
  YC_BiXoa_0153: "YC_BiXoa_0153",

  VGD_NEW_020: "VGD_NEW_020",
  VGD_Cho_GDV_ChapNhap_021: "VGD_Cho_GDV_ChapNhap_021",
  VGD_XuLyHienTruong_022: "VGD_XuLyHienTruong_022",
  VGD_DuocCapNhat_023: "VGD_DuocCapNhat_023",
  VGD_ChoXacNhan_024: "VGD_ChoXacNhan_024",
  VGD_ChoPhanTichOLab_026: "VGD_ChoPhanTichOLab_026",
  VGD_DaCoKetQuaPhanTichOLab_0261: "VGD_DaCoKetQuaPhanTichOLab_0261",
  VGD_CoTheXuatBaoCao_028: "VGD_CoTheXuatBaoCao_028",
  VGD_HoanThanhHienTruong_025: "VGD_HoanThanhHienTruong_025",

  CT_DaTao_040: "CT_DaTao_040",
  CT_DieuChinh_041: "CT_DieuChinh_041",
  CT_ChoDuyet_042: "CT_ChoDuyet_042",
  CT_DaDuyet_043: "CT_DaDuyet_043",
  CT_DuocDieuChinh_044: "CT_DuocDieuChinh_044",
  CT_DuocDieuChinh_052: "CT_DuocDieuChinh_052",
  CT_BiTuChoi_0431: "CT_BiTuChoi_0431",
  CT_DaGuiKhachHang_049: "CT_DaGuiKhachHang_049",
  CT_KhachHangDongY_050: "CT_KhachHangDongY_050",
  CT_BiKHTuChoi_051: "CT_BiKHTuChoi_051",
  CT_ChoDuyetHuyCTDaDuyet_046: "CT_ChoDuyetHuyCTDaDuyet_046",
  CT_BiHuy_047: "CT_BiHuy_047",

  HD_VuaTao_060: "HD_VuaTao_060",
  HD_ChoDuyet_061: "HD_ChoDuyet_061",
  HD_DuocDuyet_062: "HD_DuocDuyet_062",
  HD_BiTuChoi_0621: "HD_BiTuChoi_0621",
  HD_ChoDuyetHuyHoaDonDaDuyet_063: "HD_ChoDuyetHuyHoaDonDaDuyet_063",
  HD_BiHuy_064: "HD_BiHuy_064",
  HD_ChoDuyetDeDongVuGiamDinh_066: "HD_ChoDuyetDeDongVuGiamDinh_066",
  HD_KhongDuyet_067: "HD_KhongDuyet_067",
  HD_BiXoa_0622 : "HD_BiXoa_0622",

  HD_HoanThanh_065: "HD_HoanThanh_065",
  VGD_HoanThanh_0200: "VGD_HoanThanh_0200",
  VGD_ChoXoa_0201: "VGD_ChoXoa_0201",
  VGD_BiXoa_0202: "VGD_BiXoa_0202",

  CT_HoanThanh_055: "CT_HoanThanh_055",
  CT_BiXoa_056: "CT_BiXoa_056",
  KHOITAO_DOITUONG: "KHOITAO_DOITUONG",
  HoanThanh_0201: "HoanThanh_0201",

  YC_DaHuy: "YC_DaHuy",
  VGD_DaHuy: "VGD_DaHuy",
  CT_DaHuy: "CT_DaHuy",
  HD_DaHuy: "HD_DaHuy",

  YC_ChoDuyetHuy: "YC_ChoDuyetHuy",
  VGD_ChoDuyetHuy: "VGD_ChoDuyetHuy",
  VGD_ChoDuyetHuy1: "VGD_ChoDuyetHuy1",
  VGD_ChoDuyetHuy2: "VGD_ChoDuyetHuy2",
  CT_ChoDuyetHuy: "CT_ChoDuyetHuy",
  HD_ChoDuyetHuy: "HD_ChoDuyetHuy",
  VGD_BiHuy: "VGD_BiHuy",

  YC_HoanThanh: "YC_HoanThanh",
  VGD_HoanThanh: "VGD_HoanThanh",
  CT_HoanThanh: "CT_HoanThanh",
  HD_HoanThanh: "HD_HoanThanh",

  LAB_KHOITAO_DOITUONG: "LAB_KHOITAO_DOITUONG",
  LAB_YCPT_TaoMoi_080: "LAB_YCPT_TaoMoi_080",
  LAB_YCPT_ChoChapNhan_081: "LAB_YCPT_ChoChapNhan_081",
  LAB_YCPT_BiTraVe_0821: "LAB_YCPT_BiTraVe_0821",
  LAB_YCPT_BiDieuChinh_083: "LAB_YCPT_BiDieuChinh_083",
  LAB_YCPT_DaChapNhan_082: "LAB_YCPT_DaChapNhan_082",
  LAB_YCPT_BiXoa_0824: "LAB_YCPT_BiXoa_0824",

  LAB_VPT_TaoMoi_0822: "LAB_VPT_TaoMoi_0822",
  LAB_VPT_DangXuLy_0823: "LAB_VPT_DangXuLy_0823",
  LAB_VPT_CoKetQuaPT_084: "LAB_VPT_CoKetQuaPT_084",
  LAB_VPT_ChoDuyetKetQuaPT_085: "LAB_VPT_ChoDuyetKetQuaPT_085",
  LAB_VPT_BiTuChoiKQPT_0861: "LAB_VPT_BiTuChoiKQPT_0861",
  LAB_VPT_BiDieuChinhKQPT_087: "LAB_VPT_BiDieuChinhKQPT_087",
  LAB_VPT_DaDuyetKQPT_086: "LAB_VPT_DaDuyetKQPT_086",
  LAB_VPT_DaGuiKhachHangKQPT_088: "LAB_VPT_DaGuiKhachHangKQPT_088",
  LAB_VPT_KhachHangChapNhanKQPT_0881: "LAB_VPT_KhachHangChapNhanKQPT_0881",
  LAB_VPT_KhachHangTuChoiKQPT_0882: "LAB_VPT_KhachHangTuChoiKQPT_0882",
  LAB_VPT_ChoDuyetHuy: "LAB_VPT_ChoDuyetHuy",
  LAB_VPT_DaHuy: "LAB_VPT_DaHuy",
  LAB_VPT_BiTuChoiHuy: "LAB_VPT_BiTuChoiHuy",

  LAB_HD_TaoMoi_089: "LAB_HD_TaoMoi_089",
  LAB_HD_ChoDuyet_090: "LAB_HD_ChoDuyet_090",
  LAB_HD_BiTuChoi_0911: "LAB_HD_BiTuChoi_0911",
  LAB_HD_BiDieuChinh_092: "LAB_HD_BiDieuChinh_092",
  LAB_HD_DuocDuyet_091: "LAB_HD_DuocDuyet_091",
  LAB_HD_ChoDuyetHuyHoaDonDaDuyet_094: "LAB_HD_ChoDuyetHuyHoaDonDaDuyet_094",
  LAB_HD_BiXoa_0912: "LAB_HD_BiXoa_0912",

  LAB_HD_ChoDuyetHuy: "LAB_HD_ChoDuyetHuy",
  LAB_HD_DaHuy: "LAB_HD_DaHuy",
  LAB_HD_BiTuChoiHuy: "LAB_HD_BiTuChoiHuy",

  LAB_VPT_HoanThanh_093: "LAB_VPT_HoanThanh_093"
}

export default TrangThai;
