<style>
  .my-title-font-size{
      font-size: 12px;
  }
  .my-label-font-size{
      font-size: 14px;
  }
</style>
<template>
<f7-page :page-content="false"  class="my-bg-color">
    <f7-navbar class="">
        <f7-nav-left  back-link="Back"></f7-nav-left>
        <f7-nav-title>Chọn danh sách Niêm</f7-nav-title>

    </f7-navbar>




   


      <f7-fab position="right-bottom" slot="fixed" >
        <i class="fa fa-bars" aria-hidden="true"></i>
        <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
        <f7-fab-buttons position="top">
           <f7-fab-button :fab-close="true" label="Lưu" @click="runSave()"  color="orange">
            <i class="fas fa-save" aria-hidden="true"></i>
          </f7-fab-button>
         
          <f7-fab-button :fab-close="true" label="Trang chủ"    color="lightblue">
            <i class="fa fa-home" aria-hidden="true"></i>
          </f7-fab-button>

        </f7-fab-buttons>
      </f7-fab>

        <div class="page-content">
           
            <div class="card">
                <f7-list>
                    <f7-list-item accordion-item :title="'VGD số ' + vuGiamDinh.soChungThu" :accordion-item-opened="true">
                        <f7-accordion-content>
                            <f7-list >
                               <f7-list-item class="my-title-font-size" title="Danh sách Niêm:" smart-select :smart-select-params="{openIn: 'sheet'}">
                                    <select name="niemSuDung" multiple v-model="dsNiemSuDungId">
                                        <option :key="index" v-for="(item, index) in dsNewNiemThuocGDV" :value="item.id">{{item.guid}} ({{getMaPhongBan(item.departmentId)}})</option>
                                    </select>
                                </f7-list-item>
                            </f7-list>
                            <f7-list-item title="Loại niêm :" smart-select :smart-select-params="{openIn: 'sheet', closeOnSelect: true}">
                                    <select  name="loaiNiemSelect" v-model="loaiNiem">
                                        <option value="niem_tau">Niêm tàu</option>
                                        <option value="niem_xalan">Niêm xà lan</option>
                                        <option value="niem_bon">Niêm bồn</option>
                                        <option value="niem_xe_bon">Niêm xe bồn</option>
                                        <option value="niem_mau">Niêm mẫu</option>
                                        <option value="niem_duongong">Niêm đường ống</option>
                                        <option value="niem_khac">Khác</option>
                                    </select>
                            </f7-list-item>
                            <f7-list-input label="Ngày niêm phong:" type="date" placeholder=""  :value="ngayNiemPhong" @input="ngayNiemPhong = $event.target.value">
                            </f7-list-input>
                        </f7-accordion-content>
                    </f7-list-item>
                </f7-list>
            </div>
            <f7-button medium raised fill style="margin: 15px; height: 40px; line-height: 40px;" @click="runSave()">
                    <f7-link class="fs-roboto-bold text-color-white"  >Lưu</f7-link>
            </f7-button>
        </div>

    
</f7-page>
</template>

<script>
import {
    Roles,
} from "../../global.js";
import TrangThai from "../../common.js";
var dateFns = require('date-fns');
import NavBar from "../components/NavBar.vue";
import apiEndPoint from "../../apiEndpoint.js";
import axios from "axios";
import {
    EventLogin
} from "../event-login.js";
export default {
    components: {
        NavBar,
    },
    data() {
        return {
            phongBanItems: [],
            dsNewNiemGiamDinhVien: [],
            clickChangePhanLoaiNiem: false,
            dsNiemSuDungId: [],
            dsNiemThuocGDV: [],
            dsNewNiemThuocGDV: [],
            loaiNiem: '',
            ngayNiemPhong: null
        };
    },
    updated() {

    },
    mounted() {
     
       
    },
    methods: {
        getMaPhongBan(id){
            let itemPB = this.phongBanItems.find(e => e.id === id);
            return itemPB?itemPB.ma:'';
        },
        formatDate(date) { // trả về ngày chuỗi yyyy-mm-dd
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        async getDSNiemDuocCapPhat(gdvId) {
            let url = apiEndPoint + "api/Seals/getAllNiemThuocGDV_status_NEW";
            let result = [];
            try {
                let res = await axios.get(url, {
                    params: {
                        userId : gdvId,
                    }
                });
                result = res.data.result;

            } catch (error) {
                console.log(error);
            }
            return result;
        },
        async getDSPhongBan() {
            let url = apiEndPoint + "api/PhongBans";
            let result = [];
            try {
                let res = await axios.get(url);
                result = res.data;
                console.log('rés',res.data);
            } catch (error) {
                console.log(error);
            }
            return result;
        },
        async getNhanVienById(userId) {
            let url = apiEndPoint + "api/AppUsers/"+userId;
            let result = [];
            try {
                let res = await axios.get(url);
                result = res.data;
            } catch (error) {
                console.log(error);
            }
            return result;
        },
        getSealStatus(type){
            switch(type){
                case 'NEW': return 'Mới'
                case 'USED': return 'Đã dùng'
                case 'BROKEN': return 'Hỏng'
                case 'LOST': return 'Mất'
            }
        },
        getSealType(type){
            switch(type){
                case 'niem_tau': return 'Niêm tàu'
                case 'niem_xalan': return 'Niêm xà lan'
                case 'niem_bon': return 'Niêm bồn'
                case 'niem_xe_bon': return 'Niêm xe bồn'
                case 'niem_mau': return 'Niêm mẫu'
                case 'niem_duongong': return 'Niêm đường ống'
                case 'niem_khac': return 'Khác'
            }
        },
         async getChiTietNiem(id) {
            try {
                let res = await axios.get(apiEndPoint + "api/Seals/" + id);
                return res.data;
            } catch (error) {
                console.error(error);
                return {};
            }
        },
         openAlert(titlename, cb) {
            const app = this.$f7;
            app.dialog.alert('' + titlename, 'Thông báo', cb);
        },
        openPreloader(titlename, time) {
            const app = this.$f7;
            app.dialog.preloader(titlename);
            setTimeout(function () {
              app.dialog.close();
            }, time);
        },
         async GuiEmail(fromEmail, toListEmails, subject, bodyText, LoaiEmail) {
            console.log('viet GuiEmail  test', toListEmails);
            let item = {
                to: toListEmails, from: fromEmail,
                subject: subject, text: bodyText
            };
            let rec = await axios.post(apiEndPoint+ "api/ActionHistories/sendEmail", item);
            return rec.data;
        },
        async ThongBaoNiemDaSuDung(data ,soChungThu , toDay){
            let listSealNumbers = data.dsNiemSuDung.map(e => e.guidNumber); 
            listSealNumbers = listSealNumbers.sort((a,b)=> a-b);
            let user = await this.getNhanVienById(this.userInfo.userId)
            let text = '<table><tr><td>Tổng số niêm sử dụng:</td><td>'+ listSealNumbers.length + '</td></tr>';
            text += '<tr><td>Danh sách niêm sử dụng:</td><td>' + listSealNumbers + '</td></tr>';
            text += '<tr><td>Vụ giám định:</td><td>' + soChungThu + '</td></tr>';
            text += '<tr><td>Trạng thái niêm:</td><td>Đã sử dụng </td></tr>';
            text += '<tr><td>Loại niêm:</td><td>' + this.getSealType(data.loaiNiem) + '</td></tr>';
            text += '<tr><td>Người niêm phong:</td><td>' + user.ten + '</td></tr>';
            text += '<tr><td>Ngày niêm phong:</td><td>'+ dateFns.format(new Date(data.ngayNiemPhong),'dd/MM/yyyy') + '</td></tr>'; 
            text += '<tr><td>Ngày nhập lên hệ thống:</td><td>' + dateFns.format(new Date(toDay),'dd/MM/yyyy HH:mm:ss') + '</td></tr>';
            text += '</table>'
            let title  = "Thông báo đã cập nhật Niêm sử dụng cho VGD "+ soChungThu + " lên hệ thống QLGD bởi " + user.ten;
            let fromEmail = 'admin@eic.com.vn';
            try{
                await this.GuiEmail(fromEmail,user.email,title,text,'THONGBAO');
            }catch(err){
                console.error(err);
                this.openAlert("Có lỗi xảy ra!");
            }
        },
        async runSave(){
            if(!this.dsNiemSuDungId.length){
                this.openAlert("Vui lòng chọn Niêm");
                return;
            }
            if(!this.loaiNiem){
                this.openAlert("Vui lòng chọn Phân loại niêm");
                return;
            }
            if(!this.ngayNiemPhong){
                this.openAlert("Vui lòng chọn ngày niêm phong");
                return;
            }

            this.openPreloader("Đang thực hiện...",2000);
            const today = new Date();
            try{
                for(let sealId of this.dsNiemSuDungId){
                    let item = {
                        status: "USED",
                        type: this.loaiNiem,
                        dayUsed: this.ngayNiemPhong,
                        soChungThu: this.vuGiamDinh.soChungThu,
                        dayInput: today
                    }
                    axios.patch(apiEndPoint + 'api/Seals/' + sealId, item)
                }
                // Gửi email thông báo niêm đã cập nhật lên hệ thống
                let dsNiemSuDung = this.dsNewNiemThuocGDV.filter(e => {
                    if(this.dsNiemSuDungId.includes(e.id)){
                        return e
                    }
                })
                let data = {
                    dsNiemSuDung : dsNiemSuDung,
                    dsNiemSuDungId: this.dsNiemSuDungId,
                    loaiNiem: this.loaiNiem,
                    ngayNiemPhong: this.ngayNiemPhong
                }
                await this.ThongBaoNiemDaSuDung(data,this.vuGiamDinh.soChungThu, today);
                // this.openAlert("Thực hiện thành công!");
                EventLogin.$emit("reloadPageChiTietVGD");
                this.$f7router.back();
               
            }catch(err){
                console.error(err);
                this.openAlert("Có lỗi xảy ra!");
            }

      },
    },
    async created() {
        this.ngayNiemPhong = this.formatDate(new Date());
        this.userInfo =  JSON.parse(localStorage.getItem('eic_userInfo'));
        this.vuGiamDinh =  JSON.parse(localStorage.getItem('eic_temp'));
        this.dsNiemThuocGDV =  await this.getDSNiemDuocCapPhat(this.userInfo.userId);
        this.dsNewNiemThuocGDV = this.dsNiemThuocGDV.filter(e => e.status === "NEW");
        this.phongBanItems = await this.getDSPhongBan();
    }
}
</script>
