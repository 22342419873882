<template>
  <f7-page :page-content="false" @page:init="onPageInit" class="my-bg-color">
    <f7-navbar class>
      <f7-nav-left back-link="Back"></f7-nav-left>
      <f7-nav-title>Chi tiết Yêu Cầu Phân Tích</f7-nav-title>
    </f7-navbar>
    <div class="page-content">
     <f7-block-title>Thông tin phân tích :</f7-block-title>
      <div class="card">
        <f7-list no-hairlines-md>
          <f7-list-input
            disabled
            label="Đơn vị yêu cầu :"
            type="text"
            placeholder
            :value="getTenKhachHang(chiTietYCPT.khachHangId)"
          ></f7-list-input>
          <f7-list-input
            disabled
            label="Tên mẫu :"
            type="text"
            placeholder
            :value="chiTietYCPT.tenMau"
          ></f7-list-input>
          <f7-list-input
            disabled
            label="Số niêm :"
            type="text"
            placeholder
            :value="chiTietYCPT.soNiem"
          ></f7-list-input>
          <f7-list-input
            disabled
            label="Số chứng thư Vụ Giám Định :"
            placeholder
            type="text"
            :value="chiTietYCPT.soChungThuVuGiamDinh"
          ></f7-list-input>
          <f7-list-input
            disabled
            label="Nơi lấy mẫu :"
            type="text"
            placeholder
            :value="chiTietYCPT.noiLayMau"
          ></f7-list-input>
          <f7-list-input
            disabled
            label="Trung tâm phân tích :"
            type="text"
            placeholder
            :value="getTenPhongBan(chiTietYCPT.phongBanId)"
          ></f7-list-input>
          <!-- <f7-list-input disabled label="Ngày gởi yêu cầu:" type="text" placeholder="" :value="formatDate(chiTietYCPT.ngayYeuCau)">
          </f7-list-input>-->
     
        </f7-list>
      </div>
     <f7-block-title>Bộ chỉ tiêu :</f7-block-title>
      <div class="card">
          <div class="data-table data-table-init">
            <table>
              <thead>
                <tr>
                  <th class="label-cell">PT</th>
                  <th class="label-cell">Chỉ tiêu</th>
                  <th class="label-cell">Phương Pháp</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="index" v-for="(item, index) in chiTietYCPT.cacChiTieuPhanTich">
                  <td class="checkbox-cell">
                    <label class="checkbox">
                      <input type="checkbox" :checked="item.isSelected" disabled />
                      <i class="icon-checkbox"></i>
                    </label>
                  </td>
                  <td class="label-cell">{{item.tenChiTieu}}</td>
                  <td class="label-cell">{{item.phuongPhapThu}}</td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import TrangThai from "./../../common.js";
var dateFns = require("date-fns");
import NavBar from "./../components/NavBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
import { EventLogin } from "../event-login.js";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      chiTietYCPT: {},
      clickChangeTenCongViec: false,
    };
  },
  updated() {},
  mounted() {},
  methods: {
    onPageInit() {},
    getTenKhachHang(id) {
      let found = this.dsKhachHangLab.find((e) => e.id == id);
      if (!found) return "";
      return found.hoTen;
    },
    getTenPhongBan(id) {
      let found = this.dsPhongBan.find((e) => e.id == id);
      if (!found) return "";
      return found.ten;
    },
    getTenNhanVien(id) {
      let found = this.dsNhanVien.find((e) => e.id == id);
      if (!found) return "";
      return found.ten;
    },
    openFile(item) {
      window.open(item, "_system");
      return false;
    },
    formatDate(date) {
      var result = dateFns.format(new Date(date), "dd/MM/yyyy HH:mm");
      return result;
    },
  },
  async created() {
    this.chiTietYCPT = JSON.parse(localStorage.getItem("eic_temp"));

    this.dsKhachHangLab = JSON.parse(
      localStorage.getItem("eic_dsKhachHangLab")
    );
    this.dsNhanVien = JSON.parse(localStorage.getItem("eic_dsNhanVien"));
    this.dsPhongBan = JSON.parse(localStorage.getItem("eic_dsPhongBan"));
    // this.dsHangMucYCGD = JSON.parse(localStorage.getItem('eic_dsHangMucYCGD'));
    // this.userInfo =  JSON.parse(localStorage.getItem('eic_userInfo'));
    // this.dsGiamDinhVien = this.getAllGDV(this.chiTietVGD);
    // console.log('viet this.dsGiamDinhVien ',this.dsGiamDinhVien );
  },
};
</script>
