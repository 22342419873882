<template>
<f7-page :page-content="false" @page:init="onPageInit" class="my-bg-color">
    <f7-navbar class="">
        <f7-nav-left back-link="Back"></f7-nav-left>
        <f7-nav-title>Chi tiết công việc GĐV</f7-nav-title>

    </f7-navbar>
    <div class="page-content">
        <div class="card">
            <f7-list no-hairlines-md >
                <f7-list-item disabled class="my-title-font-size" title="Tên công việc :" smart-select :smart-select-params="{openIn: 'sheet', closeOnSelect: true}">
                                        <!-- <div class="my-label-font-size" v-if="!clickChangeTenCongViec" > {{chiTietCV.tenCongViec}}</div> -->
                                        <select name="tenCongViec" v-model="chiTietCV.tenCongViec" >
                                            <option value="Hoàn thành hiện trường">Hoàn thành hiện trường</option>
                                            <option value="Báo cáo sơ bộ">Báo cáo sơ bộ</option>
                                            <option value="Báo cáo chuyển tiếp">Báo cáo chuyển tiếp</option>
                                            <option value="Báo cáo cuối cùng">Báo cáo cuối cùng</option>
                                        </select>
                </f7-list-item>
                <f7-list-input label="Mô tả :" type="text" placeholder="" :value="chiTietCV.noiDung" @input="chiTietCV.noiDung = $event.target.value">
                </f7-list-input>
                <f7-list-input label="Ngày giờ :" type="text" placeholder="" :value="formatDate(chiTietCV.thoiGian)" disabled>
                </f7-list-input>
                <f7-list-input label="Tên tài liệu :" type="text" placeholder="" :value="chiTietCV.tenTaiLieu" v-if="chiTietCV.duongDanFileTaiLieu">
                </f7-list-input>
                <f7-list-item header="File tài liệu :" v-if="chiTietCV.duongDanFileTaiLieu">
                <f7-link @click="openFile(chiTietCV.duongDanFileTaiLieu)" href="" target= '_blank' external> Xem
                </f7-link>
                </f7-list-item>

                <f7-list-input label="GĐV thực hiện" type="text" placeholder="" :value="getTenNhanVien(chiTietCV.userId)" disabled>
                </f7-list-input>
            </f7-list>
        </div>
    </div>


</f7-page>
</template>

<script>
import TrangThai from "./../../common.js";
var dateFns = require('date-fns');
import NavBar from "./../components/NavBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
import {
    EventLogin
} from "../event-login.js";
export default {
    components: {
        NavBar,
    },
    data() {
        return {
          chiTietCV: null,
          clickChangeTenCongViec: false,
        };
    },
    updated() {},
    mounted() {

    },
    methods: {
        onPageInit() {

        },
        getTenNhanVien(id) {
            let found = this.dsNhanVien.find(e => e.id == id);
            if (!found) return "";
            return found.ten;
        },
        openFile(item){
          window.open(item, '_system'); return false;
        },
        formatDate(date){
          var result = dateFns.format(
              new Date(date),
              'dd/MM/yyyy HH:mm'
            )
          return result;
        }

    },
    mounted() {},
    async created() {
        // await this.getChiTietVGD();
        this.chiTietCV = JSON.parse(localStorage.getItem('eic_temp'));

        // this.dsKhachHang = JSON.parse(localStorage.getItem('eic_dsKhachHang'));
        this.dsNhanVien = JSON.parse(localStorage.getItem('eic_dsNhanVien'));
        // this.dsHangMucYCGD = JSON.parse(localStorage.getItem('eic_dsHangMucYCGD'));
        // this.userInfo =  JSON.parse(localStorage.getItem('eic_userInfo'));
        // this.dsGiamDinhVien = this.getAllGDV(this.chiTietVGD);
        // console.log('viet this.dsGiamDinhVien ',this.dsGiamDinhVien );

    }
}
</script>
