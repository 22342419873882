<template>
<f7-page class="style-bg" @page:init="onPageInit" @page:beforeremove="onPageBeforeRemove">
    <f7-navbar>
        <f7-nav-left back-link="Back"></f7-nav-left>
        <f7-nav-title class="fs-roboto-medium">Đăng ký</f7-nav-title>
        <f7-nav-right>
            <f7-link></f7-link>
        </f7-nav-right>
    </f7-navbar>
    <f7-toolbar class="" style="height: 45px;" tabbar labels :position="isBottom ? 'bottom' : 'top'">
        <f7-link tab-link-active tab-link @click="register()" class="justify-content-center">
            <!-- <i class="fa fa-home icon" style="font-size: 24px" aria-hidden="true"></i> -->
            <span class="tabbar-label textformat fs-roboto-bold fs-15 text-color-white" style="text-transform: capitalize;">Gởi</span>
        </f7-link>
    </f7-toolbar>
    <f7-popup class="demo-popup-tinh-tp" :opened="popupOpenedTinhTP" @popup:closed="popupOpenedTinhTP = false">
        <f7-view main>
            <f7-page :page-content="false" @page:beforeremove="onPageBeforeRemove">
                <div class="navbar">
                    <div class="navbar-bg"></div>
                    <div class="navbar-inner sliding">
                        <f7-nav-left>
                            <f7-link class="text-color-white back" @click="popupOpenedTinhTP = false"><i class="icon icon-back"></i><span class=""></span></f7-link>
                        </f7-nav-left>
                        <div class="title">Tỉnh/thành phố</div>
                        <div class="subnavbar">
                            <form data-search-container=".virtual-list" data-search-item="li" data-search-in=".item-title" class="searchbar searchbar-init">
                                <div class="searchbar-inner">
                                    <div class="searchbar-input-wrap">
                                        <input type="search" placeholder="Search" />
                                        <i class="searchbar-icon"></i>
                                        <span class="input-clear-button"></span>
                                    </div>
                                    <span class="searchbar-disable-button if-not-aurora">Cancel</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="searchbar-backdrop"></div>
                <div class="page-content smart-select-page" style="padding-top: 91px">
                    <div class="list simple-list searchbar-not-found">
                        <ul>
                            <li>Nothing found</li>
                        </ul>
                    </div>
                    <f7-list class="searchbar-found no-margin style-text-search" media-list virtual-list>
                        <ul>
                            <li class="" v-for="(item, index) in dataTinhTP" :key="index">
                                <label class="item-radio item-content">
                                    <input type="radio" name="radio-demo-tp" @change="onChange_tinhThanh(item, $event)" :value="`${item.slug}`">
                                    <i class="icon icon-radio"></i>

                                    <div class="item-inner">
                                        <div class="item-title">{{item.name}}</div>
                                    </div>
                                </label>
                            </li>

                        </ul>
                    </f7-list>
                </div>
            </f7-page>
        </f7-view>
    </f7-popup>
    <f7-popup class="demo-popup-quan-huyen" :opened="popupOpenedQuanHuyen" @popup:closed="popupOpenedQuanHuyen = false">
        <f7-view main>
            <f7-page :page-content="false" @page:beforeremove="onPageBeforeRemove">
                <div class="navbar">
                    <div class="navbar-bg"></div>
                    <div class="navbar-inner sliding">
                        <f7-nav-left>
                            <f7-link class="text-color-white back" @click="popupOpenedQuanHuyen = false"><i class="icon icon-back"></i><span class=""></span></f7-link>
                        </f7-nav-left>
                        <div class="title">Quận Huyện</div>
                        <div class="subnavbar">
                            <form data-search-container=".virtual-list" data-search-item="li" data-search-in=".item-title" class="searchbar searchbar-init">
                                <div class="searchbar-inner">
                                    <div class="searchbar-input-wrap">
                                        <input type="search" placeholder="Search" />
                                        <i class="searchbar-icon"></i>
                                        <span class="input-clear-button"></span>
                                    </div>
                                    <span class="searchbar-disable-button if-not-aurora">Cancel</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="searchbar-backdrop"></div>
                <div class="page-content smart-select-page" style="padding-top: 91px">
                    <div class="list simple-list searchbar-not-found">
                        <ul>
                            <li>Nothing found</li>
                        </ul>
                    </div>
                    <f7-list class="searchbar-found no-margin style-text-search" media-list virtual-list>
                        <ul>

                            <li v-for="(item, index) in dataQuanHuyen" :key="index">
                                <label class="item-radio item-content">
                                    <input type="radio" name="radio-demo-qh" @change="onChange_quanHuyen(item, $event)" :value="`${item.slug}`">
                                    <i class="icon icon-radio"></i>

                                    <div class="item-inner">
                                        <div class="item-title">{{item.name_with_type}}</div>
                                    </div>
                                </label>
                            </li>

                        </ul>
                    </f7-list>
                </div>
            </f7-page>
        </f7-view>
    </f7-popup>
    <f7-popup class="demo-popup-phuong-xa" :opened="popupOpenedPhuongXa" @popup:closed="popupOpenedPhuongXa = false">
        <f7-view main>
            <f7-page :page-content="false" @page:beforeremove="onPageBeforeRemove">
                <div class="navbar">
                    <div class="navbar-bg"></div>
                    <div class="navbar-inner sliding">
                        <f7-nav-left>
                            <f7-link class="text-color-white back" @click="popupOpenedPhuongXa = false"><i class="icon icon-back"></i><span class=""></span></f7-link>
                        </f7-nav-left>
                        <div class="title">Phường Xã</div>
                        <div class="subnavbar">
                            <form data-search-container=".virtual-list-px" data-search-item="li" data-search-in=".item-title" class="searchbar searchbar-init">
                                <div class="searchbar-inner">
                                    <div class="searchbar-input-wrap">
                                        <input type="search" placeholder="Search" />
                                        <i class="searchbar-icon"></i>
                                        <span class="input-clear-button"></span>
                                    </div>
                                    <span class="searchbar-disable-button if-not-aurora">Cancel</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="searchbar-backdrop"></div>
                <div class="page-content smart-select-page" style="padding-top: 91px">
                    <div class="list simple-list searchbar-not-found">
                        <ul>
                            <li>Nothing found</li>
                        </ul>
                    </div>
                    <f7-list class="searchbar-found no-margin style-text-search" media-list virtual-list>
                        <ul>
                            <li @click="closeSmartSelect()" v-for="(item, index) in dataXaPhuong" :key="index">
                                <label class="item-radio item-content">
                                    <input type="radio" name="radio-demo-px" @change="onChange_phuongXa(item, $event)" :value="`${item.slug}`">
                                    <i class="icon icon-radio"></i>

                                    <div class="item-inner">
                                        <div class="item-title">{{item.name_with_type}}</div>
                                    </div>
                                </label>
                            </li>
                        </ul>
                    </f7-list>
                </div>
            </f7-page>
        </f7-view>
    </f7-popup>
    <f7-popup class="demo-popup-nam-sinh" :opened="popupOpenedNamSinh" @popup:closed="popupOpenedNamSinh = false">
        <f7-view main>
            <f7-page :page-content="false" @page:beforeremove="onPageBeforeRemove">
                <div class="navbar">
                    <div class="navbar-bg"></div>
                    <div class="navbar-inner sliding">
                        <f7-nav-left>
                            <f7-link class="text-color-white back" @click="popupOpenedNamSinh = false"><i class="icon icon-back"></i><span class=""></span></f7-link>
                        </f7-nav-left>
                        <div class="title">Chọn năm sinh</div>
                        <div class="subnavbar">
                            <form data-search-container=".virtual-list-px" data-search-item="li" data-search-in=".item-title" class="searchbar searchbar-init">
                                <div class="searchbar-inner">
                                    <div class="searchbar-input-wrap">
                                        <input type="search" placeholder="Search" />
                                        <i class="searchbar-icon"></i>
                                        <span class="input-clear-button"></span>
                                    </div>
                                    <span class="searchbar-disable-button if-not-aurora">Cancel</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="searchbar-backdrop"></div>
                <div class="page-content smart-select-page" style="padding-top: 91px">
                    <div class="list simple-list searchbar-not-found">
                        <ul>
                            <li>Nothing found</li>
                        </ul>
                    </div>
                    <f7-list class="searchbar-found no-margin style-text-search" media-list virtual-list>
                        <ul>
                            <li @click="closeSmartSelect()" v-for="(item, index) in arrNamSinh" :key="index">
                                <label class="item-radio item-content">
                                    <input type="radio" name="radio-demo-ns" @change="onChange_namSinh(item, $event)" :value="`${item}`">
                                    <i class="icon icon-radio"></i>

                                    <div class="item-inner">
                                        <div class="item-title">{{item}}</div>
                                    </div>
                                </label>
                            </li>
                        </ul>
                    </f7-list>
                </div>
            </f7-page>
        </f7-view>
    </f7-popup>
    <f7-block class="no-padding no-margin">
        <f7-block class="no-padding no-margin-bottom" style="margin-top: 15px">
            <div class="list no-hairlines ios no-margin" style="width: 100%">
                <ul style="border-radius: 5px;">
                    <li class="item-content item-input">
                        <div class="item-inner no-margin-left">
                            <div class="item-title item-label fs-roboto-medium text-color-black">Bạn thuộc đối tượng <span class="text-color-red">*</span></div>
                            <div class="item-input-wrap style-fs-input" style="padding-top: 5px;">
                                <f7-row>
                                    <f7-col width="50" :key="index" v-for="(item, index) in listDoiTuong">
                                        <div class="list no-hairlines md no-margin">
                                            <ul class="no-padding-left">
                                                <li>
                                                    <label class="item-radio item-content">
                                                        <input type="radio" name="doi-tuong" :value="item.slug" @change="onChangeDoiTuong(index, item, $event)" />
                                                        <i class="icon icon-radio" style="left: 0; margin-top: -15px;"></i>
                                                        <div class="item-inner">
                                                            <div class="item-title fs-roboto-light fs-15" style="padding-left: 15px;">{{item.tendoituong}}</div>
                                                        </div>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </f7-col>
                                </f7-row>
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input" v-if="showDoiTuongCaNhan">
                        <div class="item-inner no-margin-left">
                            <div class="item-title item-label fs-roboto-medium text-color-black">Họ và Tên <span class="text-color-red">*</span></div>
                            <div class="item-input-wrap style-fs-input">
                                <input class="fs-roboto-light no-padding-inner" required validate type="text" v-model="name" placeholder="Nhập họ và tên...">
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input" v-if="showDoiTuongCLB">
                        <!-- <div class="item-media align-self-center">
                            <i class="fa fa-volume-control-phone" style="font-size: 20px" aria-hidden="true"></i>
                        </div> -->
                        <div class="item-inner no-margin-left">
                            <div class="item-title item-label fs-roboto-medium text-color-black">Tên câu lạc bộ <span class="text-color-red">*</span></div>
                            <div class="item-input-wrap style-fs-input">
                                <input class="fs-roboto-light no-padding-inner" required validate type="text" v-model="name" placeholder="Nhập tên CLB...">
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input" v-if="showDoiTuongCaNhan">
                        <div class="item-inner no-margin-left">
                            <div class="item-title item-label fs-roboto-medium text-color-black">Chọn giới tính <span class="text-color-red">*</span></div>
                            <div class="item-input-wrap style-fs-input" style="padding-top: 5px;">
                                <f7-row>
                                    <f7-col width="50">
                                        <div class="list no-hairlines md no-margin">
                                            <ul class="no-padding-left">
                                                <li>
                                                    <label class="item-radio item-content">
                                                        <input type="radio" name="gioiTinh" value="nam" @change="onChangeGioiTinh($event)" />
                                                        <i class="icon icon-radio" style="left: 0; margin-top: -15px;"></i>
                                                        <div class="item-inner">
                                                            <div class="item-title fs-roboto-light fs-15" style="padding-left: 15px;">Nam</div>
                                                        </div>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </f7-col>
                                    <f7-col width="50">
                                        <div class="list no-hairlines md no-margin">
                                            <ul class="no-padding-left">
                                                <li>
                                                    <label class="item-radio item-content">
                                                        <input type="radio" name="gioiTinh" value="nu" @change="onChangeGioiTinh($event)" />
                                                        <i class="icon icon-radio" style="left: 0; margin-top: -15px;"></i>
                                                        <div class="item-inner">
                                                            <div class="item-title fs-roboto-light fs-15" style="padding-left: 15px;">Nữ</div>
                                                        </div>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </f7-col>
                                </f7-row>
                            </div>
                        </div>
                    </li>
                    <li v-if="showDoiTuongCaNhan">
                        <!-- <div class="item-inner no-margin-left"> -->
                        <div class="item-title item-label fs-roboto-medium text-color-black" style="padding-left: 15px; padding-top: 10px">Năm sinh</div>
                        <!-- <div class="item-input-wrap style-fs-input"> -->
                        <!-- <input type="text" class="fs-roboto-light fs-15" @click="showCalendar()" placeholder="Chọn ngày tháng năm" readonly="readonly" id="demo-calendar-default1" /> -->
                        <!-- <input class="fs-roboto-light fs-15" type="text" placeholder="Date Time" :value="birthdate" readonly="readonly" id="demo-picker-date" /> -->
                        <!-- <input type="text" placeholder="Date Time" @click="showCalendar()" readonly="readonly" id="demo-picker-date" /> -->
                        <!-- </div> -->
                        <!-- <div class="block no-padding no-margin margin-bottom">
                                <div id="demo-picker-date-container"></div>
                            </div> -->
                        <!-- </div> -->
                        <!-- <a class="item-link smart-select smart-select-init style-item-content">
                            <select name="namsinh">
                                <option v-for="(item, index) in arrNamSinh" :key="index" :value="item">{{item}}</option>
                            </select>
                            <div class="item-content">
                                <div class="item-inner">
                                    <div class="item-title fs-roboto-light fs-15">Chọn năm sinh</div>
                                </div>
                            </div>
                        </a> -->
                        <f7-link href="#" @click="popupOpenedNamSinh = true" class="item-link item-content" style="padding-right: 15px;">
                            <div class="item-inner">
                                <div class="item-title fs-roboto-light fs-15">Chọn năm sinh</div>
                                <div class="item-after fs-roboto-light fs-15">{{getNamSinh}}</div>
                            </div>
                        </f7-link>
                    </li>
                    <li class="item-content item-input" v-if="showDoiTuongCaNhan || showDoiTuongCLB">
                        <!-- <div class="item-media align-self-center">
                            <i class="fa fa-volume-control-phone" style="font-size: 20px" aria-hidden="true"></i>
                        </div> -->
                        <div class="item-inner no-margin-left">
                            <div class="item-title item-label fs-roboto-medium text-color-black">Tài khoản đăng nhập(số điện thoại) <span class="text-color-red">*</span></div>
                            <div class="item-input-wrap style-fs-input">
                                <input class="fs-roboto-light no-padding-inner" required validate type="number" v-model="phone" placeholder="Nhập số điện thoại...">
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input" v-if="showDoiTuongCaNhan || showDoiTuongCLB">
                        <div class="item-inner no-margin-left">
                            <div class="item-title item-label fs-roboto-medium text-color-black">Mật khẩu <span class="text-color-red">*</span></div>
                            <div class="item-input-wrap style-fs-input">
                                <input class="fs-roboto-light no-padding-inner" required validate type="password" v-model="password" placeholder="Nhập mật khẩu...">
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input" v-if="showDoiTuongCaNhan || showDoiTuongCLB">
                        <div class="item-inner no-margin-left">
                            <div class="item-title item-label fs-roboto-medium text-color-black">Xác nhận mật khẩu <span class="text-color-red">*</span></div>
                            <div class="item-input-wrap style-fs-input">
                                <input class="fs-roboto-light no-padding-inner" required validate type="password" v-model="re_password" placeholder="Nhập lại mật khẩu...">
                            </div>
                        </div>
                    </li>
                    <li class="item-content item-input" v-if="showDoiTuongCaNhan || showDoiTuongCLB">
                        <div class="item-inner no-margin-left">
                            <div class="item-title item-label fs-roboto-medium text-color-black">Email</div>
                            <div class="item-input-wrap style-fs-input">
                                <input class="fs-roboto-light no-padding-inner" type="email" v-model="email" placeholder="Nhập email">
                            </div>
                        </div>
                    </li>
                    <!-- smart-select-one -->
                    <!-- <li v-if="showDoiTuongCaNhan || showDoiTuongCLB">
                        <a class="item-link smart-select smart-select-init smart-select-tinh-tp smart-select-closed" data-open-in="page" data-virtual-list="true" data-searchbar="true" data-searchbar-placeholder="Tìm kiếm">
                            <div class="fs-roboto-medium fs-13 text-color-black" style="padding-left: 15px; padding-top: 5px;">Địa chỉ <span class="text-color-red">*</span></div>
                            <select name="thanhpho" v-model="tinhThanhSelect" @change="onChange_tinhThanh($event)">
                                <option v-for="(item , index) in dataTinhTP" :key="index" :value="item.slug">{{item.name}}</option>
                            </select>
                            <div class="item-content style-item-after">
                                <div class="item-inner">
                                    <div class="item-title fs-roboto-light fs-15">Tỉnh/thành phố</div>
                                </div>
                            </div>
                        </a>
                    </li> -->
                    <li v-if="showDoiTuongCaNhan || showDoiTuongCLB">
                        <div class="fs-roboto-medium fs-13 text-color-black" style="padding-left: 15px; padding-top: 5px;">Địa chỉ <span class="text-color-red">*</span></div>
                        <f7-link href="#" @click="popupOpenedTinhTP = true" class="item-link item-content">
                            <div class="item-inner">
                                <div class="item-title fs-roboto-light fs-15">Tỉnh/thành phố</div>
                                <div class="item-after fs-roboto-light fs-15">{{getNameTinhThanh}}</div>
                            </div>
                        </f7-link>
                    </li>
                    <li v-if="showDoiTuongCaNhan || showDoiTuongCLB">
                        <f7-link href="#" @click="popupOpenedQuanHuyen = true" class="item-link item-content">
                            <div class="item-inner">
                                <div class="item-title fs-roboto-light fs-15">Quận/Huyện</div>
                                <div class="item-after fs-roboto-light fs-15">{{getNameQuanHuyen}}</div>
                            </div>
                        </f7-link>
                    </li>
                    <li v-if="showDoiTuongCaNhan || showDoiTuongCLB">
                        <f7-link href="#" @click="popupOpenedPhuongXa = true" class="item-link item-content">
                            <div class="item-inner">
                                <div class="item-title fs-roboto-light fs-15">Phường/Xã</div>
                                <div class="item-after fs-roboto-light fs-15">{{getNamePhuongXa}}</div>
                            </div>
                        </f7-link>
                    </li>
                    <!-- smart-select-two -->
                    <!-- <li v-if="showDoiTuongCaNhan || showDoiTuongCLB">
                        <a ref="fileInput" class="item-link smart-select smart-select-init smart-select-quan-huyen " data-virtual-list="true" data-open-in="page" data-searchbar="true" data-searchbar-placeholder="Tìm kiếm">
                            <select name="quanhuyen" v-model="quanHuyenSelect" @change="onChange_quanHuyen($event)">
                                <option v-for="(item , index) in dataQuanHuyen" :key="index" :value="item.slug">{{item.name_with_type}}</option>
                            </select>
                            <div class="item-content">
                                <div class="item-inner">
                                    <div class="item-title fs-roboto-light fs-15">Quận/Huyện</div>

                                </div>
                            </div>
                        </a>
                    </li> -->
                    <!-- <li v-if="showDoiTuongCaNhan || showDoiTuongCLB">
                        <a class="item-link smart-select smart-select-init smart-select-phuong-xa" data-open-in="page" data-searchbar="true" data-searchbar-placeholder="Tìm kiếm">
                            <select name="phuongxa" v-model="phuongXaSelect">
                                <option v-for="(item , index) in dataXaPhuong" :key="index" :value="item.slug">{{item.name_with_type}}</option>
                            </select>
                            <div class="item-content">
                                <div class="item-inner">
                                    <div class="item-title fs-roboto-light fs-15">Phường/Xã</div>
                                </div>
                            </div>
                        </a>
                    </li> -->
                    <li class="item-content item-input" v-if="showDoiTuongCaNhan || showDoiTuongCLB">
                        <div class="item-inner no-margin-left">
                            <div class="item-input-wrap">
                                <f7-list-input class="fs-roboto-light no-padding-inner" type="text" :value="address" @input="address = $event.target.value" placeholder="Nhập số nhà, đường..."></f7-list-input>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </f7-block>
        <!-- <f7-block style="margin-top: 15px" v-if="showDoiTuongCaNhan || showDoiTuongCLB">
            <P class="fs-roboto-light fs-13" style="font-style: italic;">Ở bước tiếp theo, bạn sẽ nhận được mã xác nhận số điện thoại gửi qua SMS</P>
        </f7-block> -->
    </f7-block>
</f7-page>
</template>

<script>
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
export default {
    data() {
        const listDoiTuong = [{
            id: 1,
            tendoituong: "Câu Lạc Bộ",
            slug: "cau-lac-bo"
        }, {
            id: 2,
            tendoituong: "Cá nhân",
            slug: "ca-nhan"
        }];
        return {
            listDoiTuong,
            isBottom: true,
            pickerInline: null,
            doiTuongSlug: "",
            name: "",
            gioiTinh: "",
            birthdate: "",
            address_tp: "",
            address_qh: "",
            address_px: "",
            address: "",
            phone: "",
            email: "",
            password: "",
            re_password: "",
            objectInfo: [{
                doiTuongSlug: "",
                name: "",
                gioiTinh: "",
                birthdate: "",
                address_tp: "",
                address_qh: "",
                address_px: "",
                address: "",
                phone: "",
                password: "",
                email: "",
            }],
            showDoiTuongCLB: false,
            showDoiTuongCaNhan: false,
            pickerInline: null,
            popupOpened: false,
            popupOpenedTinhTP: false,
            popupOpenedQuanHuyen: false,
            popupOpenedPhuongXa: false,
            popupOpenedNamSinh: false,
            tinhThanhSelect: '',
            quanHuyenSelect: '',
            phuongXaSelect: '',
            dataTinhTP: [],
            dataQuanHuyen: [],
            dataXaPhuong: [],
            quanHuyenFilter_TinhThanh: '',
            xaPhuongFilter_QuanHuyen: '',
            getDataAppUser: [],
            getNameTinhThanh: "",
            getNameQuanHuyen: "",
            getNamePhuongXa: "",
            arrNamSinh: [],
            getNamSinh:"",
        };
    },
    updated() {
        // this.onPageInit();
        // this.calendarDefault1.open();
    },
    methods: {
        values_nam() {
            for (var i = 1950; i <= 2030; i++) {
                this.arrNamSinh.push(i);
            }
            console.log(this.arrNamSinh)
        },
        showCalendar() {
            const self = this;
            // var today = new Date();
            // this.pickerInline = this.$f7.picker.create({
            //     containerEl: '#demo-picker-date-container',
            //     inputEl: '#demo-picker-date',
            //     toolbar: false,
            //     rotateEffect: true,
            //     value: [
            //         // today.getMonth(),
            //         // today.getDate(),
            //         today.getFullYear(),
            //         // today.getHours(),
            //         // today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()
            //     ],
            //     formatValue: function (values, displayValues) {
            //         return displayValues[0] + ' ' + values[1] + ', ' + values[2] + ' ' + values[3] + ':' + values[4];
            //     },
            //     cols: [
            //         // Months
            //         {
            //             values: ('0 1 2 3 4 5 6 7 8 9 10 11').split(' '),
            //             displayValues: ('January February March April May June July August September October November December').split(' '),
            //             textAlign: 'left'
            //         },
            //         // Days
            //         {
            //             values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
            //         },
            //         // Years
            //         {
            //             values: (function () {
            //                 var arr = [];
            //                 for (var i = 1950; i <= 2030; i++) {
            //                     arr.push(i);
            //                 }
            //                 return arr;
            //             })(),
            //         },
            //         // Space divider
            //         {
            //             divider: true,
            //             content: '&nbsp;&nbsp;'
            //         },
            //         // Hours
            //         {
            //             values: (function () {
            //                 var arr = [];
            //                 for (var i = 0; i <= 23; i++) {
            //                     arr.push(i);
            //                 }
            //                 return arr;
            //             })(),
            //         },
            //         // Divider
            //         {
            //             divider: true,
            //             content: ':'
            //         },
            //         // Minutes
            //         {
            //             values: (function () {
            //                 var arr = [];
            //                 for (var i = 0; i <= 59; i++) {
            //                     arr.push(i < 10 ? '0' + i : i);
            //                 }
            //                 return arr;
            //             })(),
            //         }
            //     ],
            //     on: {
            //         change: function (picker, values, displayValues) {
            //             var daysInMonth = new Date(picker.value[2], picker.value[0] * 1 + 1, 0).getDate();
            //             if (values[1] > daysInMonth) {
            //                 picker.cols[1].setValue(daysInMonth);
            //             }
            //         },
            //     }
            // });
        },
        onPageBeforeRemove() {
            const self = this;
            if (self.popup) self.popup.destroy();
            if (self.calendar) self.calendar.destroy();
        },
        closePopupTinhTP() {
            this.popupOpenedTinhTP = false;
        },
        closePopupQuanHuyen() {
            this.popupOpenedQuanHuyen = false;
        },
        closePopupPhuongXa() {
            this.popupOpenedPhuongXa = false;
        },
        closeSmartSelect() {
            this.popupOpenedTinhTP = false;
            this.popupOpenedQuanHuyen = false;
            this.popupOpenedPhuongXa = false;
            this.popupOpenedNamSinh = false;
        },
        async onChange_tinhThanh(item, $event) {
            let name_TinhThanh = $event.target.value;
            this
                .dataTinhTP
                .forEach((val, i) => {
                    if (val.slug === name_TinhThanh) {
                        this.quanHuyenFilter_TinhThanh = val.code;
                    }
                });
            this.getNameTinhThanh = item.name;
            await this.getAllQuanHuyen();
            this.address_tp = item.name;
            this.popupOpenedQuanHuyen = true;
        },
        async onChange_quanHuyen(item, $event) {
            let name_QuanHuyen = $event.target.value;
            this
                .dataQuanHuyen
                .forEach((val, i) => {
                    if (val.slug === name_QuanHuyen) {
                        this.xaPhuongFilter_QuanHuyen = val.code;
                    }
                });
            this.getNameQuanHuyen = item.name_with_type;
            this.address_qh = item.name_with_type;
            this.getAllXaPhuong();
            this.popupOpenedPhuongXa = true;
        },
        async onChange_phuongXa(item, event) {
            this.address_px = item.name_with_type;
            this.getNamePhuongXa = item.name_with_type;
        },
        onChange_namSinh(item, event){
            this.getNamSinh = item;
        },
        async getAllTinhTp() {
            let url = apiEndPoint + "api/TinhTps";
            let filter = {
                fields: {
                    id: true,
                    name: true,
                    slug: true,
                    code: true
                }
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.dataTinhTP = res.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getAllQuanHuyen() {
            let url = apiEndPoint + "api/QuanHuyens";
            let filter = {
                where: {
                    parent_code: this.quanHuyenFilter_TinhThanh
                },
                fields: {
                    id: true,
                    name_with_type: true,
                    parent_code: true,
                    slug: true,
                    code: true
                }
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.dataQuanHuyen = res.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getAllXaPhuong() {
            let url = apiEndPoint + "api/XaPhuongs";
            let filter = {
                where: {
                    parent_code: this.xaPhuongFilter_QuanHuyen
                },
                fields: {
                    id: true,
                    name_with_type: true,
                    parent_code: true,
                    slug: true
                }
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.dataXaPhuong = res.data;
            } catch (error) {
                console.log(error);
            }
        },
        onChangeGioiTinh($event) {
            this.gioiTinh = $event.target.value;
            console.log(this.gioiTinh);
        },
        onChangeDoiTuong(index, item, $event) {
            if ($event.target.checked === true) {
                this.doiTuongSlug = item.slug;
                if (item.slug === "cau-lac-bo") {
                    this.showDoiTuongCLB = true;
                    this.showDoiTuongCaNhan = false;
                } else if (item.slug === "ca-nhan") {

                    this.showDoiTuongCaNhan = true;
                    this.showDoiTuongCLB = false;

                } else {
                    this.showDoiTuongCaNhan = false;
                    this.showDoiTuongCLB = false;
                }
            }
        },
        onPageInit() {
            const self = this;
            // this.calendarDefault1 = this.$f7.calendar.create({
            //     inputEl: '#demo-calendar-default1',
            //     openIn: 'customModal',
            //     header: true,
            //     footer: true,
            //     on: {
            //         change: (calendar, value) => {
            //             this.birthdate = value[0];
            //         }
            //     }
            // });

        },
        onChange(event) {
            this.doituong = event.target.value;
        },
        openAlert(titlename, cb) {
            const app = this.$f7;
            app.dialog.alert('' + titlename, 'Thông báo', cb);
        },
        async checkPhoneExist(phoneNumber) {
            let isPhoneExist = false;

            let url = apiEndPoint + "api/AppUsers/count";
            let res = await axios.get(url, {
                params: {
                    where: {
                        username: phoneNumber
                    }
                }
            });
            console.log('res check phone exist', res)
            let count = res.data.count;
            return count > 0 ? true : false;
        },
        async register() {
            console.log(this.name)
            if (!this.name || !this.phone || !this.password || !this.re_password || !this.address_tp || !this.address_qh || !this.address_px) {
                this.openAlert("Cần nhập đầy đủ thông tin");
                return;
            } else {
                if (await this.checkPhoneExist(this.phone) == true) {
                    this.openAlert("Số điện thoại đã có người sử dụng");
                } else {
                    if (this.password != this.re_password) {
                        this.openAlert("Mật khẩu không khớp");
                    } else {
                        this.objectInfo = [{
                            doiTuongSlug: this.doiTuongSlug,
                            name: this.name,
                            gioiTinh: this.gioiTinh,
                            birthdate: this.getNamSinh,
                            address_tp: this.address_tp,
                            address_qh: this.address_qh,
                            address_px: this.address_px,
                            address: this.address,
                            username: this.phone,
                            password: this.password,
                            email: this.email,
                        }];
                        // this.$f7router.navigate("/xac-nhan-otp/" + JSON.stringify(this.objectInfo) + '/');
                        let urlPost = apiEndPoint + "api/AppUsers";

                        try {
                            axios.post(urlPost, this.objectInfo).then(r => {
                                console.log('r', r);
                            }).catch(e => {
                                console.log('e', e);
                            })

                        } catch (err) {
                            console.log(err);
                        }
                        this.openAlert("Đăng ký thành công. Cảm ơn bạn đã đến với Conec", () => {
                            this.$f7router.navigate("/");
                        });
                    }

                }
            }

        }
    },
    async created() {
        await this.getAllTinhTp();
        await this.getAllQuanHuyen();
        await this.getAllXaPhuong();
        this.values_nam();
    }
};
</script>
