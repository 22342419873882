<style>
#fileInput {
    display: none;
}
</style>
<template>
<f7-page :page-content="false" @page:init="onPageInit" class="my-bg-color">
    <f7-navbar class="">
        <f7-nav-left back-link="Back"></f7-nav-left>
        <f7-nav-title>Thêm Checkout GĐV</f7-nav-title>

    </f7-navbar>

     <f7-fab position="right-bottom" slot="fixed" text="Thêm"    @click="runThemCheckoutGDV(checkoutItem)">
        <f7-icon ios="f7:checkmark" aurora="f7:checkmark" md="material:checkmark"></f7-icon>
    </f7-fab>


<div class="page-content">
  <div class="card">
    <f7-list >
              <f7-list-input label="Vụ giám định (*):" type="text" placeholder="" :value="soChungThu" disabled>
              </f7-list-input>
              <f7-list-input label="Thời gian checkout (*):" type="text" placeholder="" :value="ngayCheckout" disabled>
              </f7-list-input>
              <f7-list-input label="Vị trí GPS (*):" type="text" placeholder="" :value="locationName" disabled>
              </f7-list-input>
               
            </f7-list>
            <f7-block style="margin-top: 15px;text-align:center">
                        <image-uploader :preview="false" :className="['fileinput', { 'fileinput--loaded': hasImage }]" capture="false" :debug="1" doNotResize="gif" :autoRotate="false" outputFormat="blob" @input="upLoadFile" ref="fileUpload" :maxWidth="1024" :quality="0.7">
                            <label for="fileInput" slot="upload-label">
                                <figure>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                        <path class="path1" d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z" />
                                    </svg>
                                </figure>
                                <span class="upload-caption">
                                    {{
                    hasImage ? "Chụp lại" : "Chọn để mở máy ảnh"
                    }}
                                </span>
                            </label>
                        </image-uploader>
            </f7-block>
            <f7-block style="padding: 0px 50px">
                <img style="border-radius: 10px;" :src="imageLink" />
            </f7-block>
  </div>
</div>


</f7-page>
</template>

<script>
import Vue from "vue";
var dateFns = require('date-fns');
import NavBar from "../components/NavBar.vue";
import apiEndPoint from "../../apiEndpoint.js";
import axios from "axios";
import ImageUploader from "vue-image-upload-resize";
import {
    EventLogin
} from "../event-login.js";
Vue.use(ImageUploader);
export default {
    components: {
        NavBar,
    },
    data() {
        return {
          checkoutItem: {},
          vuGiamDinhID: null,
          soChungThu: null,
          selectedFiles: null,
          ngayCheckout: null,
          locationName: null,
          latitude: null,
          longitude: null,
          imageLink: '',
          uploadLink: apiEndPoint + "api/Containers/CheckoutImage/upload",
          downloadLink: apiEndPoint + "api/Containers/CheckoutImage/download/",
          checkinLink: apiEndPoint + "api/Checkins",
          hasImage: false,
          imageItem: null,
        };
    },
    updated() {},
    mounted() {

    },
    methods: {
        onPageInit() {

        },
         upLoadFile: function (file) {
            this.original = this.$refs.fileUpload.$el.children[1].files[0];
            this.imageItem = file;
            console.log("viet upLoadFile this.imageItem", this.imageItem);
            const img = this.imageItem;

            let filename = this.original.name;
            let formData = new FormData();
            if (this.imageItem) formData.append("upload", this.imageItem,Date.now()+'_'+ filename);

            axios
                .post(this.uploadLink, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data;"
                    }
                })
                .then(res => {
                    let newFileName = res.data.result.files.upload[0].name;
                    this.imageLink = this.downloadLink + newFileName;
                    this.hasImage = true;
                    console.log("upload done b!", res.data);
                })
                .catch(error => {
                    alert("Upload không thành công!" + error.message);
                    console.log(error);
                });
        },

         async success(pos) {
            var crd = pos.coords;
          
            console.log('Your current position is:');
            console.log(`Latitude : ${crd.latitude}`);
            console.log(`Longitude: ${crd.longitude}`);
            console.log(`More or less ${crd.accuracy} meters.`);

            this.latitude = crd.latitude;
            this.longitude = crd.longitude;

            await fetch(`https://eu1.locationiq.com/v1/reverse.php?key=pk.c03abb11fdf2c3801a05a9a13d4e9df8&lat=${crd.latitude}&lon=${crd.longitude}&format=json`).then(response => response.json())
            .then(data => {
              console.log('vietdata',data)
              this.locationName = data.display_name
            });;
          },
          error(err) {
            this.locationName = err.message;
            console.warn(`ERROR(${err.code}): ${err.message}`);
            this.openAlert('Vui lòng cho phép trình duyệt truy cập vị trí GPS để checkin/checkout')

          },
        async getCurrentLocation(){
          var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          };
          navigator.geolocation.getCurrentPosition(this.success, this.error, options);
        },
       
     
        openAlert(titlename, cb) {
            const app = this.$f7;
            app.dialog.alert('' + titlename, 'Thông báo', cb);
        },
        openPreloader(titlename, time) {
            const app = this.$f7;
            app.dialog.preloader(titlename);
            setTimeout(function () {
              app.dialog.close();
            }, time);
        },
       async runThemCheckoutGDV(item){
         
          if(!this.latitude){
            this.openAlert('Vui lòng cho phép trình duyệt truy cập vị trí GPS để checkin/checkout')
            return ;
          }
          if(!this.longitude){
            this.openAlert('Vui lòng cho phép trình duyệt truy cập vị trí GPS để checkin/checkout')
            return ;
          }
          if(!this.imageLink){
            this.openAlert('Vui lòng chụp ảnh Checkout')
            return ;
          }
        
         item.userId = this.userInfo.userId;
         item.idVuGiamDinh = Number.parseInt(this.vuGiamDinhID);
         item.dateCreated = new Date();
         item.soChungThu = this.soChungThu;
         item.imageUrl = this.imageLink;
         item.note = "";
         item.latitude = this.latitude;
         item.longitude = this.longitude;
         item.locationName = this.locationName;
         item.type = "checkout";
         console.log('viet item',item);
         this.openPreloader("Đang thực hiện...",2000);
          let rec = await axios.post(apiEndPoint + 'api/Checkins', item);
          if(rec.data){
           this.openAlert("Thực hiện thành công!");
           this.$f7router.back();
           EventLogin.$emit("reloadPageChiTietVGD");
         }

        },
        getTenNhanVien(id) {
            let found = this.dsNhanVien.find(e => e.id == id);
            if (!found) return "";
            return found.ten;
        },
        openFile(item){
          window.open(item, '_system'); return false;
        },
        formatDate(date){
          var result = dateFns.format(
              new Date(date),
              'dd/MM/yyyy HH:mm:ss'
            )
          return result;
        },
        formatDate2(date){
          var result = dateFns.format(
              new Date(date),
              'yyyy-MM-dd'
            )
          return result;
        }

    },
    mounted() {},
    async created() {
        this.ngayCheckout = this.formatDate(new Date());
        await this.getCurrentLocation();
        this.vuGiamDinhID = localStorage.getItem('eic_temp_vgdId');
        this.soChungThu = localStorage.getItem('eic_temp_soChungThu');
        this.dsNhanVien = JSON.parse(localStorage.getItem('eic_dsNhanVien'));
        this.userInfo =  JSON.parse(localStorage.getItem('eic_userInfo'));


    }
}
</script>
