<template>
<f7-page :page-content="false" @page:init="onPageInit" class="my-bg-color">
    <f7-navbar class="">
        <f7-nav-left back-link="Back"></f7-nav-left>
        <f7-nav-title>Chi tiết Vụ Phân Tích</f7-nav-title>

    </f7-navbar>
    <f7-fab position="right-bottom" slot="fixed" @click="inPhieuKQPT(chiTietVPT)" color="green">
        <i class="fa fa-print" aria-hidden="true"></i>
    </f7-fab>

    <div class="page-content">
  <f7-block-title>Thông tin phân tích :</f7-block-title>
      <div class="card">
        <f7-list>
            <f7-list-input disabled label="Đơn vị yêu cầu :" type="text" placeholder="" :value="getTenKhachHang(chiTietVPT.khachHangId)">
            </f7-list-input>
            <f7-list-input disabled label="Tên mẫu :" type="text" placeholder="" :value="chiTietVPT.tenMau">
            </f7-list-input>
            <f7-list-input disabled label="Số niêm :" type="text" placeholder="" :value="chiTietVPT.soNiem">
            </f7-list-input>
            <f7-list-input disabled label="Số chứng thư Vụ Giám Định :" placeholder="" type="text" :value="chiTietVPT.soChungThuVuGiamDinh">
            </f7-list-input>
            <f7-list-input disabled label="Nơi lấy mẫu :" type="text" placeholder="" :value="chiTietVPT.noiLayMau">
            </f7-list-input>
            <f7-list-input disabled label="Trung tâm phân tích :" type="text" placeholder="" :value="getTenPhongBan(chiTietVPT.phongBanId)">
            </f7-list-input>
            <f7-list-input disabled label="Ngày gởi yêu cầu:" type="text" placeholder="" :value="formatDate(chiTietVPT.ngayYeuCau)">
            </f7-list-input>
          

        </f7-list>
      </div>
  <f7-block-title>Kết quả phân tích :</f7-block-title>
    <div class="card">
      <f7-list>
            <div class="data-table data-table-init">
                <table>
                    <thead>
                        <tr>
                            <th class="label-cell">Chỉ tiêu</th>
                            <th class="label-cell">Đơn vị</th>
                            <th class="label-cell">Kết quả</th>
                            <th class="label-cell">Phương pháp</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(item, index) in chiTietVPT.cacChiTieuPhanTich">
                            <td class="label-cell">{{item.tenChiTieu}}</td>
                            <td class="label-cell">{{item.donVi}}</td>
                            <td class="label-cell">{{item.ketQua}}</td>
                            <td class="label-cell">{{item.phuongPhapThu}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
      </f7-list>
    </div>
    </div>
   

</f7-page>
</template>

<script>
import {
    Roles
} from "./../../global.js";
import TrangThai from "./../../common.js";
var dateFns = require('date-fns');
import NavBar from "./../components/NavBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
import {
    EventLogin
} from "../event-login.js";
import jsreport from 'jsreport-browser-client-dist';

export default {
    components: {
        NavBar,
    },
    data() {
        return {
            chiTietVPT: null,
            clickChangeTenCongViec: false,
            dsPhongBanLab: null
        };
    },
    updated() {},
    mounted() {

    },
    methods: {
        async getAddress_PTN(id) {
            let url = apiEndPoint + "api/Categories/"+id+"/children";
            let filter = {
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
              return res.data;

            } catch (error) {
                console.log(error);
            }
        },
      getTenbyId(id) {
            let ten = '';
            this.dsNhanVien.forEach(e2 => {
              if (id == e2.id.toString()) {
                ten = e2.ten;
                return;
              }
            });
            return ten;
          },
          getTenKhachHangById(id) {
            let ten = '';
            this.dsKhachHangLab.forEach(e => {
              if (id == e.id) {
                ten = e.hoTen;
                return;
              }
            });
            return ten;
          },
       async inPhieuKQPT(item) {
          let sttChiTieu = [];
          let setSTTChiTieu = function(vpt) {
              let i = 0;
              vpt.cacChiTieuPhanTich.forEach(e => {
                if (e.isSelected) {
                  i = i + 1
                  sttChiTieu.push(i);
                } else {
                  sttChiTieu.push(0);
                }
              });
            }

          setSTTChiTieu(item);


          let xuLyTenNguoiDuyet = function(nguoiDuyet) {
            if (item.trangthai == "LAB_VPT_DaDuyetKQPT_086" ||
              item.trangthai == "LAB_VPT_DaGuiKhachHangKQPT_088" ||
              item.trangthai == "LAB_VPT_KhachHangChapNhanKQPT_0881" ||
              item.trangthai == "LAB_VPT_HoanThanh_093") {
                return nguoiDuyet;
            }else {
              return '';
            }
          }
          let xuLyTenMau = function(tenMau){
                //nếu tên bộ chỉ tiêu là THAN NOI, THAN INDO.... thì tên mẫu hiện ra trên kết quả phân tích chỉ là THAN
                if(tenMau == 'ETHANOL') return tenMau;
                if(tenMau.indexOf('THAN')>-1){
                  return 'THAN';
                }else if(tenMau.indexOf('MOGAS')>-1){
                  return 'MOGAS';
                } else{
                  return tenMau;
                }
              }
          let xuLyDonVi = function(chitieu){
            let kq;
            if(chitieu.donVi == "%mol & %vol" || chitieu.donVi =="% mol & % vol"){
              kq = "%mol<br>&<br>%vol";
            }else{
              kq = chitieu.donVi?chitieu.donVi.replace(/;/g, '<br>'):chitieu.donVi
            }
            return kq;
          }
          let xuLyTenChiTieu = function(chitieu) {
            let kq;
            let tenCT = chitieu.tenChiTieu;
            if(!tenCT) return kq;
            if (chitieu.duocCongNhanVilas) {
              if (tenCT.indexOf(':') > -1) {
                  // xử lý hiện công thức hóa học:
              if(!chitieu.tenTiengAnh){
                if (tenCT.indexOf(':') > -1){
                  kq = tenCT.replace(/:/g, ':<sup>(**)</sup><br>').replace(/;/g, '<br>');
                } else {
                  kq =  tenCT + '<sup>(**)</sup>';
                }
              } else {
                kq = tenCT.replace(/:/g, ':<br>').replace(/;/g, '<br>');
              }

                if(tenCT.indexOf('Ethanol') == -1) kq = kq.replace('Ethan','<span>&emsp; C<sub>2</sub>H<sub>6</sub> (Ethan)</span>');
                kq = kq.replace('Propan','<span>&emsp; C<sub>3</sub>H<sub>8</sub> (Propan)</span>');
                kq = kq.replace('Iso Butan','<span>&emsp;  i-C<sub>4</sub>H<sub>10</sub> (Iso Butan)</span>');
                kq = kq.replace('Normal Butan','<span>&emsp;  n-C<sub>4</sub>H<sub>10</sub> (Normal Butan)</span>');
                kq = kq.replace('Neo Pentan','<span>&emsp;  neo-C<sub>5</sub>H<sub>12</sub> (Neo Pentan)</span>');
                kq = kq.replace('Iso Pentan','<span>&emsp;  i-C<sub>5</sub>H<sub>12</sub> (Iso Pentan)</span>');
                kq = kq.replace('Normal Pentan','<span>&emsp; n-C<sub>5</sub>H<sub>12</sub> (Normal Pentan)</span>');
                kq = kq.replace('Propen','<span>&emsp;  C<sub>3</sub>H<sub>6</sub> (Propen)</span>');
                kq = kq.replace('Buten','<span>&emsp; C<sub>4</sub>H<sub>8</sub> (Buten)');
                kq = kq.replace('Pentene','<span>&emsp; C<sub>5</sub>H<sub>10</sub> (Pentene)</span>');
                kq = kq.replace('1-3 Butadiene','<span>&emsp; C<sub>4</sub>H<sub>6</sub> (1-3 Butadiene)</span>');
                kq = kq.replace('Olefin','<span>&emsp; Olefin</span>');

              } else {

                kq = tenCT.replace('Hydro Sulfua','H<sub>2</sub>S');
                if(kq.indexOf(';')>-1){
                  kq = kq.replace(/;/g,'<br>');
                }
              }
            } else {
              kq = tenCT.replace(/:/g, ':<br>').replace(/;/g, '<br>');
            }
            return kq;
          };
          let xuLyTenChiTieuTiengAnh = function(chitieu) {
            let kq;
            let tenCT = chitieu.tenChiTieu;
            let tenTA = chitieu.tenTiengAnh;
            if(!tenTA) return '';
            if (chitieu.duocCongNhanVilas) {
              if (tenTA.indexOf(':') > -1) {
                kq = tenTA.replace(/:/g, ':<sup>(**)</sup><br>').replace(/;/g, '<br>');
                // xử lý hiện công thức hóa học:
                if(tenTA.indexOf('Ethanol') == -1) kq = kq.replace('Ethan','<span>&emsp; C<sub>2</sub>H<sub>6</sub> (Ethan)</span>');
                kq = kq.replace('Propan','<span>&emsp; C<sub>3</sub>H<sub>8</sub> (Propan)</span>');
                kq = kq.replace('Iso Butan','<span>&emsp;  i-C<sub>4</sub>H<sub>10</sub> (Iso Butan)</span>');
                kq = kq.replace('Normal Butan','<span>&emsp;  n-C<sub>4</sub>H<sub>10</sub> (Normal Butan)</span>');
                kq = kq.replace('Neo Pentan','<span>&emsp;  neo-C<sub>5</sub>H<sub>12</sub> (Neo Pentan)</span>');
                kq = kq.replace('Iso Pentan','<span>&emsp;  i-C<sub>5</sub>H<sub>12</sub> (Iso Pentan)</span>');
                kq = kq.replace('Normal Pentan','<span>&emsp; n-C<sub>5</sub>H<sub>12</sub> (Normal Pentan)</span>');
                kq = kq.replace('Propen','<span>&emsp;  C<sub>3</sub>H<sub>6</sub> (Propen)</span>');
                kq = kq.replace('Buten','<span>&emsp; C<sub>4</sub>H<sub>8</sub> (Buten)');
                kq = kq.replace('Pentene','<span>&emsp; C<sub>5</sub>H<sub>10</sub> (Pentene)</span>');
                kq = kq.replace('1-3 Butadiene','<span>&emsp; C<sub>4</sub>H<sub>6</sub> (1-3 Butadiene)</span>');
                kq = kq.replace('Olefin','<span>&emsp; Olefin</span>');
              } else {
                kq = tenTA.replace('Hydro Sulfua','H<sub>2</sub>S');
                kq = kq + '<sup>(**)</sup>';
                if(kq.indexOf(';')>-1){
                  kq = kq.replace(/;/g,'<br>');
                }
              }
              // Replace các trạng thái của THAN
              let arrTrangThai = ['(arb)','(arb )','(db)','(db )','(adb)','(adb )','(daf)','(daf )','(dmmf)','(dmmf )'];
              arrTrangThai.forEach(e =>{
                if(tenTA.indexOf(e)>-1){
                  kq = kq.replace(e,`<sup>${e}</sup>`)
                }
              })
            } else {
              kq = tenTA.replace(/:/g, ':<br>').replace(/;/g, '<br>');
            }
            return kq;
          }

          let xuLyKetQua = function(chitieu) {
            let kq;
            let str = chitieu.ketQua;
            if(chitieu.boChiTieuId == 21){
              console.log('viet day la chi tieu LPG');
              if(chitieu.tenChiTieu.indexOf('Ethan')>-1){
                console.log('viet day la chi tieu Thành phần/Competions');
                kq = `<table cellspacing=0 style='border:0px;width:100%;'><tr><td style='text-align:center;border:0px;border-right:1px solid'>%mol</td><td style='text-align:center;border:0px'>%vol</td></tr>`;
                let arrStr = str.split(";");
                console.log('viet arrStr',arrStr);
                arrStr.forEach(e => {
                  if(!e) return ;
                  let m = e.split("-");
                  let s = `<tr><td style='text-align:center;border:0px;border-right:1px solid'>${m[0]}</td><td style='text-align:center;border:0px'>${m[1]}</td></tr>`
                  kq = kq.concat(s);
                });
                kq = kq.concat(`</table>`)
                return kq;
              } else if(chitieu.tenChiTieu.indexOf('Hàm lượng Olefin')>-1){
                console.log('viet day la chi tieu Hàm lượng Olefin');
                kq = `<table cellspacing=0 style='border:0px;width:100%;'>`;
                let arrStr = str.split(";");
                console.log('viet arrStr',arrStr);
                arrStr.forEach(e => {
                  if(!e) return ;
                  let m = e.split("-");
                  let s = `<tr><td style='text-align:center;border:0px;border-right:1px solid'>${m[0]}</td><td style='text-align:center;border:0px'>${m[1]}</td></tr>`
                  kq = kq.concat(s);
                });
                kq = kq.concat(`</table>`)
                return kq;
              } else if(chitieu.tenChiTieu.indexOf('Hàm lượng 1-3 Butadience')>-1){
                console.log('viet day la chi tieu Hàm lượng 1-3 Butadience');
                kq = `<table cellspacing=0 style='border:0px;width:100%;'>`;
                let arrStr = str.split(";");
                console.log('viet arrStr',arrStr);
                arrStr.forEach(e => {
                  if(!e) return ;
                  let m = e.split("-");
                  let s = `<tr><td style='text-align:center;border:0px;border-right:1px solid'>${m[0]}</td><td style='text-align:center;border:0px'>${m[1]}</td></tr>`
                  kq = kq.concat(s);
                });
                kq = kq.concat(`</table>`)
                return kq;
              }

            }

            if(chitieu.tenChiTieu.indexOf(':')>-1){
              if (str.indexOf(';') > -1) {
                kq = '<br>' + str.replace(/;/g, '<br>');  // cần xuống thêm một dòng ở đầu kq
              } else {
                kq ='<br>' + str;
              }
            } else {
              if(str.indexOf(';')> -1){
                kq = str.replace(/;/g,'<br>');
              } else {
                kq = str;
              }
            }

            return kq;
          }
          jsreport.serverUrl = 'https://qlgd.eic.com.vn:5488';
          let so = item.soChungThu;
          let nguoiYeuCau = this.getTenKhachHangById(item.khachHangId);
          let soYeuCau = item.soChungThuVuGiamDinh;
          let tenMau = xuLyTenMau(item.tenMau);
          let kyHieuMau = `${soYeuCau} ( No. seal: ${item.soNiem})`;
          let soLuongMau = "01";
          let ngayNhanMau = '';
          if (item.ngayNhanMau) {
            ngayNhanMau = dateFns.format(new Date(item.ngayNhanMau),'dd/MM/yyyy');

          }
          let thoiGianLuuMau = item.thoiGianLuuMau;

          let ketQuaPhanTich = [];


          // sắp xếp các chỉ tiêu phân tích in ra dựa vào TT đã cài đặt.
          // tham khảo hàm sort tại : https://developer.mozilla.org/vi/docs/Web/JavaScript/Reference/Global_Objects/Array/S%E1%BA%AFp_x%E1%BA%BFp
          item.cacChiTieuPhanTich.sort((a, b) => {
              return a.thuTuHienThiTrenPhieuKQPT - b.thuTuHienThiTrenPhieuKQPT;
            });

          item.cacChiTieuPhanTich.forEach((e, index) => {
            if (e.isSelected) {
              let temp = {
                kq: sttChiTieu[index],
                kq1: xuLyTenChiTieu(e),
                kq2: e.donVi ? xuLyDonVi(e) : e.donVi,
                kq3: e.ketQua ? xuLyKetQua(e) : e.ketQua,
                kq4: e.phuongPhapThu? e.phuongPhapThu.replace(/;/g, '<br>'): e.phuongPhapThu,
                kq5: xuLyTenChiTieuTiengAnh(e)
              }
              ketQuaPhanTich.push(temp);
            }
          });




          let opinion1 = "Lưu ý: (**) : Các chỉ tiêu đã được công nhận Vilas.";
          let opinion2 = "";
          //let gdv = this.dsNhanVien.find(nv => nv.id === this.itemChungThu.dSGiamDinhVienChinhID[0]);

          let phanTichVien = this.getTenbyId(item.dSPhanTichVienChinhID[0].toString());
          let nguoiDuyet = item.nguoiKyKQPT;
          let ngayDuyet;
          if(item.ngayKyKQPT){
            let d = new Date(item.ngayKyKQPT);
            let month = d.getMonth() + 1
            ngayDuyet = dateFns.format(new Date(item.ngayKyKQPT),"dd/MM/yyyy")
          } else {
            ngayDuyet = '';
          }

  let getMaPhongBan = (id) => {
          let pb = this.dsPhongBanLab.find(e => e.id == id);
          return pb.ma;
        }
        let getDiaDiemKy = (ma) => {
          switch (ma) {
            case 'PTNHCM':
              return 'TP.HCM (Ho Chi Minh City)';
            case 'PTNQN':
              return 'Quảng Ninh (Quang Ninh Province)';
            case 'PTNQNG':
              return 'Quảng Ngãi (Quang Ngai Province)';
            case 'PTNTV':
              return 'Trà Vinh (Tra Vinh Province)';
            case 'PTNHP':
              return 'TP Hải Phòng (Hai Phong City)';
            case 'PTNHD':
              return 'Hải Dương (Hai Duong Province)';
            default:
              return '';
          }
        }

        let maPB = getMaPhongBan(item.phongBanId);
        let diaDiemKy = getDiaDiemKy(maPB);

         let getIDCategoryPTN = (ma) => {
            switch (ma) {
              case 'PTNHCM':
                return 170;
              case 'PTNQN':
                return 169;
              case 'PTNQNG':
                return 172;
              case 'PTNTV':
                return 168;
              case 'PTNHP':
                return 171;
              case 'PTNHD':
                return 232;
              default:
                return -1;
            }
          }
          let idCategoryPTN = getIDCategoryPTN(maPB);
          let footerAddress = await this.getAddress_PTN(idCategoryPTN);
          let footerAddressVI ="";
          let footerAddressEN ="";
          if(footerAddress[0].danhMuc.indexOf("-vi")>-1){
            footerAddressVI = footerAddress[0].tenDanhMuc; 
            footerAddressEN = footerAddress[1].tenDanhMuc;
          }else{
            footerAddressVI = footerAddress[1].tenDanhMuc; 
            footerAddressEN = footerAddress[0].tenDanhMuc;
          }


          var request = {
            template: { "shortid": "BJ4DLHijU" },
            data: {
              "so": so,
              "ngay": "04/09/2019",
              "nguoiYeuCau": nguoiYeuCau,
              "soYeuCau": soYeuCau,
              "tenMau": tenMau,
              "kyHieuMau": kyHieuMau,
              "soLuongMau": soLuongMau,
              "ngayNhanMau": ngayNhanMau,
              "thoiGianLuuMau": thoiGianLuuMau,
               "diaDiemKy": diaDiemKy,


              "tieuDeE": "No.",
              "tieuDeE1": "Items",
              "tieuDeE2": "Unit",
              "tieuDeE3": "Result",
              "tieuDeE4": "ASTM Method",


              "tieuDeV": "Stt",
              "tieuDeV1": "Chỉ tiêu",
              "tieuDeV2": "Đơn vị",
              "tieuDeV3": "Kết quả",
              "tieuDeV4": "P.Pháp ASTM",


              "ketQuaPhanTich": ketQuaPhanTich,
              "Opinion1": opinion1,
              "Opinion2": opinion2,
              "phanTichVien": phanTichVien,
              "nguoiDuyet": xuLyTenNguoiDuyet(nguoiDuyet),
              "ngayDuyet": ngayDuyet,
              "footer_address_vi": footerAddressVI,
              "footer_address_en": footerAddressEN
            }
          };
          let ten = "KetQuaThuNghiem_" + item.soChungThu + ".pdf"
          jsreport.download(ten, request);
        },
        onPageInit() {

        },
        veTrangChu() {
            this.$f7router.back('/home/');
        },
        getTenKhachHang(id) {
            let found = this.dsKhachHangLab.find(e => e.id == id);
            if (!found) return "";
            return found.hoTen;
        },
        getTenPhongBan(id) {
            let found = this.dsPhongBan.find(e => e.id == id);
            if (!found) return "";
            return found.ten;
        },
        getTenNhanVien(id) {
            let found = this.dsNhanVien.find(e => e.id == id);
            if (!found) return "";
            return found.ten;
        },
        openFile(item) {
            window.open(item, '_system');
            return false;
        },
        formatDate(date) {
            var result = dateFns.format(
                new Date(date),
                'dd/MM/yyyy HH:mm'
            )
            return result;
        }

    },
    mounted() {},
    async created() {
        // await this.getChiTietVGD();
        this.chiTietVPT = JSON.parse(localStorage.getItem('eic_temp'));

        this.dsKhachHangLab = JSON.parse(localStorage.getItem('eic_dsKhachHangLab'));
        this.dsNhanVien = JSON.parse(localStorage.getItem('eic_dsNhanVien'));
        this.dsPhongBan = JSON.parse(localStorage.getItem('eic_dsPhongBan'));
        this.dsPhongBanLab = this.dsPhongBan.filter((e) => e.boPhan == "LAB");

        // this.dsHangMucYCGD = JSON.parse(localStorage.getItem('eic_dsHangMucYCGD'));
        this.userInfo =  JSON.parse(localStorage.getItem('eic_userInfo'));
        // this.dsGiamDinhVien = this.getAllGDV(this.chiTietVGD);
        // console.log('viet this.dsGiamDinhVien ',this.dsGiamDinhVien );

    }
}
</script>
