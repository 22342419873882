<template>
<f7-page :page-content="false" @page:init="onPageInit" class="my-bg-color">
    <f7-navbar class="">
        <f7-nav-left back-link="Back"></f7-nav-left>
        <f7-nav-title>Thêm Mẫu Phân Tích</f7-nav-title>

    </f7-navbar>
    <f7-fab position="right-bottom" slot="fixed" text="Lưu"    @click="themMau()">
        <f7-icon ios="f7:checkmark" aurora="f7:checkmark" md="material:checkmark"></f7-icon>
    </f7-fab>
    <f7-tabs>
        <f7-tab class="page-content" tab-active style="padding-top: 50px">
            <div class="card">
                <f7-list no-hairlines-md>
                    <f7-list-item :disabled="true" class="my-title-font-size" title="Tên mẫu (*):" smart-select :smart-select-params="{openIn: 'sheet', closeOnSelect: true}">
                        <div class="my-label-font-size" > {{getTenMauById(itemThongTinPhanTich.idBoChiTieu)}}</div>
                        <select name="tenMau" v-model="itemThongTinPhanTich.idBoChiTieu" @change="loadChiTieuCon(itemThongTinPhanTich.idBoChiTieu)">
                            <option :key="index" v-for="(item, index) in dsBoChiTieu" :value="item.id">{{item.ten}}</option>
                        </select>
                    </f7-list-item>
                    <f7-list-input label="Ký hiệu mẫu (*) :" type="text" placeholder="Nhập số niêm" :value="itemThongTinPhanTich.soNiem" @input="itemThongTinPhanTich.soNiem = $event.target.value">
                    </f7-list-input>
                    <!-- <f7-list-item class="my-title-font-size" title="Khách hàng:" smart-select :smart-select-params="{searchbar: true, searchbarPlaceholder: 'Search', scrollToSelectedItem: true, closeOnSelect: true, virtualList: true}">
                                <div class="my-label-font-size" v-if="!clickChangeDoiTac"> {{getTenKhachHang(khachHangId)}}</div>
                                <select name="khachHang" v-model="khachHangId"  @change="onChange_DoiTacYeuCau($event)">
                                    <option :key="index" v-for="(item, index) in dsKhachHang" :value="item.id" :data-display-as="item.hoTen" >{{cleanAccents(item.shortname?item.hoTen+' ('+item.shortname+')':item.hoTen)}}</option>
                                </select>
                    </f7-list-item> -->
                </f7-list>
            </div>
            <!-- <div class="card">
                <f7-button medium raised fill style="margin-top: 15px; height: 40px; line-height: 40px;">
                            <f7-link class="fs-roboto-bold text-color-white" style="width: 100%;" @click="openDanhSachYCPT()"  link="">Sao chép YCPT của KH</f7-link>
                    </f7-button>
            </div> -->
            <f7-block-title>Bộ chỉ tiêu :</f7-block-title>
            <div class="data-table data-table-init card">
                <table>
                    <thead>
                        <tr>
                            <th class="label-cell">PT</th>
                            <th class="label-cell">Chỉ tiêu</th>
                            <th class="label-cell">Phương pháp</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(item, index) in itemThongTinPhanTich.cacChiTieuPhanTich ">
                            <td class="checkbox-cell">
                                <label class="checkbox">
                                    <input :name="index" type="checkbox" :checked="item.isSelected"  @change="()=> item.isSelected = !item.isSelected" />
                                    <i class="icon-checkbox"></i>
                                </label>
                            </td>
                            <td class="label-cell">{{item.tenChiTieu}}</td>
                            <td class="label-cell">{{item.phuongPhapThu}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <f7-block-title>Phân loại:</f7-block-title>
            <div class="card">
                    <f7-list>
                      <f7-list-item
                        radio
                        radio-icon="start"
                        title="Mẫu thường"
                        name="optionType"
                        :checked = "itemThongTinPhanTich.type=='mau-binh-thuong'?true:false"
                        value="mau-binh-thuong"
                        @change="itemThongTinPhanTich.type = $event.target.value"
                      >
                      </f7-list-item>
                      <f7-list-item
                        radio
                        radio-icon="start"
                        title="Mẫu tổng hợp"
                        name="optionType"
                        :checked = "itemThongTinPhanTich.type=='mau-tong-hop'?true:false"
                        value="mau-tong-hop"
                        @change="itemThongTinPhanTich.type = $event.target.value"
                      >
                      </f7-list-item>
                      <f7-list-item
                        radio
                        radio-icon="start"
                        title="Mẫu để tính bình quân"
                        name="optionType"
                        :checked = "itemThongTinPhanTich.type=='mau-binh-quan'?true:false"
                        value="mau-binh-quan"
                        @change="itemThongTinPhanTich.type = $event.target.value"
                      >
                      </f7-list-item>
                    </f7-list>
            </div>
            <div style="margin-bottom:100px"></div>
        </f7-tab>
    </f7-tabs>

</f7-page>
</template>

<script>
import TrangThai from "./../../common.js";
var dateFns = require('date-fns');
import NavBar from "./../components/NavBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
import {
    EventLogin
} from "../event-login.js";
export default {
    components: {
        NavBar,
    },
    data() {
        return {
            cacChiTieuPhanTich: null,
            itemThongTinPhanTich: {},
            itemsThongTinPhanTich: [],
            dsBoChiTieu: [],
            enableUpdateButton: false,
            popupOpened: false,
            dsMau: [],
            chiTietYCPT: {},
            chiTietVGD: {},
            clickChangeTenCongViec: false,
            dsKhachHang: [],
            clickChangeDoiTac: false,
            khachHangId: null
        };
    },
    updated() {},
    mounted() {
      EventLogin.$on("cloneYCPT", async data => {
            let url = apiEndPoint + "api/YeuCauPhanTiches/"+data.id;
            try{
              let res = await axios.get(url);
              this.cacChiTieuPhanTich = res.data.cacChiTieuPhanTich;
            }catch(err){
              console.log(err);
            }

        });

    },
    methods: {
          openDanhSachYCPT(){
            let obj = {boChiTieuId: this.itemThongTinPhanTich.idBoChiTieu , khachHangId: this.khachHangId}
            localStorage.setItem('eic_SaoChepBoChiTieuKhachHang',JSON.stringify(obj));
            this.$f7router.navigate('/view-ds-ycpt-da-gui/');
          },
         cleanAccents(str){
            str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
            str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
            str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
            str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
            str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
            str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
            str = str.replace(/đ/g, "d");
            str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
            str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
            str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
            str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
            str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
            str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
            str = str.replace(/Đ/g, "D");
            // Combining Diacritical Marks
            str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // huyền, sắc, hỏi, ngã, nặng
            str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // mũ â (ê), mũ ă, mũ ơ (ư)

            return str;
        },
        onChange_DoiTacYeuCau(){
          this.clickChangeDoiTac = true;
        },
        onPageInit() {

        },
        checkedChange(item){
          console.log('viet item',item);
        },


        getTenMauById(id) {
            let found = this.dsBoChiTieu.find(e => e.id == id);
            if (!found) return "";
            return found.ten;
        },
         openAlert(titlename, cb) {
            const app = this.$f7;
            app.dialog.alert('' + titlename, 'Thông báo', cb);
        },

        themMau() {
            if(!this.itemThongTinPhanTich.idBoChiTieu){
              this.openAlert('Bạn chưa chọn mẫu.');
              return;
            }
            if(!this.itemThongTinPhanTich.soNiem){
              this.openAlert('Bạn chưa nhập ký hiệu mẫu.');
              return;
            }
            this.itemThongTinPhanTich.tenMau = this.getTenMauById(this.itemThongTinPhanTich.idBoChiTieu);
            EventLogin.$emit("THEM_MAU_YCPT", this.itemThongTinPhanTich);
            this.$f7router.back();

        },
        async getCongViecGiamDinhVien(item) {
            let url = apiEndPoint + "api/CongViecGiamDinhViens";
            let filter = {
                where: {
                    vuGiamDinhId: item.id
                },
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.dsCongViecGDV = res.data;
                console.log('viet this.dsCongViecGDV', this.dsCongViecGDV)
            } catch (error) {
                alert(error);
            }
        },
        async loadChiTieuCon(id) {
            let url = apiEndPoint + "api/ChiTieuCons";
            let filter = {
                where: {
                    boChiTieuId: id
                }
            };
            if (this.itemsThongTinPhanTich == undefined) this.itemsThongTinPhanTich = [];
            let index = this.itemsThongTinPhanTich.findIndex(e => id == e.idBoChiTieu); // kiểm tra xem đã có tên mẫu (bộ chỉ tiêu) chưa, nếu có rồi thì load lại các mục chọn chỉ tiêu phân tích.

            if (index != -1) {
                this.itemThongTinPhanTich.cacChiTieuPhanTich = this.itemsThongTinPhanTich[index].cacChiTieuPhanTich;
            } else {
                try {
                    let res = await axios.get(url, {
                        params: {
                            filter: filter
                        }
                    });
                    this.itemThongTinPhanTich.cacChiTieuPhanTich = res.data;
                    this.cacChiTieuPhanTich = res.data;
                } catch (error) {
                    alert(error);
                }
                // Mặc định chọn hết các chỉ tiêu isSelected == true
                this.itemThongTinPhanTich.cacChiTieuPhanTich.forEach(e => {
                    if (e.tenChiTieu && e.tenChiTieu.length) {
                        e.isSelected = true;
                    } else {
                        e.isSelected = false
                    }

                });
            }

        },
        async getDSBoChiTieu() {
            let url = apiEndPoint + "api/BoChiTieus";
            let filter = {
                fields: {
                    "id": true,
                    "ten": true,
                },
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.dsBoChiTieu = res.data;

            } catch (error) {
                console.log(error);
            }
        },
        getPhongBanByID(id) {
            let found = this.dsPhongBan.find(e => e.id == id);
            if (!found) return "";
            return found;
        },
        getKhachHangByMa(maKH) {
            let found = this.dsKhachHangLab.find(e => e.ma == maKH);
            if (!found) return "";
            return found;
        },
        getTenKhachHang(id) {
            let found = this.dsKhachHang.find(e => e.id == id);
            if (!found) return "";
            return found.hoTen;
        },
        getTenPhongBan(id) {
            let found = this.dsPhongBan.find(e => e.id == id);
            if (!found) return "";
            return found.ten;
        },
        getTenNhanVien(id) {
            let found = this.dsNhanVien.find(e => e.id == id);
            if (!found) return "";
            return found.ten;
        },
        openFile(item) {
            window.open(item, '_system');
            return false;
        },
        formatDate(date) {
            var result = dateFns.format(
                new Date(date),
                'dd/MM/yyyy HH:mm'
            )
            return result;
        }

    },
    async created() {
        this.itemThongTinPhanTich = window.myData;
        if(!this.itemThongTinPhanTich.type){
          this.itemThongTinPhanTich.type = 'mau-binh-thuong';
        }
        if(!this.itemThongTinPhanTich.cacChiTieuPhanTich){
          await this.loadChiTieuCon(this.itemThongTinPhanTich.idBoChiTieu);
        }
        this.dsPhongBan = JSON.parse(localStorage.getItem('eic_dsPhongBan'));
        this.dsKhachHangLab = JSON.parse(localStorage.getItem('eic_dsKhachHangLab'));
        this.dsKhachHang = JSON.parse(localStorage.getItem('eic_dsKhachHang'));
        this.dsNhanVien = JSON.parse(localStorage.getItem('eic_dsNhanVien'));

        await this.getDSBoChiTieu();

    }
}
</script>
