<template>
<f7-page :page-content="false" @page:init="onPageInit" class="my-bg-color">
    <f7-navbar class="">
        <f7-nav-left back-link="Back"></f7-nav-left>
        <f7-nav-title>Thêm công việc GĐV</f7-nav-title>

    </f7-navbar>

     <f7-fab position="right-bottom" slot="fixed" text="Thêm"    @click="runThemCongViecGDV(chiTietCV)">
        <f7-icon ios="f7:checkmark" aurora="f7:checkmark" md="material:checkmark"></f7-icon>
    </f7-fab>


<div class="page-content">
  <div class="card">
    <f7-list >
                <f7-list-item  class="my-title-font-size" title="Tên công việc :" smart-select :smart-select-params="{openIn: 'sheet', closeOnSelect: true}">
                                        <!-- <div class="my-label-font-size" v-if="!clickChangeTenCongViec" > {{chiTietCV.tenCongViec}}</div> -->
                                        <select name="tenCongViec" v-model="chiTietCV.tenCongViec" >
                                            <option value="">--Chọn--</option>
                                            <option value="Hoàn thành hiện trường">Hoàn thành hiện trường</option>
                                            <option value="Báo cáo sơ bộ">Báo cáo sơ bộ</option>
                                            <option value="Báo cáo chuyển tiếp">Báo cáo chuyển tiếp</option>
                                            <option value="Báo cáo cuối cùng">Báo cáo cuối cùng</option>
                                        </select>
                </f7-list-item>

                  <f7-list-input label="Mô tả :" type="text" placeholder="" :value="chiTietCV.noiDung" @input="chiTietCV.noiDung = $event.target.value">
                  </f7-list-input>
                  <li>
                    <div class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">File tài liệu :</div>
                        <div class="item-input-wrap">
                          <input type="file" ref="fileInput" @change="uploadFile()" placeholder="" class="">
                          </div>
                        </div>
                      </div>
                    </li>




            </f7-list>
  </div>
        
</div>


</f7-page>
</template>

<script>
import TrangThai from "./../../common.js";
var dateFns = require('date-fns');
import NavBar from "./../components/NavBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
import {
    EventLogin
} from "../event-login.js";
export default {
    components: {
        NavBar,
    },
    data() {
        return {
          chiTietCV: {},
          clickChangeTenCongViec: false,
          vuGiamDinhID: null,
          selectedFiles: null,
        };
    },
    updated() {},
    mounted() {

    },
    methods: {
        onPageInit() {

        },
        async uploadFile(){
          console.log('viet uploadFile',this.$refs.fileInput.files)
          let file = this.$refs.fileInput.files[0];
           let formData = new FormData();
           let uploadLink = apiEndPoint + 'api/CongViecGiamDinhVienContainers/cvgd-folder/upload';
           let downloadLink = apiEndPoint + 'api/CongViecGiamDinhVienContainers/cvgd-folder/download/';
          formData.append('upload', file);
          let res = await axios.post(uploadLink, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          });
          console.log('viet res',res);
          this.chiTietCV.tenTaiLieu = res.data.result.files.upload[0].name;
          this.chiTietCV.duongDanFileTaiLieu =  downloadLink + this.chiTietCV.tenTaiLieu;
        },
        openAlert(titlename, cb) {
            const app = this.$f7;
            app.dialog.alert('' + titlename, 'Thông báo', cb);
        },
        openPreloader(titlename, time) {
            const app = this.$f7;
            app.dialog.preloader(titlename);
            setTimeout(function () {
              app.dialog.close();
            }, time);
        },
       async runThemCongViecGDV(item){
         if(!item.tenCongViec){
           this.openAlert("Vui lòng chọn công việc!");
           return;
         }
         this.openPreloader("Đang thực hiện...",2000);
          item.vuGiamDinhId = this.vuGiamDinhID;
          item.thoiGian = new Date();
          item.userId = this.userInfo.userId;
          console.log('viet item',item);
          let rec = await axios.post(apiEndPoint + 'api/CongViecGiamDinhViens', item);
          if(rec.data){
           this.openAlert("Thực hiện thành công!");
           this.$f7router.back();
           EventLogin.$emit("reloadPageChiTietVGD");




         }

        },
        getTenNhanVien(id) {
            let found = this.dsNhanVien.find(e => e.id == id);
            if (!found) return "";
            return found.ten;
        },
        openFile(item){
          window.open(item, '_system'); return false;
        },
        formatDate(date){
          var result = dateFns.format(
              new Date(date),
              'dd/MM/yyyy HH:mm'
            )
          return result;
        }

    },
    mounted() {},
    async created() {
        // await this.getChiTietVGD();
        this.vuGiamDinhID = localStorage.getItem('eic_temp');
        console.log('viet this.vuGiamDinhID',this.vuGiamDinhID);

        // this.dsKhachHang = JSON.parse(localStorage.getItem('eic_dsKhachHang'));
        this.dsNhanVien = JSON.parse(localStorage.getItem('eic_dsNhanVien'));
        // this.dsHangMucYCGD = JSON.parse(localStorage.getItem('eic_dsHangMucYCGD'));
        this.userInfo =  JSON.parse(localStorage.getItem('eic_userInfo'));
        // this.dsGiamDinhVien = this.getAllGDV(this.chiTietVGD);
        // console.log('viet this.dsGiamDinhVien ',this.dsGiamDinhVien );

    }
}
</script>
