<template>
  <f7-page :page-content="false" @page:init="onPageInit" class="my-bg-color">
    <f7-navbar class>
      <f7-nav-left back-link="Back"></f7-nav-left>
      <f7-nav-title>VPT số {{chiTietVPT.soChungThu}}</f7-nav-title>
    </f7-navbar>

    <f7-fab position="right-bottom" slot="fixed">
      <i class="fa fa-bars" aria-hidden="true"></i>
      <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
      <f7-fab-buttons position="top">
        <f7-fab-button
          :fab-close="true"
          label="Duyệt KQPT"
          v-if="enableDuyetKQPT(chiTietVPT)"
          @click="runDuyetKetQuaPhanTich(chiTietVPT)"
          color="green"
        >
          <i class="fas fa-check-square" aria-hidden="true"></i>
        </f7-fab-button>
        <f7-fab-button
          :fab-close="true"
          label="Không Duyệt KQPT"
          v-if="enableDuyetKQPT(chiTietVPT)"
          @click="openLyDoTuChoiDialog(chiTietVPT)"
          color="red"
        >
          <i class="fa fa-ban" aria-hidden="true"></i>
        </f7-fab-button>
        <f7-fab-button
          :fab-close="true"
          label="Gởi duyệt KQPT"
          v-if="enableGuiDuyetKQPT(chiTietVPT)"
          @click="runGuiDuyetKetQuaPhanTich(chiTietVPT)"
          color="green"
        >
          <i class="fa fa-share-square" aria-hidden="true"></i>
        </f7-fab-button>
        <f7-fab-button
          :fab-close="true"
          label="Lưu cập nhật"
          v-if="enableLuuDieuChinhVPT(chiTietVPT)"
          @click="runSave(chiTietVPT)"
          color="orange"
        >
          <i class="fas fa-save" aria-hidden="true"></i>
        </f7-fab-button>
        <f7-fab-button
          :fab-close="true"
          label="In Phiếu KQPT"
          v-if="enableInPhieuKQPT(chiTietVPT)"
          @click="inPhieuKQPT(chiTietVPT)"
          color="deeppurple"
        >
          <i class="fa fa-print" aria-hidden="true"></i>
        </f7-fab-button>
        <!-- <f7-fab-button :fab-close="true" label="Yêu cầu hủy VPT" color="red"  v-if="enableYeuCauHuyVPT(chiTietVPT)" @click="runSave(chiTietVPT)" >
            <i class="fa fa-ban" aria-hidden="true"></i>
        </f7-fab-button>-->
        <f7-fab-button :fab-close="true" label="Trang chủ" color="lightblue" @click="veTrangChu()">
          <i class="fa fa-home" aria-hidden="true"></i>
        </f7-fab-button>
      </f7-fab-buttons>
    </f7-fab>

    <div class="page-content">
      <div class="card">
        <f7-list accordion-list no-hairlines-md>
          <f7-list-item accordion-item title="1. Thông tin phân tích">
            <f7-accordion-content>
              <f7-list no-hairlines-md>
                <f7-list-input
                  disabled
                  label="Đơn vị yêu cầu :"
                  type="text"
                  placeholder
                  :value="getTenKhachHang(chiTietVPT.khachHangId)"
                ></f7-list-input>
                <f7-list-input
                  disabled
                  label="Tên mẫu (*) :"
                  type="text"
                  placeholder
                  :value="chiTietVPT.tenMau"
                  @input="chiTietVPT.tenMau = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Số niêm :"
                  type="text"
                  placeholder
                  :value="chiTietVPT.soNiem"
                  @input="chiTietVPT.soNiem = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  disabled
                  label="Số chứng thư Vụ Giám Định (*) :"
                  placeholder
                  type="text"
                  :value="chiTietVPT.soChungThuVuGiamDinh"
                  @input="chiTietVPT.soChungThuVuGiamDinh = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Tên tàu :"
                  type="text"
                  placeholder
                  :value="chiTietVPT.noiLayMau"
                  @input="chiTietVPT.noiLayMau = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Ngày nhận mẫu:"
                  type="date"
                  placeholder
                  :value="formatDate2(chiTietVPT.ngayNhanMau)"
                  @input="chiTietVPT.ngayNhanMau = new Date($event.target.value)"
                ></f7-list-input>
                <f7-list-input
                  label="Thời gian lưu mẫu:"
                  type="text"
                  placeholder
                  :value="chiTietVPT.thoiGianLuuMau"
                  @input="chiTietVPT.thoiGianLuuMau = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Tên hiển thị:"
                  type="text"
                  placeholder
                  :value="chiTietVPT.tenHienThi"
                  @input="chiTietVPT.tenHienThi = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  v-if="enableNgayKy(chiTietVPT)"
                  label="Ngày ký KQPT:"
                  type="date"
                  placeholder
                  :value="formatDate2(chiTietVPT.ngayKyKQPT)"
                  @input="chiTietVPT.ngayKyKQPT = new Date($event.target.value)"
                ></f7-list-input>
                <f7-list-item
                  disabled
                  class="my-title-font-size"
                  title="Trung tâm (*):"
                  smart-select
                  :smart-select-params="{openIn: 'sheet', closeOnSelect: true}"
                >
                  <div
                    class="my-label-font-size"
                    v-if="!clickChangeTrungTam"
                  >{{getTenPhongBan(chiTietVPT.phongBanId)}}</div>
                  <select
                    name="phongBanLab"
                    v-model="chiTietVPT.phongBanId"
                    @change="() => clickChangeTrungTam=true"
                  >
                    <option
                      :key="index"
                      v-for="(item, index) in dsPhongBanLab"
                      :value="item.id"
                    >{{item.ten}}</option>
                  </select>
                </f7-list-item>
                <f7-list-input
                  label="Ngày muốn nhận KQPT:"
                  type="date"
                  placeholder
                  :value="formatDate2(chiTietVPT.ngayMuonNhanKQPT)"
                  @input="chiTietVPT.ngayMuonNhanKQPT = new Date($event.target.value)"
                ></f7-list-input>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
      </div>
      <div class="card">
        <f7-list accordion-list no-hairlines-md>
          <f7-list-item
            accordion-item
            title="2. Phân tích viên đang thực hiện"
            :accordion-item-opened="trangThai_TaoMoiVPT(chiTietVPT)"
          >
            <f7-accordion-content>
              <f7-list-item
                :disabled="disableChiDinhPTV()"
                class="my-title-font-size"
                title="PTV chính:"
                smart-select
                :smart-select-params="{openIn: 'sheet'}"
              >
                <div
                  class="my-label-font-size"
                  v-if="!clickChangePTVChinh"
                >{{dSPhanTichVienChinhID?"hiện có " + dSPhanTichVienChinhID.length + " người":"hiện có 0 người"}}</div>
                <select
                  name="phanTichVienChinh"
                  multiple
                  v-model="dSPhanTichVienChinhID"
                  @change="() => clickChangePTVChinh = true"
                >
                  <option
                    :key="index"
                    v-for="(item, index) in dsPhanTichVien"
                    :value="item.id"
                  >{{item.ten}}</option>
                </select>
              </f7-list-item>
              <f7-list-item
                :disabled="disableChiDinhPTV()"
                class="my-title-font-size"
                title="PTV phụ:"
                smart-select
                :smart-select-params="{openIn: 'sheet'}"
              >
                <div
                  class="my-label-font-size"
                  v-if="!clickChangePTVPhu"
                >{{dSPhanTichVienPhuID?"hiện có " + dSPhanTichVienPhuID.length + " người":"hiện có 0 người"}}</div>
                <select
                  name="phanTichVienPhu"
                  multiple
                  v-model="dSPhanTichVienPhuID"
                  @change="() => clickChangePTVPhu = true"
                >
                  <option
                    :key="index"
                    v-for="(item, index) in dsPhanTichVien"
                    :value="item.id"
                  >{{item.ten}}</option>
                </select>
              </f7-list-item>
              <f7-block-title v-if="dSPhanTichVienChinhID.length">Phân tích viên chính:</f7-block-title>
              <f7-list v-if="dSPhanTichVienChinhID.length">
                <f7-list-item
                  :key="index"
                  v-for="(item, index) in dSPhanTichVienChinhID"
                  :value="item"
                >- {{getTenNhanVien(item)}}</f7-list-item>
              </f7-list>

              <f7-block-title v-if="dSPhanTichVienPhuID.length">Phân tích viên phụ:</f7-block-title>
              <f7-list v-if="dSPhanTichVienPhuID.length">
                <f7-list-item
                  :key="index"
                  v-for="(item, index) in dSPhanTichVienPhuID"
                  :value="item"
                >- {{getTenNhanVien(item)}}</f7-list-item>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
      </div>
      <div class="card">
        <f7-list accordion-list no-hairlines-md>
          <f7-list-item accordion-item title="3. Danh sách mẫu">
            <f7-accordion-content>
              <f7-list no-hairlines-md>
                <f7-block>
                  <f7-row>
                    <f7-col>
                      <f7-button fill color="green" @click="runThemMau()">
                        <i class="fa fa-plus"></i> Thêm mẫu
                      </f7-button>
                    </f7-col>
                    <f7-col></f7-col>
                    <f7-col></f7-col>
                  </f7-row>
                </f7-block>
                <div class="card data-table data-table-init">
                  <table>
                    <thead>
                      <tr>
                        <th rowspan="2" class="label-cell" style="text-align:center;">TT</th>
                        <th
                          rowspan="2"
                          class="label-cell"
                          style="text-align:center;min-width:200px"
                        >Ký hiệu mẫu</th>
                        <th
                          class="label-cell"
                          style="text-align:center;min-width:100px"
                          v-for="(item,index) in chiTietVPT.cacChiTieuPhanTich[0].cacChiTieuPhanTich"
                          :key="index"
                        >{{item.tenChiTieu}}</th>
                        <th rowspan="2" class="label-cell">Loại</th>
                        <th rowspan="2" class="label-cell">Chi tiết</th>
                        <th rowspan="2" class="label-cell">Xắp xếp</th>
                        <th
                          rowspan="2"
                          class="label-cell"
                          style="text-align:center;min-width:200px"
                        >Ký hiệu mẫu</th>
                      </tr>
                      <tr>
                        <th
                          class="label-cell"
                          style="text-align:center"
                          v-for="(item,index) in chiTietVPT.cacChiTieuPhanTich[0].cacChiTieuPhanTich"
                          :key="index"
                        >{{item.donVi}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr :key="index" v-for="(item, index) in chiTietVPT.cacChiTieuPhanTich">
                        <td>{{index + 1}}</td>
                        <td>
                          <f7-input
                            class="my-input-style-1"
                            inputStyle="font-size:10pt;height: 20pt"
                            type="text"
                            name
                            :value="item.soNiem"
                            @input="item.soNiem = $event.target.value"
                          />
                        </td>
                        <td :key="index2" v-for="(item2,index2) in item.cacChiTieuPhanTich">
                          <f7-input
                            v-show="item2.isSelected"
                            class="my-input-style-1"
                            inputStyle="font-size:10pt;height: 20pt"
                            type="text"
                            name
                            :value="item2.ketQua"
                            @input="item2.ketQua = $event.target.value"
                          />
                        </td>
                        <td
                          class="label-cell"
                        >{{item.type=='mau-binh-quan'?'Mẫu bình quân':item.type=='mau-tong-hop'?'Mẫu tổng hợp':''}}</td>
                        <td>
                          <f7-button
                            small
                            round
                            @click="runUpdateMauIndex(item,index)"
                            style="margin-top:5px;margin-bottom:2px"
                          >
                            <i class="fas fa-pencil-alt"></i>
                          </f7-button>
                          <f7-button
                            small
                            round
                            @click="runDeleteMauIndex(index)"
                            color="red"
                            style="margin-top:2px;margin-bottom:5px"
                          >
                            <i class="fas fa-trash"></i>
                          </f7-button>
                        </td>
                        <td>
                          <f7-button
                            small
                            round
                            @click="moveItemUp(index, item)"
                            style="margin-top:5px;margin-bottom:2px"
                          >
                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                          </f7-button>
                          <f7-button
                            small
                            round
                            @click="moveItemDown(index, item)"
                            style="margin-top:2px;margin-bottom:5px"
                          >
                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                          </f7-button>
                        </td>
                        <td>
                          <f7-input
                            class="my-input-style-1"
                            inputStyle="font-size:10pt;height: 20pt"
                            type="text"
                            name
                            :value="item.soNiem"
                            @input="item.soNiem = $event.target.value"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
      </div>
      <div class="card">
        <f7-list accordion-list no-hairlines-md>
          <f7-list-item accordion-item title="4. Lịch sử">
            <f7-accordion-content>
              <f7-list media-list>
                <f7-list-item
                  :key="index"
                  v-for="(item, index) in dsLichSuVPT"
                  :title="item.message"
                  :subtitle="'bởi '+ getTenNhanVien(item.appUserId)"
                  :text="formatDate(item.date)"
                ></f7-list-item>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
      </div>
      <div class="card" v-if="showChamCong()">
                <f7-list>
                    <f7-list-item accordion-item title="5. Phân tích viên chấm công">
                        <f7-accordion-content>
                             <f7-button medium raised fill style="margin: 15px; height: 40px; line-height: 40px;">
                                <f7-link class="fs-roboto-bold text-color-white"  @click="themChamCongPTV(chiTietVPT)">Thêm chấm công</f7-link>
                            </f7-button>
                             <div class="data-table data-table-init">
                                <table>
                                    <thead>
                                        <tr>
                                            <th class="label-cell">STT</th>
                                            <th class="label-cell">Tên PTV</th>
                                            <th class="label-cell">Ngày công</th>
                                            <th class="label-cell">Tỷ lệ tự chấm (%)</th>
                                            <th class="label-cell">Trạng thái</th>
                                            <th class="label-cell">Người duyệt</th>
                                            <th class="label-cell">Ngày duyệt</th>
                                            <th class="label-cell">Tỷ lệ TP duyệt (%)</th>
                                            <th class="label-cell">Hành động</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr :key="index" v-for="(item, index) in itemsChamCong">
                                            <td class="label-cell">{{index+1}}</td>
                                            <td class="label-cell">{{item.tenNhanVien}}</td>
                                            <td class="label-cell">{{formatDate3(item.ngayCong)}}</td>
                                            <td class="label-cell">{{item.tyLe}}%</td>
                                            <td class="label-cell" style="color:orange" v-if="item.trangThai==='chua-duyet'">{{item.trangThai==='chua-duyet'?'Chờ duyệt':'Đã duyệt'}}</td>
                                            <td class="label-cell" style="color:green" v-if="item.trangThai==='da-duyet'">{{item.trangThai==='chua-duyet'?'Chờ duyệt':'Đã duyệt'}}</td>
                                            <td class="label-cell">{{getTenNhanVien(item.idNguoiDuyetChamCong)}}</td>
                                            <td class="label-cell">{{formatDate3(item.ngayDuyetChamCong)}}</td>
                                            <td class="label-cell" style="color:green">{{item.idNguoiDuyetChamCong?item.tyLe_Duyet:0}}%</td>
                                            <td class="label-cell">
                                                <div v-if="item.trangThai==='chua-duyet'">
                                                    <f7-button fill color="red" @click="runDeleteChamCong(item)">Xóa</f7-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                        </f7-accordion-content>
                    </f7-list-item>
                </f7-list>
                <div class="m-b-100"></div>

      </div>
    </div>
  </f7-page>
</template>

<script>
import { Roles } from "./../../global.js";
import TrangThai from "./../../common.js";
var dateFns = require("date-fns");
import NavBar from "./../components/NavBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
import { EventLogin } from "../event-login.js";
import jsreport from "jsreport-browser-client-dist";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
         itemsChamCong:[],
      giamSoLuongMau: false,
      myIndex: null,
      chiTietVPT: { cacChiTieuPhanTich: [{ cacChiTieuPhanTich: [] }] },
      clickChangeTrungTam: false,
      dsPhongBanLab: [],
      dsPhongBan: [],
      dsNghiepVu: [],
      clickChangePTVChinh: false,
      clickChangePTVPhu: false,
      dSPhanTichVienChinhID: [],
      dSPhanTichVienPhuID: [],
      dsPhanTichVien: [],
      dsLichSuVPT: [],
      ngayNhanMau: "",
      change: false,
    };
  },
  updated() {},
  mounted() {
    EventLogin.$on("THEM_MAU_YCPT", (itemThongTinPhanTich) => {
      if (this.myIndex===null) {
        this.chiTietVPT.cacChiTieuPhanTich.push(itemThongTinPhanTich);
      } else {
        this.chiTietVPT.cacChiTieuPhanTich[this.myIndex] = itemThongTinPhanTich;
      }
    });
     EventLogin.$on("reloadPageChiTietVPT", async e => {
            console.log("viet reloadPageChiTietVPT");
            await this.getChiTietVPT();
        });
  },
  methods: {
      async getAddress_PTN(id) {
            let url = apiEndPoint + "api/Categories/"+id+"/children";
            let filter = {
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
              return res.data;

            } catch (error) {
                console.log(error);
            }
        },
         async runDeleteChamCong(item){
            console.log('runDeleteChamCong()');
            this.openConfirm("Chắc chắn bạn muốn xóa chấm công?", async() => {
             let rs = await axios.delete(apiEndPoint + 'api/Chamconglabs/'+item.id);
             console.log('viet delete cham cong',rs.data);
              if(rs.data.count){
                this.itemsChamCong = await this.getAllChamCongPTV();
                this.openAlert("Thực hiện thành công!");
            }

            });
        },
        async getAllChamCongPTV(){
            let filterChamCong = { 
                where:{
                    idVuPhanTich : this.chiTietVPT.id,
                    idNhanVien : this.userInfo.userId
                },
                order: "ngayCong desc"
                };
            let request = {
                params: {
                    filter: filterChamCong
                }
            }
             let rs = await axios.get(apiEndPoint + 'api/Chamconglabs',request);
            return rs.data;
        },
        getVaiTro(idNV,listPTVChinhId,listPTVPhuId){
            if(listPTVChinhId.includes(idNV)){
            return 'ptv-chinh';
            }else if(listPTVPhuId.includes(idNV)){
            return 'ptv-phu';
            }else{
            return '';
            }
        },
      themChamCongPTV(vpt){
        let vaiTro = this.getVaiTro(this.userInfo.userId,vpt.dSPhanTichVienChinhID,vpt.dSPhanTichVienPhuID)
        localStorage.setItem("eic_temp_vaiTro",vaiTro);
        localStorage.setItem("eic_temp_vptId", vpt.id);
        localStorage.setItem("eic_temp_soChungThu", vpt.soChungThu);
        this.$f7router.navigate("/them-cham-cong-ptv/" );
      },
     showChamCong(){
            if(this.chiTietVPT.trangthai===TrangThai.LAB_VPT_TaoMoi_0822){
                return false;
            }
            return true;
        },
    runThemMau() {
      this.myIndex = null;
      console.log("viet myIndex", this.myIndex);
      window.myData = { idBoChiTieu: this.chiTietVPT.idBoChiTieu };
      this.$f7router.navigate("/them-mau-yeu-cau-phan-tich-new/");
    },
    openConfirm(titlename, cb) {
      const app = this.$f7;
      app.dialog.confirm("" + titlename, "Cảnh báo", cb);
    },
    runUpdateMauIndex(item, index) {
      this.myIndex = index;
      console.log("viet myIndex", this.myIndex);
      window.myData = item;
      this.$f7router.navigate("/them-mau-yeu-cau-phan-tich-new/");
    },
    runDeleteMauIndex(index) {
      this.openConfirm("Bạn có chắn chắn xóa!", (result) => {
        console.log(result);
        this.chiTietVPT.cacChiTieuPhanTich.splice(index, 1);
      });
    },
    moveItemUp(i, item) {
      if (i === 0) return;

      this.moveItem(i, i - 1, item);
    },

    moveItemDown(i, item) {
      if (i === this.chiTietVPT.cacChiTieuPhanTich.length - 1)
        // -3 vì cần loại ra 2 chỉ tiêu dự phòng
        return;

      this.moveItem(i, i + 1, item);
    },

    moveItem(oldIndex, newIndex, item) {
      this.chiTietVPT.cacChiTieuPhanTich.splice(oldIndex, 1);
      this.chiTietVPT.cacChiTieuPhanTich.splice(newIndex, 0, item);
    },
    changeViewBoChiTieu() {
      this.change = !this.change;
      console.log("viet change", this.change);
      return this.change;
    },
    async GetPeriodTime(actionNum, phongbanId) {
      let request = {
        params: {
          actionNum: actionNum,
          phongbanId: phongbanId,
        },
      };
      let rs = await axios.get(
        apiEndPoint + "api/Actions/getPeriodTime/",
        request
      );
      console.log("viet period time rs", rs.data);
      return rs.data;
    },
    async ThietLapDeadline(actionCode, item) {
      // thiết lập deadline mới cho trạng thái mới của VPT
      let today = new Date();
      let thoigianbatdau = new Date();
      thoigianbatdau.setHours(8, 0, 0, 0);
      let thoigianketthuc = new Date();
      thoigianketthuc.setHours(17, 0, 0, 0);
      let thoigianbatdau_tomorrow = new Date(
        today.getTime() + 24 * 60 * 60 * 1000
      );
      thoigianbatdau_tomorrow.setHours(8, 0, 0, 0);
      let res = await this.GetPeriodTime(actionCode, item.phongBanId);
      let periodTime = res.periodTime;
      if (periodTime == 0 || periodTime == -1) periodTime = 8 * 60 * 60; // chưa thiết lập defaultperiodTime thì mặc định là 8 tiếng.

      let deadline;

      // xử lý không cho deadline nằm ngoài giờ hành chính: 8h->17h
      if (
        thoigianbatdau.getTime() <= today.getTime() &&
        thoigianketthuc.getTime() >= today.getTime()
      ) {
        deadline = new Date(today.getTime() + 1000 * periodTime); // periodTime đổi sang miliseconds
        if (deadline > thoigianketthuc)
          deadline = new Date(
            deadline.getTime() +
              thoigianbatdau.getTime() -
              thoigianketthuc.getTime() +
              24 * 60 * 60 * 1000
          );
      } else if (thoigianbatdau.getTime() > today.getTime()) {
        deadline = new Date(thoigianbatdau.getTime() + 1000 * periodTime);
      } else if (thoigianketthuc.getTime() < today.getTime()) {
        deadline = new Date(
          thoigianbatdau_tomorrow.getTime() + 1000 * periodTime
        );
      }

      return deadline;
    },
    async ThongBaoVuPhanTich(actionCode, item, vgd) {
      let dsNhanVien = JSON.parse(localStorage.getItem("eic_dsNhanVien"));

      // Tất cả các email thông báo kết quả phân tích đều CC cho Cầm luôn nha
      // let duongThanhCam = await this.nhanVienSrv.Get(264);
      let duongThanhCam = dsNhanVien.find((e) => e.id == 264);

      let toListNhanVien = [];

      // let nhanVien = await this.nhanVienSrv.GetByListIds([item.lastUserId]);
      // let fromNhanVien = nhanVien.pop();

      let nhanVien = dsNhanVien.find((e) => e.id == item.lastUserId);
      let fromNhanVien = nhanVien;
      let fromEmail = fromNhanVien.email;

      if (actionCode == 85) {
        // Gửi duyệt kết quả phân tích thì email cho trưởng phòng để duyệt.
        // toListNhanVien = await this.nhanVienSrv.GetTP_CS_GD_Lab(item.phongBanId);
        toListNhanVien = dsNhanVien.filter((e) => {
          let arrRoleId = [
            Roles.TruongPhong_Lab,
            Roles.GiamDoc_Lab,
            Roles.CS_Lab,
          ];
          if (e.phongBanQuanLyId) {
            return (
              arrRoleId.includes(e.roleId) &&
              (e.phongBanId == item.phongBanId ||
                e.phongBanQuanLyId.includes(item.phongBanId))
            );
          } else {
            return (
              arrRoleId.includes(e.roleId) && e.phongBanId == item.phongBanId
            );
          }
        });
        toListNhanVien.push(duongThanhCam);
      } else if (actionCode == 82) {
        // Đã chấp nhận YCPT thì email cho người yêu cầu biết.
        // toListNhanVien = await this.nhanVienSrv.GetYC(item.nguoiYeuCauUserId);
        toListNhanVien = dsNhanVien.filter(
          (e) => e.id == item.nguoiYeuCauUserId
        );
      } else if (actionCode == 86 || actionCode == 261) {
        // Đã gửi KQPT cho khách hàng thì email cho khách hàng biết
        // Cần gửi cho CS và trưởng phòng của Vụ Giám Định liên quan đến vụ phân tích.
        try {
          // Lấy ra Vụ Giám Định liên quan đến Vụ Phân Tích:
          let phongBanId = vgd.phongBanId;
          // let dsNhanVienCS = await this.nhanVienSrv.GetCS(phongBanId);
          // let dsNhanVienTP = await this.nhanVienSrv.GetTruongPhong(phongBanId);
          let dsNhanVienCS_TP = dsNhanVien.filter((e) => {
            let arrRoleId = [
              Roles.CS,
              Roles.CSCN,
              Roles.TruongPhong,
              Roles.TruongPhongNotHD,
              Roles.TruongPhongHD,
            ];
            if (e.phongBanQuanLyId) {
              return (
                arrRoleId.includes(e.roleId) &&
                (e.phongBanId == phongBanId ||
                  e.phongBanQuanLyId.includes(phongBanId))
              );
            } else {
              return arrRoleId.includes(e.roleId) && e.phongBanId == phongBanId;
            }
          });

          toListNhanVien = [...dsNhanVienCS_TP, duongThanhCam];
        } catch (error) {
          alert(error);
        }
      } else if (actionCode == 823) {
        // Chỉ định phân tích viên thì người nhận email là PTV được chỉ định (bao gồm cả PTV chính và phụ)
        // toListNhanVien = await this.nhanVienSrv.GetPTV(item);
        let listId = [];
        if (item.dSPhanTichVienPhuID) {
          listId = [...item.dSPhanTichVienChinhID, ...item.dSPhanTichVienPhuID];
        } else {
          listId = [...item.dSPhanTichVienChinhID];
        }
        toListNhanVien = dsNhanVien.filter((e) => listId.includes(e.id));
      } else if (actionCode == 861) {
        // Từ chối kết quả phân tích thì người nhận là CS và PTV
        // let dsNhanVienCS = await this.nhanVienSrv.GetCS(item.phongBanId);
        let dsNhanVienCS = dsNhanVien.filter((e) => {
          let arrRoleId = [Roles.CS_Lab];
          if (e.phongBanQuanLyId) {
            return (
              arrRoleId.includes(e.roleId) &&
              (e.phongBanId == item.phongBanId ||
                e.phongBanQuanLyId.includes(item.phongBanId))
            );
          } else {
            return (
              arrRoleId.includes(e.roleId) && e.phongBanId == item.phongBanId
            );
          }
        });
        // let dsPTV = await this.nhanVienSrv.GetPTV(item);
        let listId = [];
        if (item.dSPhanTichVienPhuID) {
          listId = [...item.dSPhanTichVienChinhID, ...item.dSPhanTichVienPhuID];
        } else {
          listId = [...item.dSPhanTichVienChinhID];
        }
        let dsPTV = dsNhanVien.filter((e) => listId.includes(e.id));

        toListNhanVien = [...dsPTV, ...dsNhanVienCS, duongThanhCam];
      }
      let dsemail = toListNhanVien.map(function (element) {
        return element.email;
      });
      let toListEmails = dsemail.join(", ");
      console.log("toListEmails", toListEmails);

      let tenDoiTuong = "Vụ phân tích Số " + item.soChungThu;
      let getHistoryMessage = function (num) {
        if (num == 823) {
          return "Chỉ định Phân tích viên";
        } else if (num == 85) {
          return "Gửi duyệt KQPT";
        } else if (num == 261) {
          return "Đã có kết quả phân tích từ Lab";
        } else if (num == 861) {
          return "Từ chối duyệt KQPT";
        }
      };
      let hanhDong = getHistoryMessage(actionCode);
      let subject = tenDoiTuong + " | " + hanhDong + " bởi " + fromNhanVien.ten;

      let tenKhachHang = this.getTenKhachHang(item.khachHangId);

      let text = `Tên khách hàng: <strong>${tenKhachHang}</strong>. \r\n <br/>
                        Số phiếu KQPT: <strong>${
                          item.soChungThu
                        }</strong>. \r\n <br/>
                        Số chứng thư Vụ Giám Định: <strong>${
                          item.soChungThuVuGiamDinh
                        }</strong>. \r\n <br/>
                        Tên mẫu: <strong>${
                          item.tenHienThi ? item.tenHienThi : item.tenMau
                        }</strong>. \r\n <br/>
                        Số niêm: <strong>${item.soNiem}</strong>. \r\n <br/>`;

      if (actionCode == 86 || actionCode == 261) {
        let tenKhachHangVGD = vgd.tenDoiTac;
        if (vgd.khachHangId > 0) {
          tenKhachHangVGD = this.getTenKhachHangQLGD(vgd.khachHangId);
        }

        let getDanhSachTenByIds = function (listIds) {
          if (!listIds) return "";
          let listNV = dsNhanVien.filter((e) => listIds.includes(e.id));
          let dsTen = listNV.map((nv) => {
            return nv.ten;
          });
          return dsTen.join(", ");
        };

        let dsTenGDVChinh = getDanhSachTenByIds(vgd.dSGiamDinhVienChinhID);
        let dsTenGDVPhu = getDanhSachTenByIds(vgd.dSGiamDinhVienPhuID);

        text = `Tên khách hàng: ${tenKhachHangVGD}. \r\n <br/>
                          Số Chứng Thư: ${vgd.soChungThu}. \r\n <br/>
                          Hạng mục yêu cầu giám định: ${vgd.hangMucYeuCauGiamDinh}. \r\n <br/>
                          Thời gian giám định: ${vgd.thoiGianGiamDinh}. \r\n <br/>
                          Địa điểm giám định: ${vgd.diaDiemGiamDinh}. \r\n <br/>
                          Phương tiện vận tải: ${vgd.phuongTienVanTai}. \r\n <br/>
                          Người liên hệ: ${vgd.tenNguoiLienHe}. \r\n <br/>
                          Số điện thoại: ${vgd.dienThoaiNguoiLienHe}. \r\n <br/>
                          Giám Định Viên chính: ${dsTenGDVChinh}. \r\n <br/>
                          Danh Sách Giám định Viên Phụ: ${dsTenGDVPhu}`;
        text = text + `<br/><hr>`;
        text = text + `<h3>KẾT QUẢ PHÂN TÍCH</h3>`;

        let sttChiTieu = [];
        let setSTTChiTieu = function (vpt) {
          let i = 0;
          vpt.cacChiTieuPhanTich.forEach((e) => {
            if (e.isSelected) {
              i = i + 1;
              sttChiTieu.push(i);
            } else {
              sttChiTieu.push(0);
            }
          });
        };
        let xuLyTenMau = function (tenMau) {
          //nếu tên bộ chỉ tiêu là THAN NOI, THAN INDO.... thì tên mẫu hiện ra trên kết quả phân tích chỉ là THAN
          if (tenMau == "ETHANOL") return tenMau;
          if (tenMau.indexOf("THAN") > -1) {
            return "THAN";
          } else if (tenMau.indexOf("MOGAS") > -1) {
            return "MOGAS";
          } else {
            return tenMau;
          }
        };
        let xuLyDonVi = function (chitieu) {
          let kq;
          if (
            chitieu.donVi == "%mol & %vol" ||
            chitieu.donVi == "% mol & % vol"
          ) {
            kq = "%mol<br>&<br>%vol";
          } else {
            kq = chitieu.donVi
              ? chitieu.donVi.replace(/;/g, "<br>")
              : chitieu.donVi;
          }
          return kq;
        };
        let xuLyTenChiTieu = function (chitieu) {
          let kq;
          let tenCT = chitieu.tenChiTieu;
          if (!tenCT) return kq;
          if (chitieu.duocCongNhanVilas) {
            if (tenCT.indexOf(":") > -1) {
              // xử lý hiện công thức hóa học:
              if (!chitieu.tenTiengAnh) {
                if (tenCT.indexOf(":") > -1) {
                  kq = tenCT
                    .replace(/:/g, ":<sup>(**)</sup><br>")
                    .replace(/;/g, "<br>");
                } else {
                  kq = tenCT + "<sup>(**)</sup>";
                }
              } else {
                kq = tenCT.replace(/:/g, ":<br>").replace(/;/g, "<br>");
              }

              // if (tenCT.indexOf("Ethanol") == -1)
              //   kq = kq.replace(
              //     "Ethan",
              //     '<span style="color:orangered">&emsp; C<sub>2</sub>H<sub>6</sub> (Ethan)</span>'
              //   );
              // kq = kq.replace(
              //   "Propan",
              //   '<span style="color:green">&emsp; C<sub>3</sub>H<sub>8</sub> (Propan)</span>'
              // );
              // kq = kq.replace(
              //   "Iso Butan",
              //   '<span style="color:orangered">&emsp;  i-C<sub>4</sub>H<sub>10</sub> (Iso Butan)</span>'
              // );
              // kq = kq.replace(
              //   "Normal Butan",
              //   '<span style="color:green">&emsp;  n-C<sub>4</sub>H<sub>10</sub> (Normal Butan)</span>'
              // );
              // kq = kq.replace(
              //   "Neo Pentan",
              //   '<span style="color:orangered">&emsp;  neo-C<sub>5</sub>H<sub>12</sub> (Neo Pentan)</span>'
              // );
              // kq = kq.replace(
              //   "Iso Pentan",
              //   '<span style="color:green">&emsp;  i-C<sub>5</sub>H<sub>12</sub> (Iso Pentan)</span>'
              // );
              // kq = kq.replace(
              //   "Normal Pentan",
              //   '<span style="color:orangered">&emsp; n-C<sub>5</sub>H<sub>12</sub> (Normal Pentan)</span>'
              // );
              // kq = kq.replace(
              //   "Propen",
              //   '<span style="color:green">&emsp;  C<sub>3</sub>H<sub>6</sub> (Propen)</span>'
              // );
              // kq = kq.replace(
              //   "Buten",
              //   '<span style="color:orangered">&emsp; C<sub>4</sub>H<sub>8</sub> (Buten)'
              // );
              // kq = kq.replace(
              //   "Pentene",
              //   '<span style="color:green">&emsp; C<sub>5</sub>H<sub>10</sub> (Pentene)</span>'
              // );
              // kq = kq.replace(
              //   "1-3 Butadiene",
              //   '<span style="color:orangered">&emsp; C<sub>4</sub>H<sub>6</sub> (1-3 Butadiene)</span>'
              // );
              // kq = kq.replace(
              //   "Olefin",
              //   '<span style="color:green">&emsp; Olefin</span>'
              // );
            } else {
              kq = tenCT.replace("Hydro Sulfua", "H<sub>2</sub>S");
              if (kq.indexOf(";") > -1) {
                kq = kq.replace(/;/g, "<br>");
              }
            }
          } else {
            kq = tenCT.replace(/:/g, ":<br>").replace(/;/g, "<br>");
          }
          return kq;
        };
        let xuLyTenChiTieuTiengAnh = function (chitieu) {
          let kq;
          let tenCT = chitieu.tenChiTieu;
          let tenTA = chitieu.tenTiengAnh;
          if (!tenTA) return "";
          if (chitieu.duocCongNhanVilas) {
            if (tenTA.indexOf(":") > -1) {
              kq = tenTA
                .replace(/:/g, ":<sup>(**)</sup><br>")
                .replace(/;/g, "<br>");
              // xử lý hiện công thức hóa học:
              // if (tenTA.indexOf("Ethanol") == -1)
              //   kq = kq.replace(
              //     "Ethan",
              //     '<span style="color:orangered">&emsp; C<sub>2</sub>H<sub>6</sub> (Ethan)</span>'
              //   );
              // kq = kq.replace(
              //   "Propan",
              //   '<span style="color:green">&emsp; C<sub>3</sub>H<sub>8</sub> (Propan)</span>'
              // );
              // kq = kq.replace(
              //   "Iso Butan",
              //   '<span style="color:orangered">&emsp;  i-C<sub>4</sub>H<sub>10</sub> (Iso Butan)</span>'
              // );
              // kq = kq.replace(
              //   "Normal Butan",
              //   '<span style="color:green">&emsp;  n-C<sub>4</sub>H<sub>10</sub> (Normal Butan)</span>'
              // );
              // kq = kq.replace(
              //   "Neo Pentan",
              //   '<span style="color:orangered">&emsp;  neo-C<sub>5</sub>H<sub>12</sub> (Neo Pentan)</span>'
              // );
              // kq = kq.replace(
              //   "Iso Pentan",
              //   '<span style="color:green">&emsp;  i-C<sub>5</sub>H<sub>12</sub> (Iso Pentan)</span>'
              // );
              // kq = kq.replace(
              //   "Normal Pentan",
              //   '<span style="color:orangered">&emsp; n-C<sub>5</sub>H<sub>12</sub> (Normal Pentan)</span>'
              // );
              // kq = kq.replace(
              //   "Propen",
              //   '<span style="color:green">&emsp;  C<sub>3</sub>H<sub>6</sub> (Propen)</span>'
              // );
              // kq = kq.replace(
              //   "Buten",
              //   '<span style="color:orangered">&emsp; C<sub>4</sub>H<sub>8</sub> (Buten)'
              // );
              // kq = kq.replace(
              //   "Pentene",
              //   '<span style="color:green">&emsp; C<sub>5</sub>H<sub>10</sub> (Pentene)</span>'
              // );
              // kq = kq.replace(
              //   "1-3 Butadiene",
              //   '<span style="color:orangered">&emsp; C<sub>4</sub>H<sub>6</sub> (1-3 Butadiene)</span>'
              // );
              // kq = kq.replace(
              //   "Olefin",
              //   '<span style="color:green">&emsp; Olefin</span>'
              // );
            } else {
              kq = tenTA.replace("Hydro Sulfua", "H<sub>2</sub>S");
              kq = kq + "<sup>(**)</sup>";
              if (kq.indexOf(";") > -1) {
                kq = kq.replace(/;/g, "<br>");
              }
            }
            // Replace các trạng thái của THAN
            let arrTrangThai = [
              "(arb)",
              "(arb )",
              "(db)",
              "(db )",
              "(adb)",
              "(adb )",
              "(daf)",
              "(daf )",
              "(dmmf)",
              "(dmmf )",
            ];
            arrTrangThai.forEach((e) => {
              if (tenTA.indexOf(e) > -1) {
                kq = kq.replace(e, `<sup>${e}</sup>`);
              }
            });
          } else {
            kq = tenTA.replace(/:/g, ":<br>").replace(/;/g, "<br>");
          }
          return kq;
        };

        let xuLyKetQua = function (chitieu) {
          let kq;
          let str = chitieu.ketQua;
          if (!str) return kq;
          if (chitieu.boChiTieuId == 21) {
            console.log("viet day la chi tieu LPG");
            if (chitieu.tenChiTieu.indexOf("Ethan") > -1) {
              console.log("viet day la chi tieu Thành phần/Competions");
              kq = `<table cellspacing=0 style='border:0px;width:100%;line-height: 22px;'><tr><td style='text-align:center;border:0px;border-right:1px solid;border-color: black;'>%mol</td><td style='text-align:center;border:0px'>%vol</td></tr>`;
              let arrStr = str.split(";");
              arrStr.forEach((e, index) => {
                if (!e) return;
                let m = e.split("-");
                let s = `<tr style="color:${
                  index % 2 == 0 ? "orangered" : "green"
                }"><td style='text-align:center;border:0px;border-right:1px solid;border-color: black;'>${
                  m[0]
                }</td><td style='text-align:center;border:0px'>${
                  m[1]
                }</td></tr>`;
                kq = kq.concat(s);
              });
              kq = kq.concat(`</table>`);
              return kq;
            } else if (chitieu.tenChiTieu.indexOf("Hàm lượng Olefin") > -1) {
              console.log("viet day la chi tieu Hàm lượng Olefin");
              kq = `<table cellspacing=0 style='border:0px;width:100%;'>`;
              let arrStr = str.split(";");
              arrStr.forEach((e) => {
                if (!e) return;
                let m = e.split("-");
                let s = `<tr><td style='text-align:center;border:0px;border-right:1px solid'>${m[0]}</td><td style='text-align:center;border:0px'>${m[1]}</td></tr>`;
                kq = kq.concat(s);
              });
              kq = kq.concat(`</table>`);
              return kq;
            } else if (
              chitieu.tenChiTieu.indexOf("Hàm lượng 1-3 Butadience") > -1
            ) {
              console.log("viet day la chi tieu Hàm lượng 1-3 Butadience");
              kq = `<table cellspacing=0 style='border:0px;width:100%;'>`;
              let arrStr = str.split(";");
              arrStr.forEach((e) => {
                if (!e) return;
                let m = e.split("-");
                let s = `<tr><td style='text-align:center;border:0px;border-right:1px solid'>${m[0]}</td><td style='text-align:center;border:0px'>${m[1]}</td></tr>`;
                kq = kq.concat(s);
              });
              kq = kq.concat(`</table>`);
              return kq;
            }
          }

          if (chitieu.tenChiTieu.indexOf(":") > -1) {
            if (str.indexOf(";") > -1) {
              kq = "<br>" + str.replace(/;/g, "<br>"); // cần xuống thêm một dòng ở đầu kq
            } else {
              kq = "<br>" + str;
            }
          } else {
            if (str.indexOf(";") > -1) {
              kq = str.replace(/;/g, "<br>");
            } else {
              kq = str;
            }
          }

          return kq;
        };

        setSTTChiTieu(item);
        let ngayNhanMau = "";
        if (item.ngayNhanMau) {
          ngayNhanMau = dateFns.format(
            new Date(item.ngayNhanMau),
            "dd/MM/yyyy"
          );
        }

           let getMaPhongBan = (id) => {
          let pb = this.dsPhongBanLab.find(e => e.id == id);
          return pb.ma;
        }
        let getDiaDiemKy = (ma) => {
          switch (ma) {
            case 'PTNHCM':
              return 'TP.HCM (Ho Chi Minh City)';
            case 'PTNQN':
              return 'Quảng Ninh (Quang Ninh Province)';
            case 'PTNQNG':
              return 'Quảng Ngãi (Quang Ngai Province)';
            case 'PTNTV':
              return 'Trà Vinh (Tra Vinh Province)';
            case 'PTNHP':
              return 'TP Hải Phòng (Hai Phong City)';
            case 'PTNHD':
              return 'Hải Dương (Hai Duong Province)';
            default:
              return '';
          }
        }

        let maPB = getMaPhongBan(item.phongBanId);
        let diaDiemKy = getDiaDiemKy(maPB);
         let phanTichVien = this.getTenbyId(
        item.dSPhanTichVienChinhID[0].toString()
      );
      let nguoiDuyet = item.nguoiKyKQPT;
      let ngayDuyet;
      if (item.ngayKyKQPT) {
        let d = new Date(item.ngayKyKQPT);
        let month = d.getMonth() + 1;
        ngayDuyet = dateFns.format(new Date(item.ngayKyKQPT), "dd/MM/yyyy");
      } else {
        ngayDuyet = "";
      }
       let getIDCategoryPTN = (ma) => {
            switch (ma) {
              case 'PTNHCM':
                return 170;
              case 'PTNQN':
                return 169;
              case 'PTNQNG':
                return 172;
              case 'PTNTV':
                return 168;
              case 'PTNHP':
                return 171;
              case 'PTNHD':
                return 232;
              default:
                return -1;
            }
          }
      let idCategoryPTN = getIDCategoryPTN(maPB);
      let footerAddress = await this.getAddress_PTN(idCategoryPTN);
          let footerAddressVI ="";
          let footerAddressEN ="";
          if(footerAddress[0].danhMuc.indexOf("-vi")>-1){
            footerAddressVI = footerAddress[0].tenDanhMuc; 
            footerAddressEN = footerAddress[1].tenDanhMuc;
          }else{
            footerAddressVI = footerAddress[1].tenDanhMuc; 
            footerAddressEN = footerAddress[0].tenDanhMuc;
          }

            let giamSoLuongMau = false;
                  item.cacChiTieuPhanTich.forEach(sample=>{
                  if(sample.type=='mau-binh-quan'){
                    giamSoLuongMau = true;
                    return
                  }
                });
           text = text + `<table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" bgcolor="#DCF0F8" style="margin:0;padding:15px;background-color:#DCF0F8;width:100%!important;font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#444;line-height:18px">
               <tbody>
                 <tr>
                   <td align="center" valign="top" style="font-family:Arial,Helvetica,sans-serif;font-size:12px;color:#444;line-height:18px;font-weight:normal">
                     
                     <table border="0" cellpadding="0" cellspacing="0" width="95%" style="margin-top:15px;margin-bottom:15px">
                       <tbody>
                         <tr>
                           <td>
                             <table border="0" cellpadding="0" cellspacing="0" style="line-height:0">
                               <tbody>
                               </tbody>
                             </table>
                           </td>
                         </tr>
                         <tr>
                           <td align="center" valign="bottom" id="m_-8288361455967164091headerImage">
                             <table width="100%" cellpadding="0" cellspacing="0">
                               <tbody>
                                 <tr>
                                   <td valign="top" bgcolor="#FFFFFF" width="100%" style="padding:0;text-align:center">
                                     <a style="border:medium none;text-decoration:none;color:#007ed3" href="http://eic.com.vn" target="_blank" >
                                       <img alt="eic.com.vn" src="http://eic.com.vn/tra-cuu-chung-thu/header_vn_new.png" style="border:none;outline:none;text-decoration:none;display:inline;width:100%" class="CToWUd">
                                     </a>
                                   </td>
                                 </tr>
                               </tbody>
                             </table>
                           </td>
                         </tr>
                         
                         <tr style="background:#fff">
                           <td align="left" width="100%" height="auto" style="padding:15px">
                           <table style="width:100%;font-size:12pt;">
                           <tr>
                               <td colspan=2 style="font-size:20pt;font-weight:bold;text-align:center">PHIẾU KẾT QUẢ THỬ NGHIỆM </td>
                           </tr>
                           <tr>
                               <td colspan=2 style="font-size:18pt;font-weight:bold;text-align:center;padding-top:15px">( <i>TEST REPORT</i> ) </td>
                           </tr>
                           <tr>
                               <td colspan=2 style="text-align:right">Số (<i>No.</i>) : ${item.soChungThu}</td>
                           </tr>
                           <tr>
                               <td colspan=2 style="text-align:left;width:100%;">
                                   <table style="width:100%;font-size:12pt;">
                                       <tr>
                                           <td style="width:2%">&nbsp;</td>
                                           <td style="width:45%">- Người yêu cầu (<i>Requester</i>)</td>
                                           <td style="width:53%">:&emsp; ${tenKhachHang}</td>
                                       </tr>
                                       <tr>
                                           <td >&nbsp;</td>
                                           <td>- Số yêu cầu (<i>Required number</i>)</td>
                                           <td>:&emsp;  ${item.soChungThuVuGiamDinh?item.soChungThuVuGiamDinh:''}</td>
                                       </tr>
                                       <tr>
                                           <td >&nbsp;</td>
                                           <td>- Loại mẫu (<i>Sample name</i>)</td>
                                           <td>:&emsp; ${item.tenHienThi?item.tenHienThi:xuLyTenMau(item.tenMau)}</td>
                                       </tr>
                                       <tr>
                                           <td >&nbsp;</td>
                                           <td>- Tên tàu (<i>Ship name</i>)</td>
                                           <td>:&emsp; ${item.noiLayMau?item.noiLayMau:''}</td>
                                       </tr>
                                       <tr>
                                           <td >&nbsp;</td>
                                           <td>- Số lượng mẫu (<i>Number of sample</i>)</td>
                                           <td>:&emsp; ${giamSoLuongMau?item.soLuongMau-1:item.soLuongMau}</td>
                                       </tr>
                                       <tr>
                                           <td >&nbsp;</td>
                                           <td>- Ngày nhận mẫu (<i>Date/time of receipt of sample</i>)</td>
                                           <td>:&emsp; ${ngayNhanMau}</td>
                                       </tr>
                                        <tr>
                                           <td >&nbsp;</td>
                                           <td>- Thời gian lưu mẫu (<i>Sample holding time</i>)</td>
                                           <td>:&emsp; ${item.thoiGianLuuMau}</td>
                                       </tr>
                                   </table>
                               </td>
                           </tr>
                       </table>
                   </td>
                 </tr>`;
             text = text + `<tr style="background:#fff">
                              <td align="center" valign="top" style="font-family:Arial,Helvetica,sans-serif;font-size:14px;color:#444;line-height:18px;font-weight:normal">`;
              text = text + ` 
                            <table cellspacing="0" style="border-collapse:collapse;width:95%;">
                            <tr style="text-align:center">
                                <td style="border: 1px solid;width:2%;height:20px;border-bottom:0px">
                                    <strong>TT</strong>
                                </td>
                                <td style="border: 1px solid;width:30%;border-bottom:0px">
                                    <strong>Tên/ ký hiệu mẫu</strong>
                                </td> `;
              item.cacChiTieuPhanTich[0].cacChiTieuPhanTich.forEach((chitieu,index) =>{
                text = text + ` <td style="border: 1px solid;border-bottom:0px;border-right:1px solid">
                                    <strong>${chitieu.tenChiTieu}</strong>
                                </td>`;
              }) ;
              text = text + `  </tr>
                                <tr style="text-align:center">
                                    <td rowspan="2" style="border: 1px solid;border-top:0px">No.</td>
                                    <td rowspan="2" style="border: 1px solid;border-top:0px">Seal No.</td>`;

              item.cacChiTieuPhanTich[0].cacChiTieuPhanTich.forEach((chitieu,index) =>{
                text = text + `  <td style="border: 1px solid;border-top:0px;border-right:1px solid">
                                ${chitieu.phuongPhapThu}
                                </td>`;
              }) ;    

              text = text + ` </tr>
                              <tr style="text-align:center">`;     

              item.cacChiTieuPhanTich[0].cacChiTieuPhanTich.forEach((chitieu,index) =>{
                text = text + `  <td style="border-top:0px;border-right:1px solid">
                              ${chitieu.donVi}
                              </td>`;
              }) ;      
              
              text = text + `</tr>`;    
              item.cacChiTieuPhanTich.forEach((sample,index) =>{
                text = text + `  <tr style="text-align:center">
                <td style="border: 1px solid;height:20px;">
                    <strong>${index+1}</strong>
                </td>
                <td style="border: 1px solid;width:30%;">
                    <strong>${sample.soNiem}</strong>
                </td>`;
                sample.cacChiTieuPhanTich.forEach((chitieu,key)=>{
                  text = text + `<td style="border: 1px solid;border-right:1px solid">${chitieu.ketQua?chitieu.ketQua:''}</td>
                  `;
                })
                text = text + `</tr>`;
              
           
              }) ;      
              text = text + `</table>`; 
             text = text + `</td></tr>`;
             text = text + `<tr style="background:#fff">
                              <td align="center" valign="top" style="font-family:Arial,Helvetica,sans-serif;font-size:14px;color:#444;line-height:18px;font-weight:normal">`;
             text = text + `<table cellspacing="0" style="width:100%;border:0px;margin-top: 25px;">
             <tr><td style="width:50%;border:0px;text-align:center;font-size:12pt"></td>
               <td  style="border:0px;text-align:center;font-size:12pt">${diaDiemKy}, ${ngayDuyet}</td></tr>
             <tr>
                 <td style="width:50%;border:0px;text-align:center;font-size:12pt">Người phân tích (<i>Staff Analytical</i>)</td>
                 <td style="border:0px;text-align:center;font-size:12pt">Trung tâm phân tích (<i>Analysis Center</i>)</td>
             </tr>
             <tr>
                 <td style="border:0px;text-align:center;font-size:12pt">
                     <div>
                                  <span>
                               <span style="color:black">
                                 <strong>
                                    <span style="text-transform: uppercase;" >${phanTichVien}</span>
                                 </strong>
                               </span>
                             </span>
                   </div>
                 </td>
                 <td style="border:0px;text-align:center;font-size:12pt">
                     <div>
                                           <span >
                                 &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;  &emsp; &emsp; <div class="container">
                                   <img class="a" src='http://eic.com.vn/tra-cuu-chung-thu/f-check_30.png' alt="Norway">
                                     <div class="center">
                                       <strong>
                                           <span>
                                             <span style="color:black">Được ký bởi (<i>Signed by</i>):
                                               <br/>
                                               <span>
                                                 <span style="color:black;text-transform: uppercase;">
                                                   <em>${nguoiDuyet}</em>
                                                 </span>
                                               </span>
                                               <br/>
                                             </span>
                                           </span>
                                       </strong>
                                     </div>
                                     </img>
                                   </div>
                                 </span>
                     </div>
                 
                 </td>
             </tr>
                           </table>`
             text = text + `</td></tr>`;

             text = text + `<tr style="background:#fff">
                              <td align="center" valign="top" style="font-family:Arial,Helvetica,sans-serif;font-size:14px;color:#444;line-height:18px;font-weight:normal">`;
             text = text + `<table style="width:95%;font-size:11px;margin-top: 50px">
                              <tr>
                                  <td style="width:15%">&nbsp;</td>
                                  <td style="width:70%;text-align:center">Địa chỉ trung tâm: ${footerAddressVI}
                                  <br>(<i>Address: ${footerAddressEN}</i>)
                                  <td style="width:15%">&nbsp;</td>
                              </tr>
                              <tr>
                                  <td style="width:15%">LAB 024-26/5/2016</td>
                                  <td style="width:70%;text-align:center">Các kết quả thử nghiệm ghi trong phiếu này chỉ có giá trị trên mẫu gửi phân tích 
                                  <br>(<i>The results are valid only on the sample submitted for analysis</i>)</td>
                                  <td style="width:15%">&nbsp;</td>
                              </tr>
                          </table>`;
             text = text + `</td></tr>`;

             text = text + `</tbody></table>`;


        // Đổi tiêu đề email cho phù hợp với phòng ban.
        tenDoiTuong = "Vụ giám định Số chứng thư " + vgd.soChungThu;
        hanhDong = "Đã có kết quả phân tích từ Lab";
        subject = tenDoiTuong + " | " + hanhDong + " bởi " + fromNhanVien.ten;
      }
      return this.GuiEmail(fromEmail, toListEmails, subject, text, "THONGBAO");
    },
    async GuiEmail(fromEmail, toListEmails, subject, bodyText, LoaiEmail) {
      console.log("viet GuiEmail  test", toListEmails);
      let item = {
        to: toListEmails,
        from: fromEmail,
        subject: subject,
        text: bodyText,
      };
      let rec = await axios.post(
        apiEndPoint + "api/ActionHistories/sendEmail",
        item
      );
      return rec.data;
    },
    onPageInit() {},
    veTrangChu() {
      if (this.userInfo.roleId == Roles.PhanTichVien_Lab) {
        this.$f7router.back("/home-ptv/");
      } else {
        this.$f7router.back("/home-lab/");
      }
    },
    enableNgayKy(item) {
      let check = false;
      let arrTrangThai = [
        TrangThai.LAB_VPT_DangXuLy_0823,
        TrangThai.LAB_VPT_ChoDuyetKetQuaPT_085,
        TrangThai.LAB_VPT_KhachHangChapNhanKQPT_0881,
      ];
      if (arrTrangThai.includes(item.trangthai)) {
        check = true;
      }
      return check;
    },
    showBoChiTieu(item) {
      let check = false;
      if (item.trangthai == TrangThai.LAB_VPT_TaoMoi_0822) {
        check = true;
      }
      return check;
    },
    showBoChiTieu_ChoDuyet(item) {
      let check = false;
      if (item.trangthai == TrangThai.LAB_VPT_ChoDuyetKetQuaPT_085) {
        check = true;
      }
      return check;
    },
    disableChiDinhPTV() {
      let check = true;
      if (
        this.userInfo.roleId == Roles.TruongPhong_Lab ||
        this.userInfo.roleId == Roles.GiamDoc_Lab ||
        this.userInfo.roleId == Roles.PhoGiamDoc_Lab
      ) {
        check = false;
      }
      return check;
    },
    validateKQPT(item) {
      let check = true;
      item.cacChiTieuPhanTich.forEach((e) => {
        if (e.isSelected == true) {
          if (e.ketQua == null) {
            check = false;
          }
        }
      });
      if (check == false) {
        this.openAlert("Bạn chưa nhập đủ Kết quả phân tích!");
      }
      return check;
    },
    async getLichSuVPT(item) {
      let url = apiEndPoint + "api/ActionHistories";
      let filter = {
        where: {
          objectId: item.id,
          objectType: "VuPhanTich",
        },
        order: "date desc",
      };
      let filterYCPT = {
        where: {
          objectId: item.ycphantichId,
          objectType: "YeuCauPhanTich",
        },
        order: "date desc",
      };
      try {
        let resVPT = await axios.get(url, {
          params: {
            filter: filter,
          },
        });
        let resYCPT = await axios.get(url, {
          params: {
            filter: filterYCPT,
          },
        });
        this.dsLichSuVPT = [...resVPT.data, ...resYCPT.data];
      } catch (error) {
        alert(error);
      }
    },
    getAllPTV(vpt) {
      let phongBanId = vpt.phongBanId;
      let dsPTV = this.dsNhanVien.filter((element) => {
        let dsPhongBanIds = [];
        if (element.phongBanId !== null || element.phongBanId !== undefined) {
          dsPhongBanIds.push(element.phongBanId);
        }
        if (
          element.phongBanQuanLyId !== null &&
          element.phongBanQuanLyId !== undefined &&
          element.phongBanQuanLyId.length !== 0
        ) {
          element.phongBanQuanLyId.forEach((e2) => {
            dsPhongBanIds.push(e2);
          });
        }
        if (dsPhongBanIds.includes(vpt.phongBanId)) return element;
      });
      return dsPTV;
    },
    openToast(text, position, closeTimeout) {
      const app = this.$f7;
      // Create center toast
      var toast = app.toast.create({
        text,
        position,
        closeTimeout,
      });
      toast.open();
    },
    async getChiTietVPT() {
      let url = apiEndPoint + "api/VuPhanTiches";
      let filter = {
        where: {
          id: this.$f7route.params.id,
        },
      };
      try {
        let res = await axios.get(url + "/findOne", {
          params: {
            filter: filter,
          },
        });
        this.chiTietVPT = res.data;
        console.log("viet getChiTietVPT", this.chiTietVPT);
        this.dSPhanTichVienChinhID =
          this.chiTietVPT.dSPhanTichVienChinhID || [];
        this.dSPhanTichVienPhuID = this.chiTietVPT.dSPhanTichVienPhuID || [];

        if (this.chiTietVPT.trangthai == TrangThai.LAB_VPT_TaoMoi_0822) {
          //  this.openToast('Vụ cần chỉ định PTV!', 'center', 3000);
        }
        // nếu VPT đang ở trạng thái chờ duyệt kết quả thì ngayKyKQPT mặc định là ngày hiện tại

        if (
          !this.chiTietVPT.ngayKyKQPT &&
          this.chiTietVPT.trangthai == TrangThai.LAB_VPT_ChoDuyetKetQuaPT_085
        ) {
          this.chiTietVPT.ngayKyKQPT = new Date();
        }

        this.getLichSuVPT(this.chiTietVPT);
        this.itemsChamCong = await this.getAllChamCongPTV();
      } catch (error) {
        console.log(error);
      }
    },
    openAlert(titlename, cb) {
      const app = this.$f7;
      app.dialog.alert("" + titlename, "Thông báo", cb);
    },
    openPreloader(titlename, time) {
      const app = this.$f7;
      app.dialog.preloader(titlename);
      setTimeout(function () {
        app.dialog.close();
      }, time);
    },
    openLyDoTuChoiDialog(item) {
      const app = this.$f7;
      var cb = async (lyDo) => {
        if (!lyDo) {
          this.openAlert("Bạn chưa nhập lý do");
          this.openLyDoTuChoiDialog(item);
          return;
        }
        item.lyDoTraVe = lyDo;
        await this.runKhongDuyetKetQuaPhanTich(item);
      };
      app.dialog.prompt("Lý do không duyệt: ", "", cb);
    },
    openEditKetQuaDialog(item) {
      const app = this.$f7;
      var cb = async (value) => {
        if (!value) {
          this.openAlert("Bạn chưa nhập giá trị");
          this.openEditKetQuaDialog(item);
          return;
        }
        item.ketQua = value;
      };
      app.dialog.prompt("", "", cb, "", item.ketQua);
    },
    openEditPhuongPhapThuDialog(item) {
      const app = this.$f7;
      var cb = async (value) => {
        if (!value) {
          this.openAlert("Bạn chưa nhập giá trị");
          this.openEditPhuongPhapThuDialog(item);
          return;
        }
        item.phuongPhapThu = value;
      };
      app.dialog.prompt("", "", cb, "", item.phuongPhapThu);
    },
    openEditDonViDialog(item) {
      const app = this.$f7;
      var cb = async (value) => {
        if (!value) {
          this.openAlert("Bạn chưa nhập giá trị");
          this.openEditDonViDialog(item);
          return;
        }
        item.donVi = value;
      };
      app.dialog.prompt("", "", cb, "", item.donVi);
    },
    openEditTenChiTieuDialog(item) {
      const app = this.$f7;
      var cb = async (value) => {
        if (!value) {
          this.openAlert("Bạn chưa nhập giá trị");
          this.openEditTenChiTieuDialog(item);
          return;
        }
        item.tenChiTieu = value;
      };
      app.dialog.prompt("", "", cb, "", item.tenChiTieu);
    },

    getTenKhachHang(id) {
      let found = this.dsKhachHangLab.find((e) => e.id == id);
      if (!found) return "";
      return found.hoTen;
    },
    getTenKhachHangQLGD(id) {
      let found = this.dsKhachHang.find((e) => e.id == id);
      if (!found) return "";
      return found.hoTen;
    },
    getTenPhongBan(id) {
      let found = this.dsPhongBan.find((e) => e.id == id);
      if (!found) return "";
      return found.ten;
    },
    getTenNhanVien(id) {
      let found = this.dsNhanVien.find((e) => e.id == id);
      if (!found) return "";
      return found.ten;
    },
    openFile(item) {
      window.open(item, "_system");
      return false;
    },
    formatDate(date) {
      if (!date) return "";
      var result = dateFns.format(new Date(date), "dd/MM/yyyy HH:mm");
      return result;
    },
    formatDate2(date) {
      if (!date) return "";
      var result = dateFns.format(new Date(date), "yyyy-MM-dd");
      return result;
    },
     formatDate3(date) {
      if (!date) return "";
      var result = dateFns.format(new Date(date), "dd/MM/yyyy");
      return result;
    },
    enableDuyetKQPT(item) {
      let check = false;
      let arrTrangThai = [TrangThai.LAB_VPT_ChoDuyetKetQuaPT_085];
      let arrRole = [
        Roles.GiamDoc_Lab,
        Roles.PhoGiamDoc_Lab,
        Roles.TruongPhong_Lab,
      ];
      let userInfo = JSON.parse(localStorage.getItem("eic_userInfo"));
      if (
        arrTrangThai.includes(item.trangthai) &&
        arrRole.includes(userInfo.roleId)
      ) {
        check = true;
      }
      return check;
    },
    enableGuiDuyetKQPT(item) {
      let check = false;
      let arrTrangThai = [
        TrangThai.LAB_VPT_DangXuLy_0823,
        TrangThai.LAB_VPT_BiTuChoiKQPT_0861,
      ];
      if (arrTrangThai.includes(item.trangthai)) {
        check = true;
      }
      return check;
    },
    enableLuuDieuChinhVPT(item) {
      let check = false;
      let arrTrangThai = [
        TrangThai.LAB_VPT_TaoMoi_0822,
        TrangThai.LAB_VPT_DangXuLy_0823,
        TrangThai.LAB_VPT_KhachHangChapNhanKQPT_0881,
        TrangThai.LAB_VPT_BiTuChoiKQPT_0861,
      ];
      if (arrTrangThai.includes(item.trangthai)) {
        check = true;
      }
      return check;
    },
    enableInPhieuKQPT(item) {
      let check = false;
      let arrTrangThai = [
        TrangThai.LAB_VPT_DangXuLy_0823,
        TrangThai.LAB_VPT_ChoDuyetKetQuaPT_085,
        TrangThai.LAB_VPT_KhachHangChapNhanKQPT_0881,
        TrangThai.LAB_VPT_BiTuChoiKQPT_0861,
      ];
      if (arrTrangThai.includes(item.trangthai)) {
        check = true;
      }
      return check;
    },
    enableYeuCauHuyVPT(item) {
      let check = false;
      let arrTrangThai = [
        TrangThai.LAB_VPT_TaoMoi_0822,
        TrangThai.LAB_VPT_DangXuLy_0823,
      ];
      if (arrTrangThai.includes(item.trangthai)) {
        check = true;
      }
      return check;
    },
    async SaveHistory(actionId, objectId, message, note) {
      let userInfo = JSON.parse(localStorage.getItem("eic_userInfo"));
      let history = {
        actionId: actionId,
        appUserId: userInfo.userId,
        objectId: objectId,
        objectType: "VuPhanTich",
        date: new Date(),
        message: message,
        note: note,
      };
      try {
        let res = await axios.post(
          apiEndPoint + "api/ActionHistories",
          history
        );
        console.log("viet res", res);
      } catch (error) {
        alert(error);
      }
    },
    async SaveHistory_VGD(actionId, objectId, message, note) {
      let userInfo = JSON.parse(localStorage.getItem("eic_userInfo"));
      let history = {
        actionId: actionId,
        appUserId: userInfo.userId,
        objectId: objectId,
        objectType: "VuGiamDinh",
        date: new Date(),
        message: message,
        note: note,
      };
      try {
        let res = await axios.post(
          apiEndPoint + "api/ActionHistories",
          history
        );
        console.log("viet res", res);
      } catch (error) {
        alert(error);
      }
    },

    validateVPT(vpt) {
      let check = false;
      if (!vpt) {
        this.openAlert("Vụ phân tích không tồn tại");
        return check;
      }

      if (!vpt.khachHangId) {
        this.openAlert("Bạn chưa chọn Khách hàng");
        return check;
      }
      if (!vpt.soChungThuVuGiamDinh) {
        this.openAlert("Bạn chưa nhập Số chứng thư vụ giám định");
        return check;
      }
      if (!vpt.phongBanId) {
        this.openAlert("Bạn chưa chọn Trung tâm phân tích");
        return check;
      }

      check = true;
      return check;
    },
    trangThai_TaoMoiVPT(item) {
      if (item.trangthai == TrangThai.LAB_VPT_TaoMoi_0822) return true;
      return false;
    },
    async runDuyetKetQuaPhanTich(item) {
      let check = this.validateVPT(item);
      if (!check) {
        return;
      }
      let check2 = this.validateKQPT(item);
      if (!check2) {
        return;
      }

      this.openPreloader("Đang thực hiện...", 3000);

      item.dSPhanTichVienChinhID = this.dSPhanTichVienChinhID;
      item.dSPhanTichVienPhuID = this.dSPhanTichVienPhuID;

      item.trangthai = TrangThai.LAB_VPT_KhachHangChapNhanKQPT_0881;
      this.SaveHistory(
        881,
        item.id,
        "Duyệt và Gởi Khách Hàng KQPT (mqlgd)",
        ""
      );
      item.deadline = await this.ThietLapDeadline(261, item);

      item.lastModified = new Date();
      item.lastUserId = this.userInfo.userId;
      // cập nhật số lượng mẫu:
      item.soLuongMau = item.cacChiTieuPhanTich.length;
      // Người ký KQPT
      item.nguoiKyKQPT = this.userInfo.ten;

      var rs;
      if (item.id > 0) {
        rs = await axios.patch(
          apiEndPoint + "api/VuPhanTiches" + "/" + item.id,
          item
        );
        console.log("viet save vpt", rs);
        if (rs) {
          this.openAlert("Thực hiện thành công!");
          this.$f7router.back();
          EventLogin.$emit("reloadPageHomeLab");
        } else {
          this.openAlert("Có lỗi xảy ra!");
        }
      }

      let vgd;
      let vgds = [];
      // nếu là từ phòng ban gửi YCPT tới Lab mới có vuGiamDinhId.
      if (item.vuGiamDinhId) {
        let res = await axios.get(
          apiEndPoint + "api/VuGiamDinhs" + "/" + item.vuGiamDinhId
        );
        vgd = res.data;
        if (vgd.replyIds) {
          if (!vgd.replyIds.includes(item.id)) vgd.replyIds.push(item.id);
        } else {
          vgd.replyIds = [];
          vgd.replyIds.push(item.id);
        }

        let res2 = await axios.patch(
          apiEndPoint + "api/VuGiamDinhs" + "/" + item.vuGiamDinhId,
          vgd
        );
        console.log("viet res data", res2);
        this.SaveHistory_VGD(
          261,
          vgd.id,
          "Đã có kết quả phân tích từ Lab (mqlgd)",
          ""
        );
        this.ThongBaoVuPhanTich(261, item, vgd);
      } else if (
        item.soChungThuVuGiamDinh &&
        item.soChungThuVuGiamDinh.length == 8
      ) {
        // Trả kết quả dựa vào số chứng thư của VGD
        let soCT = item.soChungThuVuGiamDinh;
        let filter = { where: { soChungThu: soCT } };
        let res = await axios.get(apiEndPoint + "api/VuGiamDinhs", {
          params: {
            filter: filter,
          },
        });
        vgds = res.data;

        vgds.forEach(async (e) => {
          if (e.replyIds) {
            if (!e.replyIds.includes(item.id)) e.replyIds.push(item.id);
          } else {
            e.replyIds = [];
            e.replyIds.push(item.id);
          }

          let res = await axios.patch(
            apiEndPoint + "api/VuGiamDinhs" + "/" + e.id,
            e
          );
          console.log("viet res data", res);
          this.SaveHistory_VGD(
            261,
            e.id,
            "Đã có kết quả phân tích từ Lab (mqlgd)",
            ""
          );
          this.ThongBaoVuPhanTich(261, item, e);
        });
      } else {
        //ko làm gì cả
      }
    },
    async runKhongDuyetKetQuaPhanTich(item) {
      let check = this.validateVPT(item);
      if (!check) {
        return;
      }
      let check2 = this.validateKQPT(item);
      if (!check2) {
        return;
      }

      this.openPreloader("Đang thực hiện...", 3000);

      item.dSPhanTichVienChinhID = this.dSPhanTichVienChinhID;
      item.dSPhanTichVienPhuID = this.dSPhanTichVienPhuID;

      item.trangthai = TrangThai.LAB_VPT_BiTuChoiKQPT_0861;
      this.SaveHistory(861, item.id, "Từ chối KQPT (mqlgd)", "");
      item.deadline = await this.ThietLapDeadline(861, item);

      item.lastModified = new Date();
      item.lastUserId = this.userInfo.userId;

      item.ngayKyKQPT = null;

      var rs;
      if (item.id > 0) {
        rs = await axios.patch(
          apiEndPoint + "api/VuPhanTiches" + "/" + item.id,
          item
        );
        console.log("viet từ chối vpt", rs);
        if (rs) {
          this.openAlert("Thực hiện thành công!");
          this.$f7router.back();
          EventLogin.$emit("reloadPageHomeLab");
          this.ThongBaoVuPhanTich(861, item);
        } else {
          this.openAlert("Có lỗi xảy ra!");
        }
      }
    },
    async runGuiDuyetKetQuaPhanTich(item) {
      let check = this.validateVPT(item);
      if (!check) {
        return;
      }
      let check2 = this.validateKQPT(item);
      if (!check2) {
        return;
      }
      this.openPreloader("Đang thực hiện...", 3000);

      item.dSPhanTichVienChinhID = this.dSPhanTichVienChinhID;
      item.dSPhanTichVienPhuID = this.dSPhanTichVienPhuID;

      item.trangthai = TrangThai.LAB_VPT_ChoDuyetKetQuaPT_085;
      this.SaveHistory(85, item.id, "Gửi duyệt KQPT (mqlgd)", "");
      this.ThongBaoVuPhanTich(85, item);
      item.deadline = await this.ThietLapDeadline(85, item);

      item.lastModified = new Date();
      item.lastUserId = this.userInfo.userId;
      // cập nhật số lượng mẫu:
      item.soLuongMau = item.cacChiTieuPhanTich.length;
      var rs;
      if (item.id > 0) {
        rs = await axios.patch(
          apiEndPoint + "api/VuPhanTiches" + "/" + item.id,
          item
        );
        console.log("viet save vpt", rs);
        if (rs) {
          this.openAlert("Thực hiện thành công!");
          this.$f7router.back();
          EventLogin.$emit("reloadPageHomeLab");
        } else {
          this.openAlert("Có lỗi xảy ra!");
        }
      }
    },
    async runSave(item) {
      let check = this.validateVPT(item);
      if (!check) {
        return;
      }
      this.openPreloader("Đang thực hiện...", 3000);

      item.dSPhanTichVienChinhID = this.dSPhanTichVienChinhID;
      item.dSPhanTichVienPhuID = this.dSPhanTichVienPhuID;

      if (
        item.trangthai == TrangThai.LAB_VPT_TaoMoi_0822 &&
        this.dSPhanTichVienChinhID.length > 0
      ) {
        item.trangthai = TrangThai.LAB_VPT_DangXuLy_0823;
        this.SaveHistory(823, item.id, "Chỉ định Phân tích viên (mqlgd)", "");
        this.ThongBaoVuPhanTich(823, item);
        item.deadline = await this.ThietLapDeadline(823, item);
      } else if (
        item.trangthai == TrangThai.LAB_VPT_KhachHangChapNhanKQPT_0881
      ) {
        item.trangthai = TrangThai.LAB_VPT_DangXuLy_0823;
        this.SaveHistory(
          84,
          item.id,
          "Cập nhật công việc phân tích (mqlgd)",
          ""
        );
        item.deadline = await this.ThietLapDeadline(84, item);
      } else {
        this.SaveHistory(1001, item.id, "Điều chỉnh thông tin VPT (mqlgd)", "");
        item.deadline = await this.ThietLapDeadline(1001, item);
      }

      item.lastModified = new Date();
      item.lastUserId = this.userInfo.userId;

      // cập nhật số lượng mẫu:
      item.soLuongMau = item.cacChiTieuPhanTich.length;

      var rs;
      if (item.id > 0) {
        rs = await axios.patch(
          apiEndPoint + "api/VuPhanTiches" + "/" + item.id,
          item
        );
        console.log("viet save vpt", rs);
        if (rs) {
          this.openAlert("Thực hiện thành công!");
          this.$f7router.back();
          EventLogin.$emit("reloadPageHomeLab");
        } else {
          this.openAlert("Có lỗi xảy ra!");
        }
      }
    },

    getTenbyId(id) {
      let ten = "";
      this.dsNhanVien.forEach((e2) => {
        if (id == e2.id.toString()) {
          ten = e2.ten;
          return;
        }
      });
      return ten;
    },

    getTenKhachHangById(id) {
      let ten = "";
      this.dsKhachHangLab.forEach((e) => {
        if (id == e.id) {
          ten = e.hoTen;
          return;
        }
      });
      return ten;
    },
    async inPhieuKQPT(item) {
      let sttChiTieu = [];
      let setSTTChiTieu = function (vpt) {
        let i = 0;
        vpt.cacChiTieuPhanTich.forEach((e) => {
          if (e.isSelected) {
            i = i + 1;
            sttChiTieu.push(i);
          } else {
            sttChiTieu.push(0);
          }
        });
      };

      setSTTChiTieu(item);

      let xuLyTenNguoiDuyet = function (nguoiDuyet) {
        if (
          item.trangthai == "LAB_VPT_DaDuyetKQPT_086" ||
          item.trangthai == "LAB_VPT_DaGuiKhachHangKQPT_088" ||
          item.trangthai == "LAB_VPT_KhachHangChapNhanKQPT_0881" ||
          item.trangthai == "LAB_VPT_HoanThanh_093"
        ) {
          return nguoiDuyet;
        } else {
          return "";
        }
      };
      let xuLyTenMau = function (tenMau) {
        //nếu tên bộ chỉ tiêu là THAN NOI, THAN INDO.... thì tên mẫu hiện ra trên kết quả phân tích chỉ là THAN
        if (tenMau == "ETHANOL") return tenMau;
        if (tenMau.indexOf("THAN") > -1) {
          return "THAN";
        } else if (tenMau.indexOf("MOGAS") > -1) {
          return "MOGAS";
        } else {
          return tenMau;
        }
      };
      let xuLyDonVi = function (chitieu) {
        let kq;
        if (
          chitieu.donVi == "%mol & %vol" ||
          chitieu.donVi == "% mol & % vol"
        ) {
          kq = "%mol<br>&<br>%vol";
        } else {
          kq = chitieu.donVi
            ? chitieu.donVi.replace(/;/g, "<br>")
            : chitieu.donVi;
        }
        return kq;
      };
      let xuLyTenChiTieu = function (chitieu) {
        let kq;
        let tenCT = chitieu.tenChiTieu;
        if (!tenCT) return kq;
        if (chitieu.duocCongNhanVilas) {
          if (tenCT.indexOf(":") > -1) {
            // xử lý hiện công thức hóa học:
            if (!chitieu.tenTiengAnh) {
              if (tenCT.indexOf(":") > -1) {
                kq = tenCT
                  .replace(/:/g, ":<sup>(**)</sup><br>")
                  .replace(/;/g, "<br>");
              } else {
                kq = tenCT + "<sup>(**)</sup>";
              }
            } else {
              kq = tenCT.replace(/:/g, ":<br>").replace(/;/g, "<br>");
            }

            // if (tenCT.indexOf("Ethanol") == -1)
            //   kq = kq.replace(
            //     "Ethan",
            //     "<span>&emsp; C<sub>2</sub>H<sub>6</sub> (Ethan)</span>"
            //   );
            // kq = kq.replace(
            //   "Propan",
            //   "<span>&emsp; C<sub>3</sub>H<sub>8</sub> (Propan)</span>"
            // );
            // kq = kq.replace(
            //   "Iso Butan",
            //   "<span>&emsp;  i-C<sub>4</sub>H<sub>10</sub> (Iso Butan)</span>"
            // );
            // kq = kq.replace(
            //   "Normal Butan",
            //   "<span>&emsp;  n-C<sub>4</sub>H<sub>10</sub> (Normal Butan)</span>"
            // );
            // kq = kq.replace(
            //   "Neo Pentan",
            //   "<span>&emsp;  neo-C<sub>5</sub>H<sub>12</sub> (Neo Pentan)</span>"
            // );
            // kq = kq.replace(
            //   "Iso Pentan",
            //   "<span>&emsp;  i-C<sub>5</sub>H<sub>12</sub> (Iso Pentan)</span>"
            // );
            // kq = kq.replace(
            //   "Normal Pentan",
            //   "<span>&emsp; n-C<sub>5</sub>H<sub>12</sub> (Normal Pentan)</span>"
            // );
            // kq = kq.replace(
            //   "Propen",
            //   "<span>&emsp;  C<sub>3</sub>H<sub>6</sub> (Propen)</span>"
            // );
            // kq = kq.replace(
            //   "Buten",
            //   "<span>&emsp; C<sub>4</sub>H<sub>8</sub> (Buten)"
            // );
            // kq = kq.replace(
            //   "Pentene",
            //   "<span>&emsp; C<sub>5</sub>H<sub>10</sub> (Pentene)</span>"
            // );
            // kq = kq.replace(
            //   "1-3 Butadiene",
            //   "<span>&emsp; C<sub>4</sub>H<sub>6</sub> (1-3 Butadiene)</span>"
            // );
            // kq = kq.replace("Olefin", "<span>&emsp; Olefin</span>");
          } else {
            kq = tenCT.replace("Hydro Sulfua", "H<sub>2</sub>S");
            if (kq.indexOf(";") > -1) {
              kq = kq.replace(/;/g, "<br>");
            }
          }
        } else {
          kq = tenCT.replace(/:/g, ":<br>").replace(/;/g, "<br>");
        }
        return kq;
      };
      let xuLyTenChiTieuTiengAnh = function (chitieu) {
        let kq;
        let tenCT = chitieu.tenChiTieu;
        let tenTA = chitieu.tenTiengAnh;
        if (!tenTA) return "";
        if (chitieu.duocCongNhanVilas) {
          if (tenTA.indexOf(":") > -1) {
            kq = tenTA
              .replace(/:/g, ":<sup>(**)</sup><br>")
              .replace(/;/g, "<br>");
            // xử lý hiện công thức hóa học:
            if (tenTA.indexOf("Ethanol") == -1)
              kq = kq.replace(
                "Ethan",
                "<span>&emsp; C<sub>2</sub>H<sub>6</sub> (Ethan)</span>"
              );
            kq = kq.replace(
              "Propan",
              "<span>&emsp; C<sub>3</sub>H<sub>8</sub> (Propan)</span>"
            );
            kq = kq.replace(
              "Iso Butan",
              "<span>&emsp;  i-C<sub>4</sub>H<sub>10</sub> (Iso Butan)</span>"
            );
            kq = kq.replace(
              "Normal Butan",
              "<span>&emsp;  n-C<sub>4</sub>H<sub>10</sub> (Normal Butan)</span>"
            );
            kq = kq.replace(
              "Neo Pentan",
              "<span>&emsp;  neo-C<sub>5</sub>H<sub>12</sub> (Neo Pentan)</span>"
            );
            kq = kq.replace(
              "Iso Pentan",
              "<span>&emsp;  i-C<sub>5</sub>H<sub>12</sub> (Iso Pentan)</span>"
            );
            kq = kq.replace(
              "Normal Pentan",
              "<span>&emsp; n-C<sub>5</sub>H<sub>12</sub> (Normal Pentan)</span>"
            );
            kq = kq.replace(
              "Propen",
              "<span>&emsp;  C<sub>3</sub>H<sub>6</sub> (Propen)</span>"
            );
            kq = kq.replace(
              "Buten",
              "<span>&emsp; C<sub>4</sub>H<sub>8</sub> (Buten)"
            );
            kq = kq.replace(
              "Pentene",
              "<span>&emsp; C<sub>5</sub>H<sub>10</sub> (Pentene)</span>"
            );
            kq = kq.replace(
              "1-3 Butadiene",
              "<span>&emsp; C<sub>4</sub>H<sub>6</sub> (1-3 Butadiene)</span>"
            );
            kq = kq.replace("Olefin", "<span>&emsp; Olefin</span>");
          } else {
            kq = tenTA.replace("Hydro Sulfua", "H<sub>2</sub>S");
            kq = kq + "<sup>(**)</sup>";
            if (kq.indexOf(";") > -1) {
              kq = kq.replace(/;/g, "<br>");
            }
          }
          // Replace các trạng thái của THAN
          let arrTrangThai = [
            "(arb)",
            "(arb )",
            "(db)",
            "(db )",
            "(adb)",
            "(adb )",
            "(daf)",
            "(daf )",
            "(dmmf)",
            "(dmmf )",
          ];
          arrTrangThai.forEach((e) => {
            if (tenTA.indexOf(e) > -1) {
              kq = kq.replace(e, `<sup>${e}</sup>`);
            }
          });
        } else {
          kq = tenTA.replace(/:/g, ":<br>").replace(/;/g, "<br>");
        }
        return kq;
      };

      let xuLyKetQua = function (chitieu) {
        let kq;
        let str = chitieu.ketQua;
        if (chitieu.boChiTieuId == 21) {
          console.log("viet day la chi tieu LPG");
          if (chitieu.tenChiTieu.indexOf("Ethan") > -1) {
            console.log("viet day la chi tieu Thành phần/Competions");
            kq = `<table cellspacing=0 style='border:0px;width:100%;'><tr><td style='text-align:center;border:0px;border-right:1px solid'>%mol</td><td style='text-align:center;border:0px'>%vol</td></tr>`;
            let arrStr = str.split(";");
            console.log("viet arrStr", arrStr);
            arrStr.forEach((e) => {
              if (!e) return;
              let m = e.split("-");
              let s = `<tr><td style='text-align:center;border:0px;border-right:1px solid'>${m[0]}</td><td style='text-align:center;border:0px'>${m[1]}</td></tr>`;
              kq = kq.concat(s);
            });
            kq = kq.concat(`</table>`);
            return kq;
          } else if (chitieu.tenChiTieu.indexOf("Hàm lượng Olefin") > -1) {
            console.log("viet day la chi tieu Hàm lượng Olefin");
            kq = `<table cellspacing=0 style='border:0px;width:100%;'>`;
            let arrStr = str.split(";");
            console.log("viet arrStr", arrStr);
            arrStr.forEach((e) => {
              if (!e) return;
              let m = e.split("-");
              let s = `<tr><td style='text-align:center;border:0px;border-right:1px solid'>${m[0]}</td><td style='text-align:center;border:0px'>${m[1]}</td></tr>`;
              kq = kq.concat(s);
            });
            kq = kq.concat(`</table>`);
            return kq;
          } else if (
            chitieu.tenChiTieu.indexOf("Hàm lượng 1-3 Butadience") > -1
          ) {
            console.log("viet day la chi tieu Hàm lượng 1-3 Butadience");
            kq = `<table cellspacing=0 style='border:0px;width:100%;'>`;
            let arrStr = str.split(";");
            console.log("viet arrStr", arrStr);
            arrStr.forEach((e) => {
              if (!e) return;
              let m = e.split("-");
              let s = `<tr><td style='text-align:center;border:0px;border-right:1px solid'>${m[0]}</td><td style='text-align:center;border:0px'>${m[1]}</td></tr>`;
              kq = kq.concat(s);
            });
            kq = kq.concat(`</table>`);
            return kq;
          }
        }

        if (chitieu.tenChiTieu.indexOf(":") > -1) {
          if (str.indexOf(";") > -1) {
            kq = "<br>" + str.replace(/;/g, "<br>"); // cần xuống thêm một dòng ở đầu kq
          } else {
            kq = "<br>" + str;
          }
        } else {
          if (str.indexOf(";") > -1) {
            kq = str.replace(/;/g, "<br>");
          } else {
            kq = str;
          }
        }

        return kq;
      };
      jsreport.serverUrl = "https://demo-qlgd.eic.com.vn:5488";
      let so = item.soChungThu;
      let nguoiYeuCau = this.getTenKhachHangById(item.khachHangId);
      let soYeuCau = item.soChungThuVuGiamDinh;
      let tenTau = item.noiLayMau;
      let tenMau = item.tenHienThi ? item.tenHienThi : xuLyTenMau(item.tenMau);
      let kyHieuMau = `${soYeuCau} ( No. seal: ${item.soNiem})`;
      // let soLuongMau = "01";
      let soLuongMau = this.giamSoLuongMau
        ? item.soLuongMau - 1
        : item.soLuongMau;
      let ngayNhanMau = "";
      if (item.ngayNhanMau) {
        ngayNhanMau = dateFns.format(new Date(item.ngayNhanMau), "dd/MM/yyyy");
      }
      let thoiGianLuuMau = item.thoiGianLuuMau;

      let ketQuaPhanTich = [];

      // sắp xếp các chỉ tiêu phân tích in ra dựa vào TT đã cài đặt.
      // tham khảo hàm sort tại : https://developer.mozilla.org/vi/docs/Web/JavaScript/Reference/Global_Objects/Array/S%E1%BA%AFp_x%E1%BA%BFp
      item.cacChiTieuPhanTich.sort((a, b) => {
        return a.thuTuHienThiTrenPhieuKQPT - b.thuTuHienThiTrenPhieuKQPT;
      });

      item.cacChiTieuPhanTich.forEach((e, index) => {
        let temp = {
          stt: index + 1,
          soNiem: e.soNiem,
          tenMau: e.tenMau,
          cacChiTieuPhanTich: e.cacChiTieuPhanTich.map((i) => i.ketQua),
        };
        ketQuaPhanTich.push(temp);
      });

      let opinion1 = "Lưu ý: (**) : Các chỉ tiêu đã được công nhận Vilas.";
      let opinion2 = "";
      //let gdv = this.dsNhanVien.find(nv => nv.id === this.itemChungThu.dSGiamDinhVienChinhID[0]);

      let phanTichVien = this.getTenbyId(
        item.dSPhanTichVienChinhID[0].toString()
      );
      let nguoiDuyet = item.nguoiKyKQPT;
      let ngayDuyet;
      if (item.ngayKyKQPT) {
        let d = new Date(item.ngayKyKQPT);
        let month = d.getMonth() + 1;
        ngayDuyet = dateFns.format(new Date(item.ngayKyKQPT), "dd/MM/yyyy");
      } else {
        ngayDuyet = "";
      }

      let temp = item.cacChiTieuPhanTich[0].cacChiTieuPhanTich;
      let listChiTieu = temp.map((e) => e.tenChiTieu);
      let listPhuongPhapThu = temp.map((e) =>
        e.phuongPhapThu.replace("(", "<br>(")
      );
      let listDonVi = temp.map((e) => e.donVi);

        let getMaPhongBan = (id) => {
          let pb = this.dsPhongBanLab.find(e => e.id == id);
          return pb.ma;
        }
        let getDiaDiemKy = (ma) => {
          switch (ma) {
            case 'PTNHCM':
              return 'TP.HCM (Ho Chi Minh City)';
            case 'PTNQN':
              return 'Quảng Ninh (Quang Ninh Province)';
            case 'PTNQNG':
              return 'Quảng Ngãi (Quang Ngai Province)';
            case 'PTNTV':
              return 'Trà Vinh (Tra Vinh Province)';
            case 'PTNHP':
              return 'TP Hải Phòng (Hai Phong City)';
            case 'PTNHD':
              return 'Hải Dương (Hai Duong Province)';
            default:
              return '';
          }
        }

        let maPB = getMaPhongBan(item.phongBanId);
        let diaDiemKy = getDiaDiemKy(maPB);

          let getIDCategoryPTN = (ma) => {
            switch (ma) {
              case 'PTNHCM':
                return 170;
              case 'PTNQN':
                return 169;
              case 'PTNQNG':
                return 172;
              case 'PTNTV':
                return 168;
              case 'PTNHP':
                return 171;
              case 'PTNHD':
                return 232;
              default:
                return -1;
            }
          }
          let idCategoryPTN = getIDCategoryPTN(maPB);
          let footerAddress = await this.getAddress_PTN(idCategoryPTN);
          let footerAddressVI ="";
          let footerAddressEN ="";
          if(footerAddress[0].danhMuc.indexOf("-vi")>-1){
            footerAddressVI = footerAddress[0].tenDanhMuc; 
            footerAddressEN = footerAddress[1].tenDanhMuc;
          }else{
            footerAddressVI = footerAddress[1].tenDanhMuc; 
            footerAddressEN = footerAddress[0].tenDanhMuc;
          }

      var request = {
        template: { shortid: "BJ4DLHijU" },
        data: {
          so: so,
          ngay: "04/09/2019",
          nguoiYeuCau: nguoiYeuCau,
          soYeuCau: soYeuCau,
          tenMau: tenMau,
          kyHieuMau: kyHieuMau,
          soLuongMau: soLuongMau,
          ngayNhanMau: ngayNhanMau,
          thoiGianLuuMau: thoiGianLuuMau,
          diaDiemKy: diaDiemKy,

          listChiTieu: listChiTieu,
          listPhuongPhapThu: listPhuongPhapThu,
          listDonVi: listDonVi,
          tenTau: tenTau,

          ketQuaPhanTich: ketQuaPhanTich,
          Opinion1: opinion1,
          Opinion2: opinion2,
          phanTichVien: phanTichVien,
          nguoiDuyet: xuLyTenNguoiDuyet(nguoiDuyet),
          ngayDuyet: ngayDuyet,
          footer_address_vi: footerAddressVI,
          footer_address_en: footerAddressEN
        },
      };
      let ten = "KetQuaThuNghiem_" + item.soChungThu + ".pdf";
      jsreport.download(ten, request);
    },
  },
  async created() {
    this.dsPhongBan = JSON.parse(localStorage.getItem("eic_dsPhongBan"));
    this.dsPhongBanLab = this.dsPhongBan.filter((e) => e.boPhan == "LAB");
    this.userInfo = JSON.parse(localStorage.getItem("eic_userInfo"));
    this.dsKhachHangLab = JSON.parse(
      localStorage.getItem("eic_dsKhachHangLab")
    );
    this.dsKhachHang = JSON.parse(localStorage.getItem("eic_dsKhachHang"));
    this.dsNhanVien = JSON.parse(localStorage.getItem("eic_dsNhanVien"));
    this.dsPhongBan = JSON.parse(localStorage.getItem("eic_dsPhongBan"));
    await this.getChiTietVPT();
    this.chiTietVPT.cacChiTieuPhanTich.forEach((sample) => {
      if (sample.type == "mau-binh-quan") {
        this.giamSoLuongMau = true;
        return;
      }
    });
    this.dsPhanTichVien = this.getAllPTV(this.chiTietVPT);
  },
};
</script>
