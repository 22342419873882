
export const Roles = {
  User:1,
  Admin:2,
  TruongPhong: 4,
  TruongPhongHD: 16,
  TruongPhongNotHD:  17,
  GiamDinhVien:5,
  CS: 6,
  CSCN: 13,
  GiamDoc: 7,
  PhoGiamDoc:  15,
  PhoGiamDocHD: 18,
  GiamDocCN:  14,
  NhanVienTaoYeuCau:  8,
  KeToan: 9,
  KeToanCN: 10,
  KinhDoanh: 11,
  KinhDoanhCN:  12,


  //Phòng Lab
  GiamDoc_Lab:  20,
  PhoGiamDoc_Lab:  24,
  CS_Lab:  19,
  TruongPhong_Lab:  21,
  KeToan_Lab: 23,
  PhanTichVien_Lab:  22,
}

export const PhongBanKinhDoanh = {
   ListPhongBan :[4]
}

export const ChiNhanh = {
   RolesCN:  [10, 12, 14],

   PhongBanCN:  [6, 7, 8],

   CNMienBac:  6,
   CNMienTrung: 7,
   CNMienNam: 8,

   ListMienBac:  [12, 13],
   ListMienTrung:  [14, 15, 16, 19],
   ListMienNam:  [17, 18],
}
