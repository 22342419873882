<template>
  <f7-page :page-content="false" @page:init="onPageInit" class="my-bg-color">
    <f7-navbar class>
      <f7-nav-left back-link="Back"></f7-nav-left>
      <f7-nav-title>Chi tiết YCPT số {{chiTietYCPT.id}}</f7-nav-title>
    </f7-navbar>

    <f7-fab position="right-bottom" slot="fixed">
      <i class="fa fa-bars" aria-hidden="true"></i>
      <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
      <f7-fab-buttons position="top">
        <f7-fab-button
          :fab-close="true"
          label="Chấp nhận"
          color="green"
          v-if="enableChapNhanYCPT(chiTietYCPT)"
          @click="runCreateVuPhanTich(chiTietYCPT)"
        >
          <i class="fas fa-check-square" aria-hidden="true"></i>
        </f7-fab-button>
        <f7-fab-button
          :fab-close="true"
          label="Lưu điều chỉnh"
          v-if="enableLuuDieuChinhYCPT(chiTietYCPT)"
          @click="runSave(chiTietYCPT)"
          color="orange"
        >
          <i class="fas fa-save" aria-hidden="true"></i>
        </f7-fab-button>
        <f7-fab-button
          :fab-close="true"
          label="Gửi lại PB"
          v-if="enableGuiLaiYCPT(chiTietYCPT)"
          @click="runGuiLaiYCPT(chiTietYCPT)"
          color="orange"
        >
          <i class="fas fa-save" aria-hidden="true"></i>
        </f7-fab-button>
        <f7-fab-button
          :fab-close="true"
          label="Từ chối"
          color="red"
          v-if="enableTuChoiYCPT(chiTietYCPT)"
          @click="openLyDoTuChoiDialog(chiTietYCPT)"
        >
          <i class="fa fa-ban" aria-hidden="true"></i>
        </f7-fab-button>
        <f7-fab-button
          :fab-close="true"
          label="Xóa YCPT"
          color="pink"
          v-if="enableXoaYCPT(chiTietYCPT)"
          @click="openXoaYCPTDialog(chiTietYCPT)"
        >
          <i class="fas fa-trash" aria-hidden="true"></i>
        </f7-fab-button>
        <f7-fab-button :fab-close="true" label="Trang chủ" color="lightblue" @click="veTrangChu()">
          <i class="fa fa-home" aria-hidden="true"></i>
        </f7-fab-button>
      </f7-fab-buttons>
    </f7-fab>

  <div class="page-content">
    <f7-block-title>Thông tin phân tích :</f7-block-title>
      <div class="card">
<f7-list no-hairlines-md>
          <f7-list-input
            disabled
            label="Đơn vị yêu cầu :"
            type="text"
            placeholder
            :value="getTenKhachHang(chiTietYCPT.khachHangId)"
          ></f7-list-input>
          <f7-list-input
            disabled
            label="Tên mẫu (*) :"
            type="text"
            placeholder
            :value="chiTietYCPT.tenMau"
            @input="chiTietYCPT.tenMau = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            label="Số niêm :"
            type="text"
            placeholder
            :value="chiTietYCPT.soNiem"
            @input="chiTietYCPT.soNiem = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            disabled
            label="Số chứng thư Vụ Giám Định (*) :"
            placeholder
            type="text"
            :value="chiTietYCPT.soChungThuVuGiamDinh"
            @input="chiTietYCPT.soChungThuVuGiamDinh = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            label="Nơi lấy mẫu :"
            type="text"
            placeholder
            :value="chiTietYCPT.noiLayMau"
            @input="chiTietYCPT.noiLayMau = $event.target.value"
          ></f7-list-input>
          <!-- <f7-list-input disabled label="Trung tâm phân tích :" type="text" placeholder="" :value="getTenPhongBan(chiTietYCPT.phongBanId)">
          </f7-list-input>-->
          <f7-list-item
            class="my-title-font-size"
            title="Trung tâm (*):"
            smart-select
            :smart-select-params="{openIn: 'sheet', closeOnSelect: true}"
          >
            <div
              class="my-label-font-size"
              v-if="!clickChangeTrungTam"
            >{{getTenPhongBan(chiTietYCPT.phongBanId)}}</div>
            <select
              name="phongBanLab"
              v-model="chiTietYCPT.phongBanId"
              @change="() => clickChangeTrungTam=true"
            >
              <option
                :key="index"
                v-for="(item, index) in dsPhongBanLab"
                :value="item.id"
              >{{item.ten}}</option>
            </select>
          </f7-list-item>
          <!-- <f7-list-input disabled label="Ngày gởi yêu cầu:" type="text" placeholder="" :value="formatDate(chiTietYCPT.ngayYeuCau)">
          </f7-list-input>-->
      
        </f7-list>
      </div>
         <f7-block-title>Bộ chỉ tiêu :</f7-block-title>
      <div class="card">
          <div class="data-table data-table-init">
            <table>
              <thead>
                <tr>
                  <th class="label-cell">PT</th>
                  <th class="label-cell">Chỉ tiêu</th>
                  <th class="label-cell">Phương Pháp</th>
                </tr>
              </thead>
              <tbody>
                <tr :key="index" v-for="(item, index) in chiTietYCPT.cacChiTieuPhanTich">
                  <td class="checkbox-cell">
                    <label class="checkbox">
                      <input
                        type="checkbox"
                        :checked="item.isSelected"
                        @change="()=> item.isSelected = !item.isSelected"
                      />
                      <i class="icon-checkbox"></i>
                    </label>
                  </td>
                  <td class="label-cell">{{item.tenChiTieu}}</td>
                  <td class="label-cell">{{item.phuongPhapThu}}</td>
                </tr>
              </tbody>
            </table>
          </div>

      </div>
  </div>
        
   
  </f7-page>
</template>

<script>
import { Roles } from "./../../global.js";
import TrangThai from "./../../common.js";
var dateFns = require("date-fns");
import NavBar from "./../components/NavBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
import { EventLogin } from "../event-login.js";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      chiTietYCPT: {},
      clickChangeTrungTam: false,
      dsPhongBanLab: [],
      dsPhongBan: [],
      dsNghiepVu: [],
    };
  },
  updated() {},
  mounted() {},
  methods: {
    async GetPeriodTime(actionNum, phongbanId) {
      let request = {
        params: {
          actionNum: actionNum,
          phongbanId: phongbanId,
        },
      };
      let rs = await axios.get(
        apiEndPoint + "api/Actions/getPeriodTime/",
        request
      );
      console.log("viet period time rs", rs.data);
      return rs.data;
    },
    async ThietLapDeadline(actionCode, item) {
      // thiết lập deadline mới cho trạng thái mới của VPT
      let today = new Date();
      let thoigianbatdau = new Date();
      thoigianbatdau.setHours(8, 0, 0, 0);
      let thoigianketthuc = new Date();
      thoigianketthuc.setHours(17, 0, 0, 0);
      let thoigianbatdau_tomorrow = new Date(
        today.getTime() + 24 * 60 * 60 * 1000
      );
      thoigianbatdau_tomorrow.setHours(8, 0, 0, 0);
      let res = await this.GetPeriodTime(actionCode, item.phongBanId);
      let periodTime = res.periodTime;
      if (periodTime == 0 || periodTime == -1) periodTime = 8 * 60 * 60; // chưa thiết lập defaultperiodTime thì mặc định là 8 tiếng.

      let deadline;

      // xử lý không cho deadline nằm ngoài giờ hành chính: 8h->17h
      if (
        thoigianbatdau.getTime() <= today.getTime() &&
        thoigianketthuc.getTime() >= today.getTime()
      ) {
        deadline = new Date(today.getTime() + 1000 * periodTime); // periodTime đổi sang miliseconds
        if (deadline > thoigianketthuc)
          deadline = new Date(
            deadline.getTime() +
              thoigianbatdau.getTime() -
              thoigianketthuc.getTime() +
              24 * 60 * 60 * 1000
          );
      } else if (thoigianbatdau.getTime() > today.getTime()) {
        deadline = new Date(thoigianbatdau.getTime() + 1000 * periodTime);
      } else if (thoigianketthuc.getTime() < today.getTime()) {
        deadline = new Date(
          thoigianbatdau_tomorrow.getTime() + 1000 * periodTime
        );
      }

      return deadline;
    },
    async ThongBaoYeuCauPhanTich(actionCode, item) {
      let dsNhanVien = JSON.parse(localStorage.getItem("eic_dsNhanVien"));

      let toListNhanVien = [];
      let nhanVien = dsNhanVien.find((e) => e.id == item.lastUserId);
      let fromNhanVien = nhanVien;
      let fromEmail = fromNhanVien.email;

      if (actionCode == 81 || actionCode == 824) {
        // YCPT đã gử tới phòng lab và YCPT bị xóa
        toListNhanVien = dsNhanVien.filter((e) => {
          let arrRoleId = [
            Roles.TruongPhong_Lab,
            Roles.GiamDoc_Lab,
            Roles.CS_Lab,
          ];
          if (e.phongBanQuanLyId) {
            return (
              arrRoleId.includes(e.roleId) &&
              (e.phongBanId == item.phongBanId ||
                e.phongBanQuanLyId.includes(item.phongBanId))
            );
          } else {
            return (
              arrRoleId.includes(e.roleId) && e.phongBanId == item.phongBanId
            );
          }
        });
      }

      let dsemail = toListNhanVien.map(function (element) {
        return element.email;
      });
      let toListEmails = dsemail.join(", ");
      console.log("toListEmails", toListEmails);

      let tenDoiTuong = "Yêu cầu phân tích Số " + item.id;
      let getHistoryMessage = function (num) {
        if (num == 81) {
          return "Đã gởi tới Phòng Lab";
        } else if (num == 824) {
          return "Xóa Yêu Cầu Phân Tích";
        }
      };
      let hanhDong = getHistoryMessage(actionCode);
      let subject = tenDoiTuong + " | " + hanhDong + " bởi " + fromNhanVien.ten;
      let tenKhachHang = this.getTenKhachHang(item.khachHangId);

      let text = `Tên khách hàng: ${tenKhachHang}. \r\n <br/>
                        Số chứng thư Vụ Giám Định: ${item.soChungThuVuGiamDinh}. \r\n <br/>
                        Tên mẫu: ${item.tenMau}. \r\n <br/>
                        Số niêm: ${item.soNiem}. \r\n <br/>`;
      return this.GuiEmail(fromEmail, toListEmails, subject, text, "THONGBAO");
    },
    async GuiEmail(fromEmail, toListEmails, subject, bodyText, LoaiEmail) {
      console.log("viet GuiEmail  test", toListEmails);
      let item = {
        to: toListEmails,
        from: fromEmail,
        subject: subject,
        text: bodyText,
      };
      let rec = await axios.post(
        apiEndPoint + "api/ActionHistories/sendEmail",
        item
      );
      return rec.data;
    },
    onPageInit() {},
    veTrangChu() {
      if (this.userInfo.roleId == Roles.PhanTichVien_Lab) {
        this.$f7router.back("/home-ptv/");
      } else {
        this.$f7router.back("/home-lab/");
      }
    },
    async getChiTietYCPT() {
      let url = apiEndPoint + "api/YeuCauPhanTiches";
      let filter = {
        where: {
          id: this.$f7route.params.id,
        },
      };
      try {
        let res = await axios.get(url + "/findOne", {
          params: {
            filter: filter,
          },
        });
        this.chiTietYCPT = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    openAlert(titlename, cb) {
      const app = this.$f7;
      app.dialog.alert("" + titlename, "Thông báo", cb);
    },
    openPreloader(titlename, time) {
      const app = this.$f7;
      app.dialog.preloader(titlename);
      setTimeout(function () {
        app.dialog.close();
      }, time);
    },

    getTenKhachHang(id) {
      let found = this.dsKhachHangLab.find((e) => e.id == id);
      if (!found) return "";
      return found.hoTen;
    },
    getTenPhongBan(id) {
      let found = this.dsPhongBan.find((e) => e.id == id);
      if (!found) return "";
      return found.ten;
    },
    getTenNhanVien(id) {
      let found = this.dsNhanVien.find((e) => e.id == id);
      if (!found) return "";
      return found.ten;
    },
    openFile(item) {
      window.open(item, "_system");
      return false;
    },
    formatDate(date) {
      var result = dateFns.format(new Date(date), "dd/MM/yyyy HH:mm");
      return result;
    },
    enableXoaYCPT(item) {
      let check = false;
      if (item.trangthai == TrangThai.LAB_YCPT_BiTraVe_0821) {
        check = true;
      }
      return check;
    },
    enableTuChoiYCPT(item) {
      let check = false;
      if (item.trangthai == TrangThai.LAB_YCPT_ChoChapNhan_081) {
        check = true;
      }
      return check;
    },
    enableGuiLaiYCPT(item) {
      let check = false;
      if (item.trangthai == TrangThai.LAB_YCPT_BiTraVe_0821) {
        check = true;
      }
      return check;
    },
    enableLuuDieuChinhYCPT(item) {
      let check = false;
      if (item.trangthai == TrangThai.LAB_YCPT_ChoChapNhan_081) {
        check = true;
      }
      return check;
    },
    enableChapNhanYCPT(item) {
      let check = false;
      if (item.trangthai == TrangThai.LAB_YCPT_ChoChapNhan_081) {
        check = true;
      }
      return check;
    },
    async SaveHistory(actionId, objectId, message, note) {
      let userInfo = JSON.parse(localStorage.getItem("eic_userInfo"));
      let history = {
        actionId: actionId,
        appUserId: userInfo.userId,
        objectId: objectId,
        objectType: "YeuCauPhanTich",
        date: new Date(),
        message: message,
        note: note,
      };
      try {
        let res = await axios.post(
          apiEndPoint + "api/ActionHistories",
          history
        );
        console.log("viet res", res);
      } catch (error) {
        alert(error);
      }
    },
    async SaveHistory_2(actionId, objectId, message, note) {
      let userInfo = JSON.parse(localStorage.getItem("eic_userInfo"));
      let history = {
        actionId: actionId,
        appUserId: userInfo.userId,
        objectId: objectId,
        objectType: "VuPhanTich",
        date: new Date(),
        message: message,
        note: note,
      };
      try {
        let res = await axios.post(
          apiEndPoint + "api/ActionHistories",
          history
        );
        console.log("viet res", res);
      } catch (error) {
        alert(error);
      }
    },
    validateYCPT(ycpt) {
      let check = false;
      if (!ycpt) {
        this.openAlert("Yêu cầu phân tích không tồn tại");
        return check;
      }

      if (!ycpt.khachHangId) {
        this.openAlert("Bạn chưa chọn Khách hàng");
        return check;
      }
      if (!ycpt.soChungThuVuGiamDinh) {
        this.openAlert("Bạn chưa nhập Số chứng thư vụ giám định");
        return check;
      }
      if (!ycpt.phongBanId) {
        this.openAlert("Bạn chưa chọn Trung tâm phân tích");
        return check;
      }

      check = true;
      return check;
    },
    openLyDoTuChoiDialog(item) {
      const app = this.$f7;
      var cb = async (lyDo) => {
        if (!lyDo) {
          this.openAlert("Bạn chưa nhập lý do");
          this.openLyDoTuChoiDialog(item);
          return;
        }
        item.lyDoTraVe = lyDo;
        await this.runTuChoi(item);
      };
      app.dialog.prompt("Lý do từ chối: ", "", cb);
    },
    openXoaYCPTDialog(item) {
      const app = this.$f7;
      var cb = async () => {
        await this.runXoaYCPT(item);
      };
      app.dialog.confirm("Bạn có chắc chắn xóa?", "Thông báo", cb);
    },
    async getDSNghiepVu(idPhongBan) {
      let filter = {
        where: {
          phongBanId: idPhongBan,
        },
      };
      let url = apiEndPoint + "api/NghiepVus";
      try {
        let res = await axios.get(url, {
          params: {
            filter: filter,
          },
        });
        console.log("viet res NghiepVus", res);
        this.dsNghiepVu = res.data;
      } catch (error) {
        alert(error);
      }
    },
    async FindOneByMa(ma) {
      let filter = {
        where: {
          ma: ma,
        },
      };
      let rec;
      let url = apiEndPoint + "api/ThongTinTaoMaChungThus/findOne";
      try {
        rec = await axios.get(url, {
          params: {
            filter: filter,
          },
        });
      } catch (error) {
        return null;
      }
      return rec.data;
    },
    async Post_ThongTinTaoMaChungThu(item) {
      let rec = await axios.post(
        apiEndPoint + "api/ThongTinTaoMaChungThus",
        item
      );
      return rec.data;
    },
    async Put_ThongTinTaoMaChungThu(item) {
      let rec = await axios.put(
        apiEndPoint + "api/ThongTinTaoMaChungThus" + "/" + item.id,
        item
      );
      return rec.data;
    },
    FormatNumberLength(num, length) {
      //FormatNumberLength(10000, 5) outputs '10000'
      var r = "" + num;
      while (r.length < length) {
        r = "0" + r;
      }
      return r;
    },
    async IncreaseByMa(ma) {
      let recEntity = await this.FindOneByMa(ma);
      if (recEntity == null)
        return await this.Post_ThongTinTaoMaChungThu({
          ma: ma,
          giaTri: 1,
        });
      ++recEntity.giaTri;
      return await this.Put_ThongTinTaoMaChungThu(recEntity);
    },
    async GenerateCertificateNo(soChungThuTheoLinhVuc) {
      let YY = String(new Date().getFullYear() % 1000);
      soChungThuTheoLinhVuc = YY + soChungThuTheoLinhVuc; //số lĩnh vực kết hợp theo năm, sang năm mới thì tạo ra SoLinhVuc mới với giá trị 01
      let rec = await this.IncreaseByMa(soChungThuTheoLinhVuc); //lấy số thứ tự theo mã đang có
      let soChungThu = "";
      if (soChungThuTheoLinhVuc.length == 5) {
        soChungThu =
          soChungThuTheoLinhVuc + this.FormatNumberLength(rec.giaTri, 3);
      } else {
        soChungThu =
          soChungThuTheoLinhVuc + this.FormatNumberLength(rec.giaTri, 4);
      }

      return soChungThu;
    },
    async CreateVuPhanTich(soChungThuTheoLinhVuc, item) {
      let vuPhanTich = item;
      vuPhanTich.ycphantichId = item.id;
      delete vuPhanTich.id;
      this.userItem = JSON.parse(localStorage.getItem("eic_userInfo"));
      vuPhanTich.trangthai = TrangThai.LAB_VPT_TaoMoi_0822;
      vuPhanTich.lastModified = new Date();
      vuPhanTich.lastUserId = this.userItem.userId;
      vuPhanTich.ngayNhanMau = new Date();
      vuPhanTich.thoiGianLuuMau = "1 THÁNG";
      vuPhanTich.tenHienThi = vuPhanTich.tenMau;

      vuPhanTich.soChungThuTheoLinhVuc = soChungThuTheoLinhVuc;
      vuPhanTich.flagTaoChungThu = true;
      vuPhanTich.soChungThu = await this.GenerateCertificateNo(
        soChungThuTheoLinhVuc
      );
      vuPhanTich.deadline = await this.ThietLapDeadline(822, item);

      let rs = await axios.post(apiEndPoint + "api/VuPhanTiches", vuPhanTich);
      console.log("viet vpt ", rs);
      await this.SaveHistory_2(822, rs.data.id, "Tạo Vụ Phân Tích (mqlgd)", "");

      if (rs) {
        this.openAlert("Thực hiện thành công!");
      }
    },
    async runCreateVuPhanTich(item) {
      this.openPreloader("Đang thực hiện...", 3000);
      item.trangthai = TrangThai.LAB_YCPT_DaChapNhan_082;
      await this.SaveHistory(82, item.id, "Chấp nhận YCPT (mqlgd)", "");

      let res = await axios.patch(
        apiEndPoint + "api/YeuCauPhanTiches" + "/" + item.id,
        item
      );
      console.log("viet ycpt res", res);
      // Tạo Vụ Phân Tích, ko cần chọn lĩnh vực.
      await this.getDSNghiepVu(item.phongBanId);
      console.log("viet dsNghiepVu", this.dsNghiepVu);
      if (!item.dsSoChungThuTheoLinhVuc) item.dsSoChungThuTheoLinhVuc = [];
      item.dsSoChungThuTheoLinhVuc.push(this.dsNghiepVu[0].ma);
      item.dsSoChungThuTheoLinhVuc.forEach(async (soChungThuTheoLinhVuc) => {
        await this.CreateVuPhanTich(soChungThuTheoLinhVuc, item);
        EventLogin.$emit("reloadPageHomeLab");
        this.$f7router.back("/home-lab/");
      });
    },
    async runXoaYCPT(item) {
      this.openPreloader("Đang thực hiện...", 3000);

      item.lastModified = new Date();
      item.lastUserId = this.userInfo.userId;
      item.trangthai = TrangThai.LAB_YCPT_BiXoa_0824;
      var rs;
      if (item.id > 0) {
        rs = await axios.patch(
          apiEndPoint + "api/YeuCauPhanTiches" + "/" + item.id,
          item
        );
        this.SaveHistory(824, item.id, "Xóa YCPT (mqlgd)", "");
        this.ThongBaoYeuCauPhanTich(824, item);

        console.log("viet xoa ycpt", rs);
        if (rs) {
          this.openAlert("Thực hiện thành công!");
          this.$f7router.back();
          EventLogin.$emit("reloadPageHomeLab");
        } else {
          this.openAlert("Có lỗi xảy ra!");
        }
      }
    },
    async runTuChoi(item) {
      let check = this.validateYCPT(item);
      if (!check) {
        alert("Chưa nhập đủ thông tin!");
        return;
      }
      this.openPreloader("Đang thực hiện...", 3000);

      item.lastModified = new Date();
      item.lastUserId = this.userInfo.userId;
      item.trangthai = TrangThai.LAB_YCPT_BiTraVe_0821;
      var rs;
      if (item.id > 0) {
        rs = await axios.patch(
          apiEndPoint + "api/YeuCauPhanTiches" + "/" + item.id,
          item
        );
        this.SaveHistory(831, item.id, "Từ chối YCPT (mqlgd)", "");
        item.deadline = await this.ThietLapDeadline(831, item);
        console.log("viet save ycpt", rs);
        if (rs) {
          this.openAlert("Thực hiện thành công!");
          this.$f7router.back();
          EventLogin.$emit("reloadPageHomeLab");
        } else {
          this.openAlert("Có lỗi xảy ra!");
        }
      }
    },
    async runGuiLaiYCPT(item) {
      let check = this.validateYCPT(item);
      if (!check) {
        return;
      }
      this.openPreloader("Đang thực hiện...", 3000);

      item.lastModified = new Date();
      item.lastUserId = this.userInfo.userId;
      item.lyDoTraVe = null;
      item.trangthai = TrangThai.LAB_YCPT_ChoChapNhan_081;
      var rs;
      if (item.id > 0) {
        rs = await axios.patch(
          apiEndPoint + "api/YeuCauPhanTiches" + "/" + item.id,
          item
        );
        this.SaveHistory(81, item.id, "Gửi lại YCPT đến PB (mqlgd)", "");
        this.ThongBaoYeuCauPhanTich(81, item);
        item.deadline = await this.ThietLapDeadline(81, item);
        console.log("viet save ycpt", rs);
        if (rs) {
          this.openAlert("Thực hiện thành công!");
          this.$f7router.back();
          EventLogin.$emit("reloadPageHomeLab");
        } else {
          this.openAlert("Có lỗi xảy ra!");
        }
      }
    },
    async runSave(item) {
      let check = this.validateYCPT(item);
      if (!check) {
        return;
      }
      this.openPreloader("Đang thực hiện...", 3000);

      item.lastModified = new Date();
      item.lastUserId = this.userInfo.userId;

      var rs;
      if (item.id > 0) {
        rs = await axios.patch(
          apiEndPoint + "api/YeuCauPhanTiches" + "/" + item.id,
          item
        );
        if (item.trangthai == TrangThai.LAB_YCPT_ChoChapNhan_081) {
          this.SaveHistory(831, item.id, "Điều chỉnh YCPT (mqlgd)", "");
          item.deadline = await this.ThietLapDeadline(831, item);
        }
        console.log("viet save ycpt", rs);
        if (rs) {
          this.openAlert("Thực hiện thành công!");
          this.$f7router.back();
          EventLogin.$emit("reloadPageHomeLab");
        } else {
          this.openAlert("Có lỗi xảy ra!");
        }
      }
    },
  },
  async created() {
    this.dsPhongBan = JSON.parse(localStorage.getItem("eic_dsPhongBan"));
    this.dsPhongBanLab = this.dsPhongBan.filter((e) => e.boPhan == "LAB");
    this.userInfo = JSON.parse(localStorage.getItem("eic_userInfo"));
    this.dsKhachHangLab = JSON.parse(
      localStorage.getItem("eic_dsKhachHangLab")
    );
    this.dsNhanVien = JSON.parse(localStorage.getItem("eic_dsNhanVien"));
    this.dsPhongBan = JSON.parse(localStorage.getItem("eic_dsPhongBan"));
    await this.getChiTietYCPT();
  },
};
</script>
