<template>
<f7-page :page-content="false" @page:init="onPageInit" class="my-bg-color">
    <f7-navbar class="">
        <f7-nav-left back-link="Back"></f7-nav-left>
        <f7-nav-title>Tạo Yêu Cầu Phân Tích</f7-nav-title>

    </f7-navbar>
    <f7-fab position="right-bottom" slot="fixed" text="Tạo YC"  @click="runCreateYCPT()">
        <f7-icon ios="f7:checkmark" aurora="f7:checkmark" md="material:checkmark"></f7-icon>
  </f7-fab>
  <div class="page-content">
    <f7-block-title>Thông tin phân tích :</f7-block-title>
    <div class="card">
            <f7-list no-hairlines-md >
                <f7-list-input disabled label="Đơn vị yêu cầu (*):" type="text" placeholder="" :value="getTenKhachHang(chiTietYCPT.khachHangId)">
                </f7-list-input>
                <f7-list-input disabled label="Số chứng thư Vụ Giám Định (*):" placeholder="" type="text" :value="chiTietYCPT.soChungThuVuGiamDinh" @input="chiTietYCPT.soChungThuVuGiamDinh = $event.target.value">
                </f7-list-input>
                <f7-list-input  label="Nơi lấy mẫu :" type="text" placeholder="" :value="chiTietYCPT.noiLayMau" @input="chiTietYCPT.noiLayMau = $event.target.value">
                </f7-list-input>
                 <f7-list-item class="my-title-font-size" title="Trung tâm (*):" smart-select :smart-select-params="{openIn: 'sheet', closeOnSelect: true}">
                        <select name="phongBanLab" v-model="chiTietYCPT.phongBanId">
                            <option :key="index" v-for="(item, index) in dsPhongBanLab" :value="item.id">{{item.ten}}</option>
                        </select>
                   </f7-list-item>
                <f7-list-input  label="Ngày muốn nhận KQPT:" type="date" placeholder="" :value="ngayMuonNhanKQPT" @input="ngayMuonNhanKQPT = $event.target.value">
                </f7-list-input>
                
              


            </f7-list>
    </div>
    <f7-block-title>Danh sách mẫu : <span style="color: green">{{itemsThongTinPhanTich.length}}</span></f7-block-title>

    <div class="card">
     <f7-button medium raised fill style="margin-top: 15px; height: 40px; line-height: 40px;">
                            <f7-link class="fs-roboto-bold text-color-white" style="width: 100%;" @click="runThemMau()" link="">Thêm mẫu</f7-link>
                  </f7-button>
    </div>
    <div class="card">
          <f7-list>
                      <f7-list-item swipeout :key="index" v-for="(item, index) in itemsThongTinPhanTich" :title="item.soNiem?item.tenMau + '/'+ item.soNiem:item.tenMau">
                         <f7-swipeout-actions right>
                           <!-- <f7-swipeout-button color="orange">Sửa</f7-swipeout-button> -->
                            <f7-swipeout-button @click="removeMau(index)" delete>Xóa</f7-swipeout-button>
                          </f7-swipeout-actions>
                      </f7-list-item>

                  </f7-list>
    </div>
  </div>
  

</f7-page>
</template>

<script>
import TrangThai from "./../../common.js";
var dateFns = require('date-fns');
import NavBar from "./../components/NavBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
import {
    EventLogin
} from "../event-login.js";
export default {
    components: {
        NavBar,
    },
    data() {
        return {
          isLoading: true,
          dsPhongBanLab: [],
          ngayMuonNhanKQPT:"",
          itemThongTinPhanTich:{},
          itemsThongTinPhanTich:[],
          dsBoChiTieu: [],
          enableUpdateButton: false,
          popupOpened:false,
          dsMau:[],
          chiTietYCPT: {},
          chiTietVGD:{},
          clickChangeTenCongViec: false,
        };
    },
    updated() {},
    mounted() {
      EventLogin.$on("themMauYCPT", itemThongTinPhanTich => {
                  this.itemsThongTinPhanTich.push(itemThongTinPhanTich);
                  console.log('viet eventlogin this.itemsThongTinPhanTich', this.itemsThongTinPhanTich);

      });
    },
    methods: {
        onPageInit() {

        },
        openAlert(titlename, cb) {
            const app = this.$f7;
            app.dialog.alert('' + titlename, 'Thông báo', cb);
        },
        openPreloader(titlename, time) {
            const app = this.$f7;
            app.dialog.preloader(titlename);
            setTimeout(function () {
              app.dialog.close();
            }, time);
        },
        removeMau(index){
          this.itemsThongTinPhanTich.splice(index,1);
        },
         validateYCPT(ycpt){
          let check = false;
          if(!ycpt){
            this.openAlert("Yêu cầu phân tích không tồn tại")
            return check;
          }

          if(!ycpt.khachHangId){
            this.openAlert("Bạn chưa chọn Khách hàng");
            return check;
          }
          if(!ycpt.soChungThuVuGiamDinh){
            this.openAlert("Bạn chưa nhập Số chứng thư vụ giám định");
            return check;
          }
          if(!ycpt.phongBanId){
            this.openAlert("Bạn chưa chọn Trung tâm phân tích");
            return check;
          }
          if(!this.itemsThongTinPhanTich.length){
            this.openAlert("Bạn chưa thêm Mẫu phân tích");
            return check;
          }
          check = true;
          return check;
        },
        async runCreateYCPT(){
           let check = this.validateYCPT(this.chiTietYCPT);
            if(!check){
              return ;
            }
            this.openPreloader("Đang thực hiện...",2000);

            let count = 0;

                for (const item of this.itemsThongTinPhanTich) {
                  try {
                    let ycpt = {};
                    ycpt.ngayYeuCau = new Date();
                    ycpt.khachHangId = this.chiTietYCPT.khachHangId;
                    ycpt.phongBanId = this.chiTietYCPT.phongBanId;
                    ycpt.noiLayMau = this.chiTietYCPT.noiLayMau;
                    ycpt.soChungThuVuGiamDinh = this.chiTietYCPT.soChungThuVuGiamDinh;
                    ycpt.vuGiamDinhId = this.chiTietYCPT.vuGiamDinhId;
                    ycpt.tenMau = item.tenMau;
                    ycpt.soNiem = item.soNiem;
                    ycpt.idBoChiTieu = item.idBoChiTieu;
                    ycpt.cacChiTieuPhanTich = item.cacChiTieuPhanTich;
                    ycpt.ngayMuonNhanKQPT = new Date(this.ngayMuonNhanKQPT);
                    let userInfo = JSON.parse(localStorage.getItem('eic_userInfo'));
                    ycpt.nguoiYeuCauUserId = userInfo.userId;
                    ycpt.trangthai = TrangThai.LAB_YCPT_ChoChapNhan_081;
                    ycpt.lastModified = new Date();
                    ycpt.lastUserId = userInfo.userId;

                    let rs = await axios.post(apiEndPoint+ 'api/YeuCauPhanTiches', ycpt);
                    await this.SaveHistory(80,rs.data.id,"Tạo Yêu Cầu Phân Tích (mqlgd)","")
                    // if (rs) {
                    //     this.openAlert("Thực hiện thành công!");
                    // }

                  } catch (error) {
                    alert(error)
                  }
                  EventLogin.$emit("reloadPageChiTietVGD");
                  this.$f7router.back();

                }



        },
          async SaveHistory(actionId, objectId, message, note){
            let userInfo = JSON.parse(localStorage.getItem('eic_userInfo'));
            let history = {
               actionId: actionId,
                appUserId: userInfo.userId,
                objectId : objectId ,
                objectType : "YeuCauPhanTich" ,
                date: new Date(),
                message:  message ,
                note:  note
            }
             try {
                let res = await axios.post(apiEndPoint + "api/ActionHistories", history)
                console.log('viet res', res);
            } catch (error) {
                alert(error);
            }
        },
        runThemMau(){
          this.$f7router.navigate('/them-mau-yeu-cau-phan-tich/');
        },
        openPopup(){
          console.log('viet open popup')
        },
        getTenMauById(id){
         let found = this.dsBoChiTieu.find(e => e.id == id);
            if (!found) return "";
            return found.ten;
        },
        themMau(){
            this.itemThongTinPhanTich.tenMau = this.getTenMauById(this.itemThongTinPhanTich.idBoChiTieu);

            this.itemsThongTinPhanTich.push(this.itemThongTinPhanTich);
            this.itemThongTinPhanTich = {idBoChiTieu:null,soNiem:""};
            this.$f7.popup.destroy();
            console.log('viet themMau',this.itemsThongTinPhanTich)

        },
         async getCongViecGiamDinhVien(item){
         let url = apiEndPoint + "api/CongViecGiamDinhViens";
            let filter = {
                where: {
                    vuGiamDinhId: item.id
                },
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.dsCongViecGDV = res.data;
                console.log('viet this.dsCongViecGDV',this.dsCongViecGDV)
            } catch (error) {
               alert(error);
            }
      },
         async loadChiTieuCon(id){
           console.log('viet loadChiTieuCon',id);
          let url = apiEndPoint + "api/ChiTieuCons";
          let filter = {where: {boChiTieuId: id}};
          if(this.itemsThongTinPhanTich == undefined) this.itemsThongTinPhanTich = [];
          console.log('viet this.itemsThongTinPhanTich',this.itemsThongTinPhanTich)
          let index = this.itemsThongTinPhanTich.findIndex(e => id == e.idBoChiTieu); // kiểm tra xem đã có tên mẫu (bộ chỉ tiêu) chưa, nếu có rồi thì load lại các mục chọn chỉ tiêu phân tích.

          if(index!=-1){
            this.itemThongTinPhanTich.cacChiTieuPhanTich = this.itemsThongTinPhanTich[index].cacChiTieuPhanTich;
            console.log('viet index!=-1',index)
          }else{
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.itemThongTinPhanTich.cacChiTieuPhanTich = res.data;
                console.log('viet  this.itemThongTinPhanTich.cacChiTieuPhanTich', this.itemThongTinPhanTich.cacChiTieuPhanTich)
            } catch (error) {
               alert(error);
            }
            // Mặc định chọn hết các chỉ tiêu isSelected == true
            this.itemThongTinPhanTich.cacChiTieuPhanTich.forEach(e => {
              if(e.tenChiTieu && e.tenChiTieu.length){
                e.isSelected = true;
              } else {
                e.isSelected = false
              }

            });
          }


        },
        async getDSBoChiTieu() {
            let url = apiEndPoint + "api/BoChiTieus";
            let filter = {
                fields: {
                  "id":true,
                  "ten":true,
                },
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.dsBoChiTieu = res.data;

            } catch (error) {
                console.log(error);
            }
        },
        getPhongBanByID(id){
            let found = this.dsPhongBan.find(e => e.id == id);
            if (!found) return "";
            return found;
        },
        getKhachHangByMa(maKH) {
            let found = this.dsKhachHangLab.find(e => e.ma == maKH);
            if (!found) return "";
            return found;
        },
        getTenKhachHang(id) {
            let found = this.dsKhachHangLab.find(e => e.id == id);
            if (!found) return "";
            return found.hoTen;
        },
        getTenPhongBan(id) {
            let found = this.dsPhongBan.find(e => e.id == id);
            if (!found) return "";
            return found.ten;
        },
        getTenNhanVien(id) {
            let found = this.dsNhanVien.find(e => e.id == id);
            if (!found) return "";
            return found.ten;
        },
        openFile(item) {
            window.open(item, '_system');
            return false;
        },
        formatDate(date) {
            var result = dateFns.format(
                new Date(date),
                'dd/MM/yyyy HH:mm'
            )
            return result;
        }

    },
    async created() {
        this.dsPhongBan = JSON.parse(localStorage.getItem('eic_dsPhongBan'));
        this.dsPhongBanLab = this.dsPhongBan.filter(e=>e.boPhan == 'LAB');
        this.dsKhachHangLab = JSON.parse(localStorage.getItem('eic_dsKhachHangLab'));
        this.dsNhanVien = JSON.parse(localStorage.getItem('eic_dsNhanVien'));
        this.chiTietVGD = JSON.parse(localStorage.getItem('eic_temp'));


        console.log('viet this.chiTietVGD',this.chiTietVGD);
        this.chiTietYCPT.soChungThuVuGiamDinh = this.chiTietVGD.soChungThu;
        this.chiTietYCPT.vuGiamDinhId = this.chiTietVGD.id;

        let phongban = this.getPhongBanByID(this.chiTietVGD.phongBanId);
        console.log('viet phongban',phongban);
        let khachHang = this.getKhachHangByMa(phongban.ma);
        console.log('viet khachHang',khachHang);
        this.chiTietYCPT.khachHangId = khachHang.id;
        await this.getDSBoChiTieu();




    }
}
</script>
