<template>
<f7-page class="style-bg" >
    <f7-navbar>
        <f7-nav-left back-link="Back"></f7-nav-left>
        <f7-nav-title class="fs-roboto-medium">Trợ giúp</f7-nav-title>
        <f7-nav-right>
            <f7-link></f7-link>
        </f7-nav-right>
    </f7-navbar>
    <div >
        <f7-block-title>Hướng dẫn sử dụng phần mềm QLGD</f7-block-title>
        <f7-list>
        <f7-list-item link="#" @click="openFile('https://qlgd.eic.com.vn/help/content/1.Tao_VGD.pdf')" title="1. Tạo Vụ Giám Định" after="Tải về">
            <f7-icon slot="media" icon="demo-list-icon"></f7-icon>
        </f7-list-item>
        <f7-list-item link="#" @click="openFile('https://qlgd.eic.com.vn/help/content/2.Phan_cong_GDV.pdf')" title="2. Phân Công Giám Định Viên" after="Tải về">
            <f7-icon slot="media" icon="demo-list-icon"></f7-icon>
        </f7-list-item>
        <f7-list-item link="#" @click="openFile('https://qlgd.eic.com.vn/help/content/3.Gui_YCPT.pdf')" title="3. Gửi Yêu Cầu Phân Tích" after="Tải về">
            <f7-icon slot="media" icon="demo-list-icon"></f7-icon>
        </f7-list-item>
        <f7-list-item link="#" @click="openFile('https://qlgd.eic.com.vn/help/content/4.Nhan_KQPT.pdf')" title="4. Nhận Kết Quả Phân Tích" after="Tải về">
            <f7-icon slot="media" icon="demo-list-icon"></f7-icon>
        </f7-list-item>
        <f7-list-item link="#" @click="openFile('https://qlgd.eic.com.vn/help/content/5.Cap_nhat_cong_viec_hien_truong.pdf')" title="5. Cập Nhật Công Việc Hiện Trường" after="Tải về">
            <f7-icon slot="media" icon="demo-list-icon"></f7-icon>
        </f7-list-item>
        <f7-list-item link="#" @click="openFile('https://qlgd.eic.com.vn/help/content/6.Xuat_chung_thu.pdf')" title="6. Xuất Chứng Thư" after="Tải về">
            <f7-icon slot="media" icon="demo-list-icon"></f7-icon>
        </f7-list-item>
        <f7-list-item link="#" @click="openFile('https://qlgd.eic.com.vn/help/content/7.Tao_hoa_don_nhap.pdf')" title="7. Tạo Hóa Đơn Nháp" after="Tải về">
            <f7-icon slot="media" icon="demo-list-icon"></f7-icon>
        </f7-list-item>
        <f7-list-item link="#" @click="openFile('https://qlgd.eic.com.vn/help/content/8.Duyet_hoa_don.pdf')" title="8. Duyệt Hóa Đơn" after="Tải về">
            <f7-icon slot="media" icon="demo-list-icon"></f7-icon>
        </f7-list-item>
        <f7-list-item link="#" @click="openFile('https://qlgd.eic.com.vn/help/content/9.Tao_YCPT.pdf')" title="9. Tạo Yêu Cầu Phân Tích" after="Tải về">
            <f7-icon slot="media" icon="demo-list-icon"></f7-icon>
        </f7-list-item>
        <f7-list-item link="#" @click="openFile('https://qlgd.eic.com.vn/help/content/10.Chap_nhan_YCPT_Phan_cong_PTV.pdf')" title="10. Chấp Nhận YCPT và Phân Công Phân Tích Viên" after="Tải về">
            <f7-icon slot="media" icon="demo-list-icon"></f7-icon>
        </f7-list-item>
        <f7-list-item link="#" @click="openFile('https://qlgd.eic.com.vn/help/content/11.Cap_nhat_ket_qua_phan_tich.pdf')" title="11. Cập Nhật Kết Quả Phân Tích" after="Tải về">
            <f7-icon slot="media" icon="demo-list-icon"></f7-icon>
        </f7-list-item>
        <f7-list-item link="#" @click="openFile('https://qlgd.eic.com.vn/help/content/12.Duyet_ket_qua_phan_tich.pdf')" title="12. Duyệt Kết Quả Phân Tích" after="Tải về">
            <f7-icon slot="media" icon="demo-list-icon"></f7-icon>
        </f7-list-item>
        <f7-list-item link="#" @click="openFile('https://qlgd.eic.com.vn/help/content/13.Cham_cong_hien_truong.pdf')" title="13. Chấm Công Hiện Trường" after="Tải về">
            <f7-icon slot="media" icon="demo-list-icon"></f7-icon>
        </f7-list-item>
        </f7-list>
    </div>
</f7-page>
</template>

<script>
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
export default {
    data() {
        return {
        };
    },
    updated() {
    },
    methods: {
        openFile(link){
            window.open(link);
        }
    },
    async created() {
    }
};
</script>
