import { render, staticRenderFns } from "./tao-moi-yeu-cau-phan-tich-tu-lab.vue?vue&type=template&id=63d62e7f&"
import script from "./tao-moi-yeu-cau-phan-tich-tu-lab.vue?vue&type=script&lang=js&"
export * from "./tao-moi-yeu-cau-phan-tich-tu-lab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports