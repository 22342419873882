<template>
  <f7-page :page-content="false" @page:init="onPageInit" @page:beforeremove="onPageBeforeRemove" class="my-bg-color">
    <f7-navbar class>
      <f7-nav-left back-link="Back"></f7-nav-left>
      <f7-nav-title>TẠO MỚI VỤ GIÁM ĐỊNH</f7-nav-title>
    </f7-navbar>
    <!-- Toolbar FAB Morph Target -->
    <!-- <f7-toolbar bottom class="fab-morph-target">
        <f7-button fill @click="runCreateVuGiamDinh()">Tạo mới</f7-button>
    </f7-toolbar>-->
    <!-- FAB Left Bottom (Blue) -->
    <!-- Will morph to Toolbar -->
    <!-- <f7-fab position="right-bottom" slot="fixed" morph-to=".toolbar.fab-morph-target">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
    </f7-fab>-->
    <!-- FAB Right Bottom (Orange) -->
    <f7-fab position="right-bottom" slot="fixed" text="Tạo VGD" @click="runCreateVuGiamDinh()">
      <f7-icon ios="f7:checkmark" aurora="f7:checkmark" md="material:checkmark"></f7-icon>
    </f7-fab>

    <div class="page-content">
      <div class="card">
        <f7-list>
          <f7-list-item accordion-item title="1. Thông tin đối tác">
            <f7-accordion-content>
              <f7-list no-hairlines-md>
                <f7-list-item
                  class="my-title-font-size"
                  title="Yêu cầu I (*):"
                  smart-select
                  :smart-select-params="{searchbar: true, searchbarPlaceholder: 'Search', scrollToSelectedItem: true, closeOnSelect: true, virtualList: true}"
                >
                  <select
                    name="khachHang1"
                    v-model="chiTietVGD.khachHangId"
                    @change="filterHopDong(chiTietVGD.khachHangId)"
                  >
                    <option
                      :key="index"
                      v-for="(item, index) in dsKhachHang"
                      :value="item.id"
                      :data-display-as="item.hoTen"
                    >{{cleanAccents(item.shortname?item.hoTen+' ('+item.shortname+')':item.hoTen)}}</option>
                  </select>
                </f7-list-item>
                <f7-list-item
                  class="my-title-font-size"
                  title="Yêu cầu II :"
                  smart-select
                  :smart-select-params="{searchbar: true, searchbarPlaceholder: 'Search', scrollToSelectedItem: true, closeOnSelect: true, virtualList: true}"
                >
                  <select name="khachHang2" v-model="chiTietVGD.khachHangIdII">
                    <option
                      :key="index"
                      v-for="(item, index) in dsKhachHang"
                      :value="item.id"
                      :data-display-as="item.hoTen"
                    >{{cleanAccents(item.shortname?item.hoTen+' ('+item.shortname+')':item.hoTen)}}</option>
                  </select>
                </f7-list-item>
                <f7-list-item
                  class="my-title-font-size"
                  title="Liên quan I:"
                  smart-select
                  :smart-select-params="{searchbar: true, searchbarPlaceholder: 'Search', virtualList: true}"
                >
                  <select name="khachHangLienQuan1" multiple v-model="khachHangLienQuanIdI">
                    <option
                      :key="index"
                      v-for="(item, index) in dsKhachHang"
                      :value="item.id"
                      :data-display-as="item.hoTen"
                    >{{cleanAccents(item.shortname?item.hoTen+' ('+item.shortname+')':item.hoTen)}}</option>
                  </select>
                </f7-list-item>
                <f7-list-item
                  class="my-title-font-size"
                  title="Liên quan II:"
                  smart-select
                  :smart-select-params="{searchbar: true, searchbarPlaceholder: 'Search', virtualList: true}"
                >
                  <select name="khachHangLienQuan2" multiple v-model="khachHangLienQuanIdII">
                    <option
                      :key="index"
                      v-for="(item, index) in dsKhachHang"
                      :value="item.id"
                      :data-display-as="item.hoTen"
                    >{{cleanAccents(item.shortname?item.hoTen+' ('+item.shortname+')':item.hoTen)}}</option>
                  </select>
                </f7-list-item>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
      </div>
      <div class="card">
          <f7-list>
              <f7-list-item accordion-item title="2. Thông tin hợp đồng">
                  <f7-accordion-content>
                      <f7-list no-hairlines-md>
                      <f7-list-item class="my-title-font-size"
                        title="Hợp đồng của Đối tác:"
                        smart-select
                        :smart-select-params="{openIn: 'sheet', closeOnSelect: true}"
                        >
                        <select
                            name="hopDong"
                            v-model="chiTietVGD.hDCungCapDichVuId"
                        >
                            <option
                            :key="index"
                            v-for="(item, index) in itemsHopDongKhachHang"
                            :value="item.id"
                            :data-display-as="item.soHopDong"
                            >{{item.soHopDong+' (Ngày ký: '+formatDate(item.ngayKy)+ ')'}}</option>
                        </select>
                        </f7-list-item>
                      </f7-list>
                  </f7-accordion-content>
              </f7-list-item>
          </f7-list>
      </div>
      <div class="card">
        <f7-list>
          <f7-list-item accordion-item title="3. Thông tin đơn hàng">
            <f7-accordion-content>
              <f7-list no-hairlines-md>
                <f7-list-input
                  label="Tên hàng (*):"
                  type="text"
                  placeholder
                  :value="chiTietVGD.tenHang"
                  @input="chiTietVGD.tenHang = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Mã ký hiệu :"
                  type="text"
                  placeholder
                  :value="chiTietVGD.maKyHieu"
                  @input="chiTietVGD.maKyHieu = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Số lượng :"
                  type="number"
                  placeholder
                  :value="chiTietVGD.soLuong"
                  @input="chiTietVGD.soLuong = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Đơn vị Số lượng :"
                  type="text"
                  placeholder
                  :value="chiTietVGD.dVSoLuong"
                  @input="chiTietVGD.dVSoLuong = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Khối lượng (Cả bì) :"
                  type="number"
                  placeholder
                  :value="chiTietVGD.khoiLuong"
                  @input="chiTietVGD.khoiLuong = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Đơn vị Khối lượng (Cả bì) :"
                  type="text"
                  placeholder
                  :value="chiTietVGD.dVKhoiLuong"
                  @input="chiTietVGD.dVKhoiLuong = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Khối lượng (tịnh) :"
                  type="number"
                  placeholder
                  :value="chiTietVGD.khoiLuongTinh"
                  @input="chiTietVGD.khoiLuongTinh = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Đơn vị Khối lượng (tịnh) :"
                  type="text"
                  placeholder
                  :value="chiTietVGD.dVKhoiLuongTinh"
                  @input="chiTietVGD.dVKhoiLuongTinh = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Đơn bảo hiểm :"
                  type="text"
                  placeholder
                  :value="chiTietVGD.donBaoHiem"
                  @input="chiTietVGD.donBaoHiem = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Số đơn bảo hiểm :"
                  type="text"
                  placeholder
                  :value="chiTietVGD.soDonBaoHiem"
                  @input="chiTietVGD.soDonBaoHiem = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Vận đơn số :"
                  type="text"
                  placeholder
                  :value="chiTietVGD.vanDonSo"
                  @input="chiTietVGD.vanDonSo = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Cảng xếp hàng :"
                  type="text"
                  placeholder
                  :value="chiTietVGD.cangXepHang"
                  @input="chiTietVGD.cangXepHang = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Cảng dỡ hàng :"
                  type="text"
                  placeholder
                  :value="chiTietVGD.cangDoHang"
                  @input="chiTietVGD.cangDoHang = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Phương tiện vận tải :"
                  type="text"
                  placeholder
                  :value="chiTietVGD.phuongTienVanTai"
                  @input="chiTietVGD.phuongTienVanTai = $event.target.value"
                ></f7-list-input>
                <f7-list-item
                  class="my-title-font-size"
                  title="Tình trạng H.Hóa :"
                  smart-select
                  :smart-select-params="{openIn: 'sheet', closeOnSelect: true}"
                >
                  <select name="tinhTrangHangHoa" v-model="chiTietVGD.tinhTrangHangHoa">
                    <option value="Bình thường">Bình thường</option>
                    <option value="Hàng rời">Hàng rời</option>
                    <option value="Hàng hóa bị hư hỏng">Hàng hóa bị hư hỏng</option>
                  </select>
                </f7-list-item>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
      </div>
      <div class="card">
        <f7-list>
          <f7-list-item accordion-item title="4. Thông tin giám định">
            <f7-accordion-content>
              <f7-list no-hairlines-md>
                <f7-list-item
                  class="my-title-font-size"
                  title="Hạng mục YCGĐ (*):"
                  smart-select
                  :smart-select-params="{openIn: 'sheet', closeOnSelect: true}"
                >
                  <select name="hangMucYCGD" multiple v-model="hangMucYeuCauGiamDinhArray">
                    <option
                      :key="index"
                      v-for="(item, index) in dsHangMucYCGD"
                      :value="item.tenDanhMuc"
                    >{{item.tenDanhMuc}}</option>
                  </select>
                </f7-list-item>
                <f7-list-input
                  label="Thời gian bắt đầu giám định (*):"
                  type="date"
                  placeholder
                  :value="thoiGianGiamDinh1"
                  @input="thoiGianGiamDinh1 = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Thời gian kết thúc giám định :"
                  type="date"
                  placeholder
                  :value="thoiGianGiamDinh2"
                  @input="thoiGianGiamDinh2 = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Địa điểm giám định (*):"
                  type="text"
                  placeholder
                  :value="chiTietVGD.diaDiemGiamDinh"
                  @input="chiTietVGD.diaDiemGiamDinh = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Thông tin liên hệ :"
                  type="text"
                  placeholder
                  :value="chiTietVGD.tenNguoiLienHe"
                  @input="chiTietVGD.tenNguoiLienHe = $event.target.value"
                ></f7-list-input>
                <f7-list-input
                  label="Ghi chú :"
                  type="text"
                  placeholder
                  :value="chiTietVGD.ghiChu"
                  @input="chiTietVGD.ghiChu = $event.target.value"
                ></f7-list-input>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
      </div>
      <div class="card">
        <f7-list>
          <f7-list-item accordion-item title="5. Gửi tới phòng ban">
            <f7-accordion-content>
              <f7-list>
                <f7-list-item
                  class="my-title-font-size"
                  title="Phòng ban (*):"
                  smart-select
                  :smart-select-params="{openIn: 'sheet', closeOnSelect: true}"
                >
                  <select
                    name="phongBan"
                    v-model="chiTietVGD.phongBanId"
                    @change="getDSNghiepVu(chiTietVGD.phongBanId)"
                  >
                    <option
                      :key="index"
                      v-for="(item, index) in dsPhongBan"
                      :value="item.id"
                    >{{item.ten}}</option>
                  </select>
                </f7-list-item>
                <f7-list-item
                  class="my-title-font-size"
                  title="Lĩnh vực (*):"
                  smart-select
                  :smart-select-params="{openIn: 'sheet', closeOnSelect: true}"
                >
                  <select
                    name="linhVuc"
                    multiple
                    v-model="dsSoChungThuTheoLinhVuc"
                    @change="createSoChungThu(dsSoChungThuTheoLinhVuc)"
                  >
                    <option
                      :key="index"
                      v-for="(item, index) in dsNghiepVu"
                      :value="item.soChungThu"
                    >{{item.ten}}</option>
                  </select>
                </f7-list-item>
                <f7-list-item
                  class="my-title-font-size"
                  title="Số chứng thư sẽ tạo : "
                >{{dsSoChungThuSeTao.toString()}}</f7-list-item>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list>
      </div>
      <div class="m-b-100"></div>
    </div>
  </f7-page>
</template>

<script>
import TrangThai from "./../../common.js";
var dateFns = require("date-fns");
import NavBar from "./../components/NavBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import axios from "axios";
import { EventLogin } from "../event-login.js";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      allHopDong:[],
      itemsHopDongKhachHang: [],
      dsSoChungThuSeTao: [],
      dsSoChungThuTheoLinhVuc: [],
      dsNghiepVu: [],
      khachHangLienQuanIdI: [],
      khachHangLienQuanIdII: [],
      hangMucYeuCauGiamDinhArray: [],
      thoiGianGiamDinh1: "",
      thoiGianGiamDinh2: "",
      dsHangMucYCGD: [],
      dsKhachHang: [],
      countLike: 0,
      hideBtn: true,
      isBottom: true,
      popupOpened: false,
      chiTietVGD: {},
      isLoggedIn: false,
      hiddenItem: [],
      photos: [],
      getDataUser: {},
      hidePreloader: false,
      showPreloader: true,
      idCurrent: "",
      urlCode: "https://play.google.com/store/apps/details?id=eic.vinaas.app",
      userId: "",
      showLike: true,
      hideDayTin: false,
      getPhoto: [],
    };
  },
  updated() {},
  mounted() {
    // window.FB.XFBML.parse();
    if (this.$userInfoGlobal) {
      if (this.$userInfoGlobal.role == "admin") this.isAdmin = true; // user có role là admin
      console.log(this.$userInfoGlobal);
      this.isLoggedIn = true; // user đã đăng nhập
      this.name = this.$userInfoGlobal.name;
    }
    EventLogin.$on("userLoggedIn", (userInfo) => {
      this.isLoggedIn = true;
      console.log("viet this.isLoggedIn", this.isLoggedIn);
      this.name = userInfo.name;
      console.log("viet this.name", this.name);
    });
    EventLogin.$on("updateProfile", (userInfo) => {
      this.isLoggedIn = true;
      this.name = userInfo.name;
    });
    EventLogin.$on("userLoggedOut", (e) => {
      this.isLoggedIn = false;
      this.isAdmin = false;
      this.name = "";
      this.$userInfoGlobal = null;
    });
  },
  methods: {
    cleanAccents(str) {
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
      str = str.replace(/Đ/g, "D");
      // Combining Diacritical Marks
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // huyền, sắc, hỏi, ngã, nặng
      str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // mũ â (ê), mũ ă, mũ ơ (ư)

      return str;
    },
    validateVuGiamDinh(vgd) {
      let check = false;
      if (!vgd) {
        this.openAlert("Vụ giám định không tồn tại");
        return check;
      }

      if (!vgd.khachHangId) {
        this.openAlert("Bạn chưa chọn Đối tác yêu cầu I");
        return check;
      }
      if (!vgd.tenHang) {
        this.openAlert("Bạn chưa nhập Tên Hàng");
        return check;
      }
      if (!this.hangMucYeuCauGiamDinhArray.length) {
        this.openAlert("Bạn chưa chọn Hạng mục YCGĐ");
        return check;
      }
      if (!vgd.diaDiemGiamDinh) {
        this.openAlert("Bạn chưa nhập Địa điểm giám định");
        return check;
      }
      if (!this.thoiGianGiamDinh1) {
        this.openAlert("Bạn chưa chọn Thời gian bắt đầu giám định");
        return check;
      }
      if (!vgd.phongBanId) {
        this.openAlert("Bạn chưa chọn Phòng ban");
        return check;
      }
      if (!this.dsSoChungThuTheoLinhVuc.length) {
        this.openAlert("Bạn chưa chọn Lĩnh vực");
        return check;
      }
      check = true;
      return check;
    },
    openAlert(titlename, cb) {
      const app = this.$f7;
      app.dialog.alert("" + titlename, "Thông báo", cb);
    },

    async FindOneByMa(ma) {
      let filter = {
        where: {
          ma: ma,
        },
      };
      let rec;
      let url = apiEndPoint + "api/ThongTinTaoMaChungThus/findOne";
      try {
        rec = await axios.get(url, {
          params: {
            filter: filter,
          },
        });
      } catch (error) {
        return null;
      }
      return rec.data;
    },

    async IncreaseByMa_NotChangeDatabase(ma) {
      let recEntity = await this.FindOneByMa(ma);
      if (recEntity == null)
        return {
          ma: ma,
          giaTri: 1,
        };
      ++recEntity.giaTri;
      return recEntity;
    },
    async IncreaseByMa(ma) {
      let recEntity = await this.FindOneByMa(ma);
      if (recEntity == null)
        return await this.Post_ThongTinTaoMaChungThu({
          ma: ma,
          giaTri: 1,
        });
      ++recEntity.giaTri;
      return await this.Put_ThongTinTaoMaChungThu(recEntity);
    },
    async Post_ThongTinTaoMaChungThu(item) {
      let rec = await axios.post(
        apiEndPoint + "api/ThongTinTaoMaChungThus",
        item
      );
      return rec.data;
    },
    async Put_ThongTinTaoMaChungThu(item) {
      let rec = await axios.put(
        apiEndPoint + "api/ThongTinTaoMaChungThus" + "/" + item.id,
        item
      );
      return rec.data;
    },
    FormatNumberLength(num, length) {
      //FormatNumberLength(10000, 5) outputs '10000'
      var r = "" + num;
      while (r.length < length) {
        r = "0" + r;
      }
      return r;
    },
    async GenerateCertificateNo_NotChangeDatabase(soChungThuTheoLinhVuc) {
      let dauSoCT = soChungThuTheoLinhVuc;
      let YY = String(new Date().getFullYear() % 1000);
      soChungThuTheoLinhVuc = YY + soChungThuTheoLinhVuc; //số lĩnh vực kết hợp theo năm, sang năm mới thì tạo ra SoLinhVuc mới với giá trị 01
      
      // begin -  xử lý không cho số chứng thư nhảy sang 9 chữ số. (cho năm 2023)
            // Phòng năng lượng - Dầu sản phẩm - Số chứng thư 2023 : 15 - 16
            // Phòng Nghiệp vụ 3 CN Miền Trung - Dầu sản phẩm Ins3.CEB : 67 - 68 
            // Dự án Nghi Sơn - Dầu Sản Phẩm Nghi Sơn : 914 - 915
            // Yêu cầu : Chạy hết số 999 hoặc 9999 thì nhảy sang đầu số kế tiếp. Không cho SCT tràn sang 9 chữ số .
            let temp = await this.FindOneByMa(soChungThuTheoLinhVuc);
            if(dauSoCT === '15' || dauSoCT === '67'){
                if(temp && temp.giaTri >= 9999){
                    let dauSoCTNew = Number.parseInt(dauSoCT) + 1;
                    soChungThuTheoLinhVuc = YY + dauSoCTNew.toString();
                }
            }else if(['231','421','423','621','623','914'].includes(dauSoCT)){
                if(temp && temp.giaTri >= 999){
                    let dauSoCTNew = Number.parseInt(dauSoCT) + 1;
                    soChungThuTheoLinhVuc = YY + dauSoCTNew.toString();
                }
            }

      // end - xử lý không cho số chứng thư nhảy sang 9 chữ số. (cho năm 2023)
      
      let rec = await this.IncreaseByMa_NotChangeDatabase(
        soChungThuTheoLinhVuc
      ); //lấy số thứ tự theo mã đang có

      let soChungThu = "";
      if (soChungThuTheoLinhVuc.length == 5) {
        soChungThu =
          soChungThuTheoLinhVuc + this.FormatNumberLength(rec.giaTri, 3);
      } else {
        soChungThu =
          soChungThuTheoLinhVuc + this.FormatNumberLength(rec.giaTri, 4);
      }

      return soChungThu;
    },
    async GenerateCertificateNo(soChungThuTheoLinhVuc) {
      let dauSoCT = soChungThuTheoLinhVuc;
      let YY = String(new Date().getFullYear() % 1000);
      soChungThuTheoLinhVuc = YY + soChungThuTheoLinhVuc; //số lĩnh vực kết hợp theo năm, sang năm mới thì tạo ra SoLinhVuc mới với giá trị 01
      
      // begin -  xử lý không cho số chứng thư nhảy sang 9 chữ số. (cho năm 2023)
            // Phòng năng lượng - Dầu sản phẩm - Số chứng thư 2023 : 15 - 16
            // Phòng Nghiệp vụ 3 CN Miền Trung - Dầu sản phẩm Ins3.CEB : 67 - 68 
            // Dự án Nghi Sơn - Dầu Sản Phẩm Nghi Sơn : 914 - 915
            // Yêu cầu : Chạy hết số 999 hoặc 9999 thì nhảy sang đầu số kế tiếp. Không cho SCT tràn sang 9 chữ số .
            let temp = await this.FindOneByMa(soChungThuTheoLinhVuc);
            if(dauSoCT === '15' || dauSoCT === '67'){
                if(temp && temp.giaTri >= 9999){
                    let dauSoCTNew = Number.parseInt(dauSoCT) + 1;
                    soChungThuTheoLinhVuc = YY + dauSoCTNew.toString();
                }
            }else if(['231','421','423','621','623','914'].includes(dauSoCT)){
                if(temp && temp.giaTri >= 999){
                    let dauSoCTNew = Number.parseInt(dauSoCT) + 1;
                    soChungThuTheoLinhVuc = YY + dauSoCTNew.toString();
                }
            }

      // end - xử lý không cho số chứng thư nhảy sang 9 chữ số. (cho năm 2023)

      let rec = await this.IncreaseByMa(soChungThuTheoLinhVuc); //lấy số thứ tự theo mã đang có
      let soChungThu = "";
      if (soChungThuTheoLinhVuc.length == 5) {
        soChungThu =
          soChungThuTheoLinhVuc + this.FormatNumberLength(rec.giaTri, 3);
      } else {
        soChungThu =
          soChungThuTheoLinhVuc + this.FormatNumberLength(rec.giaTri, 4);
      }

      return soChungThu;
    },
    async createSoChungThu(items) {
      console.log("viet createSoChungThu items", items);
      this.dsSoChungThuSeTao = [];
      if (items) {
        items.forEach(async (item) => {
          let soChungThuSeTao = await this.GenerateCertificateNo_NotChangeDatabase(
            item
          );
          console.log("viet soChungThuSeTao ", soChungThuSeTao);
          this.dsSoChungThuSeTao.push(soChungThuSeTao);
          this.dsSoChungThuSeTao_toString = this.dsSoChungThuSeTao.join();
        });
      }
    },

    async getDSNghiepVu(idPhongBan) {
      let filter = {
        where: {
          phongBanId: idPhongBan,
          trangThai: true
        },
      };
      let url = apiEndPoint + "api/NghiepVus";
      try {
        let res = await axios.get(url, {
          params: {
            filter: filter,
          },
        });
        console.log("viet res NghiepVus", res);
        this.dsNghiepVu = res.data;
      } catch (error) {
        alert(error);
      }
    },
    xuLyThoiGianGiamDinh(myDate1, myDate2) {
      if (!myDate1) return "";
      if (!myDate2) return this.yyyyMMddToddMMyyyy(myDate1);
      if (new Date(myDate1) > new Date(myDate2)) {
        this.openAlert("Khoảng thời gian không hợp lệ.");
        return "";
      }
      return (
        this.yyyyMMddToddMMyyyy(myDate1) +
        " đến " +
        this.yyyyMMddToddMMyyyy(myDate2)
      );
    },
    yyyyMMddToddMMyyyy(myDate) {
      let str = myDate.split("-");
      return str[2] + "/" + str[1] + "/" + str[0];
    },
    async runCreateVuGiamDinh() {
      let check = this.validateVuGiamDinh(this.chiTietVGD);
      if (!check) {
        return;
      }
      this.openPreloader("Đang thực hiện...", 2000);
      this.chiTietVGD.thoiGianGiamDinh = this.xuLyThoiGianGiamDinh(
        this.thoiGianGiamDinh1,
        this.thoiGianGiamDinh2
      );
      this.chiTietVGD.beginThoiGianGiamDinh = new Date(this.thoiGianGiamDinh1);
      this.chiTietVGD.hangMucYeuCauGiamDinh = this.hangMucYeuCauGiamDinhArray.join();
      this.chiTietVGD.khachHangLienQuanIdI = this.khachHangLienQuanIdI;
      this.chiTietVGD.khachHangLienQuanIdII = this.khachHangLienQuanIdII;
      // Bước 1: Tạo Yêu Cầu Giám Định.
      this.chiTietVGD.trangthai = TrangThai.YC_DaChapNhan_013;

      try {
        let res = await axios.post(
          apiEndPoint + "api/YeuCauGiamDinhs",
          this.chiTietVGD
        );
        console.log("viet res", res);
        this.chiTietVGD.ycGiamDinhId = res.data.id;
        this.dsSoChungThuTheoLinhVuc.forEach(async (soChungThuTheoLinhVuc) => {
          await this.CreateVuGiamDinh(soChungThuTheoLinhVuc, this.chiTietVGD);
          EventLogin.$emit("reloadPage");
        });
      } catch (error) {
        alert(error);
      }

      this.$f7router.back("/home/");
    },

    async SaveHistory(actionId, objectId, message, note) {
      let userInfo = JSON.parse(localStorage.getItem("eic_userInfo"));
      let history = {
        actionId: actionId,
        appUserId: userInfo.userId,
        objectId: objectId,
        objectType: "VuGiamDinh",
        date: new Date(),
        message: message,
        note: note,
      };
      try {
        let res = await axios.post(
          apiEndPoint + "api/ActionHistories",
          history
        );
        console.log("viet res", res);
      } catch (error) {
        alert(error);
      }
    },

    async CreateVuGiamDinh(soChungThuTheoLinhVuc, item) {
      let vuGiamDinh = item;

      this.userItem = JSON.parse(localStorage.getItem("eic_userInfo"));
      vuGiamDinh.trangthai = TrangThai.VGD_NEW_020;
      vuGiamDinh.thoiGian = new Date();
      vuGiamDinh.lastModified = new Date();
      vuGiamDinh.lastUserId = this.userItem.userId;

      vuGiamDinh.soChungThuTheoLinhVuc = soChungThuTheoLinhVuc;
      vuGiamDinh.flagTaoChungThu = true;
      vuGiamDinh.soChungThu = await this.GenerateCertificateNo(
        soChungThuTheoLinhVuc
      );
      let rs = await axios.post(apiEndPoint + "api/VuGiamDinhs", vuGiamDinh);
      await this.SaveHistory(20, rs.data.id, "Tạo Vụ Giám Định (mqlgd)", "");
      // console.log('viet create VGD',rs);
      if (rs) {
        this.openAlert("Thực hiện thành công!");
      }
    },
    filterHopDong(idKH) {
       this.itemsHopDongKhachHang = this.allHopDong.filter(e => e.khachHangId === idKH);
        //lọc ra những hợp đồng còn hiệu lực của khách hàng:
        this.itemsHopDongKhachHang = this.itemsHopDongKhachHang.filter(e => {
        if(e.ngayHetHieuLuc===null || e.ngayHetHieuLuc >= new Date()) return e;
        })
    },
    likeNews() {
      console.log("viet likeNews", this.isLoggedIn);
      if (this.isLoggedIn === false) {
        this.openAlert("Bạn cần đăng nhập");
      } else {
        let urlPost = apiEndPoint + "api/News";
        if (!this.chiTietVGD.listUserLike) {
          this.chiTietVGD.listUserLike = [];
        }

        this.chiTietVGD.listUserLike.push(this.userId);
        try {
          axios
            .patch(urlPost, this.chiTietVGD)
            .then((res) => {
              console.log("viet res", res);
              this.showLike = false;
              this.countLike++;
            })
            .catch((error) => {
              console.log("viet error", error);
            });
        } catch (err) {
          console.log(err);
        }
      }
    },
    dislikeNews() {
      let urlPost = apiEndPoint + "api/News";
      this.chiTietVGD.listUserLike =
        (this.chiTietVGD.listUserLike &&
          this.chiTietVGD.listUserLike.filter((v) => v != this.userId)) ||
        [];
      this.chiTietVGD.countLike = this.chiTietVGD.listUserLike.length;
      try {
        axios
          .patch(urlPost, this.chiTietVGD)
          .then((res) => {
            console.log("viet res", res);
            this.showLike = true;
            this.countLike--;
          })
          .catch((error) => {
            console.log("viet error", error);
          });
      } catch (err) {
        console.log(err);
      }
    },
    callToNumber(number) {
      var temp;
      if (number.search("-") > -1) {
        temp = number.split("-");
      } else {
        temp = number.split("/");
      }
      var phone = temp[0];
      phone = phone.replace(/\./g, "");
      phone = phone.replace(/ /g, "");
      phone = phone.replace(/,/g, "");
      var onSuccess = function (result) {
        console.log("Success:" + result);
      };

      var onError = function (result) {
        console.log("Error:" + result);
      };
      window.plugins.CallNumber.callNumber(onSuccess, onError, phone, false);
    },
    onPageBeforeRemove() {
      const self = this;
      if (self.popup) self.popup.destroy();
      if (self.popover) self.popover.destroy();
    },
    openAlert(titlename, cb) {
      const app = this.$f7;
      app.dialog.alert("" + titlename, "Thông báo", cb);
    },
    openPreloader(titlename, time) {
      const app = this.$f7;
      app.dialog.preloader(titlename);
      setTimeout(function () {
        app.dialog.close();
      }, time);
    },
    clickCopy() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      testingCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "thành công" : "không thành công";
        this.openAlert("Sao chép link " + msg);
      } catch (err) {
        this.openAlert("Oops, unable to copy");
      }
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    tinHienTai(id) {
      console.log(id);
      this.idCurrent = id;
    },
    editTin(id) {
      console.log(id);
      this.$f7router.navigate("/edit-tin-dang/" + id + "/");
    },
    openConfirm(titlename, cb) {
      const app = this.$f7;
      app.dialog.confirm("" + titlename, "Cảnh báo", () => {});
    },
    deleteTin(id) {
      this.openConfirm("Chắc chắn bạn muốn xóa tin?", () => {});
    },
    async hiddenTin(id) {
      let urlPost = apiEndPoint + "api/News";
      let filter = {
        where: {
          id: id,
        },
      };
      try {
        let res = await axios.get(urlPost, {
          params: {
            filter: filter,
          },
        });
        this.hiddenItem = res.data[0];
        console.log("this.hiddenItem", this.hiddenItem);
      } catch (error) {
        console.log(error);
      }
      this.hiddenItem.xuatBan = false;
      try {
        await axios
          .patch(urlPost, this.hiddenItem)
          .then((res) => {})
          .catch((error) => {
            console.log("viet error", error);
          });
      } catch (err) {
        console.log(err);
      }
    },
    async kiemTraDangNhap() {
      this.userItem = JSON.parse(localStorage.getItem("eic_userInfo"));
      // console.log('viet this.userItem', this.userItem);
      if (this.userItem == null || this.userItem == "") {
        this.isLoggedIn = false;
      } else {
        this.isLoggedIn = true;
        if (this.userItem.username == this.chiTietVGD.username) {
          console.log(
            "this.userItem.username === this.chiTietVGD.username",
            this.userItem.username,
            this.chiTietVGD.username
          );
          this.hideDayTin = true;
        } else {
          this.userId = this.userItem.userId;
          if (
            this.chiTietVGD.listUserLike &&
            this.chiTietVGD.listUserLike.includes(this.userId)
          ) {
            this.showLike = false;
          } else {
            this.showLike = true;
          }
        }
      }
    },
    getTinhTrangTin(tinhTrang) {
      if (tinhTrang == "da-su-dung") {
        return "Đã qua sử dụng";
      } else if (tinhTrang == "moi") {
        return "Còn mới";
      } else if (tinhTrang == "cu") {
        return "Cũ";
      }
      return "";
    },
    getImages(item) {
      console.log("viet getImages(item)", item);
      return item && item.replace("/images/", "/added/");
    },
    formatDate(date) {
      if (!date) {
        return "";
      } else {
        return dateFns.format(new Date(date), "dd/MM/yyyy");
      }
    },
    formatPrice(value) {
      console.log(value);
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      console.log(value);
    },
    async getChiTietTin() {
      let url = apiEndPoint + "api/VuGiamDinhs";
      let filter = {
        where: {
          id: this.$f7route.params.id,
        },
      };
      try {
        let res = await axios.get(url + "/findOne", {
          params: {
            filter: filter,
          },
        });
        console.log("res", res);
        this.chiTietVGD = res.data;
        console.log("this.chiTietVGD", this.chiTietVGD);
      } catch (error) {
        console.log(error);
      }
    },

    async getUser() {
      let url = apiEndPoint + "api/AppUsers";
      let filter = {
        where: {
          id: this.chiTietVGD.userId,
        },
      };
      try {
        let res = await axios.get(url + "/findOne", {
          params: {
            filter: filter,
          },
        });
        console.log("res", res);
        this.getDataUser = res.data;
        console.log("this.getDataUser", this.getDataUser);
      } catch (error) {
        console.log(error);
      }
    },
    showPhotoBrowser(index) {
      let self = this;
      console.log(this.chiTietVGD.images);
      // this.getPhoto = this.chiTietVGD.images.replace('/images/', '/added/')
      this.chiTietVGD.images.forEach((e) => {
        this.getPhoto.push(e.replace("/images/", "/added/"));
      });
      console.log("viet this.getPhoto", this.getPhoto);
      self.myPhotoBrowserStandalone = self.$f7.photoBrowser.create({
        photos: this.getPhoto,
        theme: "dark",
      });
      self.myPhotoBrowserStandalone.open(index);
    },
    onPageInit() {
      let self = this;
      self.mySwiper3 = self.$f7.swiper.create(".demo-swiper-tin-chi-tiet", {
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        slidesPerView: 1,
        effect: "fade",
        speed: 1000,
        autoplay: {
          delay: 3000,
        },
        observer: true,
        observeParents: true,
      });
    },
  },
  async created() {
    this.dsKhachHang = JSON.parse(localStorage.getItem("eic_dsKhachHang"));
    this.allHopDong = JSON.parse(localStorage.getItem("eic_dsHopDong"));
    this.dsHangMucYCGD = JSON.parse(localStorage.getItem("eic_dsHangMucYCGD"));
    this.dsPhongBan = JSON.parse(localStorage.getItem("eic_dsPhongBan"));
  },
};
</script>
