<template>
<f7-page :page-content="false" @page:init="onPageInit" style="padding-bottom: 50px;"  @page:beforeremove="onPageBeforeRemove">
    <f7-navbar>
        <f7-nav-title>QLGD EIC  <span v-if="isDemo()" class="bold">(Bản Demo)</span></f7-nav-title>
    </f7-navbar>

    <!-- FAB Right Bottom (Orange) -->
    <!-- <f7-fab position="right-bottom" slot="fixed" color="orange" style="bottom: 60px" @click="createVGD()">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab> -->

    <!-- FAB Right Bottom (Orange) -->
      <f7-fab position="right-bottom" slot="fixed" style="bottom: 60px">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
        <f7-fab-buttons position="top">
          <f7-fab-button label="Tạo nhanh VGD"  @click="createFastVGD()" color="green">
             <i class="fa fa-paper-plane" aria-hidden="true"></i>
          </f7-fab-button>
          <f7-fab-button label="Tạo mới VGD" @click="createVGD()" color="yellow">
            <i class="fas fa-pencil-alt" aria-hidden="true"></i>
          </f7-fab-button>
        </f7-fab-buttons>
      </f7-fab>

    <f7-tabs class="my-bg-color">
        <f7-tab id="tab-home" class="page-content" tab-active style="padding-top: 50px">

            <f7-block class="no-padding no-margin">
                <div class="toolbar tabbar tabbar-scrollable tabbar-labels toolbar-top  no-shadow" style="height: 40px; top: 48px; position: fixed">
                    <div class="toolbar-inner swiper-container-2 swiper-init" data-space-between="5" data-slides-per-view="auto" data-centered-slides="false">
                       <div class="swiper-wrapper">
                        <f7-link tab-link="#tab_chua_chi_dinh" tab-link-active class="swiper-slide justify-content-center"  @click="scrollTop()">
                            <span class="tabbar-label text-align-center fs-roboto-medium" style="font-size: 16px !important; top: 0px; text-transform: capitalize !important; font-weight: bold;">1. Chưa chỉ định</span>
                        </f7-link>
                        <f7-link tab-link="#tab_da_chi_dinh" class="swiper-slide justify-content-center"  @click="scrollTop()">
                            <span class="tabbar-label text-align-center fs-roboto-medium" style="font-size: 16px !important; top: 0px; text-transform: capitalize !important; font-weight: bold;">2. Đã chỉ định</span>
                        </f7-link>
                        <f7-link tab-link="#tab_hoan_thanh_hien_truong" class="swiper-slide justify-content-center"  @click="scrollTop()">
                            <span class="tabbar-label text-align-center fs-roboto-medium" style="font-size: 16px !important; top: 0px; text-transform: capitalize !important; font-weight: bold;">3. Hoàn Thành Hiện Trường</span>
                        </f7-link>
                        </div>
                    </div>
                </div>
                <f7-block class="no-padding">
                    <f7-tabs class="no-padding" swipeable>
                        <f7-tab id="tab_chua_chi_dinh" class="ptr-content" tab-active>
                            <!-- Default pull to refresh preloader-->
                            <div class="ptr-preloader">
                                <div class="preloader"></div>
                                <div class="ptr-arrow"></div>
                            </div>
                            <div class="list media-list no-margin no-hairlines" v-show="loading==true">
                                  <div v-for="n in 6" :key="n" :class="`card item-content skeleton-text skeleton-effect-${effect}`">
                                             <div class="item-inner">
                                                <div class="item-title-row">
                                                    <div class="item-title">
                                                        999999
                                                    </div>
                                                </div>
                                                <div class="item-text">
                                                   Lorem ipsum dolor,
                                                </div>
                                                <div class="item-text">
                                                   Lorem ipsum dolor, sit amet
                                                </div>
                                                <div class="item-text">
                                                   Lorem ipsum dolor, sit amet consectetur
                                                </div>
                                                <div class="item-text">
                                                   Lorem ipsum dolor, sit amet consecte
                                                </div>
                                                <div class="item-text">
                                                   Lorem ipsum dolor, sit am
                                                </div>
                                            </div>
                                    </div>
                            </div>
                            <div class="list media-list no-margin no-hairlines ptr-watch-scrollable" v-show="loading==false">
                                <div class="csstransition" >
                                    <div class="card" v-for="(item , index) in dsVuGiamDinh_ChuaChiDinh" :key="index">
                                        <a v-bind:href="'/chi-tiet-vu-giam-dinh/' + item.id + '/'" class="item-link item-content no-chevron">
                                            <!-- <div class="item-media"><img src="https://via.placeholder.com/150" width="80" height="80" style="object-fit: cover; border-radius: 5px" /></div> -->
                                            <div class="item-inner">
                                                <div class="item-title-row">
                                                    <div class="item-title fs-roboto-bold fs-15" style="text-transform: capitalize; font-weight: normal; color: #007aff;padding:5px;">
                                                       {{item.soChungThu}}
                                                    </div>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                    <span style='color:#616161'>Khách hàng:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="getTenKhachHang(item.khachHangId)"></span>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                     <span style='color:#616161'>Tên hàng:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.tenHang"></span>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                    <span style='color:#616161'>Hạng mục:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.hangMucYeuCauGiamDinh"></span>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                    <span style='color:#616161'>Địa điểm:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.diaDiemGiamDinh"></span>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                    <span style='color:#616161'>Thời gian:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.thoiGianGiamDinh"></span>
                                                </div>
                                                <!-- <div class="item-text fs-roboto-light">
                                                    Trạng Thái: <span class="no-margin" style="text-transform: capitalize;" v-html="item.trangthai"></span>
                                                </div> -->
                                                <!-- <div class="item-text fs-roboto-light">
                                                    Phòng Ban: <span class="no-margin" style="text-transform: capitalize;" v-html="getTenPhongBan(item.phongBanId)"></span>
                                                </div> -->

                                            </div>
                                        </a>
                                        <div class="triangle-topright" v-if="new Date() < new Date(new Date(item.thoiGian).getTime()+24*60*60*1000)"></div>
                                        <div class="text-triangle-topright fs-roboto-medium fs-10 text-color-white text-uppercase" v-if="new Date() < new Date(new Date(item.thoiGian).getTime()+24*60*60*1000)">New</div>
                                        <!-- <div style="position: absolute; right: 5px; bottom: 10px;"><i class="fa fa-heart-o align-self-center fs-15 text-color-pink" aria-hidden="true"></i></div> -->
                                    </div>
                                </div>
                                <p class="row" style="padding: 0px 15px" v-if="dsVuGiamDinh_ChuaChiDinh.length>=20">
                                    <button class="col button button-raised fs-roboto-medium fs-13"><a @click="loadThemVGD_ChuaChiDinh()">Xem thêm</a></button>
                                </p>
                            </div>
                        </f7-tab>
                        <f7-tab id="tab_da_chi_dinh" class="ptr-content">
                            <!-- Default pull to refresh preloader-->
                            <div class="ptr-preloader">
                                <div class="preloader"></div>
                                <div class="ptr-arrow"></div>
                            </div>
                            <div class="list media-list no-margin no-hairlines" v-show="loading==false">
                                <div class="csstransition" >
                                    <div class="card" v-for="(item , index) in dsVuGiamDinh_DaChiDinh" :key="index">
                                        <a v-bind:href="'/chi-tiet-vu-giam-dinh/' + item.id + '/'" class="item-link item-content no-chevron">
                                            <!-- <div class="item-media"><img src="https://via.placeholder.com/150" width="80" height="80" style="object-fit: cover; border-radius: 5px" /></div> -->
                                            <div class="item-inner">
                                                <div class="item-title-row">
                                                    <div class="item-title fs-roboto-bold fs-15" style="text-transform: capitalize; font-weight: normal; color: #007aff">
                                                        {{item.soChungThu}}
                                                    </div>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                    <span style='color:#616161'>Khách hàng:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="getTenKhachHang(item.khachHangId)"></span>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                     <span style='color:#616161'>Tên hàng:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.tenHang"></span>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                    <span style='color:#616161'>Hạng mục:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.hangMucYeuCauGiamDinh"></span>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                    <span style='color:#616161'>Địa điểm:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.diaDiemGiamDinh"></span>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                    <span style='color:#616161'>Thời gian:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.thoiGianGiamDinh"></span>
                                                </div>

                                            </div>
                                        </a>
                                        <div class="triangle-topright" v-if="new Date() < new Date(new Date(item.thoiGian).getTime()+24*60*60*1000)"></div>
                                        <div class="text-triangle-topright fs-roboto-medium fs-10 text-color-white text-uppercase" v-if="new Date() < new Date(new Date(item.thoiGian).getTime()+24*60*60*1000)">New</div>
                                    </div>

                                </div>
                                <p class="row" style="padding: 0px 15px" v-if="dsVuGiamDinh_DaChiDinh.length>=20">
                                    <button class="col button button-raised fs-roboto-medium fs-13"><a @click="loadThemVGD_DaChiDinh()">Xem thêm</a></button>
                                </p>
                            </div>
                        </f7-tab>
                        <f7-tab id="tab_hoan_thanh_hien_truong" class="ptr-content">
                            <!-- Default pull to refresh preloader-->
                            <div class="ptr-preloader">
                                <div class="preloader"></div>
                                <div class="ptr-arrow"></div>
                            </div>
                            <div class="list media-list no-margin no-hairlines" v-show="loading==false">
                                <div class="csstransition" >
                                    <div class="card" v-for="(item , index) in dsVuGiamDinh_HoanThanhHienTruong" :key="index">
                                        <a v-bind:href="'/chi-tiet-vu-giam-dinh/' + item.id + '/'" class="item-link item-content no-chevron">
                                            <!-- <div class="item-media"><img src="https://via.placeholder.com/150" width="80" height="80" style="object-fit: cover; border-radius: 5px" /></div> -->
                                            <div class="item-inner">
                                                <div class="item-title-row">
                                                    <div class="item-title fs-roboto-bold fs-15" style="text-transform: capitalize; font-weight: normal; color: #007aff">
                                                        {{item.soChungThu}}
                                                    </div>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                    <span style='color:#616161'>Khách hàng:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="getTenKhachHang(item.khachHangId)"></span>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                     <span style='color:#616161'>Tên hàng:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.tenHang"></span>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                    <span style='color:#616161'>Hạng mục:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.hangMucYeuCauGiamDinh"></span>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                    <span style='color:#616161'>Địa điểm:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.diaDiemGiamDinh"></span>
                                                </div>
                                                <div class="item-text fs-roboto-light">
                                                    <span style='color:#616161'>Thời gian:</span> <span class="no-margin" style="text-transform: capitalize;" v-html="item.thoiGianGiamDinh"></span>
                                                </div>

                                            </div>
                                        </a>
                                        <!-- <div class="triangle-topright"></div> -->
                                        <!-- <div class="text-triangle-topright fs-roboto-medium fs-10 text-color-white text-uppercase">New</div> -->
                                        <!-- <div style="position: absolute; right: 5px; bottom: 10px;"><i class="fa fa-heart-o align-self-center fs-15 text-color-pink" aria-hidden="true"></i></div> -->
                                    </div>
                                </div>
                                <p class="row" style="padding: 0px 15px" v-if="dsVuGiamDinh_HoanThanhHienTruong.length>=20">
                                    <button class="col button button-raised fs-roboto-medium fs-13"><a @click="loadThemVGD_HoanThanhHienTruong()">Xem thêm</a></button>
                                </p>
                            </div>
                        </f7-tab>

                    </f7-tabs>

                </f7-block>
            </f7-block>
        </f7-tab>

        <!-- <f7-tab id="tab-thongbao" class="page-content" style="padding-top: 50px">
            <f7-block>Đang cập nhật</f7-block>
        </f7-tab> -->

        <f7-tab id="tab-timkiem" class="page-content" style="padding-top: 50px">
            <div class="card">
                <div class="searchbar">
                    <div class="searchbar-inner">
                        <div class="searchbar-input-wrap">
                            <input type="search" @input="searchInput($event)" placeholder="Search">
                            <i class="searchbar-icon"></i>
                            <span class="input-clear-button"></span>
                        </div>
                        <span class="searchbar-disable-button if-not-aurora">Cancel</span>
                    </div>
                </div>
            </div>
          
            <div class="block" v-if="!listVGDs.length">
                <div class="block-inner" style="color: #ccc">
                  <span style="font-size: 16px">Chưa có kết quả nào được tìm thấy!</span> <br>
                   Lưu ý:<ul>
                      <li>Nhập tối thiểu 6 chữ số của Số chứng thư để tìm kiếm.</li>
                        </ul>
                </div>
              </div>

            <div class="list list-block1 media-list no-margin no-hairlines" v-show="loading==false">
                <div class="csstransition" >
                    <div class="card" v-for="(item , index) in listVGDs" :key="index">
                        <a v-bind:href="'/chi-tiet-vu-giam-dinh/' + item.id + '/'" class="item-link item-content no-chevron">
                            <!-- <div class="item-media"><img src="https://via.placeholder.com/150" width="80" height="80" style="object-fit: cover; border-radius: 5px" /></div> -->
                            <div class="item-inner">
                                <div class="item-title-row">
                                    <div class="item-title fs-roboto-bold fs-15" style="text-transform: capitalize; font-weight: normal; color: #007aff;padding:5px;">
                                        {{item.soChungThu}}
                                    </div>
                                </div>
                                <div class="item-text fs-roboto-light">
                                    Khách Hàng: <span class="no-margin" style="text-transform: capitalize;" v-html="getTenKhachHang(item.khachHangId)"></span>
                                </div>
                                <div class="item-text fs-roboto-light">
                                    Tên Hàng: <span class="no-margin" style="text-transform: capitalize;" v-html="item.tenHang"></span>
                                </div>
                                <div class="item-text fs-roboto-light">
                                    PTVT: <span class="no-margin" style="text-transform: capitalize;" v-html="item.phuongTienVanTai"></span>
                                </div>
                                <div class="item-text fs-roboto-light">
                                    Trạng Thái: <span class="no-margin" style="text-transform: capitalize;" v-html="item.trangthai"></span>
                                </div>
                                <div class="item-text fs-roboto-light">
                                    Phòng Ban: <span class="no-margin" style="text-transform: capitalize;" v-html="getTenPhongBan(item.phongBanId)"></span>
                                </div>

                            </div>
                        </a>
                        <!-- <div class="triangle-topright"></div>
                                        <div class="text-triangle-topright fs-roboto-medium fs-10 text-color-white text-uppercase">Hot</div>
                                        <div style="position: absolute; right: 5px; bottom: 10px;"><i class="fa fa-heart-o align-self-center fs-15 text-color-pink" aria-hidden="true"></i></div> -->
                    </div>

                </div>
            </div>

        </f7-tab>

        <f7-tab id="tab-them" class="page-content" style="padding-top: 50px">
            <f7-block class="no-margin no-padding">
                <div class="list media-list no-margin no-hairlines">
                    <div>
                        <div class="card">
                            <f7-link href="" class="item-link item-content no-chevron">
                                <div class="item-media"><img src="./../assets/images/icon-avartar.png" width="80" v-if="!avatar" /><img :src="avatar" width="80" v-else /></div>
                                <div class="item-inner align-self-center">
                                    <div class="item-title-row">
                                        <div v-if="isLoggedIn === true" class="item-title fs-roboto-medium fs-15 text-color-black" style="text-transform: capitalize; font-weight: normal;">{{name}}</div>
                                        <div v-if="isLoggedIn === false" class="item-title fs-roboto-medium fs-15 text-color-black" style="text-transform: capitalize; font-weight: normal;">Xin chào bạn!</div>
                                    </div>
                                    <div class="item-text fs-roboto-light display-block fs-15" v-if="isLoggedIn === true">
                                        {{this.userInfo.ten}}
                                    </div>
                                    <div class="item-text fs-roboto-light display-block fs-13" v-if="isLoggedIn === true">
                                        {{this.userInfo.chucVu}}
                                    </div>
                                </div>
                            </f7-link>
                        </div>
                    </div>
                </div>
                <div class="list media-list no-margin">
                    <div class="card">

                        <div v-if="!isLoggedIn">
                            <a href="/dang-nhap/" class="item-link item-content">
                                <div class="item-media align-self-center" style="min-width: 30px"><i class="fas fa-sign-in-alt" style="color: #007aff"  aria-hidden="true"></i></div>
                                <div class="item-inner no-margin-left">
                                    <div class="item-title-row">
                                        <div class="fs-roboto-light fs-15">Đăng nhập</div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div v-if="isLoggedIn">
                            <a class="item-link item-content" @click="logoutUser()">
                                <div class="item-media align-self-center" style="min-width: 30px"><i class="fas fa-sign-in-alt" style="color: #007aff" aria-hidden="true"></i></div>
                                <div class="item-inner no-margin-left">
                                    <div class="item-title-row">
                                        <div class="fs-roboto-light fs-15">Đăng xuất</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                          <div>
                            <a class="item-link item-content" @click="openHelp()">
                                <div class="item-media align-self-center" style="min-width: 30px"><i class="fas fa-question-circle" style="color: #007aff" aria-hidden="true"></i></div>
                                <div class="item-inner no-margin-left">
                                    <div class="item-title-row">
                                        <div class="fs-roboto-light fs-15">Trợ giúp</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <p class="block fs-roboto-light fs-15 text-align-center">Version 1.7</p>
            </f7-block>
        </f7-tab>
    </f7-tabs>

    <f7-toolbar class="no-shadow" style="height: 48px;" tabbar labels :position="isBottom ? 'bottom' : 'top'">
        <f7-link tab-link-active tab-link="#tab-home">
            <i class="fa fa-home icon" style="font-size: 24px" aria-hidden="true"></i>
            <span class="tabbar-label textformat fs-roboto-medium fs-12">Trang chủ</span>
        </f7-link>
        <f7-link ref="activeTin" tab-link="#tab-timkiem">
            <i class="fa fa-search icon" style="font-size: 20px" aria-hidden="true"></i>
            <span class="tabbar-label textformat fs-roboto-medium fs-12">Tìm kiếm</span>
        </f7-link>
        <!-- <f7-link tab-link="#tab-thongbao">
            <i class="fa fa-bell icon" style="font-size: 20px" aria-hidden="true"></i>
            <span class="tabbar-label textformat fs-roboto-medium fs-12">Thông báo</span>
        </f7-link> -->
        <f7-link tab-link="#tab-them" style="margin-top: 3px;">
            <i class="fa fa-th-large" style="font-size: 20px; padding-top: 2px;" aria-hidden="true"></i>
            <span class="tabbar-label textformat fs-roboto-medium fs-12">Thêm</span>
        </f7-link>
    </f7-toolbar>

</f7-page>
</template>

<script>
import {
    Roles,
    PhongBanKinhDoanh,
    ChiNhanh
} from "./../../global.js";
import TrangThai from "./../../common.js";
var dateFns = require('date-fns');
import NavBar from "./../components/NavBar.vue";
import ToolBar from "./../components/ToolBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import {
    EventLogin
} from "../event-login.js";
import axios from "axios";
export default {
    components: {
        NavBar,
        ToolBar
    },
    data() {
        return {
            listVGDs: [],
            filterVGD: {
                fields: {
                    id: true,
                    khachHangId: true,
                    tenHang: true,
                    phuongTienVanTai: true,
                    soChungThu: true,
                    trangthai: true,
                    phongBanId: true,
                    diaDiemGiamDinh: true,
                    hangMucYeuCauGiamDinh: true,
                    thoiGianGiamDinh: true,

                },
                where: {

                },
                order: "id DESC"
            },
            dsKhachHang: [],
            allowInfinite: true,
            lastItemIndex: 0,
            maxItems: 200,
            itemsPerLoad: 20,

            token: null,
            dsVuGiamDinh_ChuaChiDinh: [],
            dsVuGiamDinh_DaChiDinh: [],
            dsVuGiamDinh_HoanThanhHienTruong: [],

            isBottom: true,
            getDataTinChoDuyet: [],
            getDataTinDaDuyet: [],
            getDataTinAn: [],
            getDataTinTuChoi: [],
            getDataBanTin: [],
            getDataRaoVat: [],
            isLoggedIn: false,
            isAdmin: false,
            name: '',
            avatar: '',
            isShow: false,
            username: "",
            idCurrent: "",
            hiddenItem: [],
            loading: false,
            effect: 'blink',
            popupOpened_thu_ngo: false,
            popupOpened_hd: false,
            popupOpened_hd_b2: false,
            checkThuNgo: false
        };
    },

    updated() {
        // this.$nextTick(function () {
        //     if (this.isShow) {
        //         this.$refs.activeTin.$el.click();
        //     }
        // });

    },

    mounted() {
        this.load();
        EventLogin.$on("reloadPage", async e => {
            console.log("viet reloadPage");
            await this.getDSVuGiamDinh_ChuaChiDinh();
            await this.getDSVuGiamDinh_DaChiDinh();
            await this.getDSVuGiamDinh_HoanThanhHienTruong();
            console.log("viet reloadPage abc");

        });
    },
    methods: {
          openHelp(){
            this.$f7router.navigate('/tro-giup/');
        },
        openToast(text, position, closeTimeout) {
            const app = this.$f7;
            // Create center toast
            var toast = app.toast.create({
                text,
                position,
                closeTimeout
            });
            toast.open();
        },
      isDemo() {
          return apiEndPoint !== 'https://qlgd.eic.com.vn:8001/'
        },
        scrollTop() {
            var $ = this.Dom7;
            $('.page-content').scrollTop(0);
        },
        createVGD() {
            this.$f7router.navigate("/tao-moi-vu-giam-dinh/");
        },
        createFastVGD() {
            this.$f7router.navigate("/tao-nhanh-vu-giam-dinh/");
        },
        onPageBeforeRemove() {
            const self = this;
            if (self.popup) self.popup.destroy();
            if (self.popover) self.popover.destroy();
        },
        load: function (effect) {
            var self = this;
            if (self.loading) return;
            self.effect = 'blink';
            self.loading = true;
        },

        formatDate(date) {
            return dateFns.format(new Date(date), 'dd/MM/yyyy')
        },
        logoutUser() {
            localStorage.removeItem('eic_userInfo');
            localStorage.removeItem('eic_tokenKey');
            localStorage.removeItem('eic_dateExpireToken');
            // this.$f7router.navigate("/");
            this.$f7router.navigate("/", {
                clearPreviousHistory: true,
                ignoreCache: true,
                reloadAll: true
            });
            EventLogin.$emit("userLoggedOut");
        },
        openConfirm(titlename, cb) {
            const app = this.$f7;
            app.dialog.confirm("" + titlename, "Cảnh báo", cb);
        },
        checkLogin() {
            let userInfo = JSON.parse(localStorage.getItem("conec_userInfo"));

            if (userInfo) {
                this.$f7router.navigate("/chon-danh-muc-dang-tin/");
            } else {
                this.openConfirm("Vui lòng đăng nhập!", () => {
                    this.$f7router.navigate("/dang-nhap/");
                });
            }
        },
        setAxiosGlobal(token) {
            axios.defaults.headers = {
                'Authorization': token
            };
            console.log('Add axios header Authorization global');
        },
        async kiemTraDangNhap() {
            this.userItem = JSON.parse(localStorage.getItem('eic_userInfo'));
            this.token = localStorage.getItem('eic_tokenKey');
            this.setAxiosGlobal(this.token);
            if (this.userItem) {
                this.isLoggedIn = true;
                this.name = this.userItem.name;
                this.username = this.userItem.username;
            } else {
                this.isLoggedIn = false;
            }
        },
        changeStatus() {
            this.isLoggedIn = !this.isLoggedIn;
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        CreateFilterByPhongBan(filter, phongBanId, roleId, dsphongBanId, userId) {
            Object.assign({}, filter);
            let filter1 = Object.assign({}, filter); //kế thừa toàn bộ skip, limit, order ... từ filter cũ
            filter1.where = {}; //xóa giá trị where

            if (phongBanId > 0) {
                filter1.where['and'] = [];
                filter1.where['and'].push(filter.where); //bỏ giá trị filter cũ vào 'and'

                // if (roleId !== Roles.Admin || roleId !== Roles.GiamDoc)

                if (filter.where["soChungThu"] !== undefined && filter.where["soChungThu"] !== null) {
                    let filter2 = filter.where.soChungThu
                    filter1.where['and'].push({
                        "soChungThu": filter2
                    });
                }
                if (filter.where["phongBanId"] !== undefined && filter.where["phongBanId"] !== null) {
                    let filter3 = filter.where.phongBanId
                    filter1.where['and'].push({
                        "phongBanId": filter3
                    });
                }
                if (filter.where["khachHangId"] !== undefined && filter.where["khachHangId"] !== null) {
                    let filter4 = filter.where.khachHangId
                    filter1.where['and'].push({
                        "khachHangId": filter4
                    });
                }

                if (roleId === Roles.Admin ||
                    roleId === Roles.GiamDoc ||
                    roleId === Roles.KeToan ||
                    roleId === Roles.KinhDoanh ||
                    roleId === Roles.GiamDoc_Lab) {

                } else if (roleId === Roles.TruongPhong && PhongBanKinhDoanh.ListPhongBan.includes(phongBanId)) {

                } else if (roleId === Roles.TruongPhong && phongBanId === 10) {

                } else if (phongBanId === ChiNhanh.CNMienBac) {

                    let filterPhongban = {
                        or: []
                    }
                    ChiNhanh.ListMienBac.forEach(element => {
                        filterPhongban.or.push({
                            "phongBanId": element
                        })
                    });

                    filter1.where['and'].push(filterPhongban)
                } else if (phongBanId === ChiNhanh.CNMienTrung) {

                    let filterPhongban = {
                        or: []
                    }
                    ChiNhanh.ListMienTrung.forEach(element => {
                        filterPhongban.or.push({
                            "phongBanId": element
                        })
                    });

                    filter1.where['and'].push(filterPhongban)

                } else if (phongBanId === ChiNhanh.CNMienNam) {
                    let filterPhongban = {
                        or: []
                    }

                    ChiNhanh.ListMienNam.forEach(element => {
                        filterPhongban.or.push({
                            "phongBanId": element
                        })
                    });

                    filter1.where['and'].push(filterPhongban)

                } else if (dsphongBanId !== undefined && dsphongBanId !== null) { // filter theo nhieu phong ban

                    let filterPhongban = {
                        or: []
                    };
                    let dsphongban = [];

                    dsphongban.push(phongBanId);

                    dsphongBanId.forEach(idpb => {
                        dsphongban.push(idpb)
                    })

                    dsphongban.forEach(element => {
                        filterPhongban.or.push({
                            "phongBanId": element
                        })
                    });

                    if (roleId === Roles.CS || roleId === Roles.CSCN) {
                        filterPhongban.or.push({
                            "nguoiYeuCauUserId": userId
                        })
                    }

                    filter1.where['and'].push(filterPhongban)
                    // logger.info("filterPhongban", JSON.stringify(filterPhongban))
                    // logger.info("filter1", JSON.stringify(filter1))
                } else {
                    //      filter.where["phongBanId"] = phongBanId;
                    //  filter.where = [{and : phongBanId = phongBanId, or: filter.where["or"]} ];
                    if (roleId === Roles.CS || roleId === Roles.CSCN) {
                        let filterPhongban = {
                            or: []
                        };
                        filterPhongban.or.push({
                            "nguoiYeuCauUserId": userId
                        })
                        filterPhongban.or.push({
                            "phongBanId": phongBanId
                        }) // fix tạm nhìn thấy yc của user đã tạo
                        filter1.where['and'].push(filterPhongban)
                    }
                    filter1.where['and'].push({
                        'phongBanId': phongBanId
                    })
                }

                // logger.info("GetCount", JSON.stringify(filter1))

                return filter1;
            }
        },
        async getDSVuGiamDinh_ChuaChiDinh() {
            let url = apiEndPoint + "api/VuGiamDinhs";
            let filter = {
                fields: {
                    id: true,
                    khachHangId: true,
                    tenHang: true,
                    phuongTienVanTai: true,
                    soChungThu: true,
                    trangthai: true,
                    phongBanId: true,
                    thoiGian: true,
                     diaDiemGiamDinh: true,
                    hangMucYeuCauGiamDinh: true,
                    thoiGianGiamDinh: true,
                },
                where: {
                    trangthai: TrangThai.VGD_NEW_020
                },
                limit: 20,
                order: "lastModified desc"
            };
            let filter2 = this.CreateFilterByPhongBan(filter, this.userInfo.phongBanId, this.userInfo.roleId, this.userInfo.dsphongBanId, this.userInfo.userId)
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter2
                    }
                });
                this.dsVuGiamDinh_ChuaChiDinh = res.data;
                this.loading = false;

            } catch (error) {
                console.log(error);
            }
        },
        async getDSVuGiamDinh_DaChiDinh() {
            let url = apiEndPoint + "api/VuGiamDinhs";
            let filter = {
                fields: {
                    id: true,
                    khachHangId: true,
                    tenHang: true,
                    phuongTienVanTai: true,
                    soChungThu: true,
                    trangthai: true,
                    phongBanId: true,
                    thoiGian: true,
                     diaDiemGiamDinh: true,
                    hangMucYeuCauGiamDinh: true,
                    thoiGianGiamDinh: true,
                },
                skip: 0,
                where: {
                    or: [{
                            trangthai: TrangThai.VGD_Cho_GDV_ChapNhap_021
                        },
                        {
                            trangthai: TrangThai.VGD_XuLyHienTruong_022
                        },
                        {
                            trangthai: TrangThai.VGD_DuocCapNhat_023
                        },
                    ]

                },
                limit: 20,
                order: "lastModified desc"
            };
            let filter2 = this.CreateFilterByPhongBan(filter, this.userInfo.phongBanId, this.userInfo.roleId, this.userInfo.dsphongBanId, this.userInfo.userId)
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter2
                    }
                });
                this.dsVuGiamDinh_DaChiDinh = res.data;
                this.loading = false;

            } catch (error) {
                console.log(error);
            }
        },
        async getDSVuGiamDinh_HoanThanhHienTruong() {
            let url = apiEndPoint + "api/VuGiamDinhs";
            let filter = {
                fields: {
                    id: true,
                    khachHangId: true,
                    tenHang: true,
                    phuongTienVanTai: true,
                    soChungThu: true,
                    trangthai: true,
                    phongBanId: true,
                     diaDiemGiamDinh: true,
                    hangMucYeuCauGiamDinh: true,
                    thoiGianGiamDinh: true,

                },
                where: {
                    or: [{
                            trangthai: TrangThai.VGD_HoanThanhHienTruong_025
                        },
                        {
                            trangthai: TrangThai.VGD_CoTheXuatBaoCao_028
                        }
                    ]
                },
                limit: 20,
                order: "lastModified desc"
            };
            let filter2 = this.CreateFilterByPhongBan(filter, this.userInfo.phongBanId, this.userInfo.roleId, this.userInfo.dsphongBanId, this.userInfo.userId)
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter2
                    }
                });
                this.dsVuGiamDinh_HoanThanhHienTruong = res.data;
                this.loading = false;

            } catch (error) {
                console.log(error);
            }
        },
        async loadThemVGD_ChuaChiDinh() {
            let url = apiEndPoint + "api/VuGiamDinhs";
            let filter = {
                fields: {
                    id: true,
                    khachHangId: true,
                    tenHang: true,
                    phuongTienVanTai: true,
                    soChungThu: true,
                    trangthai: true,
                    phongBanId: true,
                     diaDiemGiamDinh: true,
                    hangMucYeuCauGiamDinh: true,
                    thoiGianGiamDinh: true,

                },
                skip: this.dsVuGiamDinh_ChuaChiDinh.length,
                where: {
                    trangthai: TrangThai.VGD_NEW_020
                },
                limit: 20,
                order: "lastModified desc"
            };
            let filter2 = this.CreateFilterByPhongBan(filter, this.userInfo.phongBanId, this.userInfo.roleId, this.userInfo.dsphongBanId, this.userInfo.userId)
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter2
                    }
                });
                if (!res.data.length) {
                    this.openToast('Đã hết dữ liệu!', 'center', 2000);
                    return;
                }
                this.dsVuGiamDinh_ChuaChiDinh = [...this.dsVuGiamDinh_ChuaChiDinh, ...res.data];
                this.loading = false;

            } catch (error) {
                console.log(error);
            }

        },
        async loadThemVGD_DaChiDinh() {
            let url = apiEndPoint + "api/VuGiamDinhs";
            let filter = {
                fields: {
                    id: true,
                    khachHangId: true,
                    tenHang: true,
                    phuongTienVanTai: true,
                    soChungThu: true,
                    trangthai: true,
                    phongBanId: true,
                     diaDiemGiamDinh: true,
                    hangMucYeuCauGiamDinh: true,
                    thoiGianGiamDinh: true,

                },
                skip: this.dsVuGiamDinh_DaChiDinh.length,
                where: {
                    or: [{
                            trangthai: TrangThai.VGD_Cho_GDV_ChapNhap_021
                        },
                        {
                            trangthai: TrangThai.VGD_XuLyHienTruong_022
                        },
                        {
                            trangthai: TrangThai.VGD_DuocCapNhat_023
                        },
                    ]

                },
                limit: 20,
                order: "lastModified desc"
            };
            let filter2 = this.CreateFilterByPhongBan(filter, this.userInfo.phongBanId, this.userInfo.roleId, this.userInfo.dsphongBanId, this.userInfo.userId)
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter2
                    }
                });
                if (!res.data.length) {
                    this.openToast('Đã hết dữ liệu!', 'center', 2000);
                    return;
                }
                this.dsVuGiamDinh_DaChiDinh = [...this.dsVuGiamDinh_DaChiDinh, ...res.data];
                this.loading = false;
                console.log('viet ',this.dsVuGiamDinh_DaChiDinh)

            } catch (error) {
                console.log(error);
            }

        },
        async loadThemVGD_HoanThanhHienTruong() {
            let url = apiEndPoint + "api/VuGiamDinhs";
            let filter = {
                fields: {
                    id: true,
                    khachHangId: true,
                    tenHang: true,
                    phuongTienVanTai: true,
                    soChungThu: true,
                    trangthai: true,
                    phongBanId: true,
                     diaDiemGiamDinh: true,
                    hangMucYeuCauGiamDinh: true,
                    thoiGianGiamDinh: true,

                },
                skip: this.dsVuGiamDinh_HoanThanhHienTruong.length,
                where: {
                    or: [{
                            trangthai: TrangThai.VGD_HoanThanhHienTruong_025
                        },
                        {
                            trangthai: TrangThai.VGD_CoTheXuatBaoCao_028
                        }
                    ]

                },
                limit: 20,
                order: "lastModified desc"
            };
            let filter2 = this.CreateFilterByPhongBan(filter, this.userInfo.phongBanId, this.userInfo.roleId, this.userInfo.dsphongBanId, this.userInfo.userId)
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter2
                    }
                });
                if (!res.data.length) {
                    this.openToast('Đã hết dữ liệu!', 'center', 2000);
                    return;
                }
                this.dsVuGiamDinh_HoanThanhHienTruong = [...this.dsVuGiamDinh_HoanThanhHienTruong, ...res.data];
                this.loading = false;
                console.log('viet this.dsVuGiamDinh_HoanThanhHienTruong',this.dsVuGiamDinh_HoanThanhHienTruong)

            } catch (error) {
                console.log(error);
            }

        },
        getImages(item) {
            return item.images && item.images[0];
        },
        getMucDang(item) {
            return item.nameMucDang && item.nameMucDang;
        },
        onPageInit() {
            // begin - Xử lý Pull to refresh
            const app = this.$f7;
            var ptr = app.ptr.create('.ptr-content');
            ptr.on('refresh', async e => {
                await this.getDSVuGiamDinh_ChuaChiDinh();
                await this.getDSVuGiamDinh_DaChiDinh();
                await this.getDSVuGiamDinh_HoanThanhHienTruong();
                ptr.done();
            });
            // end - Xử lý Pull to refresh

            // begin - Xử lý Infinite Scroll
            // const app = this.$f7;
            var infiniteScroll = app.infiniteScroll.create('.infinite-scroll-content');
            var $ = this.Dom7;
            $('.infinite-scroll-content').on('infinite', () => {
                console.log('viet infinite scroll')
            });
            // Exit, if loading in progress
            // console.log('viet infiniteSroll init',infiniteScroll);
            // infiniteScroll.on('infinite', async e => {
            //     console.log('viet infinite scroll');

            // });
            // end - Xử lý Infinite Scroll
        },
        async getDSNhanVien() {
            let url = apiEndPoint + "api/AppUsers";
            let filter = {
                fields: {
                    "id": true,
                    "ten": true,
                    "phongBanId": true,
                    "phongBanQuanLyId": true,
                    "email":true,
                    "roleId": true

                },
                where: {
                    isActive: true,
                    // roleId: 5, // là Giám Định Viên
                    // phongBanId: "",
                }
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.dsNhanVien = res.data;
                localStorage.setItem("eic_dsNhanVien", JSON.stringify(this.dsNhanVien));

            } catch (error) {
                console.log(error);
            }
        },
        getTenKhachHang(id) {
            let found = this.dsKhachHang.find(e => e.id == id);
            if (!found) return "";
            return found.hoTen;
        },
        async getDSHopDong() {
            let url = apiEndPoint + "api/HopDongs";
            let filter = {
                fields: {
                    id: true,
                    khachHangId: true,
                    soHopDong: true,
                    ngayKy: true,
                    ngayHetHieuLuc: true
                },
                order: "ngayKy desc"
            };
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter
                    }
                });
                this.dsHopDong = res.data;
                localStorage.setItem("eic_dsHopDong", JSON.stringify(this.dsHopDong));

            } catch (error) {
                console.log(error);
            }
        },
        getTenPhongBan(id) {
            let found = this.dsPhongBan.find(e => e.id == id);
            if (!found) return "";
            return found.ten;
        },
        async searchInput($event) {
            let text = $event.target.value;
            await this.loadVGDTheoSoChungThu(text);
        },
        async loadVGDTheoSoChungThu(text) {
            if (!text) {
                delete this.filterVGD.where.soChungThu;
                return;
            } else if (text.length < 6) {
                return;
            } else {
                this.filterVGD.where.soChungThu = {
                    regexp: text
                };
            }

            let url = apiEndPoint + "api/VuGiamDinhs";
            let filter2 = this.CreateFilterByPhongBan(this.filterVGD, this.userInfo.phongBanId, this.userInfo.roleId, this.userInfo.dsphongBanId, this.userInfo.userId)
            try {
                let res = await axios.get(url, {
                    params: {
                        filter: filter2
                    }
                });
                this.listVGDs = res.data;

            } catch (error) {
                console.log(error);
            }
        }
    },
    async created() {
        try{
                 
            this.userInfo = JSON.parse(localStorage.getItem('eic_userInfo'));
                    this.dsKhachHang = JSON.parse(localStorage.getItem('eic_dsKhachHang'));
                    this.dsPhongBan = JSON.parse(localStorage.getItem('eic_dsPhongBan'));
                    await this.kiemTraDangNhap();
                    await this.getDSVuGiamDinh_ChuaChiDinh();
                    await this.getDSVuGiamDinh_DaChiDinh();
                    await this.getDSVuGiamDinh_HoanThanhHienTruong();
                    this.getDSHopDong();
                    this.getDSNhanVien();
            //begin - Tabbar scrollable with swipeable tab
                const app = this.$f7;
                var mySwiperTabbar = app.swiper.get('.swiper-container-2');
                var mySwiperTab = app.swiper.get('.swiper-container');
                mySwiperTab.on('slideChange', ()=> {
                    mySwiperTabbar.slideTo(mySwiperTab.activeIndex);
                    this.scrollTop();

                });
            //end - Tabbar scrollable with swipeable tab

                
        }catch(err){
            console.log('viet home.vue', err);
        }
      

    }
}
</script>
