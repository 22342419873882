<template>
<f7-page class="style-page" style="background-color: #fff !important">
    <f7-navbar>
        <f7-nav-left back-link="Back"></f7-nav-left>
        <f7-nav-title class="fs-roboto-medium">Thông tin cá nhân</f7-nav-title>
        <f7-nav-right>
            <f7-link></f7-link>
        </f7-nav-right>
    </f7-navbar>
    <f7-block class="no-margin no-padding">
        <div class="list media-list no-margin no-hairlines">
            <ul>
                <li>
                    <f7-link href="/setting-profile/" class="item-content no-chevron">
                        <div class="item-media"><img  src="./../assets/images/icon-avartar.png" width="80" v-if="!avatar"/><img :src="avatar" width="80" v-else/></div>
                        <div class="item-inner align-self-center">
                            <div class="item-title-row">
                                <div class="item-title fs-roboto-medium fs-15 text-color-black" style="text-transform: capitalize; font-weight: normal;">{{name}}</div>
                            </div>
                            <!-- <div class="item-subtitle fs-roboto-light fs-13 text-color-black" style="margin-bottom: 10px">Nhân viên Developer</div> -->
                            <div class="item-text fs-roboto-light display-block fs-13">
                                <!-- <f7-link style="margin-right: 10px" href="/setting-profile/">
                                    <div class="chip bg-color-orange" style="height: 25px;">
                                        <div class="chip-label fs-roboto-light fs-13">Chỉnh sửa trang cá nhân</div>
                                    </div>
                                </f7-link> -->
                                Chỉnh sửa trang cá nhân
                            </div>
                        </div>
                    </f7-link>
                </li>
            </ul>
        </div>
        <f7-block class="no-margin no-padding">
            <div class="list no-margin">
                <ul>
                    <li>
                        <div class="item-content">
                            <div class="item-media" style="min-width: 30px"><img src="./../assets/images/calendar.png" alt="" width="20" /></div>
                            <div class="item-inner" style="margin-left: 0px">
                                <div class="item-title fs-roboto-light fs-15 text-color-black"><span style="color: #999">Sinh năm:</span> {{birthdate}}</div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="item-content">
                            <div class="item-media" style="min-width: 30px"><i class="fa fa-user" style="font-size: 22px; color: #ccc;" aria-hidden="true"></i></div>
                            <div class="item-inner" style="margin-left: 0px">
                                <div class="item-title fs-roboto-light fs-15 text-color-black"><span style="color: #999">Giới tính:</span> {{gioiTinh}}</div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="item-content">
                            <div class="item-media" style="min-width: 30px"><i class="fa fa-map-marker" aria-hidden="true" style="font-size: 22px; color: #ccc;"></i></div>
                            <div class="item-inner" style="margin-left: 0px">
                                <div class="fs-roboto-light fs-15 text-color-black"><span style="color: #999">Địa chỉ:</span> <span v-if="address">{{address +','}} </span><span v-if="address_px">{{address_px +','}} </span><span v-if="address_qh">{{address_qh +','}} </span><span v-if="address_tp">{{address_tp}}</span></div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="item-content">
                            <div class="item-media" style="min-width: 30px"><i class="fa fa-phone" aria-hidden="true" style="font-size: 22px; color: #ccc;"></i></div>
                            <div class="item-inner" style="margin-left: 0px">
                                <div class="item-title fs-roboto-light fs-15 text-color-black">
                                    <span style="color: #999">Điện thoại:</span> {{username}}
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="item-content">
                            <div class="item-media" style="min-width: 30px"><i class="fa fa-envelope" style="font-size: 18px; color: #ccc;" aria-hidden="true"></i></div>
                            <div class="item-inner" style="margin-left: 0px">
                                <div class="item-title fs-roboto-light fs-15 text-color-black"><span style="color: #999">Email:</span> {{email}}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </f7-block>
    </f7-block>
</f7-page>
</template>

<script>
var dateFns = require('date-fns');
import NavBar from "./../components/NavBar.vue";
import ToolBar from "./../components/ToolBar.vue";
import apiEndPoint from "./../../apiEndpoint.js";
import {
    EventLogin
} from "../event-login.js";
import axios from "axios";
export default {
    components: {
        NavBar,
        ToolBar
    },
    data() {
        return {
            isBottom: true,
            isLoggedIn: false,
            isAdmin: false,
            name: '',
            avatar:'',
            isShow: false,
            birthdate: "",
            address_tp: "",
            address_qh: "",
            address_px: "",
            address: "",
            gioiTinh: "",
            username: "",
            email:"",
        };
    },
    mounted() {
        if (this.$userInfoGlobal) {
            if (this.$userInfoGlobal.role == 'admin')
                this.isAdmin = true;
            console.log(this.$userInfoGlobal)
            this.isLoggedIn = true;
            this.name = this.$userInfoGlobal.name;
            this.avatar = this.$userInfoGlobal.avatar;
        }
        EventLogin.$on("userLoggedIn", userInfo => {

            this.isLoggedIn = true;
            console.log('viet this.isLoggedIn', this.isLoggedIn);
            this.name = userInfo.name;
            this.avatar = userInfo.avatar;
            console.log('viet this.name', this.name);

        });
        EventLogin.$on("updateProfile", userInfo => {
            this.isLoggedIn = true;
            this.name = userInfo.name;
            this.avatar = userInfo.avatar;
        });
        EventLogin.$on("userLoggedOut", e => {
            this.isLoggedIn = false;
            this.isAdmin = false;
            this.name = "";
            this.avatar = "";
            this.$userInfoGlobal = null;
        });

    },
    methods: {
        formatDate(date) {
            return dateFns.format(new Date(date), 'dd/MM/yyyy')
        },
        logoutUser() {
            localStorage.removeItem('conec_userInfo');
            localStorage.removeItem('conec_tokenKey');
            EventLogin.$emit("userLoggedOut");
        },
        openConfirm(titlename, cb) {
            const app = this.$f7;
            app.dialog.confirm("" + titlename, "Cảnh báo", cb);
        },
        async kiemTraDangNhap() {
            this.userItem = JSON.parse(localStorage.getItem('conec_userInfo'));
            console.log('viet this.userItem', this.userItem);
            if (this.userItem) {
                this.isLoggedIn = true;
                this.name = this.userItem.name;
                this.avatar = this.userItem.avatar;
                this.birthdate = this.userItem.birthdate;
                this.address_tp = this.userItem.address_tp;
                this.address_qh = this.userItem.address_qh;
                this.address_px = this.userItem.address_px;
                this.address = this.userItem.address;
                this.gioiTinh = this.userItem.gioiTinh;
                this.username = this.userItem.username;
                this.email = this.userItem.email;
            } else {
                this.isLoggedIn = false;
            }
        },

    },
    async created() {
        await this.kiemTraDangNhap();
    }
};
</script>
