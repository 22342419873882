
import Home from '../pages/home.vue';
import HomeLab from '../pages/home-lab.vue';
import ChiTietVuGiamDinh from '../pages/chi-tiet-vu-giam-dinh.vue';
import ChiTietNiem from '../pages/chi-tiet-niem.vue';
import ChiTietYeuCauPhanTich from '../pages/chi-tiet-yeu-cau-phan-tich.vue';
import ChiTietYeuCauPhanTichNew from '../pages/chi-tiet-yeu-cau-phan-tich-new.vue';
import ChiTietVuPhanTich from '../pages/chi-tiet-vu-phan-tich.vue';
import ChiTietVuPhanTichNew from '../pages/chi-tiet-vu-phan-tich-new.vue';
import DangNhap from '../pages/dang-nhap.vue';
import DangKy from '../pages/dang-ky.vue';
import Profile from '../pages/profile.vue';
import TaoMoiVuGiamDinh from '../pages/tao-moi-vu-giam-dinh.vue';
import TaoNhanhVuGiamDinh from '../pages/tao-nhanh-vu-giam-dinh.vue';
import ChiTietCongViecGDV from '../pages/chi-tiet-cong-viec-gdv.vue';
import ThemCongViecGDV from '../pages/them-cong-viec-gdv.vue';
import ThemChamCongGDV from '../pages/them-cham-cong-gdv.vue';
import ThemChamCongPTV from '../pages/them-cham-cong-ptv.vue';
import ThemCheckinGDV from '../pages/them-checkin-gdv.vue';
import ThemCheckoutGDV from '../pages/them-checkout-gdv.vue';
import XemChiTietYCPT from '../pages/xem-chi-tiet-yeu-cau-phan-tich.vue';
import XemChiTietYCPTNew from '../pages/xem-chi-tiet-yeu-cau-phan-tich-new.vue';
import XemChiTietVPT from '../pages/xem-chi-tiet-vu-phan-tich.vue';
import XemChiTietVPTNew from '../pages/xem-chi-tiet-vu-phan-tich-new.vue';
import TaoMoiYCPT from '../pages/tao-moi-yeu-cau-phan-tich.vue';
import TaoMoiYCPTNew from '../pages/tao-moi-yeu-cau-phan-tich-new.vue';
import TaoMoiYCPTLab from '../pages/tao-moi-yeu-cau-phan-tich-tu-lab.vue';
import TaoMoiYCPTLabNew from '../pages/tao-moi-yeu-cau-phan-tich-tu-lab-new.vue';
import ThemMauYCPT from '../pages/them-mau-yeu-cau-phan-tich.vue';
import ThemMauYCPTNew from '../pages/them-mau-yeu-cau-phan-tich-new.vue';
import ChonDanhSachNiem from '../pages/chon-danh-sach-niem.vue';
import TroGiup from '../pages/tro-giup.vue';
import HomeGDV from '../pages/home-gdv.vue';
import HomePTV from '../pages/home-ptv.vue';
import ViewDanhSachYCPTDaGui from '../pages/view-ds-ycpt-da-gui.vue';

var routes = [
  {
    path: '/',
    component: DangNhap,

  },
  {
    path: '/home/',
    component: Home,
    // keepAlive: true, //dùng fix lỗi này: https://forum.framework7.io/t/page-in-history-reloads-when-using-the-back-button/8306

  },
  {
    path: '/view-ds-ycpt-da-gui/',
    component: ViewDanhSachYCPTDaGui,

  },
  {
    path: '/home-gdv/',
    component: HomeGDV,

  },
  {
    path: '/home-ptv/',
    component: HomePTV,

  },
  {
    path: '/home-lab/',
    component: HomeLab,

  },
  {
    path: '/tao-moi-vu-giam-dinh/',
    component: TaoMoiVuGiamDinh,

  },
  {
    path: '/tao-nhanh-vu-giam-dinh/',
    component: TaoNhanhVuGiamDinh,

  },
  {
    path: '/chi-tiet-vu-giam-dinh/:id/',
    component: ChiTietVuGiamDinh,

  },
  {
    path: '/chi-tiet-niem/:id/',
    component: ChiTietNiem,

  },
  {
    path: '/chi-tiet-yeu-cau-phan-tich/:id/',
    component: ChiTietYeuCauPhanTich,

  },
  {
    path: '/chi-tiet-yeu-cau-phan-tich-new/:id/',
    component: ChiTietYeuCauPhanTichNew,

  },
  {
    path: '/chi-tiet-vu-phan-tich/:id/',
    component: ChiTietVuPhanTich,

  },
  {
    path: '/chi-tiet-vu-phan-tich-new/:id/',
    component: ChiTietVuPhanTichNew,

  },
  {
    path: '/chi-tiet-cong-viec-gdv/',
    component: ChiTietCongViecGDV,

  },
  {
    path: '/xem-chi-tiet-yeu-cau-phan-tich/',
    component: XemChiTietYCPT,

  },
  {
    path: '/xem-chi-tiet-yeu-cau-phan-tich-new/',
    component: XemChiTietYCPTNew,

  },
  {
    path: '/xem-chi-tiet-vu-phan-tich/',
    component: XemChiTietVPT,

  },
  {
    path: '/xem-chi-tiet-vu-phan-tich-new/',
    component: XemChiTietVPTNew,

  },
  {
    path: '/tao-moi-yeu-cau-phan-tich/',
    component: TaoMoiYCPT,


  },
  {
    path: '/tao-moi-yeu-cau-phan-tich-new/',
    component: TaoMoiYCPTNew,

  },
  {
    path: '/chon-danh-sach-niem/',
    component: ChonDanhSachNiem,

  },
  {
    path: '/tao-moi-yeu-cau-phan-tich-tu-lab/',
    component: TaoMoiYCPTLab,

  },
  {
    path: '/tao-moi-yeu-cau-phan-tich-tu-lab-new/',
    component: TaoMoiYCPTLabNew,

  },
  {
    path: '/them-mau-yeu-cau-phan-tich/',
    component: ThemMauYCPT,

  },
  {
    path: '/them-mau-yeu-cau-phan-tich-new/',
    component: ThemMauYCPTNew,

  },
  {
    path: '/them-cong-viec-gdv/',
    component: ThemCongViecGDV,

  },
  {
    path: '/them-cham-cong-gdv/',
    component: ThemChamCongGDV,

  },
  {
    path: '/them-cham-cong-ptv/',
    component: ThemChamCongPTV,

  },
  {
    path: '/them-checkin-gdv/',
    component: ThemCheckinGDV,

  },
  {
    path: '/them-checkout-gdv/',
    component: ThemCheckoutGDV,

  },
  {
    path: '/tro-giup/',
    component: TroGiup,
  }, 
  {
    path: '/dang-nhap/',
    component: DangNhap,
  }, 
  {
    path: '/dang-ky/',
    component: DangKy,

  },
  {
    path: '/profile/',
    component: Profile,

  },
  

];

export default routes;
